import { deleteUserResponseProps, DeleteUserState} from './types'
import { createSlice } from '@reduxjs/toolkit'
import { deleteUser } from './userService';

export const initialState: DeleteUserState = {
    data: {} as deleteUserResponseProps,
    status: "loading",
    error: {}
}

export const deleteUserSlice = createSlice({
    name: 'deleteUser',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(deleteUser.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(deleteUser.fulfilled, (state, action) => {
            if (action.payload) {
                state.data = action.payload.data;
                state.status = action.payload.status;
                state.error = action.payload.error
            }
        })
        .addCase(deleteUser.rejected, (state, action) => {
            state.status = 'error';
            state.error = action.error
        })

    }
})

export const DeleteUserReducer = deleteUserSlice.reducer