import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import MainLayout from "src/components/Layouts/MainLayout";
import { NormalText } from "src/components/styled/text.styled";
import { Box, Stack } from "@mui/material";
import { Button } from "src/components/styled/button.styled";
import { IconCircleCheck } from "@tabler/icons";

const ActivatedPage = () => {
  const { status } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (status) {
      console.log("Status", status);
    }
  }, []);
  return (
    <MainLayout>
      <Box
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        height={"100vh"}
        textAlign={"center"}
        display={"flex"}
        sx={{ background: "#fff" }}
      >
        <Stack
          direction={"column"}
          display={"relative"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <IconCircleCheck size={"150px"} color={"#FFC535"} stroke={3} />
          <NormalText fontWeight="bold" fontSize="20px" margin="0 0 10px 0">
            Yeay, Akun kamu sudah aktif
          </NormalText>
          <NormalText
            color={"#FFC535"}
            fontWeight="bold"
            fontSize={"14px"}
            wordBreak={"break-word"}
            onClick={() => navigate("/login")}
            isPressable
          >
            Login Sekarang
          </NormalText>
        </Stack>
      </Box>
    </MainLayout>
  );
};

export default ActivatedPage;
