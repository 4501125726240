import React from 'react';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import { useNavigate, useParams } from 'react-router-dom';
import Card from 'src/components/Cards/card';
import { NormalText } from 'src/components/styled/text.styled';
import { Button } from 'src/components/styled/button.styled';
import TextField from 'src/components/Inputs/TextField';
import { Stack, Box, Grid } from '@mui/material';
import {
  ListStoreData,
  showDetailStore,
  ProductStoresPropsViewPage,
} from 'src/redux/storelist';
import useRedux from 'src/redux/useRedux';
import QRCode from 'qrcode.react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

const DetailStorePage = () => {
  let QRcode = require('qrcode');
  const navigate = useNavigate();
  const { thunkDispatch } = useRedux();
  const { id } = useParams();
  const [detail, setDetail] = React.useState<ListStoreData>();
  const [dataQR, setDataQR] = React.useState<Array<ProductStoresPropsViewPage>>(
    []
  );
  const qrCodeOpts = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    quality: 1,
    width: 220,
    margin: 5,
  };

  React.useEffect(() => {
    thunkDispatch(showDetailStore(id ? parseInt(id) : 0))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') setDetail(res.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  React.useEffect(() => {
    detail?.product_stores?.map((url: any) => {
      url.product_store_qr_code_alias_shop = `${process.env.REACT_APP_CUSTOMER_URL}/${detail.store_alias}/home/${url.product_id}`;
      
      if (url && url.product_store_qr_code_alias) {
        generateQR(url);
      } 
      if (url && url.product_store_qr_code_alias_shop) {
        generateQRShop(url);
      }
    });
  }, [detail]);

  const generateQR = (data: any) => {
    if (data) {
      QRcode.toDataURL(
        data?.product_store_qr_code,
        qrCodeOpts,
        function (err: any, url: any) {          
          let dataUrl = {
            product_name: data.product_name,
            product_store_qr_code: url.replace(
              /^data:image\/(png|jpg|jpeg);base64,/,
              ''
            ),
          };
          setDataQR((prevState: any) => [...prevState, dataUrl]);
        }
      );
    } else {
      console.log('data tidak ditemukan');
    }
  };
  
  const generateQRShop = (data: any) => {
    if (data) {
      QRcode.toDataURL(
        data?.product_store_qr_code_alias_shop,
        qrCodeOpts,
        function (err: any, url: any) {          
          let dataUrl = {
            product_name: data.product_name,
            product_store_qr_code_alias_shop: url.replace(
              /^data:image\/(png|jpg|jpeg);base64,/,
              ''
            ),
          };
          setDataQR((prevState: any) => [...prevState, dataUrl]);
        }
      );
    } else {
      console.log('data tidak ditemukan');
    }
  };

  const downloadQR = () => {
    var canvas = document.querySelector(
      '#qrcode > canvas'
    ) as HTMLCanvasElement;
    console.log(canvas);
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `QR-${
      detail && detail.store_name ? detail.store_name : 'Code'
    }.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const downloadQRProducts = () => {
    let zip = new JSZip();
    let img = zip.folder(
      `QR Products - ${detail?.store_name ? detail.store_name : ''}`
    );

    dataQR.forEach((x) => {
      if (x.product_store_qr_code) {
        img?.file(`${x.product_name}.jpg`, x.product_store_qr_code, {
          base64: true,
        });
      } else {
        console.log('data tidak tersedia');
      }
    });

    zip.generateAsync({ type: 'blob' }).then(function (content: any) {
      saveAs(
        content,
        `QR Products - ${detail?.store_name ? detail.store_name : ''}.zip`
      );
    });
  };

  const downloadQRProductsShop = () => {
    let zip = new JSZip();
    let img = zip.folder(
      `QR shop Products - ${detail?.store_name ? detail.store_name : ''}`
    );

    dataQR.forEach((x) => {            
      if (x.product_store_qr_code_alias_shop) {
        img?.file(`${x.product_name}.jpg`, x.product_store_qr_code_alias_shop, {
          base64: true,
        });
      } else {
        console.log('data tidak tersedia');
      }
    });

    zip.generateAsync({ type: 'blob' }).then(function (content: any) {
      saveAs(
        content,
        `QR shop Products - ${detail?.store_name ? detail.store_name : ''}.zip`
      );
    });
  };

  const copyURL = (url: string) => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard
        .writeText(url ?? 'AVSCCCC')
        .then(() => {
          toast.success(`Berhasil menyalin URL Toko`);
        })
        .catch(() => {
          toast.error(`Gagal menyalin URL Toko!`);
        });
    }
  };

  return (
    <MerchantLayout>
      <Card>
        <Stack
          direction={'row'}
          justifyContent="space-between"
          alignItems="center"
        >
          <NormalText fontWeight="bold" fontSize="20px" margin="0 0 20px 0">
            Detail Toko
          </NormalText>
          <a
            href={
              detail && detail.store_queue_url ? detail.store_queue_url : ''
            }
            target="_blank"
          >
            <Button width="180px" variant={'primary'}>
              Buka Queue
            </Button>
          </a>
        </Stack>
        <TextField
          label={'Nama Toko'}
          isRequired
          value={detail && detail.store_name ? detail.store_name : ''}
          disabled
        ></TextField>
        <TextField
          label={'Alamat'}
          isRequired
          readonly
          value={detail && detail.address ? detail.address : ''}
          disabled
        ></TextField>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label={'Provinsi'}
              isRequired
              readonly
              value={
                detail && detail.province.province_name
                  ? detail.province.province_name
                  : ''
              }
              disabled
            ></TextField>
            <TextField
              label={'Kota'}
              isRequired
              readonly
              value={
                detail && detail.city.city_name ? detail.city.city_name : ''
              }
              disabled
            ></TextField>
            <TextField
              label={'Kecamatan'}
              isRequired
              readonly
              value={
                detail && detail.suburb.suburbName
                  ? detail.suburb.suburbName
                  : ''
              }
              disabled
            ></TextField>
            <TextField
              label={'Kelurahan'}
              isRequired
              readonly
              value={
                detail && detail.area.area_name ? detail.area.area_name : ''
              }
              disabled
            ></TextField>
            <TextField
              label={'Nomor Telepon'}
              isRequired
              readonly
              value={detail && detail.store_phone ? detail.store_phone : ''}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              width={'100%'}
              height={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              display={'flex'}
            >
              {detail && (
                <Stack direction={'column'} alignItems={'center'}>
                  <div id="qrcode">
                    <QRCode
                      value={
                        detail && detail.store_qr_code
                          ? detail.store_qr_code
                          : ''
                      }
                      size={220}
                      level={'H'}
                      renderAs={'canvas'}
                      includeMargin={true}
                    />
                  </div>
                  <Button
                    width="180px"
                    variant={'primary'}
                    onClick={() => downloadQR()}
                  >
                    Download QR Toko
                  </Button>
                  <Button
                    margin="10px 0px 0px 0px"
                    width="180px"
                    variant={'primary'}
                    onClick={() => downloadQRProducts()}
                  >
                    Download QR Products
                  </Button>
                </Stack>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label={'URL Toko'}
              isRequired
              readonly
              type="copyStoreUrl"
              value={detail && detail.store_qr_code ? detail.store_qr_code : ''}
              copyUrl={copyURL}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6} marginTop={'22px'}>
            <Box
              width={'100%'}
              height={'100%'}
              justifyContent={'space-evenly'}
              alignItems={'center'}
              display={'flex'}
            >
              {detail && (
                <Stack direction={'column'} alignItems={'center'}>
                  <NormalText textAlign='center' fontSize={"12px"} margin="0 0 5px 0">
                    {process.env.REACT_APP_CUSTOMER_URL ?? '-'}
                  </NormalText>
                  <div id="qrcode">
                    <QRCode
                      value={
                        `${process.env.REACT_APP_CUSTOMER_URL}/${detail?.store_qr_code_alias?.split('/')?.slice(-1)[0]}/home`
                      }
                      size={220}
                      level={'H'}
                      renderAs={'canvas'}
                      includeMargin={true}
                    />
                  </div>
                  <Button
                    width="220px"
                    variant={'primary'}
                    onClick={() => downloadQR()}
                  >
                    Download QR Shop
                  </Button>
                  <Button
                    margin="10px 0px 0px 0px"
                    width="180px"
                    variant={'primary'}
                    onClick={() => downloadQRProductsShop()}
                  >
                    Download QR Products Shop
                  </Button>
                </Stack>
              )}
            </Box>
          </Grid>
        </Grid>
        <TextField
          label={'URL Google Maps'}
          isRequired
          readonly
          value={detail && detail.google_maps_url ? detail.google_maps_url : ''}
        ></TextField>
        <TextField
          label={'Latitude'}
          isRequired
          readonly
          value={detail && detail.latitude ? detail.latitude : ''}
        ></TextField>
        <TextField
          label={'Longitude'}
          isRequired
          readonly
          value={detail && detail.longitude ? detail.longitude : ''}
        ></TextField>
        <TextField
          label={'Nama Alias'}
          readonly
          value={detail && detail.store_alias ? detail.store_alias : ''}
        ></TextField>
        <Stack direction={'row'} spacing={2} margin={'20px 0 20px 0'}>
          <Button
            variant="primary"
            onClick={() => navigate('/stores/store/list')}
          >
            Kembali
          </Button>
        </Stack>
      </Card>
    </MerchantLayout>
  );
};

export default DetailStorePage;
