import React from "react";
import { styled } from "@mui/system";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import { NormalText } from "src/components/styled/text.styled";
import { Button } from "src/components/styled/button.styled";
import TextField from "src/components/Inputs/TextField";
import Dropdown, { OptionProps } from "src/components/Inputs/Dropdown";
import { Stack, Grid, Box, Switch, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Card from "src/components/Cards/card";
import ImageUpload from "./image.upload";
import Editor from "src/components/Editor";
import { getCategory, CategoryData } from "src/redux/category";
import { SubCategoryData } from "src/redux/subCategory";
import { getBrand, BrandData } from "src/redux/brands";
import { uploadImage } from "src/helper/uploadFile";
import { Tab, TabPanel, TabsList } from "src/components/Tabs";
import { SubCategoryData as SubCategoryLv3Data } from "src/redux/subCategoryLevel3";
import {
  showProduct,
  updateStore,
  CreateProductTypes,
  AssignProductStoreTypes,
} from "src/redux/products";
import { toast, ToastContainer } from "react-toastify";
import useRedux from "src/redux/useRedux";
import CurrencyFormat from "react-currency-format";
import { onlyAlphaNumeric, formatCurrency } from "src/helper/generalFunction";
import Dialog from "src/components/Modal/dialog";
import { Delete, Edit } from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { IconX } from "@tabler/icons";
import { discounttypes } from "src/constants/general";
import { getAllStore, ListStoreDataShort } from "src/redux/storelist";

const initial = {
  id: 0,
  product_id: "0",
  store_id: "",
  store_name: "",
  store_price: "",
  discount_type: "percent",
  discount: "0",
  final_price: 0,
  is_deleted: false,
  is_active: true,
};

const ProductUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    thunkDispatch,
    storeState: { Auth },
  } = useRedux();
  const [activeTab, setActiveTab] = React.useState(0);
  const [categories, setCategories] = React.useState<CategoryData[]>();
  const [subCategories, setSubCategories] = React.useState<SubCategoryData[]>();
  const [brands, setBrands] = React.useState<BrandData[]>();
  const [firstStep, setFirstStep] = React.useState(false);
  const [secondStep, setSecondStep] = React.useState(false);
  const [thirdStep, setThirdStep] = React.useState(false);
  const [lastStep, setLastStep] = React.useState(false);
  const [subsCategories, setSubsCategories] =
    React.useState<SubCategoryLv3Data[]>();
  const [loadingImg, setLoadingImg] = React.useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  // const [images, setImages] = React.useState([
  //   {
  //     file: "",
  //     main: true,
  //   },
  //   {
  //     file: "",
  //     main: false,
  //   },
  //   {
  //     file: "",
  //     main: false,
  //   },
  //   {
  //     file: "",
  //     main: false,
  //   },
  //   {
  //     file: "",
  //     main: false,
  //   },
  // ]);

  const [payload, setPayload] = React.useState<CreateProductTypes>({
    product_name: "",
    no_sku: "",
    category_id: 0,
    sub_category_id: 0,
    subs_category_id: 0,
    brand_id: 0,
    product_detail: {
      product_type: "",
      is_customizable: true,
      product_price: 0,
      discount_type: "",
      discount: 0,
      product_price_after_discount: 0,
      product_image_main: "",
      product_image_1: "",
      product_image_2: "",
      product_image_3: "",
      product_image_4: "",
    },
    product_description: {
      short_description: "",
      long_description: "",
    },
    product_store: [],
  });

  // assign produk toko --start

  const [payloadProductStores, setPayloadProductStores] = React.useState<any[]>(
    []
  );
  const [payloadProductStore, setPayloadProductStore] =
    React.useState<AssignProductStoreTypes>(initial);
  const [openRegisterStoreProduct, setOpenRegisterStoreProduct] =
    React.useState(false);
  const [submitAssign, setSubmitAssign] = React.useState(false);
  const [stores, setStores] = React.useState<ListStoreDataShort[]>();
  const [pageProductStoresTable, setPageProductStoresTable] = React.useState(0);
  const [limitProductStoresTable, setLimitProductStoresTable] =
    React.useState(5);
  const [editProductStore, setEditProductStore] = React.useState(false);

  const columns = [
    {
      name: "Toko",
      selector: (row: any) => {
        return row.store_name;
      },
      sortable: true,
    },
    {
      name: "Harga (Rp)",
      cell: (row: any) => {
        return formatCurrency(row.store_price);
      },
      sortable: true,
    },
    {
      name: "Stok Produk",
      cell: (row: any) => {
        return row.stock ? row.stock : '-';
      },
      sortable: true,
    },
    {
      name: "Diskon",
      cell: (row: any) => {
        return (
          <NormalText>
            {row.discount_type === "discount" ? "Rp" : ""}{" "}
            {row.discount ? row.discount : 0}
            {row.discount_type === "percent" ? "%" : ""}
          </NormalText>
        );
      },
    },
    {
      name: "Harga Diskon",
      cell: (row: any) => {
        return formatCurrency(row.final_price);
      },
    },
    {
      name: "Opsi",
      center: true,
      cell: (row: any) => {
        return (
          <>
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => {
                // setEditAssign(true);
                // handleEditAssignStore(row);
                // setToggleAssign(true);
                setPayloadProductStore(row);
                console.log(row);
                setOpenRegisterStoreProduct(true);
                setEditProductStore(true);
              }}
            >
              <Edit />
            </IconButton>

            <IconButton
              aria-label="delete"
              color="error"
              onClick={() => {
                //setDeleteIDProductStore(row.store_id);
                //setOpenDeleteProductStore(true);
                handleDeleteAssign(row.id);
              }}
            >
              <Delete />
            </IconButton>
          </>
        );
      },
    },
  ];

  const getTotalDiscAssign = () => {
    if (payloadProductStore.store_price && payloadProductStore.discount_type) {
      let type = payloadProductStore.discount_type;
      let price = Number(payloadProductStore.store_price);
      let disc = Number(payloadProductStore.discount);
      let totalDisc = 0;
      let afterPrice = price;
      if (type === "percent") {
        totalDisc = price * (disc / 100);
        afterPrice = price - totalDisc;
      } else {
        if (price > 0) {
          afterPrice = price - disc;
        } else {
          afterPrice = price;
        }
      }
      setPayloadProductStore({
        ...payloadProductStore,
        final_price: afterPrice,
      });
    }
  };

  const handleSetStoreName = (id: any) => {
    let findStoreName = stores?.find((store) => {
      return store.id === id || store.store_id === id;
    });

    setPayloadProductStore({
      ...payloadProductStore,
      store_name:
        findStoreName && findStoreName.store_name
          ? findStoreName.store_name
          : "",
      store_id: id,
    });
  };

  const handleDeleteAssign = (id: any) => {
    let dataProductStores = payloadProductStores.filter(
      (item: any) => item?.id !== id
    );

    setPayloadProductStores(dataProductStores);
  };

  const handleSaveAssignStore = () => {
    setSubmitAssign(true);

    if (!payloadProductStore.store_id) {
      return;
    }

    if (
      !payloadProductStore.store_price ||
      Number(payloadProductStore.store_price) === 0
    ) {
      return;
    }

    let checkStore = payloadProductStores.find(
      (store: any) => store?.store_id === payloadProductStore.store_id
    );

    if (checkStore) {
      toast.error("Tidak boleh ada toko yang sama");
      return;
    }

    let payloadData = { ...payloadProductStore, product_id: payload.id };
    // console.log(payloadData);
    let resetData = { ...initial, id: payloadProductStore.id };

    setPayloadProductStores((prevState) => [...prevState, payloadData]);
    setPayloadProductStore(resetData);
    setOpenRegisterStoreProduct(false);
    setSubmitAssign(false);
  };

  const handleUpdateAssignStore = () => {
    setSubmitAssign(true);

    if (!payloadProductStore.store_id) {
      return;
    }

    if (
      !payloadProductStore.store_price ||
      Number(payloadProductStore.store_price) === 0
    ) {
      return;
    }

    let newValue = payloadProductStores.map((item: any) => {
      if (item.id === payloadProductStore.id) {
        return (item = { ...payloadProductStore });
      } else {
        return item;
      }
    });
    let resetData = { ...initial, id: payloadProductStore.id };

    setPayloadProductStores(newValue);
    setPayloadProductStore(resetData);
    setOpenRegisterStoreProduct(false);
    setEditProductStore(false);
  };

  React.useEffect(() => {
    if (Auth.data.user_type === "user_merchant") {
      setStores(Auth?.data?.profile_data?.store_access?.data_store);
    } else {
      thunkDispatch(getAllStore({ offset: 0, limit: 0 }))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            setStores(res.data.data);
          }
        });
    }
  }, []);

  React.useEffect(() => {
    getTotalDiscAssign();
  }, [
    payloadProductStore.store_price,
    payloadProductStore.discount,
    payloadProductStore.discount_type,
  ]);

  React.useEffect(() => {
    if (
      Number(payloadProductStore.discount) > 100 &&
      payloadProductStore.discount_type === "percent"
    ) {
      setPayloadProductStore({ ...payloadProductStore, discount: "99" });
    }
  }, [payloadProductStore.discount_type]);

  // assign produk toko --end

  const producttype = [
    {
      value: "MAIN",
      label: "Main",
    },
    {
      value: "ADDITIONAL",
      label: "Additional",
    },
  ];

  const discounttype = [
    {
      value: "percent",
      label: "Persentase",
    },
    {
      value: "discount",
      label: "Potongan Harga",
    },
  ];

  const handleSubmit = () => {
    let clonePayloadProductStores = payloadProductStores;
    let productStoresData = clonePayloadProductStores.map((x: any) => {
      delete x?.id;
      delete x?.store_name;
      // delete x?.is_deleted;
      // delete x?.is_active;
      x.store_price = Number(x?.store_price);
      x.discount = Number(x?.discount);
      x.store_id = x?.store_id.toString();
      return x;
    });
    //console.log(productStoresData);
    // let cloneProductStores = payload.product_store;
    // let oldProductStoresData = cloneProductStores?.map((x: any) => {
    //   delete x?.id;
    //   delete x?.store_name;
    //   // delete x?.is_deleted;
    //   // delete x?.is_active;
    //   x.store_price = Number(x?.store_price);
    //   x.discount = Number(x?.discount);
    //   x.store_id = x?.store_id.toString();
    //   return x;
    // });
    // console.log(oldProductStoresData);

    if (productStoresData) {
      let body = { ...payload };
      body.product_store = productStoresData;
      thunkDispatch(updateStore(body))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            toast.success("Berhasil mengubah data produk");
            setTimeout(() => {
              navigate("/products/product/list");
            }, 1000);
          }
        })
        .catch((err) => {
          toast.error("Terjadi kesalahan");
          console.log(err);
        });
    }

    // thunkDispatch(updateStore(payload))
    //   .unwrap()
    //   .then((res) => {
    //     if (res && res.status === "success") {
    //       toast.success("Berhasil mengubah data produk");
    //       setTimeout(() => {
    //         navigate("/products/product/list");
    //       }, 1000);
    //     }
    //   })
    //   .catch((err) => {
    //     toast.error("Terjadi kesalahan");
    //     console.log(err);
    //   });
  };

  const getTotalDisc = () => {
    if (
      payload.product_detail.product_price &&
      payload.product_detail.discount_type
    ) {
      console.log(
        "GETTTL",
        payload.product_detail.discount_type,
        payload.product_detail.discount
      );
      let type = payload.product_detail.discount_type;
      let price = Number(payload.product_detail.product_price);
      let disc = Number(payload.product_detail.discount);
      let totalDisc = 0;
      let afterPrice = price;
      if (type === "discount") {
        totalDisc = disc;
        afterPrice = price - totalDisc;
      } else {
        console.log("HEII");
        totalDisc = price * (disc / 100);
        afterPrice = price - totalDisc;
      }
      setPayload({
        ...payload,
        product_detail: {
          ...payload.product_detail,
          product_price_after_discount: afterPrice,
        },
      });
    }
  };

  React.useEffect(() => {
    getTotalDisc();
  }, [
    payload.product_detail.product_price,
    payload.product_detail.discount,
    payload.product_detail.discount_type,
  ]);

  React.useEffect(() => {
    if (id) {
      thunkDispatch(showProduct(Number(id)))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            let response = res.data.data;
            let temp = {
              id: response.product_id,
              product_name: response.product_name,
              no_sku: response.no_sku,
              category_id:
                response.category && response.category.id
                  ? response.category.id
                  : 0,
              sub_category_id:
                response.sub_category && response.sub_category.id
                  ? response.sub_category.id
                  : 0,
              subs_category_id:
                response.subs_category && response.subs_category.id
                  ? response.subs_category.id
                  : 0,
              brand_id:
                response.brand && response.brand.id ? response.brand.id : 0,
              product_detail: { ...response.product_detail },
              product_description: { ...response.product_description },
            };
            let productStores: Array<any> =
              JSON.parse(JSON.stringify(response?.productStore)) ?? [];

            setPayloadProductStores(productStores);

            setPayload(temp);
            thunkDispatch(getCategory({ filter: "", offset: 0, limit: 0 }))
              .unwrap()
              .then((res) => {
                if (res && res.status === "success") {
                  setCategories(res.data.data);
                  let sub: SubCategoryData[] = [];
                  let subs: SubCategoryLv3Data[] = [];
                  sub = res.data.data.find((x) => x.id === temp.category_id)
                    ?.subCategory!;
                  if (sub && sub.length > 0) {
                    subs = sub.find((x) => x.id === temp.sub_category_id)
                      ?.subsCategory!;
                  }
                  setSubCategories(sub);
                  setSubsCategories(subs);
                }
              });
            thunkDispatch(getBrand({ filter: "", offset: 0, limit: 0 }))
              .unwrap()
              .then((res) => {
                if (res && res.status === "success") {
                  setBrands(res.data.data);
                }
              });
          }
        })
        .catch((err) => console.log("Product Error", err));
    }
  }, []);

  interface UploadProps {
    file: any;
    key:
      | "product_image_main"
      | "product_image_1"
      | "product_image_2"
      | "product_image_3"
      | "product_image_4";
    index: number;
  }

  const handleUploadFile = async ({ key, file, index }: UploadProps) => {
    if (file.size / 1024 > 512) {
      toast.warning(
        "Ukuran file yang anda unggah melebihi batas maksimum 512KB"
      );
    } else {
      var img = new Image();
      img.src = window.URL.createObjectURL(file);

      img.onload = async function () {
        let naturalImage = {
          width: img.naturalWidth,
          height: img.naturalHeight,
        };

        if (naturalImage.width <= 1000 && naturalImage.height <= 1000) {
          let loadingUpload = [...loadingImg];
          loadingUpload[index] = true;
          setLoadingImg(loadingUpload);
          let formdata = new FormData();
          formdata.append("image_count", "1");
          formdata.append("image-1", file);
          await uploadImage(formdata, "brand")
            .then((res) => {
              if (res.status === 200) {
                setLoadingImg([false, false, false, false, false]);
                var temp = { ...payload };
                temp.product_detail[key] = res.data.data["image-1"];
                setPayload(temp);
              }
              setLoadingImg([false, false, false, false, false]);
            })
            .catch((err) => {
              setLoadingImg([false, false, false, false, false]);
              console.error(err);
              toast.warning("Gagal mengupload image");
            });
        } else {
          toast.warning("Maksimum dimensi foto produk 1000x1000 pixel");
        }
      };
    }
  };

  const canGoToStepTwo = () => {
    setFirstStep(true);
    setPayload({
      ...payload,
      product_detail: { ...payload.product_detail, product_image_1: "" },
    });
    if (
      payload.product_name &&
      payload.product_name.length <= 50 &&
      payload.category_id &&
      payload.sub_category_id &&
      payload.brand_id &&
      payload.no_sku &&
      onlyAlphaNumeric(payload.no_sku) &&
      payload.no_sku.length <= 50
    ) {
      return true;
    }

    return false;
  };

  const canGotoStepThree = () => {
    if (activeTab === 1) {
      setSecondStep(true);
    }
    if (
      payload.product_detail.product_type &&
      payload.product_detail.product_price &&
      String(payload.product_detail.product_price).length <= 15
    ) {
      return true;
    }

    return false;
  };

  const canGotoStepFour = () => {
    if (activeTab === 2) {
      setThirdStep(true);
    }
    if (
      payload.product_description.short_description &&
      payload.product_description.short_description.length <= 150 &&
      payload.product_description.long_description
    ) {
      return true;
    }

    toast.warning(
      "Silahkan isi form dengan lengkap terlebih dahulu, sebelum menyimpan produk!"
    );
    return false;
  };

  const canSubmit = () => {
    if (activeTab === 3) {
      setLastStep(true);
    }
    if (
      Auth?.data?.profile_data &&
      Auth?.data?.profile_data?.product_store_required &&
      Auth?.data?.profile_data?.product_store_required === true
    ) {
      if (payloadProductStores && payloadProductStores.length === 0) {
        toast.warning("Produk toko wajib diisi!");
        return false;
      } else {
        return true;
      }
    }

    return true;
  };

  const handledeleteImage = (key: any) => {
    let payloadData: any = { ...payload };
    if (payloadData && payloadData.product_detail) {
      payloadData.product_detail[key] = "";
    }
    setPayload(payloadData);
  };

  React.useEffect(() => {
    if (
      Number(payload.product_detail.discount) > 100 &&
      payload.product_detail.discount_type === "percent"
    ) {
      setPayload({
        ...payload,
        product_detail: {
          ...payload.product_detail,
          discount: 99,
        },
      });
    }
  }, [payload.product_detail.discount_type]);

  return (
    <MerchantLayout>
      <ToastContainer />
      <Dialog
        open={openRegisterStoreProduct}
        handleClose={() => setOpenRegisterStoreProduct(false)}
      >
        <Box sx={{ background: "white" }}>
          <Stack
            margin={"0 0 10px 0"}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <NormalText fontWeight="bold" fontSize={"18px"}>
              Harga Toko
            </NormalText>
            <IconX
              style={{ cursor: "pointer" }}
              onClick={() => setOpenRegisterStoreProduct(false)}
            />
          </Stack>
          <Grid item xs={12} sm={12} md={12}>
            <Dropdown
              value={payloadProductStore.store_id}
              onChange={(e: any) => {
                //setPayloadProductStore({ ...payloadProductStore, store_id: e });
                handleSetStoreName(e);
              }}
              label="Pilih Toko"
              isRequired
              validationText="Toko wajib diisi"
              validationType={
                submitAssign && !payloadProductStore.store_id ? "ERROR" : ""
              }
              options={
                stores
                  ? stores.map((item: any) => {
                      return {
                        label: item.store_name,
                        value:
                          Auth.data.user_type === "user_merchant"
                            ? item.store_id
                            : item.id,
                      } as OptionProps;
                    })
                  : []
              }
            />
            <NormalText fontSize={"12px"}>Harga Produk (Rp) *</NormalText>
            <CurrencyFormat
              customInput={TextField}
              min={0}
              thousandSeparator={true}
              value={payloadProductStore.store_price}
              onValueChange={(e: any) => {
                setPayloadProductStore({
                  ...payloadProductStore,
                  store_price: e.value,
                });
              }}
            />
            {submitAssign &&
            (!payloadProductStore.store_price ||
              Number(payloadProductStore.store_price) === 0) ? (
              <NormalText fontSize={"12px"} color={"#f12147"}>
                Harga produk wajib diisi atau tidak boleh nol!
              </NormalText>
            ) : (
              submitAssign &&
              Number(payloadProductStore.store_price) < 0 && (
                <NormalText fontSize={"12px"} color={"#f12147"}>
                  Harga produk tidak boleh minus!
                </NormalText>
              )
            )}
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <Dropdown
                  value={payloadProductStore.discount_type}
                  onChange={(e: any) =>
                    setPayloadProductStore({
                      ...payloadProductStore,
                      discount_type: e,
                    })
                  }
                  label="Tipe Diskon"
                  options={discounttypes}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  type={"number"}
                  value={payloadProductStore.discount}
                  disabled={!payloadProductStore.discount_type}
                  validationType={
                    payloadProductStore.discount_type === "percent" &&
                    Number(payloadProductStore.discount) >= 100
                      ? "ERROR"
                      : ""
                  }
                  validationText={"Presentase diskon tidak boleh melebihi 99"}
                  onChange={(e: any) => {
                    let val = Number(e.target.value);
                    if (payloadProductStore.discount_type === "percent") {
                      if (Number(val) >= 100) {
                        val = 99;
                      }
                    } else if (
                      payloadProductStore.discount_type === "discount"
                    ) {
                      if (val > Number(payloadProductStore.store_price)) {
                        val = Number(payloadProductStore.store_price);
                      }
                    }

                    if (val < 0) {
                      val = 0;
                    }
                    setPayloadProductStore({
                      ...payloadProductStore,
                      discount: String(val),
                    });
                  }}
                  label={"Diskon"}
                ></TextField>
              </Grid>
            </Grid>
            <TextField
              label={"Harga produk setelah diskon (Rp)"}
              readonly
              value={payloadProductStore.final_price}
            ></TextField>
            <Stack direction={"row"} spacing={1} margin={"10px 0 0 0"}>
              <Button
                variant="primary"
                onClick={() => {
                  editProductStore
                    ? handleUpdateAssignStore()
                    : handleSaveAssignStore();
                }}
              >
                Simpan
              </Button>

              <Button
                variant="secondary"
                onClick={() => {
                  setOpenRegisterStoreProduct(false);
                  setPayloadProductStore(initial);
                }}
              >
                Batal
              </Button>
            </Stack>
          </Grid>
        </Box>
      </Dialog>
      <Card>
        <NormalText fontWeight={"bold"} fontSize={"20px"} margin={"0 0 20px 0"}>
          Ubah Produk
        </NormalText>
        <TabsUnstyled defaultValue={0} value={activeTab}>
          <TabsList>
            <Tab onChange={() => setActiveTab(0)}>Nama {"&"} Kategori</Tab>
            <Tab onChange={() => (canGoToStepTwo() ? setActiveTab(1) : null)}>
              Detail
            </Tab>
            {/* <Tab onChange={() => setActiveTab(1)}>Detail</Tab> */}
            <Tab
              onChange={() =>
                canGoToStepTwo() && canGotoStepThree() ? setActiveTab(2) : null
              }
            >
              Deskripsi
            </Tab>
            {/* <Tab onChange={() => setActiveTab(2)}>Deskripsi</Tab> */}
            <Tab
              onChange={() =>
                canGoToStepTwo() && canGotoStepThree() && canGotoStepFour()
                  ? setActiveTab(3)
                  : null
              }
            >
              Produk Toko
            </Tab>
            {/* <Tab onChange={() => setActiveTab(3)}>Produk Toko</Tab> */}
          </TabsList>
          <TabPanel value={0}>
            <StyledBox>
              <TextField
                label={"No. SKU"}
                isRequired
                validationType={
                  (firstStep && !payload.no_sku) ||
                  (firstStep && payload.no_sku.length > 50)
                    ? "ERROR"
                    : ""
                }
                validationText={
                  !payload.no_sku
                    ? "Nomor SKU harus diisi"
                    : payload.no_sku.length > 50
                    ? "Maksimum karakter untuk Nomor SKU adalah 50 karakter"
                    : ""
                }
                value={payload.no_sku}
                onChange={(e) =>
                  setPayload({ ...payload, no_sku: e.target.value })
                }
              ></TextField>
              <TextField
                label={"Nama Produk"}
                isRequired
                validationType={
                  (firstStep && !payload.product_name) ||
                  (firstStep && payload.product_name.length > 50) ||
                  (firstStep && !onlyAlphaNumeric(payload.no_sku))
                    ? "ERROR"
                    : ""
                }
                validationText={
                  !payload.product_name
                    ? "Nama produk harus diisi"
                    : payload.product_name.length > 50
                    ? "Maksimum karakter untuk nama produk adalah 50 karakter"
                    : !onlyAlphaNumeric(payload.no_sku)
                    ? "Nomor SKU hanya dapat berisi alphanumeric"
                    : ""
                }
                value={payload.product_name}
                onChange={(e) =>
                  setPayload({ ...payload, product_name: e.target.value })
                }
              ></TextField>
              <Dropdown
                label={"Kategori"}
                isRequired
                validationType={
                  firstStep && !payload.category_id ? "ERROR" : ""
                }
                validationText="Kategori harus diisi"
                options={
                  categories
                    ? categories.map((item) => {
                        return {
                          label: item.category_name,
                          value: item.id,
                        } as OptionProps;
                      })
                    : []
                }
                value={payload.category_id}
                onChange={(value: any) => {
                  setPayload({ ...payload, category_id: value });
                  let subcategory = categories?.find(
                    (x) => x.id === Number(value)
                  );
                  setSubCategories(subcategory?.subCategory ?? []);
                }}
              />
              <Dropdown
                label={"Sub Kategori"}
                isRequired
                validationType={
                  firstStep && !payload.sub_category_id ? "ERROR" : ""
                }
                validationText="Sub kategori harus diisi"
                options={
                  subCategories
                    ? subCategories.map((x) => {
                        return {
                          label: x.subcategory_name,
                          value: x.id,
                        } as OptionProps;
                      })
                    : []
                }
                value={payload.sub_category_id}
                onChange={(value: any) => {
                  setPayload({ ...payload, sub_category_id: value });
                  let subcategory = subCategories?.find(
                    (x) => x.id === Number(value)
                  );
                  setSubsCategories(subcategory?.subsCategory ?? []);
                }}
              />
              <Dropdown
                label={"Sub Kategori"}
                isRequired
                validationType={
                  firstStep && !payload.subs_category_id ? "ERROR" : ""
                }
                validationText="Sub kategori harus diisi"
                options={
                  subsCategories
                    ? subsCategories.map((x) => {
                        return {
                          label: x.subscategory_name,
                          value: x.id,
                        } as OptionProps;
                      })
                    : []
                }
                value={payload.subs_category_id}
                onChange={(value: any) =>
                  setPayload({ ...payload, subs_category_id: value })
                }
              />
              <Dropdown
                label={"Brand"}
                isRequired
                validationType={firstStep && !payload.brand_id ? "ERROR" : ""}
                validationText="Brand harus diisi"
                options={
                  brands
                    ? brands.map((x) => {
                        return {
                          label: x.brand_name,
                          value: x.id,
                        } as OptionProps;
                      })
                    : []
                }
                value={payload.brand_id}
                onChange={(value: any) =>
                  setPayload({ ...payload, brand_id: value })
                }
              />
              <Box width={"100%"} textAlign={"right"}>
                <Button
                  variant="primary"
                  onClick={() => (canGoToStepTwo() ? setActiveTab(1) : null)}
                >
                  Selanjutnya
                </Button>
              </Box>
            </StyledBox>
          </TabPanel>
          <TabPanel value={1} key={1}>
            <StyledBox>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Dropdown
                    label={"Tipe Produk"}
                    isRequired
                    validationType={
                      secondStep && !payload.product_detail.product_type
                        ? "ERROR"
                        : ""
                    }
                    validationText={"Tipe produk wajib diisi"}
                    value={payload.product_detail.product_type}
                    options={producttype}
                    onChange={(value: any) =>
                      setPayload({
                        ...payload,
                        product_detail: {
                          ...payload.product_detail,
                          product_type: value,
                        },
                      })
                    }
                  />
                  <NormalText fontSize="12px">Customizable</NormalText>
                  <Switch
                    value={payload.product_detail.is_customizable}
                    checked={payload.product_detail.is_customizable}
                    onChange={() =>
                      setPayload({
                        ...payload,
                        product_detail: {
                          ...payload.product_detail,
                          is_customizable:
                            !payload.product_detail.is_customizable,
                        },
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <NormalText fontSize={"12px"} margin={"0 0 5px 0"}>
                    Harga Produk (Rp) *
                  </NormalText>
                  <CurrencyFormat
                    customInput={TextField}
                    thousandSeparator={true}
                    value={payload.product_detail.product_price}
                    onValueChange={(e: any) => {
                      setPayload({
                        ...payload,
                        product_detail: {
                          ...payload.product_detail,
                          product_price: e.value,
                        },
                      });
                    }}
                  />
                  {secondStep && !payload.product_detail.product_price && (
                    <NormalText fontSize={"12px"} color={"#f12147"}>
                      Harga produk wajib diisi
                    </NormalText>
                  )}
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Dropdown
                        label="Tipe Diskon"
                        options={discounttype}
                        value={payload.product_detail.discount_type}
                        onChange={(value: any) =>
                          setPayload({
                            ...payload,
                            product_detail: {
                              ...payload.product_detail,
                              discount_type: value,
                            },
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        label={"Diskon"}
                        placeholder={
                          payload.product_detail.discount_type
                            ? payload.product_detail.discount_type === "percent"
                              ? "%"
                              : "Rp"
                            : "Silahkan pilih tipe diskon"
                        }
                        value={payload.product_detail.discount}
                        onChange={(e: any) => {
                          let val = Number(e.target.value);
                          console.log(
                            "ONCHANGE",
                            payload.product_detail.discount_type,
                            payload.product_detail.discount,
                            val
                          );
                          if (
                            payload.product_detail.discount_type === "percent"
                          ) {
                            if (Number(val) >= 100) {
                              val = 99;
                            }
                          } else if (
                            payload.product_detail.discount_type === "discount"
                          ) {
                            if (
                              val > Number(payload.product_detail.product_price)
                            ) {
                              val = Number(
                                payload.product_detail.product_price
                              );
                            }
                          }

                          if (val < 0) {
                            val = 0;
                          }

                          setPayload({
                            ...payload,
                            product_detail: {
                              ...payload.product_detail,
                              discount: String(val),
                            },
                          });
                        }}
                      ></TextField>
                    </Grid>
                  </Grid>
                  <TextField
                    label={"Harga produk setelah diskon (Rp)"}
                    disabled
                    placeholder={
                      !payload.product_detail.product_price ||
                      !payload.product_detail.discount
                        ? "Silhkan atur diskon produk"
                        : ""
                    }
                    value={
                      payload.product_detail.product_price_after_discount ?? ""
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <NormalText fontSize={"15px"} fontWeight={"bold"}>
                    Foto Produk
                  </NormalText>
                  <NormalText fontSize={"12px"}>
                    Masukan foto produk dengan dimensi maks. 1000x1000 pixel &
                    ukuran file maksimal 512KB
                  </NormalText>
                  <Grid container margin={"10px 0 0 0"}>
                    <Grid xs={4} sm={6} md={1.5}>
                      <ImageUpload
                        key={Date.now()}
                        loading={loadingImg[0]}
                        main={true}
                        file={payload.product_detail.product_image_main}
                        onChange={(e: any) => {
                          handleUploadFile({
                            file: e.target.files[0],
                            key: "product_image_main",
                            index: 0,
                          });
                        }}
                        handleDeleteImage={handledeleteImage}
                        keyTarget="product_image_main"
                      />
                    </Grid>
                    <Grid xs={4} sm={6} md={1.5}>
                      <ImageUpload
                        key={Date.now()}
                        loading={loadingImg[1]}
                        main={false}
                        file={payload.product_detail.product_image_1}
                        onChange={(e: any) => {
                          handleUploadFile({
                            file: e.target.files[0],
                            key: "product_image_1",
                            index: 1,
                          });
                        }}
                        handleDeleteImage={handledeleteImage}
                        keyTarget="product_image_1"
                      />
                    </Grid>
                    <Grid xs={4} sm={6} md={1.5}>
                      <ImageUpload
                        key={Date.now()}
                        loading={loadingImg[2]}
                        main={false}
                        file={payload.product_detail.product_image_2}
                        onChange={(e: any) => {
                          handleUploadFile({
                            file: e.target.files[0],
                            key: "product_image_2",
                            index: 2,
                          });
                        }}
                        handleDeleteImage={handledeleteImage}
                        keyTarget="product_image_2"
                      />
                    </Grid>
                    <Grid xs={4} sm={6} md={1.5}>
                      <ImageUpload
                        key={Date.now()}
                        loading={loadingImg[3]}
                        main={false}
                        file={payload.product_detail.product_image_3}
                        onChange={(e: any) => {
                          handleUploadFile({
                            file: e.target.files[0],
                            key: "product_image_3",
                            index: 3,
                          });
                        }}
                        handleDeleteImage={handledeleteImage}
                        keyTarget="product_image_3"
                      />
                    </Grid>
                    <Grid xs={4} sm={6} md={1.5}>
                      <ImageUpload
                        key={Date.now()}
                        loading={loadingImg[4]}
                        main={false}
                        file={payload.product_detail.product_image_4}
                        onChange={(e: any) => {
                          handleUploadFile({
                            file: e.target.files[0],
                            key: "product_image_4",
                            index: 4,
                          });
                        }}
                        handleDeleteImage={handledeleteImage}
                        keyTarget="product_image_4"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box width={"100%"} margin={"20px 0 0 0"}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Button variant="danger" onClick={() => setActiveTab(0)}>
                    Kembali
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() =>
                      canGotoStepThree() ? setActiveTab(2) : null
                    }
                  >
                    Selanjutnya
                  </Button>
                </Stack>
              </Box>
            </StyledBox>
          </TabPanel>
          <TabPanel value={2}>
            <StyledBox>
              <TextField
                label={"Deskripsi singkat"}
                isRequired
                value={payload.product_description.short_description}
                validationText={
                  lastStep && !payload.product_description.short_description
                    ? "Deskripsi singkat wajib diisi"
                    : lastStep &&
                      payload.product_description.short_description.length > 150
                    ? "Maksimum karakter untuk deskripsi singkat adalah 150 karakter"
                    : ""
                }
                validationType={
                  (lastStep &&
                    !payload.product_description.short_description) ||
                  (lastStep &&
                    lastStep &&
                    payload.product_description.short_description.length > 50)
                    ? "ERROR"
                    : ""
                }
                onChange={(e) =>
                  setPayload({
                    ...payload,
                    product_description: {
                      ...payload.product_description,
                      short_description: e.target.value,
                    },
                  })
                }
              ></TextField>
              <NormalText fontSize={"12px"} margin={"10px 0 5px 0"}>
                Deskripsi *
              </NormalText>
              <Editor
                value={payload.product_description.long_description}
                onChange={(value: any) =>
                  setPayload({
                    ...payload,
                    product_description: {
                      ...payload.product_description,
                      long_description: value,
                    },
                  })
                }
              ></Editor>
              {lastStep && !payload.product_description.long_description && (
                <NormalText
                  fontSize={"12px"}
                  margin={"2px 0 10px 0"}
                  color={"#f12147"}
                >
                  Deskripsi wajib diisi
                </NormalText>
              )}
              <Box width={"100%"} margin={"20px 0 0 0"}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Button variant="danger" onClick={() => setActiveTab(1)}>
                    Kembali
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => (canGotoStepFour() ? setActiveTab(3) : null)}
                  >
                    Selanjutnya
                  </Button>
                </Stack>
              </Box>
            </StyledBox>
          </TabPanel>
          <TabPanel value={3}>
            <StyledBox>
              <NormalText
                fontWeight="bold"
                fontSize="20px"
                margin="10px 0 20px 0"
              >
                Assign Produk Toko
              </NormalText>
              <NormalText fontSize="12px">Nama Produk</NormalText>
              <NormalText fontWeight="bold" fontSize="14px">
                {payload.product_name}
              </NormalText>
              <NormalText fontSize="12px" margin="10px 0 0 0 ">
                Harga Produk
              </NormalText>
              <NormalText fontWeight="bold" fontSize="14px">
                {formatCurrency(
                  Number(payload.product_detail.product_price ?? 0)
                )}
              </NormalText>

              <Button
                variant="primary"
                onClick={() => {
                  setOpenRegisterStoreProduct(true);
                  setPayloadProductStore({
                    ...payloadProductStore,
                    id: payloadProductStore.id + 1,
                  });
                  // setSubmit(false);
                  // setOpen(true);
                  // setIsCreate(true);
                }}
                margin={"20px 0 20px 0"}
              >
                Tambah Toko {"&"} Harga Toko
              </Button>

              <NormalText
                fontWeight="bold"
                fontSize="20px"
                margin="20px 0 10px 0"
              >
                Daftar Produk Toko
              </NormalText>
              <DataTable
                columns={columns}
                data={payloadProductStores}
                responsive
                pagination
                striped
                paginationRowsPerPageOptions={[5, 10, 25, 50]}
                paginationTotalRows={
                  payloadProductStores && payloadProductStores.length > 0
                    ? payloadProductStores.length
                    : 0
                }
                paginationPerPage={limitProductStoresTable}
                onChangeRowsPerPage={(limit) =>
                  setLimitProductStoresTable(limit)
                }
                onChangePage={(page) => setPageProductStoresTable(page)}
              />

              <Box width={"100%"} margin={"20px 0 0 0"}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Button variant="danger" onClick={() => setActiveTab(2)}>
                    Kembali
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => (canSubmit() ? handleSubmit() : null)}
                  >
                    Simpan
                  </Button>
                  {/* <Button variant="primary" onClick={() => handleSubmit()}>
                    Simpan
                  </Button> */}
                </Stack>
              </Box>
            </StyledBox>
          </TabPanel>
        </TabsUnstyled>
      </Card>
    </MerchantLayout>
  );
};

const StyledBox = styled(Box)`
  width: 100%;
  min-height: 200px;
  background: white;
  padding: 10px;
`;

export default ProductUpdate;
