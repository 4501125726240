import React from "react";
import { NormalText } from "../../components/styled/text.styled";
import { Stack, Box, Grid, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import {
  numberWithCommas,
  isAuthenticated,
  formatDate,
} from "src/helper/generalFunction";
import {
  IconUsers,
  IconPackage,
  IconReportMoney,
  IconSwitchHorizontal,
  IconCoin,
} from "@tabler/icons";
import styled from "styled-components";
import TransactionChart from "./transaction.charts";
import { getProductTotal } from "src/redux/products";
import { getCustomerTotal } from "src/redux/customer";
import {
  getTotalTransaction,
  getAmountTransaction,
  getChartTransaction,
  TransactionChartProps,
} from "src/redux/transaction";
import { getWithdrawalTotal } from "src/redux/withdraw";
import useRedux from "src/redux/useRedux";
import DateRangePicker, {
  RangeProps,
} from "src/components/Inputs/DateRange/DateRangePicker";

const DashboardPage = () => {
  const navigate = useNavigate();
  const { thunkDispatch } = useRedux();
  const [ranges, setRanges] = React.useState<RangeProps[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [charts, setCharts] = React.useState<TransactionChartProps[]>();
  const [product, setProduct] = React.useState(0);
  const [productLoading, setProductLoading] = React.useState(true);
  const [customer, setCustomer] = React.useState(0);
  const [customerLoading, setCustomerLoading] = React.useState(true);
  const [transactionTotal, setTransactionTotal] = React.useState(0);
  const [transactionTotalLoading, setTransactionTotalLoading] =
    React.useState(true);
  const [transactionAmount, setTransactionAmount] = React.useState(0);
  const [transactionAmountLoading, setTransactionAmountLoading] =
    React.useState(true);
  const [withdraw, setWithdraw] = React.useState(0);
  const [withdrawLoading, setWithdrawLoading] = React.useState(true);

  const fetchTotalProduct = () => {
    setProductLoading(true);
    thunkDispatch(
      getProductTotal({
        start_date: formatDate(String(ranges[0].startDate)),
        end_date: formatDate(String(ranges[0].endDate)),
      })
    )
      .unwrap()
      .then((res) => {
        setProductLoading(false);
        if (res && res.status === "success") {
          setProduct(res.data.data.total_product);
        }
      });
  };

  const fetchTotalCustomer = () => {
    setCustomerLoading(true);
    thunkDispatch(
      getCustomerTotal({
        start_date: formatDate(String(ranges[0].startDate)),
        end_date: formatDate(String(ranges[0].endDate)),
      })
    )
      .unwrap()
      .then((res) => {
        setCustomerLoading(false);
        if (res && res.status === "success") {
          setCustomer(res.data.data.total_customer);
        }
      });
  };

  const fetchTotalTransaction = () => {
    setTransactionTotalLoading(true);
    thunkDispatch(
      getTotalTransaction({
        start_date: formatDate(String(ranges[0].startDate)),
        end_date: formatDate(String(ranges[0].endDate)),
      })
    )
      .unwrap()
      .then((res) => {
        setTransactionTotalLoading(false);
        if (res && res.status === "success") {
          setTransactionTotal(res.data.data.total_transaction);
        }
      });
  };

  const fetchAmountTransaction = () => {
    setTransactionAmountLoading(true);
    thunkDispatch(
      getAmountTransaction({
        start_date: formatDate(String(ranges[0].startDate)),
        end_date: formatDate(String(ranges[0].endDate)),
      })
    )
      .unwrap()
      .then((res) => {
        setTransactionAmountLoading(false);
        if (res && res.status === "success") {
          setTransactionAmount(res.data.data.total_transaction_amount);
        }
      });
  };

  const fetchWithdrawTotal = () => {
    setWithdrawLoading(true);
    thunkDispatch(
      getWithdrawalTotal({
        start_date: formatDate(String(ranges[0].startDate)),
        end_date: formatDate(String(ranges[0].endDate)),
      })
    )
      .unwrap()
      .then((res) => {
        setWithdrawLoading(false);
        if (res && res.status === "success") {
          setWithdraw(res.data.data.total_withdraw);
        }
      });
  };

  React.useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login");
    }
  }, []);

  React.useEffect(() => {
    fetchTotalProduct();
    fetchTotalCustomer();
    fetchTotalTransaction();
    fetchWithdrawTotal();
    fetchAmountTransaction();
  }, [ranges]);

  React.useEffect(() => {
    thunkDispatch(getChartTransaction())
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setCharts(res.data.data);
        }
      })
      .catch((e) => console.log("ERR", e));
  }, []);

  return (
    <MerchantLayout title={"Dashboard"}>
      <Box>
        <Stack direction={"row"} justifyContent={"end"}>
          <DateRangePicker
            ranges={ranges}
            onChange={(e: any) => {
              setRanges([
                {
                  startDate: e.selection.startDate,
                  endDate: e.selection.endDate,
                  key: e.selection.key,
                },
              ]);
            }}
          />
        </Stack>
        <Box>
          <NormalText
            fontWeight={"bold"}
            fontSize={"15px"}
            margin={"0 0 10px 0"}
          >
            Informasi Umum
          </NormalText>
          <Grid container spacing={1}>
            <Grid item md={4} xs={12} sm={6}>
              <CardBox
                icon={<IconUsers size={"50px"} />}
                count={customer ?? 0}
                loading={customerLoading}
                title={"Pelanggan"}
              />
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <CardBox
                loading={productLoading}
                icon={<IconPackage size={"50px"} />}
                count={product ?? 0}
                title={"Produk"}
              />
            </Grid>
          </Grid>
        </Box>
        <Box margin={"20px 0 0 0"}>
          <NormalText
            fontWeight={"bold"}
            fontSize={"15px"}
            margin={"0 0 10px 0"}
          >
            Total Transaksi Tokomu
          </NormalText>
          <Grid container spacing={1}>
            <Grid item md={4} xs={12} sm={6}>
              <CardBox
                loading={transactionAmountLoading}
                icon={<IconCoin size={"50px"} />}
                count={numberWithCommas(transactionAmount ?? 0)}
                title={"Transaksi (Rp)"}
              />
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <CardBox
                icon={<IconSwitchHorizontal size={"50px"} />}
                count={transactionTotal ?? 0}
                loading={transactionTotalLoading}
                title={"Jumlah Transaksi"}
              />
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <CardBox
                loading={withdrawLoading}
                icon={<IconReportMoney size={"50px"} />}
                count={numberWithCommas(withdraw ?? 0)}
                title={"Pencairan (Rp)"}
              />
            </Grid>
          </Grid>
        </Box>

        <NormalText
          fontWeight={"bold"}
          fontSize={"15px"}
          margin={"20px 0 10px 0"}
        >
          Transaksi
        </NormalText>
        <Box
          width={"100%"}
          sx={{
            background: "white",
            padding: "20px 0 10px 0",
            borderRadius: "10px",
          }}
        >
          <TransactionChart transactions={charts ?? []} />
        </Box>
      </Box>
    </MerchantLayout>
  );
};

const CardBox = ({
  icon,
  count,
  title,
  loading,
}: {
  icon: React.ReactNode;
  count: number | string;
  title: string;
  loading?: boolean;
}) => {
  return (
    <CardBoxContainer>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"column"}>
          {loading ? (
            <Skeleton height={"46px"} variant={"text"} />
          ) : (
            <NormalText fontWeight="bold" fontSize="30px">
              {count}
            </NormalText>
          )}
          <NormalText fontWeight="bold" fontSize="15px">
            {title}
          </NormalText>
        </Stack>
        {icon}
      </Stack>
    </CardBoxContainer>
  );
};

const CardBoxContainer = styled.div`
  min-width: 100px;
  border-radius: 10px;
  background: #ffffff;
  padding: 10px 20px;
  box-shadow: 4px 4px #00000012;
`;

export default DashboardPage;
