import React, { useState } from "react";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import TextField from "src/components/Inputs/TextField";
import UserGroups from "../layout";
import { Stack, RadioGroup, FormControlLabel, Radio, Box } from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";
import { Button } from "src/components/styled/button.styled";
import Tables from "src/components/Table/tableData";
import useRedux from "src/redux/useRedux";
import {
  getFeeSetting,
  FeeSettingResponseProps,
  createFeeSetting,
  CreateFeeSettingTypes,
  // getSandboxFeeConfig,
  // PlatformFee,
} from "src/redux/feesetting";
import { toast, ToastContainer } from "react-toastify";
import HelpTooltip from "./help.component";
import checkPermission from "src/helper/checkPermission";
import SelectStore from "../selectStore";

const FeeSettingPage = () => {
  const {
    thunkDispatch,
    storeState: {
      Auth: {
        data: {
          profile_data: { name, full_name },
          user_type,
        },
      },
      AppOption,
    },
  } = useRedux();
  const user = name ? name : full_name ? full_name : "";
  const [search, setSearch] = useState("");
  const permissionUpdate = checkPermission("Fee Setting", "is_edit");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [selectStore, setSelectStore] = useState(0);
  // const [platformFee, setPlatformFee] = useState<PlatformFee>();
  const [errorSelectStore, setErrorSelectStore] = useState("");
  const [errorPaymentType, setErrorPaymentType] = useState("");
  const [histories, setHistories] = useState<FeeSettingResponseProps>();
  const [payload, setPayload] = useState({
    tax: "",
    service: "",
    platform_fee_type: "",
    payment_fee_type: "",
    updated_by: "",
  });

  const fetchFeeSettings = () => {
    //setLoading(true);
    thunkDispatch(
      getFeeSetting({
        filter: search,
        limit: limit,
        offset: offset,
        store_id:
          user_type === "merchant"
            ? Number(selectStore)
            : Number(AppOption?.selected_store?.id),
        user_type:
          selectStore || AppOption?.selected_store?.id
            ? "user_merchant"
            : user_type
            ? user_type
            : "",
      })
    )
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          if (res.data.data.length > 0) {
            if (search === "" && offset === 0) {
              let current = res.data.data[0];
              if (selectStore || AppOption?.selected_store?.id) {
                setPayload({
                  payment_fee_type: current.payment_fee_type,
                  platform_fee_type: current.platform_fee_type,
                  tax: String(current.tax),
                  service: String(current.service),
                  updated_by: current.updated_by,
                });
              }
            }
          }
          setLoading(false);
          setHistories(res.data);
          //console.log(res.data);
        }
      });
  };

  // React.useEffect(() => {
  //   thunkDispatch(getSandboxFeeConfig())
  //     .unwrap()
  //     .then((res) => {
  //       if (res && res.status === "success") {
  //         setPlatformFee(res.data.data);
  //         console.log(res.data.data);
  //       }
  //     });
  // }, []);

  React.useEffect(() => {
    fetchFeeSettings();
  }, [search, limit, offset, selectStore, AppOption?.selected_store?.id]);

  const saveSetting = () => {
    if (user_type === "merchant") {
      if (
        !selectStore ||
        !payload.payment_fee_type ||
        payload.payment_fee_type.length === 0
      ) {
        setErrorSelectStore(!selectStore ? "Toko wajib diisi" : "");
        setErrorPaymentType(
          !payload.payment_fee_type || payload.payment_fee_type.length === 0
            ? "Biaya pembayaran wajib diisi"
            : ""
        );
        return;
      } else {
        setErrorSelectStore("");
        setErrorPaymentType("");
      }
    } else {
      if (!payload.payment_fee_type || payload.payment_fee_type.length === 0) {
        setErrorPaymentType(
          !payload.payment_fee_type || payload.payment_fee_type.length === 0
            ? "Biaya pembayaran wajib diisi"
            : ""
        );
        return;
      } else {
        setErrorPaymentType("");
      }
    }

    let form: CreateFeeSettingTypes = {
      platform_fee_type: "",
      payment_fee_type: payload.payment_fee_type,
      tax:
        Number(payload.tax) > 100
          ? 100
          : Number(payload.tax) < 0
          ? 0
          : Number(payload.tax),
      service:
        Number(payload.service) > 100
          ? 100
          : Number(payload.service) < 0
          ? 0
          : Number(payload.service),
      updated_by: user ?? "",
      user_type: "user_merchant",
      store_id:
        user_type === "merchant"
          ? Number(selectStore)
          : Number(AppOption?.selected_store?.id),
    };

    thunkDispatch(createFeeSetting(form))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          fetchFeeSettings();
          toast.success(res.data.message);
        }
      })
      .catch((e) => {
        if (e.response) {
          console.log("ERR RESPO", e);
        }
      });
  };

  const columns = [
    {
      name: "#",
      selector: (row: any, index: number) => index + 1,
      sortable: true,
    },
    {
      name: "Tanggal",
      selector: (row: any) => row.date,
      sortable: true,
    },
    {
      name: "Pajak (%)",
      selector: (row: any) => row.tax,
      sortable: true,
    },
    {
      name: "Biaya Layanan (%)",
      selector: (row: any) => row.service,
      sortable: true,
    },
    // {
    //   name: "Tipe Biaya Platform",
    //   selector: (row: any) => row.platform_fee_type,
    //   sortable: true,
    // },
    {
      name: "Tipe Biaya Pembayaran",
      selector: (row: any) => row.payment_fee_type,
      sortable: true,
    },
    {
      name: "Diupdate Oleh",
      selector: (row: any) => row.updated_by,
      sortable: true,
    },
  ];

  return (
    <MerchantLayout>
      <ToastContainer />
      <UserGroups tabActive={1}>
        {!loading ? (
          <>
            <NormalText
              textAlign="start"
              fontWeight={"bold"}
              fontSize={"18px"}
              margin={"10px 0 20px 0"}
            >
              Pajak {"&"} Biaya Layanan
            </NormalText>
            <Box sx={{ width: "20%" }}>
              {user_type && user_type === "merchant" && (
                <SelectStore
                  value={selectStore}
                  onChange={(val: number) => setSelectStore(val)}
                />
              )}
              {errorSelectStore && errorSelectStore.length > 0 ? (
                <NormalText
                  fontSize="10px"
                  color="#e74c3c"
                  fontWeight="600"
                  margin="-5px 0 5px 0"
                >
                  {errorSelectStore}
                </NormalText>
              ) : null}
            </Box>

            <div style={{ width: "20%" }}>
              <TextField
                placeholder="Contoh 11% untuk field pajak"
                type={"number"}
                disabled={!permissionUpdate}
                label={
                  <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    Pajak {"(%)"}{" "}
                    <HelpTooltip
                      margin={"0 0 0 8px"}
                      text={
                        <div style={{ fontSize: "10px", padding: "10px" }}>
                          Merupakan persentase pajak yang akan dibebankan kepada
                          konsumen ketika melakukan pembayaran. Isi dengan value
                          0, Jika tidak dibutuhkan.
                        </div>
                      }
                    />
                  </Stack>
                }
                value={payload.tax}
                onChange={(event: any) => {
                  let val = Number(event.target.value);
                  if (Number(val) > 100) {
                    val = 100;
                  }
                  if (Number(val) < 0) {
                    val = 0;
                  }
                  setPayload({
                    ...payload,
                    tax: val.toString(),
                  });
                }}
              />
            </div>
            <div style={{ width: "20%" }}>
              <TextField
                type={"number"}
                disabled={!permissionUpdate}
                label={
                  <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    Biaya Layanan {"(%)"}{" "}
                    <HelpTooltip
                      margin={"0 0 0 8px"}
                      text={
                        <div style={{ fontSize: "10px", padding: "10px" }}>
                          Merupakan persentase biaya layanan restoran yang akan
                          dibebankan kepada konsumen ketika melakukan
                          pembayaran. Isi dengan value = 0, jika tidak
                          dibutuhkan.
                        </div>
                      }
                    />
                  </Stack>
                }
                value={payload.service}
                onChange={(event: any) => {
                  let val = Number(event.target.value);
                  if (Number(val) > 100) {
                    val = 100;
                  }
                  if (Number(val) < 0) {
                    val = 0;
                  }
                  setPayload({
                    ...payload,
                    service: val.toString(),
                  });
                }}
                placeholder="Contoh 2% untuk field layanan"
              />
            </div>
            {/* <hr />
        <NormalText
          textAlign="start"
          fontWeight={"bold"}
          fontSize={"18px"}
          margin={"10px 0 20px 0"}
        >
          Tipe Biaya
        </NormalText>

        <NormalText textAlign="start" fontSize={"12px"} margin={"0 0 20px 0"}>
          Tipe Biaya Platform
        </NormalText>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={payload.platform_fee_type}
          onChange={(event: any) =>
            setPayload({
              ...payload,
              platform_fee_type: event.target.value,
            })
          }
        >
          <FormControlLabel
            value="SPLIT"
            control={<Radio />}
            label="Split"
            sx={{ fontSize: "12px" }}
          />
          <FormControlLabel value="OWNER" control={<Radio />} label="Owner" />
          <FormControlLabel
            value="CUSTOMER"
            control={<Radio />}
            label="Customer"
          />
        </RadioGroup> */}
            <hr />
            <Stack
              direction={"row"}
              spacing={2}
              alignItems={"center"}
              margin={"10px 0 20px 0"}
            >
              <NormalText
                textAlign="start"
                fontWeight={"bold"}
                fontSize={"18px"}
              >
                Biaya Pembayaran
              </NormalText>
              <HelpTooltip
                margin={"0 0 0 8px"}
                text={
                  <div style={{ fontSize: "10px", padding: "10px" }}>
                    Merupakan biaya layanan pembayaran pihak ketiga (Xendit),
                    yang dibebankan kepada merchant dan/atau konsumen.
                    <br />
                    Notes:
                    <ul>
                      <li>Split: Pembayaran dibagi 50:50 </li>
                      <li>Customer: Pembayaran dibebankan kepada konsumen.</li>
                      <li>Owner: Pembayaran dibebankan kepada merchant.</li>
                    </ul>
                  </div>
                }
              />
            </Stack>
            <Stack direction={"column"} spacing={1}>
              <NormalText textAlign="start" fontSize={"12px"}>
                Biaya Pembayaran
              </NormalText>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={payload.payment_fee_type}
                onChange={(event: any) =>
                  setPayload({
                    ...payload,
                    payment_fee_type: event.target.value,
                  })
                }
              >
                <FormControlLabel
                  value="SPLIT"
                  disabled={!permissionUpdate}
                  control={<Radio />}
                  label="Split"
                />
                <FormControlLabel
                  value="OWNER"
                  disabled={!permissionUpdate}
                  control={<Radio />}
                  label="Owner"
                />
                <FormControlLabel
                  value="CUSTOMER"
                  disabled={!permissionUpdate}
                  control={<Radio />}
                  label="Customer"
                />
              </RadioGroup>
              {errorPaymentType && errorPaymentType.length > 0 ? (
                <NormalText
                  fontSize="10px"
                  color="#e74c3c"
                  fontWeight="600"
                  margin="-25px 0 5px 0"
                >
                  {errorPaymentType}
                </NormalText>
              ) : null}
              {/* <NormalText textAlign="start" fontWeight="bold" fontSize={"12px"}>
            Biaya Layanan
          </NormalText>
          <NormalText textAlign="start" fontWeight={"bold"} fontSize={"12px"}>
            {formatCurrency(
              platformFee && platformFee?.platform_fee
                ? Number(platformFee?.platform_fee)
                : 0
            )}
          </NormalText> */}
            </Stack>
            <Stack direction={"row"} spacing={4} margin={"20px 0 0 0"}>
              <NormalText textAlign="start" fontWeight="bold" fontSize={"12px"}>
                Info Biaya Pembayaran
              </NormalText>
              <NormalText
                textAlign="start"
                fontWeight={"bold"}
                fontSize={"12px"}
                color={"#116ff3"}
                isPressable
                onClick={() => {
                  window.open("https://www.xendit.co/id/biaya/");
                }}
              >
                Klik link berikut
              </NormalText>
            </Stack>
            {permissionUpdate && (
              <Button
                variant="primary"
                margin="20px 0 20px"
                onClick={() => saveSetting()}
              >
                Update
              </Button>
            )}
          </>
        ) : (
          <NormalText textAlign={"center"} margin={"50px 0"}>
            Tunggu Sebentar
          </NormalText>
        )}

        <Tables
          hasContainer={false}
          isSearch={true}
          records={histories && histories.data ? histories.data : []}
          columns={columns}
          onChangeSearch={(e) => setSearch(e.target.value)}
          valueSearch={search}
          totalRow={
            histories && histories.meta && histories.meta.total
              ? histories.meta.total
              : 0
          }
          paginationPerPage={limit}
          progressPending={loading}
          onChangePage={(page) => setOffset(page - 1)}
          onChangeRowsPerPage={(currentRowsPerPage) =>
            setLimit(currentRowsPerPage)
          }
        />
      </UserGroups>
    </MerchantLayout>
  );
};

export default FeeSettingPage;
