import { PreAuthState, PreAuthData } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import {
  PreAuthLogin,
  PreAuthLogout,
  GetAccountList,
  PreAuthLoginStep2,
} from "./preAuth.services";
import { removeAllState } from "src/helper/generalFunction";
import history from "src/helper/history";

export const initialState: PreAuthState = {
  accounts: {} as any,
  data: {} as PreAuthData,
  status: "loading",
  error: {},
};

export const PreAuthSlice = createSlice({
  name: "preauth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PreAuthLogin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(PreAuthLogin.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(PreAuthLogin.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      })
      .addCase(PreAuthLoginStep2.pending, (state) => {
        state.status = "loading";
      })
      .addCase(PreAuthLoginStep2.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(PreAuthLoginStep2.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      })
      .addCase(PreAuthLogout.fulfilled, (state, action) => {
        removeAllState().then(() => {
          window.location.href = "/login";
        });
      })
      .addCase(GetAccountList.fulfilled, (state, action) => {
        state.accounts = action.payload.accounts;
      });
  },
});

export const PreAuthReducer = PreAuthSlice.reducer;
