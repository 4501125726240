import { ProductAddOnsState, ProductAddOnResponse } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { getProductAddons } from './product.addon.services';

export const initialState: ProductAddOnsState = {
    data: {} as ProductAddOnResponse,
    status: "loading",
    error: {}
}

export const ProductAddOns = createSlice({
    name: 'productaddons',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProductAddons.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getProductAddons.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(getProductAddons.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
    }
})

export const ProductAddOnReducer = ProductAddOns.reducer