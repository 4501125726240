import { ReactNode } from 'react';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import { Tab, TabPanel, TabsList } from 'src/components/Tabs';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import checkPermission from 'src/helper/checkPermission';

interface Props {
  onFirstClick?: () => void;
  onSecondClick?: () => void;
  onThirdClick?: () => void;
  tabActive: number;
  children: ReactNode;
}

const UserGroups = ({ tabActive, children }: Props) => {
  const navigate = useNavigate();
  return (
    <TabsUnstyled defaultValue={tabActive}>
      <TabsList>
        {checkPermission('App Setting', 'is_view') && (
          <Tab value={0} onClick={() => navigate('/setting/appsetting')}>
            Pengaturan Aplikasi
          </Tab>
        )}
        {checkPermission('Fee Setting', 'is_view') && (
          <Tab value={1} onClick={() => navigate('/setting/feesetting')}>
            Pengaturan Biaya
          </Tab>
        )}
        {checkPermission('Loyalty Setting', 'is_view') && (
          <Tab value={2} onClick={() => navigate('/setting/loyaltysetting')}>
            Pengaturan Loyalti
          </Tab>
        )}
        {checkPermission("Delivery Setting", "is_view") && (
          <Tab value={3} onClick={() => navigate("/setting/deliverysetting")}>
            Pengaturan Pengiriman
          </Tab>
        )}
      </TabsList>
      <TabPanel value={0}>
        <StyledBox>{tabActive === 0 && children}</StyledBox>
      </TabPanel>
      <TabPanel value={1}>
        <StyledBox>{tabActive === 1 && children}</StyledBox>
      </TabPanel>
      <TabPanel value={2}>
        <StyledBox>{tabActive === 2 && children}</StyledBox>
      </TabPanel>
      <TabPanel value={3}>
        <StyledBox>{tabActive === 3 && children}</StyledBox>
      </TabPanel>
    </TabsUnstyled>
  );
};

const StyledBox = styled(Box)`
  width: 100%;
  min-height: 200px;
  background: white;
  padding: 10px;
`;

export default UserGroups;
