import { createAsyncThunk } from "@reduxjs/toolkit";
import { WithdrawState, WithdrawTotalState } from "./types";
import ApiService from "src/services/ApiService";
import { getToken } from "src/helper/generalFunction";

export interface WithdrawListProps {
  offset?: number;
  limit?: number;
  store_id?: string | null;
  start_date?: string | null;
  end_date?: string;
  status?: 'IN' | 'OUT' | "";
}

export const getWithdrawHistories = createAsyncThunk(
  "withdraw/histories/get",
  async (props: WithdrawListProps) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/finance/withdraw?limit=${props.limit ?? 0
        }&offset=${props.offset ?? 0}${props.start_date ? `&start_date=${props.start_date}` : ''}${props.end_date ? `&end_date=${props.end_date}` : ''}${props.store_id ? `&store_id=${props.store_id}` : ''}${props.status ? `&status=${props.status}` : ''}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as WithdrawState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as WithdrawState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as WithdrawState;
    }
  }
);

export const createRequestWithdraw = createAsyncThunk(
  "withdraw/histories/get",
  async (param: { store_id: number, account_number: number, amount: number }) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.post(
        `${process.env.REACT_APP_API_URL}/merchant/finance/withdraw/request-withdraw`, param,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as WithdrawState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as WithdrawState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as WithdrawState;
    }
  }
);

export const getWithdrawalTotal = createAsyncThunk(
  "withdraw/dashboard/total",
  async ({ start_date, end_date }: { start_date: string, end_date: string }) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/dashboard/total-withdraw?start_date=${start_date}&end_date=${end_date}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as WithdrawTotalState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as WithdrawTotalState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as WithdrawTotalState;
    }
  }
);