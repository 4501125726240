import { createAsyncThunk } from "@reduxjs/toolkit";
import { SubCategoryState, CreateSubCategoryTypes } from "./types";
import ApiService from "src/services/ApiService";
import axios from "axios";
import { getToken } from 'src/helper/generalFunction';

export interface SubCategoryListProps {
    offset?: number;
    limit?: number;
    filter?: string;
}

export const getSubCategory = createAsyncThunk('subcategory/get', async (props: SubCategoryListProps) => {
    try {
        const config = {
            headers:{
                token: getToken()
            }
        };        
        const response = await ApiService.get(`${process.env.REACT_APP_API_URL}/merchant/subcategory/list?limit=${props.limit ?? 0}&offset=${props.offset ?? 0}${props.filter ? '&filter=' + props.filter : ''}`,config)
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as SubCategoryState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as SubCategoryState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as SubCategoryState;

    }
})

export interface ShowCategoryProps {
    subcategory_id: number;
    access_token: string;
}

export const createSubCategory = createAsyncThunk('subcategory/post', async (props: CreateSubCategoryTypes) => {
    try {
        const { data, id} = props;
        const response = await axios.post(process.env.REACT_APP_API_URL + '/merchant/subcategory/' + id, data, {
            headers: {
                api_key: process.env.REACT_APP_API_KEY,
                token: props.access_token,
                "Content-Type": 'application/json',
            }
        })
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as SubCategoryState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as SubCategoryState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as SubCategoryState;

    }
});

export const updateSubCategory = createAsyncThunk('subcategory/update', async (props: CreateSubCategoryTypes) => {
    try {
        const { data } = props;
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/merchant/subcategory/${props.id}/update`, data, {
            headers: {
                api_key: process.env.REACT_APP_API_KEY,
                token: props.access_token,
                "Content-Type": 'application/json',
            }
        })
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as SubCategoryState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as SubCategoryState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as SubCategoryState;

    }
})

export interface DeleteSubCategoryProps {
    subcategory_id: number;
    access_token: string;
}

export const removeSubCategory = createAsyncThunk('subcategory/delete', async (props: DeleteSubCategoryProps) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/merchant/subcategory/${props.subcategory_id}/delete`, {
            headers: {
                api_key: process.env.REACT_APP_API_KEY,
                token: props.access_token,
                "Content-Type": 'application/json',
            }
        })
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as SubCategoryState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as SubCategoryState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as SubCategoryState;

    }
})

export interface UpdateStatusSubCategoryProps {
    id: number;
    access_token: string;
    data: any,
}

export const updateStatusSubCategory = createAsyncThunk('subcategory/updateStatus', async (props: UpdateStatusSubCategoryProps) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/merchant/subcategory/${props.id}/status`, props.data, {
            headers: {
                api_key: process.env.REACT_APP_API_KEY,
                token: props.access_token,
                "Content-Type": 'application/json',
            }
        })
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as SubCategoryState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as SubCategoryState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as SubCategoryState;

    }
})