import React, { useState, useEffect } from "react";
import { Stack } from "../../../components/styled/layout.styled";
import { useNavigate, useLocation } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import styles from "./styles";
import {
  Box,
  Button as ButtonMUI,
  FormHelperText,
  Switch,
  CircularProgress,
} from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";
import TextField from "src/components/Inputs/TextField";
// import { Button } from "src/components/styled/button.styled";
import { createSubCategory, updateSubCategory } from "src/redux/subCategory";
import {
  createSubSubCategory,
  updateSubSubCategory,
} from "src/redux/subCategoryLevel3";
import useRedux from "src/redux/useRedux";
import PopUp from "src/components/Modal/modal";
import { uploadImage } from "src/helper/uploadFile";
import styled from "styled-components";

interface RouteState {
  data: any;
  statusUrl: string;
  categoryName: string;
  subCategoryName: string;
  idSub: any;
  idCategory: any;
}

const UploadLabel = styled.label`
  width: 9%;
  height: 40px;
  background-color: white;
  color: black;
  padding: 10px;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 2px;
  border: 1px solid #1985d2;
  text-align: center;
  &:hover {
    background-color: #1985d2;
    color: #fff;
  }
`;

const AddBrandPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { data, statusUrl, subCategoryName, categoryName, idSub, idCategory } =
    location.state as RouteState;
  const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState("");
  const [dataAdd, setDataAdd] = useState({
    name: categoryName ?? "",
    imageWeb:
      statusUrl === "edit-level-2" || statusUrl === "edit-level-3"
        ? data?.image_web ?? ""
        : "",
    imageWebName:
      statusUrl === "edit-level-2" || statusUrl === "edit-level-3"
        ? data?.image_web ?? ""
        : "",
    imageMobileName:
      statusUrl === "edit-level-2" || statusUrl === "edit-level-3"
        ? data?.image_mobile ?? ""
        : "",
    imageMobile:
      statusUrl === "edit-level-2" || statusUrl === "edit-level-3"
        ? data?.image_mobile ?? ""
        : "",
    metaTitle: "",
    metaDescription: "",
    metaKeyword: "",
    status:
      statusUrl === "edit-level-2" || statusUrl === "edit-level-3"
        ? data?.is_active
        : false,
    subName:
      statusUrl === "edit-level-2"
        ? data?.subcategory_name ?? ""
        : statusUrl === "edit-level-3" || statusUrl === "add-level-3"
        ? subCategoryName
        : "",
    subNameLevel:
      statusUrl === "edit-level-3" ? data?.subscategory_name ?? "" : "",
  });
  const [loading, setLoading] = useState(true);
  const [errorValidasi, setErrorValidasi] = useState({
    name: "",
    imageWeb: "",
    imageMobile: "",
    status: "",
    metaTitle: "",
    metaDescription: "",
    metaKeyword: "",
    subName: "",
    subNameLevel: "",
  });

  const [loadingImg, setLoadingImg] = useState(false);
  const [loadingImgIcon, setLoadingImgIcon] = useState(false);

  const {
    thunkDispatch,
    storeState: { Auth, Role, SubCategory },
  } = useRedux();

  const handleEditUser = () => {
    let dataObject = {
      id: data?.id ?? "",
      data: {
        subcategory_name: dataAdd.subName,
        is_active: dataAdd.status,
        image_web: dataAdd.imageWeb,
        image_mobile: dataAdd.imageMobile,
      },
      access_token: Auth.data.access_token,
    };
    thunkDispatch(updateSubCategory(dataObject))
      .unwrap()
      .then((res: any) => {
        if (res && res.status == "success") {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("success");
        } else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("failed");
        }
      })
      .catch((err: any) => {
        setLoading(false);
        setStatusModal("failed");
        setOpenModal(true);
        console.error(err);
      });
    setLoading(false);
  };

  const handleEditSubSub = () => {
    let dataObject = {
      id: data.id ?? "",
      idSub: idSub ?? "",
      data: {
        subscategory_name: dataAdd.subNameLevel,
        is_active: dataAdd.status,
        image_web: dataAdd.imageWeb,
        image_mobile: dataAdd.imageMobile,
      },
      access_token: Auth.data.access_token,
    };
    thunkDispatch(updateSubSubCategory(dataObject))
      .unwrap()
      .then((res: any) => {
        if (res && res.status == "success") {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("success");
        } else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("failed");
        }
      })
      .catch((err: any) => {
        setLoading(false);
        setStatusModal("failed");
        setOpenModal(true);
        console.error(err);
      });
    setLoading(false);
  };

  const handleAddUser = () => {
    let dataObject = {
      id: data?.id ?? "",
      data: {
        subcategory_name: dataAdd.subName,
        is_active: true,
        image_web: dataAdd.imageWebName,
        image_mobile: dataAdd.imageMobileName,
      },
      access_token: Auth.data.access_token,
    };
    thunkDispatch(createSubCategory(dataObject))
      .unwrap()
      .then((res: any) => {
        if (res && res.status == "success") {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("success");
        } else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("failed");
        }
      })
      .catch((err: any) => {
        setLoading(false);
        setStatusModal("failed");
        setOpenModal(true);
        console.error(err);
      });
    setLoading(false);
  };

  const handleAddSubSub = () => {
    let dataObject = {
      id: idCategory ?? "",
      idSub: idSub ?? "",
      data: {
        subscategory_name: dataAdd.subNameLevel,
        is_active: true,
        image_web: dataAdd.imageWebName,
        image_mobile: dataAdd.imageMobileName,
      },
      access_token: Auth.data.access_token,
    };
    thunkDispatch(createSubSubCategory(dataObject))
      .unwrap()
      .then((res: any) => {
        if (res && res.status == "success") {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("success");
        } else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("failed");
        }
      })
      .catch((err: any) => {
        setLoading(false);
        setStatusModal("failed");
        setOpenModal(true);
        console.error(err);
      });
    setLoading(false);
  };

  const handleValidate = () => {
    if (statusUrl === "edit-level-2" || statusUrl === "add-level-2") {
      if (
        !dataAdd.name ||
        !dataAdd.subName ||
        dataAdd.name.length === 0 ||
        dataAdd.subName.length === 0 ||
        dataAdd.subName.length > 50
      ) {
        setErrorValidasi({
          ...errorValidasi,
          name: !dataAdd.name ? "Nama Kategori Wajib Diisi" : "",
          imageWeb: "",
          imageMobile: "",
          metaTitle: !dataAdd.metaTitle ? "Judul Meta Wajib Diisi" : "",
          metaKeyword: !dataAdd.metaKeyword ? "Keyword Meta Wajib Diisi" : "",
          metaDescription: !dataAdd.metaDescription
            ? "Deskripsi Meta Wajib Diisi"
            : "",
          subName: !dataAdd.subName
            ? "Nama Sub Kategori Wajib Diisi"
            : dataAdd.subName.length === 0
            ? "Nama Sub Kategori Wajib Diisi"
            : dataAdd.subName.length > 50
            ? "Maks 50 karakter"
            : "",
          subNameLevel: "",
        });

        return;
      }
    } else {
      if (
        !dataAdd.name ||
        !dataAdd.subNameLevel ||
        dataAdd.name.length === 0 ||
        dataAdd.subNameLevel.length === 0
      ) {
        setErrorValidasi({
          ...errorValidasi,
          name: !dataAdd.name ? "Nama Kategori Wajib Diisi" : "",
          imageWeb: "",
          imageMobile: "",
          metaTitle: !dataAdd.metaTitle ? "Judul Meta Wajib Diisi" : "",
          metaKeyword: !dataAdd.metaKeyword ? "Keyword Meta Wajib Diisi" : "",
          metaDescription: !dataAdd.metaDescription
            ? "Deskripsi Meta Wajib Diisi"
            : "",
          subName: "",
          subNameLevel: !dataAdd.subNameLevel
            ? "Nama Sub Kategori Wajib Diisi"
            : dataAdd.subNameLevel.length === 0
            ? "Nama Sub Kategori Wajib Diisi"
            : "",
        });
        return;
      }
    }
    setErrorValidasi({
      name: "",
      imageWeb: "",
      imageMobile: "",
      status: "",
      metaTitle: "",
      metaDescription: "",
      metaKeyword: "",
      subName: "",
      subNameLevel: "",
    });

    statusUrl === "edit-level-2"
      ? handleEditUser()
      : statusUrl === "edit-level-3"
      ? handleEditSubSub()
      : statusUrl === "add-level-3"
      ? handleAddSubSub()
      : handleAddUser();
  };

  const handleUploadFile = async (file: any, typeUpload: string) => {
    typeUpload === "imageWeb" ? setLoadingImg(true) : setLoadingImgIcon(true);
    let key =
      typeUpload === "imageWeb" ? "subcategory_web" : "subcategory_mobile";
    let type =
      statusUrl === "edit-level-3" || statusUrl === "add-level-3"
        ? "subscategory"
        : "subcategory";
    let formdata = new FormData();
    formdata.append("image_count", "1");
    formdata.append("image-1", file);
    await uploadImage(formdata, type)
      .then((res) => {
        if (res.status === 200) {
          setDataAdd({
            ...dataAdd,
            [typeUpload]: res.data.data["image-1"],
            [`${typeUpload}Name`]: res.data.data["image-1"],
          });
          setLoadingImg(false);
          setLoadingImgIcon(false);
        }
        setLoadingImg(false);
        setLoadingImgIcon(false);
      })
      .catch((err) => {
        setLoadingImg(false);
        setLoadingImgIcon(false);
        console.error(err);
        setErrorValidasi({
          ...errorValidasi,
          [typeUpload]: "Gagal mengupload image",
        });
      });
  };

  const handleImage = (e: any, data: string) => {
    if (e.target.files && e.target.files[0]) {
      let imageName = e.target.files[0];
      var img = new Image();
      let fileSize = imageName.size / (1024 * 1024);
      img.src = window.URL.createObjectURL(e.target.files[0]);

      img.onload = function () {
        var width = img.naturalWidth;
        var height = img.naturalHeight;
        let imageValidationWidth = data === "imageWeb" ? 360 : 300;
        let imageValidationHeight = data === "imageWeb" ? 120 : 300;
        if (
          width > imageValidationWidth ||
          height > imageValidationHeight ||
          fileSize > 5
        ) {
          setDataAdd({
            ...dataAdd,
            [data]: "",
            [`${data}Name`]: "",
          });
          setErrorValidasi({
            ...errorValidasi,
            [data]: `Dimensi tidak boleh lebih dari ${imageValidationWidth}x${imageValidationHeight} & Maks 5mb`,
          });
        } else {
          handleUploadFile(imageName, data).then((res: any) => {
            console.log("IMAGE UP OK");
          });
          setErrorValidasi({
            ...errorValidasi,
            [data]: "",
          });
        }
      };
    }
  };

  return (
    <MerchantLayout>
      <PopUp open={openModal} handleClose={() => setOpenModal(!openModal)}>
        <NormalText
          textAlign="center"
          fontWeight={"bold"}
          fontSize={"20px"}
          margin={"0 0 20px 0"}
        >
          {statusModal === "success" ? "Sukses" : "Gagal"}
        </NormalText>
        <NormalText textAlign="center" fontSize={"14px"} margin={"0 0 20px 0"}>
          {statusModal === "success" ? (
            <span>
              Sukses {statusUrl === "edit-level-2" ? "Mengupdate" : "Menambah"}{" "}
              Sub Kategori
            </span>
          ) : SubCategory?.error?.response?.data?.message ? (
            <span>{SubCategory.error.response.data.message}</span>
          ) : (
            <span>
              Gagal {statusUrl === "edit-level-2" ? "Mengupdate" : "Menambah"}{" "}
              Sub Kategori, <br /> mohon periksa kembali form data anda
            </span>
          )}
        </NormalText>
        <Box textAlign={"center"} margin={"20px 0 0 0 "}>
          <ButtonMUI
            variant="contained"
            onClick={() =>
              statusModal === "success"
                ? navigate("/products/categories")
                : setOpenModal(!openModal)
            }
            sx={{ width: 100, height: 40, marginRight: 3 }}
            color="error"
          >
            Oke
          </ButtonMUI>
        </Box>
      </PopUp>
      <Stack direction="column">
        <Box sx={styles.boxStyled}>
          <NormalText
            textAlign="start"
            fontWeight={"bold"}
            fontSize={"18px"}
            margin={"0 0 20px 0"}
          >
            {statusUrl === "edit-level-2"
              ? "Edit Sub Kategori"
              : "Tambah Sub Kategori"}
          </NormalText>
          <form>
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Nama Kategori *
            </NormalText>
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"18px"}
              fontWeight="bold"
            >
              {dataAdd.name}
            </NormalText>
            {statusUrl === "add-level-3" || statusUrl === "edit-level-3" ? (
              <>
                <NormalText
                  textAlign="left"
                  margin={"20px 0 5px 0"}
                  fontSize={"14px"}
                >
                  Nama Sub Kategori *
                </NormalText>
                <NormalText
                  textAlign="left"
                  margin={"20px 0 5px 0"}
                  fontSize={"18px"}
                  fontWeight="bold"
                >
                  {dataAdd.subName}
                </NormalText>
              </>
            ) : null}
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Nama Sub Kategori *
            </NormalText>
            <TextField
              type={"text"}
              style={{ height: 45 }}
              value={
                statusUrl === "add-level-3" || statusUrl === "edit-level-3"
                  ? dataAdd.subNameLevel
                  : dataAdd.subName
              }
              onChange={(event: any) =>
                statusUrl === "add-level-3" || statusUrl === "edit-level-3"
                  ? setDataAdd({
                      ...dataAdd,
                      subNameLevel: event.target.value,
                    })
                  : setDataAdd({
                      ...dataAdd,
                      subName: event.target.value,
                    })
              }
              validationType={
                errorValidasi.subName
                  ? "ERROR"
                  : errorValidasi.subNameLevel
                  ? "ERROR"
                  : ""
              }
              validationText={
                errorValidasi.subName || errorValidasi.subNameLevel
              }
              placeholder="Masukkan nama sub kategori anda"
            />
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Gambar Web
            </NormalText>
            <form>
              <UploadLabel
                htmlFor="imageUploadWeb"
                style={{
                  backgroundColor: loadingImg ? "#ccc" : "",
                  borderColor: loadingImg ? "#ccc" : "",
                }}
              >
                {loadingImg ? <CircularProgress size={15} /> : null}{" "}
                {loadingImg ? "" : "Pilih Gambar"}
              </UploadLabel>
              <input
                accept="image/*"
                id="imageUploadWeb"
                multiple
                type="file"
                onChange={(e: any) => handleImage(e, "imageWeb")}
                style={{ display: "none" }}
              />
              {dataAdd.imageWeb && (
                <img
                  src={dataAdd.imageWeb}
                  style={{ height: 160, width: 180, marginLeft: "30px" }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              )}
            </form>
            <FormHelperText style={{ color: "#1565c0" }}>
              Resolusi gambar maks. 360x120 Pixels {"&"} ukuran maks. 5MB
            </FormHelperText>
            <FormHelperText style={{ color: "#f12147" }}>
              {errorValidasi.imageWeb}
            </FormHelperText>
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Icon
            </NormalText>
            <form>
              <UploadLabel
                htmlFor="imageIcon"
                style={{
                  backgroundColor: loadingImgIcon ? "#ccc" : "",
                  borderColor: loadingImgIcon ? "#ccc" : "",
                }}
              >
                {loadingImgIcon ? <CircularProgress size={15} /> : null}{" "}
                {loadingImgIcon ? "" : "Pilih Gambar"}
              </UploadLabel>
              <input
                accept="image/*"
                id="imageIcon"
                multiple
                type="file"
                onChange={(e: any) => handleImage(e, "imageMobile")}
                style={{ display: "none" }}
              />
              {dataAdd.imageMobile && (
                <img
                  src={dataAdd.imageMobile}
                  style={{ height: 150, width: 150, marginLeft: "30px" }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              )}
            </form>
            <FormHelperText style={{ color: "#1565c0" }}>
              Resolusi gambar maks. 300x300 Pixels {"&"} ukuran maks. 5MB
            </FormHelperText>
            <FormHelperText style={{ color: "#f12147" }}>
              {errorValidasi.imageMobile}
            </FormHelperText>
            {statusUrl === "edit-level-2" || statusUrl === "edit-level-3" ? (
              <>
                <NormalText
                  textAlign="left"
                  margin={"20px 0 5px 0"}
                  fontSize={"14px"}
                >
                  Status
                </NormalText>
                <Switch
                  checked={dataAdd.status}
                  onChange={(e: any) =>
                    setDataAdd({
                      ...dataAdd,
                      status: e.target.checked,
                    })
                  }
                />
                <FormHelperText style={{ color: "#f12147" }}>
                  {errorValidasi.status}
                </FormHelperText>
              </>
            ) : null}

            <Box textAlign={"start"} margin={"20px 0 0 0 "}>
              <ButtonMUI
                variant="contained"
                onClick={() => navigate("/products/categories")}
                sx={{ width: 100, height: 40, marginRight: 3 }}
                color="error"
              >
                Kembali
              </ButtonMUI>
              <ButtonMUI
                variant="contained"
                onClick={() => handleValidate()}
                sx={{ width: 100, height: 40 }}
              >
                Simpan
              </ButtonMUI>
            </Box>
          </form>
        </Box>
      </Stack>
    </MerchantLayout>
  );
};

export default AddBrandPage;
