import { FeeSettingState, FeeSettingResponseProps,SandboxFeeState, SandboxFeeResponse } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { getFeeSetting, createFeeSetting, getSandboxFeeConfig } from './feesetting.services';

export const initialState: FeeSettingState = {
    data: {} as FeeSettingResponseProps,
    status: "loading",
    error: {}
}

export const FeeSettingSlice = createSlice({
    name: 'feesetting',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFeeSetting.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getFeeSetting.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(getFeeSetting.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(createFeeSetting.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(createFeeSetting.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(createFeeSetting.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
    }
})

export const AppSettingReducer = FeeSettingSlice.reducer