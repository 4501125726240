import React, { useState, useEffect } from "react";
import { Stack } from "../../../components/styled/layout.styled";
import UserGroups from "../layout";
import { useNavigate, useLocation } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import styles from "./styles";
import {
  Box,
  Autocomplete,
  Button as ButtonMUI,
  TextField as TextFieldMUI,
  FormHelperText,
} from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";
import TextField from "src/components/Inputs/TextField";
// import { Button } from "src/components/styled/button.styled";
import { editUser } from "src/redux/editUser";
import { getRole, RoleResponseProps, RoleData } from "src/redux/role";
import useRedux from "src/redux/useRedux";
import PopUp from "src/components/Modal/modal";

interface RouteState {
  data: any;
  status : string;
  role: any;
};


let roleTemp = { 
  id : 0,
  name: '',
  description : ''
}

const AddUserPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { data, status, role } = location.state as RouteState;
  const [roles, setRoles] = React.useState<RoleData[]>();
  const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState('');
  const [dataAdd, setDataAdd] = useState({
    firstName: data?.first_name?? "",
    lastName: data?.last_name?? "",
    email: data?.email?? "",
    role: role ?? roleTemp,
  });
  const [loading , setLoading] = useState(true)
  const [errorValidasi, setErrorValidasi] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  });

  const {
    thunkDispatch,
    storeState: { Auth, Role, EditUser },
  } = useRedux();

  const getListRole = () => {
    thunkDispatch(getRole({ limit: 0, offset: 0, filter: "" }))
    .unwrap()
    .then((res) => {
      if (res && res.status == "success") {
        setRoles(res.data.data);
      }
    })
    .catch((err) => {
      setRoles([]);
      console.error(err);
    });
  }

  useEffect(() => {
    Role.data && Role.data.data.length ? setRoles(Role.data.data) : getListRole();
  }, []);

  const handleAddUser = () => {
    let dataObject = {
      id : data?.id ?? 0,
      first_name: dataAdd.firstName,
      last_name: dataAdd.lastName,
      email: dataAdd.email,
      role_id: dataAdd.role.id,
      access_token: Auth.data.access_token,
    };
    thunkDispatch(editUser(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("success");
        }
        else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("failed");
        }
      })
      .catch((err) => {
        setLoading(false);
        setStatusModal("failed");
        setOpenModal(true);
        console.error(err);
      });
    setLoading(false);
  };

  const handleValidate = () => {
    if (
      !dataAdd.firstName ||
      !dataAdd.lastName ||
      !dataAdd.email ||
      !dataAdd.role.name
    ) {
      const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      setErrorValidasi({
        ...errorValidasi,
        firstName: !dataAdd.firstName ? "Nama depan wajib diisi" : "",
        lastName: !dataAdd.lastName ? "Nama belakang wajib diisi" : "",
        email: !dataAdd.email
          ? "Email wajib diisi"
          : regex.test(dataAdd.email) === false
          ? "Format data harus email (contoh : email@sandbox.co.id)"
          : "",
        role: !dataAdd.role.name ? "Role wajib diisi" : "",
      });
    } else if (dataAdd.firstName.length > 50 || dataAdd.lastName.length > 50) {
      setErrorValidasi({
        ...errorValidasi,
        firstName:
          dataAdd.firstName.length > 50
            ? "Maks 50 karakter nama depan"
            : "",
        lastName:
          dataAdd.lastName.length > 50 ? "Maks 50 karakter nama belakang" : "",
        email: "",
        role: "",
      });
    } else {
      setErrorValidasi({
        ...errorValidasi,
        firstName: "",
        lastName: "",
        email: "",
        role: "",
      });
      handleAddUser();
    }
  };

  return (
    <MerchantLayout>
      <PopUp open={openModal} handleClose={() => setOpenModal(!openModal)}>
        <NormalText
          textAlign="center"
          fontWeight={"bold"}
          fontSize={"20px"}
          margin={"0 0 20px 0"}
        >
          {statusModal === "success" ? "Sukses" : "Gagal"}
        </NormalText>
        <NormalText textAlign="center" fontSize={"14px"} margin={"0 0 20px 0"}>
          {statusModal === "success"
            ? <span>Sukses mengubah data pengguna </span>
            : EditUser?.error?.response?.data?.message ? <span>{EditUser.error.response.data.message}</span>
            : <span>Gagal mengubah data pengguna, <br /> Mohon periksa kembali data anda</span>}
        </NormalText>
        <Box textAlign={"center"} margin={"20px 0 0 0 "}>
          <ButtonMUI
            variant="contained"
            onClick={() => statusModal === "success" ? navigate("/users/list") : setOpenModal(!openModal)}
            sx={{ width: 100, height: 40, marginRight: 3 }}
            color="error"
          >
            Oke
          </ButtonMUI>
        </Box>
      </PopUp>
      <UserGroups tabActive={0}>
        <Stack direction="column">
          <Box sx={styles.boxStyled}>
            <NormalText
              textAlign="start"
              fontWeight={"bold"}
              fontSize={"18px"}
              margin={"0 0 20px 0"}
            >
              Ubah Data Pengguna
            </NormalText>
            <form>
              <NormalText
                textAlign="left"
                margin={"20px 0 5px 0"}
                fontSize={"14px"}
              >
                Nama Depan *
              </NormalText>
              <TextField
                type={"text"}
                style={{ height: 45 }}
                value={dataAdd.firstName}
                onChange={(event: any) =>
                  setDataAdd({
                    ...dataAdd,
                    firstName: event.target.value,
                  })
                }
                validationType={errorValidasi.firstName ? "ERROR" : ""}
                validationText={errorValidasi.firstName}
                placeholder="Masukkan nama depan"
              />
              <NormalText
                textAlign="left"
                margin={"20px 0 5px 0"}
                fontSize={"14px"}
              >
                Nama Belakang *
              </NormalText>
              <TextField
                type={"text"}
                style={{ height: 45 }}
                value={dataAdd.lastName}
                onChange={(event: any) =>
                  setDataAdd({
                    ...dataAdd,
                    lastName: event.target.value,
                  })
                }
                validationType={errorValidasi.lastName ? "ERROR" : ""}
                validationText={errorValidasi.lastName}
                placeholder="Masukkan nama belakang"
              />
              <NormalText
                textAlign="left"
                margin={"20px 0 5px 0"}
                fontSize={"14px"}
              >
                Email *
              </NormalText>
              <TextField
                type={"email"}
                value={dataAdd.email}
                style={{ height: 45 }}
                onChange={(event: any) =>
                  setDataAdd({
                    ...dataAdd,
                    email: event.target.value,
                  })
                }
                validationType={errorValidasi.email ? "ERROR" : ""}
                validationText={errorValidasi.email}
                placeholder="Masukkan email"
              />
              <NormalText
                textAlign="left"
                margin={"20px 0 5px 0"}
                fontSize={"14px"}
              >
                Role *
              </NormalText>
              <Autocomplete
                id="combo-box-demo"
                // options={top100Films}
                value={dataAdd.role}
                options={roles ?? []}
                getOptionLabel={(option) => option.name || ""}
                onChange={(event: any, value: any) =>
                  setDataAdd({
                    ...dataAdd,
                    role: value,
                  })
                }
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextFieldMUI
                    {...params}
                    style={{ fontSize: 14 }}
                    size="small"
                    placeholder="Masukkan role"
                    value={dataAdd.role}
                    defaultValue={dataAdd.role.name}
                  />
                )}
              />
              <FormHelperText style={{ color: "#f12147" }}>
                {errorValidasi.role}
              </FormHelperText>
              <Box textAlign={"start"} margin={"20px 0 0 0 "}>
                {/* <Button
                  variant={"danger"}
                  margin={"0 10px 0 0"}
                  onClick={() => navigate("/users/list")}
                >
                  CANCEL
                </Button> */}
                <ButtonMUI
                  variant="contained"
                  onClick={() => navigate("/users/list")}
                  sx={{ width: 100, height: 40, marginRight: 3 }}
                  color="error"
                >
                  Batal
                </ButtonMUI>
                <ButtonMUI
                  variant="contained"
                  onClick={() => handleValidate()}
                  sx={{ width: 100, height: 40 }}
                >
                  Ubah
                </ButtonMUI>
                {/* <Button variant={"primary"} onClick={() => (handleValidate())}>
                  Save
                </Button> */}
              </Box>
            </form>
          </Box>
        </Stack>
      </UserGroups>
    </MerchantLayout>
  );
};

export default AddUserPage;
