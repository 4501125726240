import moment from "moment";

export const searchFunc = (data: any, query: string, key: string) => {
  let res = data.filter((text: any) =>
    text[key].toLowerCase().includes(query.toLowerCase())
  );
  return res;
};

export const getDecryptedUser = () => {
  const localStorage = window.localStorage.getItem('@sandboxdata') ?? undefined;
  return JSON.parse(localStorage ?? '{}');
};

export const isAuthenticated = () => {
  const moment = require('moment');
  let isAuth = false;
  const decrypt = getDecryptedUser();
  if (decrypt && decrypt.Auth) {
    const auth = JSON.parse(decrypt.Auth);
    if (auth && auth.data && auth.data.expired_date) {
      let exp: string = auth.data.expired_date;
      let date_string = exp.split('.');
      if (date_string.length === 2) {
        let exp_date = moment(date_string[0]);
        isAuth = exp_date.isAfter(new Date());
      }
    }
  }

  return isAuth;
};

export const convDate = (date: any) => {
  return moment(date).format('DD-MM-YYYY')
} 

export const checkHasOrder = (value: string) => {
  return value.includes('SBX')
}

export const getToken = () => {
  let token = '';
  const decrypt = getDecryptedUser();
  if (decrypt && decrypt.Auth) {
    const auth = JSON.parse(decrypt.Auth);
    if (auth && auth.data && auth.data.access_token) {
      token = auth.data.access_token;
    }
  } else {
    window.location.pathname = '/login';
  }

  return token;
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const convertToValidDate = (val: any) => {
  let parts = val.split('-');
  // let mydate = new Date(parts[2], parts[1] - 1, parts[0]);
  let cons = parts[2] + '-' + parts[1] + '-' + parts[0];
  return cons;
};

export const formatCurrency = (money: number) => {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  }).format(money);
};

export const formatNumber = (value: number) => {
  return new Intl.NumberFormat('id-ID', {
    minimumFractionDigits: 0,
  }).format(value);
};

export const objectToParams = (obj: any) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
};

export const removeAllState = async () => {
  return await localStorage.removeItem('@sandboxdata');
};

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatDate = (date: string) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export function titleCase(str: string) {
  var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  var splitStr = str.split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    if (!format.test(splitStr[i].charAt(0))) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() +
        splitStr[i].substring(1).toLowerCase();
    } else {
      splitStr[i] = splitStr[i].charAt(0) + splitStr[i].substring(1);
    }
  }
  return splitStr.join(' ');
}

export function onlyLettersAndSpaces(str: string) {
  return /^[A-Za-z\s]*$/.test(str);
}

export function onlyAlphaNumeric(str: string) {
  return /^[A-Za-z0-9_]+$/.test(str);
}
