import React, { ReactNode } from "react";
import { Box, Modal } from "@mui/material";
import styled from "styled-components";

interface Props {
  open: boolean;
  handleClose?: React.MouseEventHandler<HTMLDivElement>;
  children?: ReactNode;
}

const Dialog = ({ open, handleClose, children }: Props) => {
  return (
    <Modal
      sx={{ minHeight: "auto" }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-description"
      aria-describedby="modal-modal-description"
    >
      <Container>{children}</Container>
    </Modal>
  );
};

const Container = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  background: white;
  min-height: 200px;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 600px;
  border-radius: 10px;
  padding: 20px;

  &:focus {
    border: none !important;
  }

  @media only screen and (max-width: 350px) {
    width: 100%;
  }
`;

export default Dialog;
