import { format } from 'path';
import './invoice.css';
import { formatCurrency, formatNumber } from 'src/helper/generalFunction';

const data = {
  image_store_url:
    'https://sandbox-api.sandbox.co.id/images/setting/030423-111124_setting_image-1.jpg',
  store_name: 'Home Center Store Berkah',
  store_address:
    'Kawasan Niaga Terpadu Sudirman (SCBD, Jl. Tulodong Atas 2 No.Lot 12, RT.5/RW.3, Senayan, Kec. Kby. Baru, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12190',
  store_phone_number: '08111111111',
  invoice_number: 'INV231200006',
  order_number: 'SBX231234000006',
  order_type: 'DINE IN',
  order_date: '06-12-2023',
  order_time: '14:41:15',
  order_detail: [
    {
      product_name: 'Onigiring Niji',
      qty: 1,
      total: '8009',
      no_sku: '7',
      notes: '',
      order_detail_add_on: [],
    },
    {
      product_name: 'Roti bakar',
      qty: 1,
      total: '90001',
      no_sku: '004',
      notes: '',
      order_detail_add_on: [],
    },
    {
      product_name: 'Ramen',
      qty: 1,
      total: '809',
      no_sku: '898989',
      notes: '',
      order_detail_add_on: [
        {
          no_sku: '898989',
          product_name: 'Sambal',
        },
      ],
    },
  ],
  sub_total: '138819',
  tax: '1389',
  tax_percentage: 1,
  service_percentage: 1,
  service_fee: '1389',
  payment_fee_type: 'OWNER',
  payment_fee_owner: '4500',
  payment_fee_customer: '0',
  total: '141597',
  order_queue: 1,
  payment_status: 'PAID',
  reference_number: '-',
  customer_name: 'sTesla',
  customer_shipping_address: '-',
  customer_billing_address: '-',
  customer_phone: '081303127334',
  destination_address: '-',
  cashier_name: 'Admin',
  order_qr_code:
    'https://sandbox-api.stagingapps.net/external/U0JYMjMxMjM0MDAwMDA2/get-order-detail',
  shipper_order_id: null,
  table: null,
  pickup_point: null,
};

type InvoiceData = {
  image_store_url?: string;
  store_name: string;
  store_address: string;
  store_phone_number: string;
  invoice_number: string;
  order_number: string;
  order_type: string;
  order_date: string;
  order_time: string;
  order_detail: OrderDetail[];
  sub_total: string;
  tax: string;
  tax_percentage: number;
  service_percentage: number;
  service_fee: string;
  payment_fee_type: string;
  payment_fee_owner: string;
  payment_fee_customer: string;
  total: string;
  order_queue: number;
  payment_status: string;
  reference_number: string;
  customer_name: string;
  customer_shipping_address: string;
  customer_billing_address: string;
  customer_phone: string;
  destination_address: string;
  cashier_name: string;
  order_qr_code: string;
  shipper_order_id: any;
  table?: any;
  pickup_point?: any;
  loyalty_usage: string;
  discount_amount: any;
  device_type: string;
};

type OrderDetail = {
  product_name: string;
  qty: number;
  total: string;
  no_sku: string;
  notes?: string;
  order_detail_add_on: OrderDetailAddOn[];
};

type OrderDetailAddOn = {
  no_sku?: string;
  product_name: string;
  product_price: string;
};

function Invoice(data: InvoiceData) {
  return (
    <div className="invoice-container">
      <div className="">
        <h1>INVOICE</h1>
        <p>
          {data.invoice_number}/{data.order_number}
        </p>
      </div>
      <div className="invoice-header">
        <div className="invoice-header__seller-section">
          <h6 className="title">Diterbitkan Atas Nama</h6>
          <div className="info-container">
            <span className="subtitle">Penjual</span>
            <p className="info">{data.store_name}</p>
          </div>
        </div>
        <div className="invoice-header__buyer-section">
          <h6 className="title">Untuk</h6>
          <div className="info-container">
            <span className="subtitle">Pembeli</span>
            <p className="info bold">{data.customer_name}</p>
          </div>
          <div className="info-container">
            <span className="subtitle">Tanggal Pembelian</span>
            <p className="info bold">{data.order_date}</p>
          </div>
          <div className="info-container">
            <span className="subtitle">Alamat Pengiriman</span>
            <p className="info">{data.customer_shipping_address ?? '-'}</p>
          </div>
        </div>
      </div>
      <div className="invoice-table">
        <div className="invoice-table__header">
          <div
            style={{
              width: '52%',
              textAlign: 'left',
            }}
            className="product-container"
          >
            Produk
          </div>
          <div
            style={{
              width: '8%',
              textAlign: 'right',
            }}
            className="quantity-container"
          >
            Jumlah
          </div>
          <div
            style={{
              width: '20%',
              textAlign: 'right',
            }}
            className="price-container"
          >
            Harga
          </div>
          <div
            style={{
              width: '20%',
              textAlign: 'right',
            }}
            className="total-container"
          >
            Total
          </div>
        </div>
        <div className="invoice-table__body">
          {data.order_detail.map((product, index) => {
            return (
              <div key={'product' + index} className="order-item-container">
                <div className="product-container">
                  <p className="text-medium bold uppercase">
                    {product.product_name}
                  </p>
                  {product.order_detail_add_on &&
                  product.order_detail_add_on.length > 0
                    ? product.order_detail_add_on.map((addon, i) => (
                        <div key={'addon' + i}>
                          <p className="text-small bold">Tambahan :</p>
                          <p className="text-small">- {addon.product_name}</p>
                        </div>
                      ))
                    : null}
                  <p className="text-small bold">Catatan :</p>
                  <p className="text-small">
                    {product.notes ? product.notes : '-'}
                  </p>
                </div>
                <div className="quantity-container">
                  <p className="text-medium">{product.qty}</p>
                </div>
                <div className="price-container">
                  <p className="text-medium">
                    {formatCurrency(
                      Number(product.total) / Number(product.qty)
                    )}
                    {product.order_detail_add_on &&
                    product.order_detail_add_on.length > 0
                      ? product.order_detail_add_on.map((addon, i) => (
                          <div key={'addon_price' + i}>
                            <p className="text-small bold">Tambahan :</p>
                            <p className="text-small">
                              - {formatCurrency(Number(addon.product_price))}
                            </p>
                          </div>
                        ))
                      : null}
                  </p>
                </div>
                <div className="total-container">
                  <p className="text-medium">
                    {formatCurrency(Number(product.total))}
                    {product.order_detail_add_on &&
                    product.order_detail_add_on.length > 0
                      ? product.order_detail_add_on.map((addon, i) => (
                          <div key={'addon_price_total' + i}>
                            <p className="text-small bold">Tambahan :</p>
                            <p className="text-small">
                              - {formatCurrency(Number(addon.product_price))}
                            </p>
                          </div>
                        ))
                      : null}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="invoice-table__footer">
          <div className="info-container">
            <h6 className="subtitle">Sub total</h6>
            <p className="info">{formatCurrency(Number(data.sub_total))}</p>
          </div>
          <div className="info-container">
            <h6 className="subtitle">Loyalty Point</h6>
            <p className="info">
              {Number(data.loyalty_usage) > 0
                ? `-${formatNumber(Number(data.loyalty_usage))}`
                : formatNumber(Number(data.loyalty_usage))}
            </p>
          </div>
          <div className="info-container">
            <h6 className="subtitle">Pajak ({(Number(data?.tax) / Number(data?.sub_total)) * 100}%)</h6>
            <p className="info">{formatCurrency(Number(data.tax))}</p>
          </div>
          <div className="info-container">
            <h6 className="subtitle">
              Biaya Layanan ({data.service_percentage}%)
            </h6>
            <p className="info">{formatCurrency(Number(data.service_fee))}</p>
          </div>
          <div className="info-container">
            <h6 className="subtitle">Biaya Transaksi</h6>
            <p className="info">
              {formatCurrency(Number(data.payment_fee_customer))}
            </p>
          </div>
          {data.device_type !== 'MINIPOS' ? (
            <div className="info-container">
              <h6 className="subtitle">Biaya platform</h6>
              <p className="info">
                {formatCurrency(
                    parseInt(data?.sub_total || '0') <= 10000 ?  (parseInt(data?.sub_total || '0') * 5) / 100 :
                    parseInt(data?.sub_total || '0') > 10000 && parseInt(data?.sub_total || '0') <= 25000 ? 500 : 
                    parseInt(data?.sub_total || '0') > 25000 && parseInt(data?.sub_total || '0') <= 150000 ? 1000 :
                    parseInt(data?.sub_total || '0') > 150000 && parseInt(data?.sub_total || '0') <= 250000 ? 1500 : 
                    parseInt(data?.sub_total || '0') > 250000 ? 5000: 0
                  )}
              </p>
            </div>

          ) : (
            <></>
          )}
          <div className="info-container">
            <h6 className="subtitle">Discount</h6>
            <p className="info">
              {formatCurrency(Number(data.discount_amount ?? 0))}
            </p>
          </div>
          <div className="info-container">
            <h6 className="subtitle">Total</h6>
            <p className="info">{formatCurrency(Number(data.total))}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invoice;
