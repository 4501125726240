import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProductsState, CreateProductStoreTypes, ProductDetailState } from "./types";
import { PaginationProps } from "src/redux/types";
import ApiService from "src/services/ApiService";
import { getToken } from 'src/helper/generalFunction';

export const getAllProductStore = createAsyncThunk('products-store/get', async (props: PaginationProps) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.get(`${process.env.REACT_APP_API_URL}/merchant/productstore/list?limit=${props.limit ?? 10}&offset=${props.offset ?? 0}${props.filter ? '&filter=' + props.filter : ''}`, config)

        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as ProductsState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as ProductsState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as ProductsState;

    }
})

export const getDetailProductStore = createAsyncThunk('products-store/show', async (product_id: number) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.get(`${process.env.REACT_APP_API_URL}/merchant/productstore/${product_id}`, config)

        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as ProductDetailState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as ProductDetailState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as ProductDetailState;

    }
})

export const assignProductStore = createAsyncThunk('products-store/assign', async (props: CreateProductStoreTypes) => {
    try {
        const { store_price, discount, discount_type, is_active, final_price, product_id, store_id, stock, is_publish, is_stock } = props
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.post(process.env.REACT_APP_API_URL + '/merchant/productstore',
            { store_price, discount, discount_type, is_active, final_price, product_id, store_id, stock, is_publish, is_stock }, config)

        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as ProductsState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as ProductsState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as ProductsState;

    }
})

export const updateProductStore = createAsyncThunk('products-store/update', async (props: CreateProductStoreTypes) => {
    try {
        const { store_price, discount, discount_type, is_active, final_price, product_id, store_id, stock, is_publish, is_stock } = props
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.put(process.env.REACT_APP_API_URL + '/merchant/productstore/' + props.id,
            { store_price, discount, discount_type, is_active, final_price, product_id, store_id, stock, is_publish, is_stock }, config)

        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as ProductsState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as ProductsState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as ProductsState;

    }
})


export interface UpdateStatusProps {
    id: number;
    is_active: boolean;
}

export const updateStatus = createAsyncThunk('product-stores/updatestatus', async (props: UpdateStatusProps) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const { is_active, id } = props
        const response = await ApiService.put(`${process.env.REACT_APP_API_URL}/merchant/productstore/${id}/status`, { is_active }, config)
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as ProductsState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as ProductsState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as ProductsState;

    }
})

export const removeProductStore = createAsyncThunk('products-store/delete', async (product_store_id: number) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.delete(`${process.env.REACT_APP_API_URL}/merchant/productstore/${product_store_id}`, config)
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as ProductsState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as ProductsState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as ProductsState;

    }
})
