import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "src/services/ApiService";
import { MetaProps } from "../types";
import axios from 'axios'

export interface IPaymentMethodState {
    data: {
        meta: MetaProps,
        data: IPaymentMethod[],
        message: string
    },
    status: "success" | 'error' | 'empty',
    error: any
}

export interface IPaymentMethod {
    id: number
    is_active: boolean
    is_available: boolean
    payment_code: string
    payment_fee_percentage: number
    payment_fee_price: string
    payment_name: string
    type_payment: string
}

export const getPaymentMethodList = createAsyncThunk('paymentmethod/list', async ({ merchant_id, device = 'MINIPOS' }: { merchant_id: string, device: 'MINIPOS' | 'KIOSK' | 'MOBILEQR' }) => {
    try {
        const config = {
            headers: {
                api_key: process.env.REACT_APP_API_KEY,
            }
        };
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/master/paymentmethod?device=${device}&merchant_id=${merchant_id}
        `, config)
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as IPaymentMethodState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as IPaymentMethodState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as IPaymentMethodState;

    }
})