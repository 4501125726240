import styled from 'styled-components';

interface InputProps {
  textAlign?: string;
}

export const Input = styled.input<InputProps>`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-progress-appearance: none;
  display: flex;
  position: relative;
  text-align: ${(p => p.textAlign ?? 'left')};
  width: 100%;
  height: auto;
  font-size: 14px;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  background-color: #eee;
  flex: none;
  order: 1;
  flex-grow: 0;
  color: black;
  font-family: sans-serif;
  margin-top: 5px;
  margin-bottom: 5px;
  background: transparent;
  &-webkit-autofill, 
  &-webkit-autofill:active, 
  &-webkit-autofill:focus, 
  &-webkit-autofill:hover, select:-webkit-autofill, select:-webkit-autofill:active, select:-webkit-autofill:focus, select:-webkit-autofill:hover, textarea:-webkit-autofill, textarea:-webkit-autofill:active, textarea:-webkit-autofill:focus, textarea:-webkit-autofill:hover {
      transition: background-color 600000s 0s,color 600000s 0s;
      background-color: transparent;
  }
  &:-webkit-autofill {
    background-color: transparent;
  }
  &:autofill {
    background-color: transparent;
  }
  &:autofill {
    background-color: transparent !important;
    background-image: none !important;
    color: -internal-light-dark(black, white) !important;
  }
  &:-internal-autofill-selected{
    background-color: transparent;
  }
  &:focus {
    outline: none !important;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-progress-appearance: none;
    background: transparent;
  }
  &::-moz-selection {
    color: white;
    background: #FCB017;
  }
  &::selection {
    color: white;
    background: #FCB017;
  }
  &:invalid {
    outline: none !important;
    border-color: #d51f40;
  }
  &::-internal-autofill-selected {
    background-color: transparent;
  }

`;

export const BorderInput = styled.div<any>`
  position: relative;
  width: 100%;
  height: ${p => p.height ?? 'auto'};
  margin-bottom: 12px;
  border: 1px solid white;
  box-sizing: border-box;
  margin: ${p => p.margin};
  padding: 5px;
  border: 1px solid #999;
  
  & > input:-internal-autofill-selected{
    background-color: transparent !important;
  }
  &:focus {
    outline: none !important;
    border-color:${({ validationType, defaultValue }) => {
    switch (validationType) {
      case 'ERROR':
        return '#d51f40'
      case 'WARNING':
        return '#E7B450';
      case 'INFO':
        return 'white';
      case 'VALID':
        return '#26BD87';
      default:
        return '#FCB017';
    }
  }};
  }
  &:focus-within {
    outline: none !important;
    border-color:${({ validationType, defaultValue }) => {
    switch (validationType) {
      case 'ERROR':
        return '#d51f40'
      case 'WARNING':
        return '#E7B450';
      case 'INFO':
        return 'white';
      case 'VALID':
        return '#26BD87';
      default:
        return '#FCB017';
    }
  }};
  }
  &:invalid {
    outline: none !important;
    border-color: #e43c5a;
  }
  -webkit-user-drag: none;
`;
export const Label = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 146%;
  user-select: none;
  color: #969393;
  margin: 9px 11px -7px 11px;
`;

export const IconStyled = styled.i `
  position: absolute;
  align-items: center;
  top: 15%;
  right: 5%;
`