import React, { useEffect, useState } from "react";
import MainLayout from "src/components/Layouts/MainLayout";
import {
  Grid,
  Stack,
  Box,
  Card,
  CardHeader,
  Avatar,
  IconButton,
} from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";
import { height } from "@mui/system";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { red } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import useRedux from "src/redux/useRedux";
import { AuthLoginStep2 } from "src/redux/auth";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChooseAccount = () => {
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: {
      PreAuth: { accounts },
    },
  } = useRedux();

  useEffect(() => {
    if (accounts && !accounts.data.data.length) {
      navigate("/login");
    }
  }, [accounts, thunkDispatch]);

  const handleLogin = (id: any) => {
    const user = accounts.data.data.find(
      (account: { id: any }) => account.id == id
    );

    const data = { id: user?.id, email: user?.email };
    thunkDispatch(AuthLoginStep2(data))
      .unwrap()
      .then((res) => {
        if (res.status === "error") {
          setIsLoginFailed(true);
          if (res.error.response) {
            setErrorMessage(res.error.response.data.message);
            if (
              res.error.response.data.message ===
              "Email dan id yang anda masukkan tidak ditemukan!"
            ) {
              toast.error("Akun Anda belum aktif, aktifkan terlebih dahulu");
            }
          } else {
            toast.error(res.error.message);
          }
          setLoading(false);
        } else {
          toast.success("Login Sukses!");
          setTimeout(() => {
            setLoading(false);
            setIsLoginFailed(false);
            navigate("/");
          }, 1000);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <MainLayout>
      <ToastContainer />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        sx={{ height: "100vh" }}
      >
        <Box mt={20} width="50%">
          <NormalText
            margin="0 0 1rem 0"
            textAlign="center"
            fontSize="25px"
            fontWeight="bold"
          >
            Choose Account
          </NormalText>
          {accounts &&
            accounts.data.data.length &&
            accounts.data.data.map((account: any) => {
              return (
                <Box key={account.id} onClick={() => handleLogin(account.id)}>
                  <CardStyled sx={{ marginBottom: "1rem" }}>
                    <CardHeader
                      avatar={
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                          {account.full_name.charAt(0)}
                        </Avatar>
                      }
                      title={account.full_name}
                    />
                  </CardStyled>
                </Box>
              );
            })}
        </Box>
      </Grid>
    </MainLayout>
  );
};

const CardStyled = styled(Card)`
  background-color: #ffffff;
  cursor: pointer;

  :hover {
    background-color: #ededed;
  }
`;

export default ChooseAccount;
