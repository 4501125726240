import React from "react";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import UserGroups from "../layout";
import { Box, Checkbox, Stack, Switch } from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";
import { Button } from "src/components/styled/button.styled";
import InputField from "src/components/Inputs/InputField";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useRedux from "src/redux/useRedux";
import { showRole, ShowRoleProps, updateRole, RoleData } from "src/redux/role";
import { ToastContainer, toast } from "react-toastify";
import { getFeatures, FeaturesData } from "src/redux/feature";
import "react-toastify/dist/ReactToastify.css";

interface FeaturesProps {
  feature_id: number;
  feature_name: string;
  key: string;
  is_view: boolean;
  is_add: boolean;
  is_edit: boolean;
  is_delete: boolean;
}

const UpdateRolePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: { Auth },
  } = useRedux();

  const [payload, setPayload] = React.useState({
    name: "",
    description: "",
    is_cashier: false,
    features: [],
  });
  const [complete, setComplete] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [featuresEmpty, setFeaturesEmpty] = React.useState(false);
  const [features, setFeatures] = React.useState([] as FeaturesProps[]);
  const [masterFeatures, setMasterFeatures] = React.useState<FeaturesData[]>();
  const [selectAll, setSelectAll] = React.useState<boolean[]>([]);

  const checkFeaturesEmpty = () => {
    if (
      features.find((x) => x.is_view || x.is_add || x.is_edit || x.is_delete)
    ) {
      setFeaturesEmpty(false);
      return false;
    } else {
      setFeaturesEmpty(true);
      return true;
    }
  };

  const handleSubmit = () => {
    setSubmitted(true);
    if (complete) {
      var reqPayload: any = {
        ...payload,
        key: payload.name.toLowerCase(),
        features: features,
        access_token: Auth.data.access_token,
        id: id,
      };
      thunkDispatch(updateRole(reqPayload))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            toast.success(res.data.message);
            navigate("/users/roles");
          } else if (res && res.status === "error") {
            toast.error(res.error.response.data.message);
          }
        })
        .catch((err) => {
          toast.error("Gagal menyimpan role");
          console.log("Error", err);
        });
    }
  };

  React.useEffect(() => {
    thunkDispatch(getFeatures(Auth.data.access_token))
      .unwrap()
      .then((featureResponse) => {
        if (featureResponse && featureResponse.status === "success") {
          setMasterFeatures(featureResponse.data.data);
          thunkDispatch(
            showRole({
              role_id: id,
              access_token: Auth.data.access_token,
            } as unknown as ShowRoleProps)
          )
            .unwrap()
            .then((res) => {
              if (
                res &&
                res.status === "success" &&
                res.data &&
                res.data.data
              ) {
                var result: RoleData = {
                  name: res.data.data.name,
                  description: res.data.data.description,
                  features: res.data.data.features,
                  is_cashier: res.data.data.is_cashier,
                  id: res.data.data.id,
                  isDeleted: res.data.data.isDeleted,
                  merchant_id: res.data.data.merchant_id,
                  key: res.data.data.key,
                };
                setPayload({
                  name: result.name,
                  description: result.description,
                  features: result.features,
                  is_cashier: result.is_cashier ?? false,
                });

                let merged: FeaturesProps[] = [];
                featureResponse.data.data.forEach((master) => {
                  let finder = result.features.find(
                    (feat: FeaturesProps) => feat.feature_id === master.id
                  );

                  if (finder && finder.feature_id === master.id) {
                    merged.push(finder);
                  } else {
                    merged.push({
                      feature_id: master.id,
                      feature_name: master.name,
                      is_add: false,
                      is_delete: false,
                      is_edit: false,
                      is_view: false,
                      key: master.key,
                    });
                  }
                });

                // result.features;
                setFeatures(merged);
                var tempSelectAll = new Array<boolean>(
                  result.features.length
                ).fill(false);
                setSelectAll(tempSelectAll);
              }
            })
            .catch((err: any) => console.log("Err", err));
        }
      });
  }, []);

  React.useEffect(() => {
    if (
      payload.name !== "" &&
      payload.description !== "" &&
      payload.name.length <= 50 &&
      payload.description.length <= 100
    ) {
      setComplete(true);
    } else {
      setComplete(false);
    }
  }, [payload]);

  return (
    <MerchantLayout>
      <ToastContainer />
      <UserGroups tabActive={1}>
        <Box
          sx={{
            height: "100%",
            backgroundColor: "white",
            justifyContent: "center",
            border: "0 solid rgba(0,0,0,.125)",
            borderRadius: "0.25rem",
            padding: "10px",
            boxShadow: "0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%)",
          }}
        >
          <NormalText margin="20px 0 20px 0" fontWeight="bold" fontSize="20px">
            Update Role
          </NormalText>
          <NormalText margin="0 0 10px 0">Role *</NormalText>
          <InputField
            value={payload.name}
            onChange={(e: any) =>
              setPayload({ ...payload, name: e.target.value })
            }
            validationText={
              payload.name === ""
                ? "Wajib diisi"
                : payload.name.length > 50
                ? "Maks 50 karakter nama role"
                : ""
            }
            validationType={
              (submitted && !payload.name) || payload.name.length > 50
                ? "ERROR"
                : ""
            }
          />
          <NormalText margin="10px 0 10px 0">Deskripsi Singkat *</NormalText>
          <InputField
            value={payload.description}
            onChange={(e: any) =>
              setPayload({ ...payload, description: e.target.value })
            }
            validationText={
              payload.description === ""
                ? "Wajib diisi"
                : payload.description.length > 100
                ? "Maks 100 karakter deskripsi singkat "
                : ""
            }
            validationType={
              (submitted && !payload.description) ||
              payload.description.length > 100
                ? "ERROR"
                : ""
            }
          />
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <NormalText>Akses POS?</NormalText>
            <Switch
              value={payload.is_cashier}
              checked={payload.is_cashier}
              onChange={(e) =>
                setPayload({ ...payload, is_cashier: !payload.is_cashier })
              }
            />
          </Stack>
          <Box sx={{ margin: "15px 0 20px 0" }}>
            <NormalText>Daftar Fitur diaktifkan *</NormalText>
            {featuresEmpty && !payload.is_cashier && (
              <NormalText color={"#f12147"} fontSize={"12px"}>
                Mohon pilih minimal 1 fitur
              </NormalText>
            )}
            <TableContainer sx={{ margin: "10px 0 0 0" }}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead sx={{ background: "#f1f1f1", fontWeight: "bold" }}>
                  <TableRow>
                    <TableCell>Menu</TableCell>
                    <TableCell align="right">Pilih Semua</TableCell>
                    <TableCell align="right">Lihat</TableCell>
                    <TableCell align="right">Buat</TableCell>
                    <TableCell align="right">Ubah</TableCell>
                    <TableCell align="right">Hapus</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {features &&
                    features.length > 0 &&
                    features.map((row, index) => (
                      <TableRow
                        key={row.feature_id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.feature_name === "Transaction"
                            ? "Balance"
                            : row.feature_name}
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            checked={
                              row.is_add &&
                              row.is_delete &&
                              row.is_edit &&
                              row.is_view
                                ? true
                                : selectAll[index]
                                ? selectAll[index]
                                : false
                            }
                            onClick={() => {
                              let temp = [...features];
                              let tempSelectAll = [...selectAll];
                              tempSelectAll[index] = !selectAll[index];
                              temp[index].is_add = !selectAll[index];
                              temp[index].is_edit = !selectAll[index];
                              temp[index].is_delete = !selectAll[index];
                              temp[index] = {
                                ...temp[index],
                                is_view: !selectAll[index],
                              };
                              setFeatures(temp);
                              setSelectAll(tempSelectAll);
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            checked={row.is_view}
                            onClick={() => {
                              let temp = [...features];
                              temp[index] = {
                                ...temp[index],
                                is_view: !row.is_view,
                              };
                              setFeatures(temp);
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            checked={row.is_add}
                            onClick={() => {
                              let temp = [...features];
                              temp[index].is_add = !row.is_add;
                              setFeatures(temp);
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            checked={row.is_edit}
                            onClick={() => {
                              let temp = [...features];
                              temp[index].is_edit = !row.is_edit;
                              setFeatures(temp);
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            checked={row.is_delete}
                            onClick={() => {
                              let temp = [...features];
                              temp[index].is_delete = !row.is_delete;
                              setFeatures(temp);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {submitted && !complete ? (
            <NormalText color={"#f12147"} fontSize={"12px"}>
              Form yang diisi belum lengkap
            </NormalText>
          ) : null}
          <Button
            variant="danger"
            margin="0 10px 0 0"
            onClick={() => navigate("/users/roles")}
          >
            Batal
          </Button>
          <Button
            variant={"primary"}
            onClick={() => {
              if (payload.is_cashier) {
                handleSubmit();
              } else {
                if (!checkFeaturesEmpty()) {
                  handleSubmit();
                } else {
                  toast.warning("Silahkan cek kembali inputan anda");
                }
              }
            }}
          >
            Simpan
          </Button>
        </Box>
      </UserGroups>
    </MerchantLayout>
  );
};

export default UpdateRolePage;
