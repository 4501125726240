import { ReactNode } from "react";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import { Tab, TabsList, TabPanel } from "src/components/Tabs";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface Props {
  onFirstClick?: () => void;
  onSecondClick?: () => void;
  tabActive: 0 | 1;
  children: ReactNode;
}

const UserGroups = ({ tabActive, children }: Props) => {
  const navigate = useNavigate();
  return (
    <TabsUnstyled defaultValue={tabActive}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabsList>
          <Tab onClick={() => navigate("/profile/merchant/profil")}>Profil</Tab>
          <Tab onClick={() => navigate("/profile/merchant/change-password")}>
            Ubah Kata Sandi
          </Tab>
        </TabsList>
      </Box>
      <TabPanel value={0}>
        <StyledBox>{tabActive === 0 && children}</StyledBox>
      </TabPanel>
      <TabPanel value={1}>
        <StyledBox>{tabActive === 1 && children}</StyledBox>
      </TabPanel>
    </TabsUnstyled>
  );
};

const StyledBox = styled(Box)`
  width: 100%;
  min-height: 200px;
  background: white;
  padding: 10px;
`;

export default UserGroups;
