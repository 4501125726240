import { createAsyncThunk } from "@reduxjs/toolkit";
import { EditUserState, editUserProps } from "./types";
import axios from "axios";

export const editUser = createAsyncThunk('user/list/add-user', async (props: editUserProps) => {
    const config = {
        headers:{
            api_key: 'Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e',
            token: props.access_token
        }
      };
      const data = {
        first_name: props.first_name,
        last_name: props.last_name,
        email: props.email,
        role_id: props.role_id,
      };
    try {
        const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/merchant/users/edit/${props.id}`,
                data,
                config
            );
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as EditUserState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as EditUserState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as EditUserState;

    }
})