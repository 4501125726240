import React, { useState, useEffect } from 'react';
import { Stack } from '../../../components/styled/layout.styled';
import { useNavigate, useLocation } from 'react-router-dom';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import styles from './styles';
import {
  Box,
  Button as ButtonMUI,
  FormHelperText,
  Switch,
  TextField,
} from '@mui/material';
import { NormalText } from 'src/components/styled/text.styled';
import IconSandboxSmall from 'src/assets/media/icons/ic-sandbox-small.svg';

interface RouteState {
  data: any;
  status: string;
}

const AddBrandPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { data, status } = location.state as RouteState;
  const [dataAdd, setDataAdd] = useState({
    name: data?.category_name ?? '',
    imageWeb: data?.image_web ?? '',
    imageMobile: data?.image_mobile ?? '',
    status: data?.is_active ?? false,
  });

  return (
    <MerchantLayout>
      <Stack direction="column">
        <Box sx={styles.boxStyled}>
          <NormalText
            textAlign="start"
            fontWeight={'bold'}
            fontSize={'18px'}
            margin={'0 0 20px 0'}
          >
            Detail Sub Kategori
          </NormalText>
          <form>
            <NormalText
              textAlign="left"
              margin={'20px 0 5px 0'}
              fontSize={'14px'}
            >
              Nama Kategori *
            </NormalText>
            <TextField
              id="outlined-read-only-input"
              sx={{ width: '100%' }}
              value={dataAdd.name}
              InputProps={{
                readOnly: true,
              }}
            />
            <NormalText
              textAlign="left"
              margin={'20px 0 5px 0'}
              fontSize={'14px'}
            >
              Gambar Web *
            </NormalText>
            <img
              src={dataAdd.imageWeb}
              style={{ height: 160, width: 180 }}
              className="brand-image"
              alt="image brand"
              loading="lazy"
            />
            <NormalText
              textAlign="left"
              margin={'20px 0 5px 0'}
              fontSize={'14px'}
            >
              Icon *
            </NormalText>
            <img
              src={dataAdd.imageMobile}
              style={{ height: 150, width: 150 }}
              className="brand-image"
              alt="image brand"
              loading="lazy"
            />
            <NormalText
              textAlign="left"
              margin={'20px 0 5px 0'}
              fontSize={'14px'}
            >
              Status *
            </NormalText>
            <Switch checked={dataAdd.status} disabled />
            <Box textAlign={'start'} margin={'20px 0 0 0 '}>
              <ButtonMUI
                variant="contained"
                onClick={() => navigate('/products/categories')}
                sx={{ width: 100, height: 40, marginRight: 3 }}
                color="error"
              >
                Kembali
              </ButtonMUI>
            </Box>
          </form>
        </Box>
      </Stack>
    </MerchantLayout>
  );
};

export default AddBrandPage;
