import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProductHistorysState, ProductHistoryDetailState } from "./types";
import { PaginationProps } from "src/redux/types";
import ApiService from "src/services/ApiService";
import { getToken } from 'src/helper/generalFunction';

export const getDetailProductHistoryStore = createAsyncThunk('products-history-store/show', async (product_id: number) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.get(`${process.env.REACT_APP_API_URL}/merchant/stock-history?product_store_id=${product_id}`, config)

        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as ProductHistoryDetailState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as ProductHistoryDetailState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as ProductHistoryDetailState;

    }
})
