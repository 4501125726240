import { createAsyncThunk } from "@reduxjs/toolkit";
import { AreaState } from "./types";
import axios from 'axios'

export const getAllArea = createAsyncThunk('area/get', async (suburban_id: number) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/master/area/${suburban_id}/suburb`, {
            headers: {
                api_key: process.env.REACT_APP_API_KEY,
            }
        })

        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as AreaState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as AreaState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as AreaState;

    }
})