import React from "react";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import { useNavigate, useParams } from "react-router-dom";
import Card from "src/components/Cards/card";
import { NormalText } from "src/components/styled/text.styled";
import { Button } from "src/components/styled/button.styled";
import TextField from "src/components/Inputs/TextField";
import Dropdown from "src/components/Inputs/Dropdown";
import { Stack, Grid } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import {
  showDetailTableType,
  updateTableType,
  CreateTableTypeProps,
} from "src/redux/tabletype";
import useRedux from "src/redux/useRedux";
import CheckPermission from "src/helper/checkPermission";

const TableTypeForm = () => {
  const navigate = useNavigate();
  const { thunkDispatch } = useRedux();
  const { id } = useParams();
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [payload, setPayload] = React.useState<CreateTableTypeProps>({
    name: "",
    minimum_table_count: 0,
    maximum_table_count: 0,
  });

  const handleSubmit = () => {
    setIsSubmit(true);
    if (
      payload.name &&
      payload.minimum_table_count > 0 &&
      payload.maximum_table_count > 0
    ) {
      let reqBody = { ...payload, id: id };
      thunkDispatch(updateTableType(reqBody as CreateTableTypeProps))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            toast.success("Berhasil mengubah tipe meja");
            setTimeout(() => {
              navigate("/stores/tabletype/list");
            }, 500);
          }
        });
    } else {
      toast.warning("Silahkan cek kembali inputan anda!");
    }
  };

  React.useEffect(() => {
    if (id) {
      thunkDispatch(showDetailTableType(Number(id)))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            let total = res.data.data.total_person;
            let splitted = total.split(" - ");
            setPayload({
              name: res.data.data.name,
              minimum_table_count: Number(splitted[0] ?? 0),
              maximum_table_count: Number(splitted[1] ?? 0),
            });
          }
        });
    }
  }, []);

  return (
    <MerchantLayout>
      <ToastContainer />
      <Card>
        <NormalText fontWeight="bold" fontSize="20px" margin="0 0 20px 0">
          Ubah Tipe Meja
        </NormalText>
        <Dropdown
          label={"Tipe"}
          validationType={isSubmit && !payload.name ? "ERROR" : ""}
          validationText={"Tipe meja wajib diisi"}
          isRequired
          options={[
            { label: "Small", value: "Small" },
            { label: "Medium", value: "Medium" },
            { label: "Large", value: "Large" },
          ]}
          value={payload.name}
          onChange={(value: any) => {
            setPayload({ ...payload, name: value });
          }}
        />
        <NormalText fontSize="13px">Jumlah Orang</NormalText>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item md={2}>
            <TextField
              isRequired
              validationText={
                isSubmit && !payload.minimum_table_count
                  ? "Total minimal wajib diisi"
                  : ""
              }
              validationType={
                isSubmit && !payload.minimum_table_count ? "ERROR" : ""
              }
              value={payload.minimum_table_count}
              minLength={1}
              type={"number"}
              onChange={(e: any) => {
                setPayload({
                  ...payload,
                  minimum_table_count: Number(e.target.value),
                });
              }}
            ></TextField>
          </Grid>
          <Grid item md={1} textAlign={"center"}>
            Sampai
          </Grid>
          <Grid item md={2}>
            <TextField
              isRequired
              validationText={
                isSubmit && !payload.maximum_table_count
                  ? "Total maksimal wajib diisi"
                  : ""
              }
              validationType={
                isSubmit && !payload.maximum_table_count ? "ERROR" : ""
              }
              value={payload.maximum_table_count}
              minLength={1}
              type={"number"}
              onChange={(e: any) => {
                setPayload({
                  ...payload,
                  maximum_table_count: Number(e.target.value),
                });
              }}
            ></TextField>
          </Grid>
        </Grid>
        <Stack direction={"row"} spacing={2} margin={"20px 0 20px 0"}>
          <Button
            variant="danger"
            onClick={() => navigate("/stores/tabletype/list")}
          >
            Batal
          </Button>
          {CheckPermission('Table Type', 'is_edit') && (
            <Button variant="primary" onClick={() => handleSubmit()}>
              Simpan
            </Button>
          )}
        </Stack>
      </Card>
    </MerchantLayout>
  );
};

export default TableTypeForm;
