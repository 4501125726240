import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProvinceState } from "./types";
import axios from 'axios'

export const getAllProvinces = createAsyncThunk('provinces/get', async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/master/province`, {
            headers: {
                api_key: process.env.REACT_APP_API_KEY,
            }
        })

        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as ProvinceState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as ProvinceState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as ProvinceState;
    }
})