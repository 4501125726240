import React from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SubMenu,
} from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft, FiSettings } from 'react-icons/fi';
import styled from 'styled-components';
import 'react-pro-sidebar/dist/css/styles.css';
import './Header.css';
import useRedux from 'src/redux/useRedux';
//import SandboxLogo from "src/assets/media/images/sandbox.svg";
// import NewSandboxLogo from "src/assets/media/icons/Logo-Pagii-Sandbox.svg";
import features from 'src/constants/MenuItem/features';
// import IconSandboxSmall from "src/assets/media/icons/ic-sandbox-small.svg";
import { Stack } from '@mui/material';
import { NormalText } from 'src/components/styled/text.styled';
import SandboxLong from 'src/assets/media/icons/OrderingLong.svg';
import SandboxShort from 'src/assets/media/icons/OrderingShort.svg';

export interface SidebarProps {
  sidebar: boolean;
  handleClickSideBar: () => void;
  handleSetStoreClick: any;
}

const Header = ({
  sidebar,
  handleClickSideBar,
  handleSetStoreClick,
}: SidebarProps) => {
  const {
    storeState: { Auth, AppOption },
  } = useRedux();

  const checkFeatures = (permission: string[]) => {
    if (Auth && Auth.data && Auth.data.features_and_permissions) {
      let finder = Auth.data.features_and_permissions.some(
        (r) =>
          permission.includes(`${r.feature_name}@is_view`) && r.is_view === true
      );

      return finder;
    } else {
      return false;
    }
  };

  return (
    <>
      <div id="header">
        <div className="closemenu" onClick={handleClickSideBar}>
          {sidebar ? <FiChevronRight /> : <FiChevronLeft />}
        </div>
        <ProSidebar collapsed={sidebar}>
          <SidebarHeader>
            {sidebar ? (
              <div className="logotext">
                <div
                  className="image text-center"
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <img
                    src={SandboxShort}
                    className="brand-image"
                    style={{ width: 40, height: 40 }}
                  />
                </div>
              </div>
            ) : (
              <div className="logotext">
                <div className="image text-left">
                  <img
                    style={{
                      height: '50px',
                      width: '100px',
                      marginTop: 30,
                      borderRadius: 10,
                      marginLeft: 20,
                    }}
                    src={SandboxLong}
                    alt="User Image"
                  />
                </div>
                <div
                  className="info text-left mt-2"
                  style={{ marginBottom: 10, marginLeft: 20 }}
                >
                  <span className="d-block" style={{ maxWidth: '190px' }}>
                    {Auth &&
                    Auth.data &&
                    Auth.data.profile_data &&
                    Auth.data.profile_data.full_name
                      ? Auth.data.profile_data.full_name
                      : ''}
                    {Auth &&
                      Auth.status === 'success' &&
                      Auth.data.user_type === 'user_merchant' && (
                        <PickStore
                          storeName={
                            AppOption &&
                            AppOption.selected_store &&
                            AppOption.selected_store.name
                              ? AppOption.selected_store.name
                              : 'Pilih Toko'
                          }
                          onSetClick={handleSetStoreClick}
                        />
                      )}
                  </span>
                </div>
              </div>
            )}
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square">
              {features &&
                features.map(
                  (item, index) =>
                    checkFeatures(item.permission) &&
                    (item.type === 'collapse' ? (
                      <SubMenu
                        key={'menu-' + index}
                        title={item.text}
                        icon={
                          <img
                            src={item.icon}
                            alt="icon"
                            style={{ width: '20px', height: '20px' }}
                          />
                        }
                      >
                        {item.children &&
                          item.children.length !== 0 &&
                          item.children.map(
                            (x, y) =>
                              checkFeatures(x.permission) && (
                                <MenuItem
                                  key={'child-' + y}
                                  active={
                                    window.location.pathname === x.path
                                      ? true
                                      : false
                                  }
                                >
                                  {x.text}
                                  <Link to={x.path} />
                                </MenuItem>
                              )
                          )}
                      </SubMenu>
                    ) : (
                      checkFeatures(item.permission) && (
                        <MenuItem
                          key={'menuitem-' + index}
                          active={
                            window.location.pathname === item.path
                              ? true
                              : false
                          }
                          icon={
                            <img
                              src={item.icon}
                              alt="icon"
                              style={{ width: '20px', height: '20px' }}
                            />
                          }
                        >
                          {item.text}
                          <Link to={item.path} />
                        </MenuItem>
                      )
                    ))
                )}
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>
    </>
  );
};

interface PickStoreProps {
  storeName: string | null;
  onSetClick?: any;
}

const PickStore = ({ storeName, onSetClick }: PickStoreProps) => {
  const [mouse, setMouse] = React.useState(false);
  return (
    <Container
      mouse={mouse}
      onMouseOver={() => setMouse(true)}
      onMouseLeave={() => setMouse(false)}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <NormalText fontSize="12px !important" padding={'unset !important'}>
          {storeName ?? 'Pilih Toko'}
        </NormalText>
        <FiSettings
          title="Pilih Toko"
          color={mouse ? 'black' : 'rgb(188 188 188)'}
          style={{ margin: '0 10px 0 0', cursor: 'pointer' }}
          onClick={onSetClick}
        />
      </Stack>
    </Container>
  );
};

interface ContainerProps {
  mouse: boolean;
}

const Container = styled.div<ContainerProps>`
  transition: ease-in-out 0.3s;
`;

export default Header;
