import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import Tables from "src/components/Table/tableData";
import { Select, FormControl, Stack, MenuItem } from "@mui/material";
import { SesiKasirData, getSesiKasir } from "src/redux/sesiKasir";
import useRedux from "src/redux/useRedux";
import { NormalText } from "src/components/styled/text.styled";
import { ListStoreData, getAllStore } from "src/redux/storelist";

const BannerPage = () => {
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: {
      Auth: {
        data: { user_type },
      },
      AppOption: { selected_store },
    },
  } = useRedux();
  const [data, setData] = useState<SesiKasirData[]>([]);
  const [dataFirst, setDataFirst] = useState<SesiKasirData[]>([]);
  const [listStore, setListStore] = useState<ListStoreData[]>([]);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [totalData, setTotalData] = useState(0);

  const columns = [
    {
      name: "Nama Kasir",
      selector: (row: any) => row.cashier_name,
      sortable: true,
      center: true,
    },
    {
      name: "Toko",
      cell: (row: any) => row.store_name,
      sortable: true,
      center: true,
    },
    {
      name: "Waktu Buka Kasir",
      cell: (row: any) => row.start_time.split(" ")[1] ?? "-",
      sortable: true,
      center: true,
    },
    {
      name: "Kode Sesi",
      cell: (row: any) => row.session_code,
      sortable: true,
      center: true,
    },
  ];

  const getAll = () => {
    let dataObject = {
      offset: offset - 1,
      limit: limit,
      filter: filter,
      store_id: status ? status : String(selected_store.id),
    };
    thunkDispatch(getSesiKasir(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          console.log(res.data);
          if (res.data && res.data.data && res.data.data.length !== 0) {
            setData(res.data.data);
            setDataFirst(res.data.data);
          }
          setTotalData(
            res.data && res.data.meta && res.data.meta.total
              ? res.data.meta.total
              : 0
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
    setLoading(false);
  };

  React.useEffect(() => {
    getAll();
  }, [filter, limit, offset, status]);

  useEffect(() => {
    if (user_type && user_type === "merchant") {
      getAll();
      thunkDispatch(getAllStore({ offset: 0, limit: 0 }))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            setListStore(res.data.data);
          }
        });
    }
  }, []);

  const Filter = (val: any) => {
    let tempVal = val !== "" ? (val == 1 ? true : false) : null;
    let res =
      tempVal !== null && dataFirst.length != 0
        ? dataFirst.filter((word: any) => word.is_active == tempVal)
        : dataFirst;
    setData(res);
    setStatus(val);
  };

  return (
    <MerchantLayout>
      <Tables
        records={data}
        columns={columns}
        title="Sesi Kasir"
        isSearch={true}
        addButton={false}
        titleAdd=""
        onChangeSearch={(e) => setFilter(e.target.value)}
        valueSearch={filter}
        handleAddButton={() =>
          navigate("/informasi/banner/add-banner", {
            state: { data: null, statusUrl: "add" },
          })
        }
        progressPending={loading}
        paginationPerPage={limit}
        totalRow={totalData}
        onChangePage={(page) => setOffset(page)}
        onChangeRowsPerPage={(currentRowsPerPage) =>
          setLimit(currentRowsPerPage)
        }
        filter={
          user_type && user_type === "merchant" ? (
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <NormalText textAlign="start" fontSize={"16px"}>
                Filter
              </NormalText>
              <FormControl sx={{ minWidth: 250 }}>
                <Select
                  value={status}
                  onChange={(e) => Filter(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ height: 40 }}
                >
                  <MenuItem value="">
                    <em>Pilih Toko</em>
                  </MenuItem>
                  {listStore &&
                    listStore.length !== 0 &&
                    listStore.map((value, index) => {
                      return (
                        <MenuItem value={value.id}>{value.store_name}</MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Stack>
          ) : undefined
        }
      />
    </MerchantLayout>
  );
};

export default BannerPage;
