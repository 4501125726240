import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NormalText } from 'src/components/styled/text.styled';
import { Box } from '@mui/material';
//import SandboxLogo from "src/assets/media/images/sandbox.svg";
import NewSandboxLogo from 'src/assets/media/icons/OrderingLong.svg';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        verticalAlign: 'center',
      }}
    >
      <Box
        width={'100%'}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        <img
          style={{ width: '100px', height: '100px' }}
          src={NewSandboxLogo}
          alt={'Logo Sandbox'}
        />
        <NormalText textAlign={'center'} fontSize={'25px'}>
          Oops, Sepertinya anda tersesat?
        </NormalText>
        <NormalText
          textAlign={'center'}
          isPressable
          onClick={() => navigate('/')}
          color={'grey'}
        >
          Ke Halaman Utama
        </NormalText>
      </Box>
    </Box>
  );
};

export default NotFound;
