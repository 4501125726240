import React from "react";
import Tables from "src/components/Table/tableData";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import {
  IconButton,
  Grid,
  Switch,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Box,
  CircularProgress,
  Fade,
} from "@mui/material";
import { Delete, Edit, Search, Close } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import ConfirmationModal from "src/components/Modal/confirmation";
import {
  getAllProduct,
  ProductResponseProps,
  removeProduct,
  updateStatus,
} from "src/redux/products";
import useRedux from "src/redux/useRedux";
import { ToastContainer, toast } from "react-toastify";
import { NormalText } from "src/components/styled/text.styled";
import { formatCurrency, getToken } from "src/helper/generalFunction";
import checkPermission from "src/helper/checkPermission";
import { Button } from "src/components/styled/button.styled";
import axios, { AxiosRequestConfig } from "axios";
import Dialog from "src/components/Modal/dialog";
import FileUpload from "./file.upload";
import { uploadFile } from "src/helper/uploadFile";

const ProductList = () => {
  const navigate = useNavigate();
  const { thunkDispatch } = useRedux();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState("");
  const [status, setStatus] = React.useState("true");
  const [loading, setLoading] = React.useState(true);
  const [query, setQuery] = React.useState("idle");
  const timerRef = React.useRef<number>();
  const [open, setOpen] = React.useState(false);
  const [deletedId, setDeletedId] = React.useState(0);
  const [products, setProducts] = React.useState<ProductResponseProps>();
  const [inputByExcel, setInputByExcel] = React.useState(false);
  const [fileExcel, setFileExcel] = React.useState<any>();
  const [fileExcelValidation, setFileExcelValidation] = React.useState("");
  const [downloadTemplate, setDownloadTemplate] = React.useState(false);
  const [downloadProduct, setDownloadProduct] = React.useState(false);
  const [downloadProgress, setDownloadProgress] = React.useState(0);

  React.useEffect(
    () => () => {
      clearTimeout(timerRef.current);
    },
    []
  );

  const columns = [
    {
      name: "Nama Produk",
      selector: (row: any) => row.product_name,
      sortable: true,
      width: "20vw",
      height: "20px",
      wrap: true,
      style: { padding: "15px" },
    },
    {
      name: "No. SKU",
      selector: (row: any) => row.no_sku,
      sortable: true,
    },
    {
      name: "Tipe",
      cell: (row: any) => {
        return row.product_detail.product_type === "MAIN"
          ? "Main"
          : "Additional";
      },
      sortable: true,
      width: "6vw",
    },
    {
      name: "Kategori",
      selector: (row: any) =>
        row && row.category && row.category.category_name
          ? row.category.category_name
          : "-",
      sortable: true,
      width: "10vw",
      wrap: true,
    },
    {
      name: "Harga",
      cell: (row: any) => {
        return formatCurrency(row.product_detail.product_price);
      },
      sortable: true,
    },
    {
      name: "Status",
      center: true,
      cell: (row: any) => {
        return (
          <Switch
            checked={row.status}
            onChange={() => handleUpdateStatus(row, "status")}
            disabled={checkPermission("Product", "is_edit") ? false : true}
          />
        );
      },
      width: "5vw",
    },
    {
      name: "Customizable",
      center: true,
      cell: (row: any) => {
        return (
          <Switch
            checked={row.product_detail.is_customizable}
            onChange={() => handleUpdateStatus(row, "customize")}
            disabled={checkPermission("Product", "is_edit") ? false : true}
          />
        );
      },
      width: "10vw",
    },
    {
      name: "Action",
      center: true,
      cell: (item: any) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {checkPermission("Product", "is_edit") && (
              <Link to={`/products/product/${item.product_id}/update`}>
                <IconButton aria-label="edit" color="primary">
                  <Edit />
                </IconButton>
              </Link>
            )}
            <Link to={`/products/product/view/${item.product_id}`}>
              <IconButton aria-label="detail" color="warning">
                <Search />
              </IconButton>
            </Link>

            {checkPermission("Product", "is_delete") && (
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => {
                  setDeletedId(item.product_id);
                  setOpen(!open);
                }}
              >
                <Delete />
              </IconButton>
            )}
          </Grid>
        );
      },
    },
  ];

  const handleDelete = () => {
    thunkDispatch(removeProduct(deletedId))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          toast.success(res.data.message);
          fetchProduct();
          setOpen(!open);
        }
      })
      .catch((err) => {
        toast.error("Terjadi Kesalahan");
        console.log(err);
        setOpen(!open);
      });
  };

  const fetchProduct = () => {
    setLoading(true);
    thunkDispatch(
      getAllProduct({
        limit: limit,
        offset: page - 1,
        filter: filter ?? "",
        status: status,
      })
    )
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setProducts(res.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleUpdateStatus = (row: any, type: string) => {
    if (type === "status") {
      thunkDispatch(
        updateStatus({
          id: row.product_id,
          status: !row.status,
          is_customizable: row.product_detail.is_customizable,
        })
      )
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            toast.success("Berhasil mengubah status produk");
            fetchProduct();
          }
        });
    } else {
      thunkDispatch(
        updateStatus({
          id: row.product_id,
          status: row.status,
          is_customizable: !row.product_detail.is_customizable,
        })
      )
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            toast.success("Berhasil mengubah status customizable produk");
            fetchProduct();
          }
        })
        .catch((err) => console.log("Error", err));
    }
  };

  const getTemplate = async () => {
    setDownloadTemplate(true);
    const headers = {
      "Content-Type": "blob",
      api_key: `${process.env.REACT_APP_API_KEY}`,
      token: getToken(),
    };
    let url = "/merchant/product/import/template";
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}${url}`,
      responseType: "arraybuffer",
      headers,
    };
    try {
      const response = await axios(config);
      const outputFilename = `Template Pendaftaran Produk dengan Excel.zip`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
      setDownloadTemplate(false);
    } catch (error) {
      alert(error);
      setDownloadTemplate(false);
    }
  };

  const exportProducts = async () => {
    setDownloadProduct(true);
    const headers = {
      "Content-Type": "blob",
      api_key: `${process.env.REACT_APP_API_KEY}`,
      token: getToken(),
    };

    let url = "/merchant/products/export";
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}${url}`,
      responseType: "arraybuffer",
      headers,
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        setDownloadProgress(percentage);
      },
    };
    try {
      const response = await axios(config);
      const outputFilename = `Daftar Produk.xlsx`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      // link.click();
      if (response.status === 200) {
        setTimeout(() => {
          link.click();
          setDownloadProduct(false);
        }, 5000);
      }
    } catch (error) {
      alert(error);
      setDownloadProduct(false);
    }
  };

  const handleUploadFile = async () => {
    if (!fileExcel) {
      setFileExcelValidation("File Wajib Diisi");
      // timerRef.current = window.setTimeout(() => {
      //   setQuery("error");
      //   setTimeout(() => {
      //     setInputByExcel(false);
      //     setQuery("idle");
      //   }, 2000);
      // }, 2000);
      return;
    }

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (query !== "idle") {
      setQuery("idle");
      return;
    }

    setQuery("progress");

    let formdata = new FormData();
    formdata.append("import", fileExcel, fileExcel?.name);

    await uploadFile(formdata)
      .then((res) => {
        if (res.status === 200) {
          fetchProduct();
          setFileExcel(null);
          setFileExcelValidation("");

          timerRef.current = window.setTimeout(() => {
            setQuery("success");
            toast.success("Product Berhasil didaftarkan");
            setTimeout(() => {
              setInputByExcel(false);
              setQuery("idle");
            }, 2000);
          }, 2000);
        }
      })
      .catch((error) => {
        if (error) {
          timerRef.current = window.setTimeout(() => {
            setQuery("failed");
            toast.error(
              "Proses upload gagal, mohon periksa kembali inputan Anda!"
            );
            toast.error(error.response.data.message);
            setTimeout(() => {
              setInputByExcel(false);
              setQuery("idle");
            }, 2000);
          }, 2000);
        }
      });
  };

  React.useEffect(() => {
    fetchProduct();
  }, [limit, page, filter, status]);

  React.useEffect(() => {
    if (downloadProgress === 100) {
      setTimeout(() => {
        setDownloadProgress(0);
      }, 5000);
    }
  }, [downloadProgress]);

  React.useEffect(() => {
    if (!downloadProduct) {
      setDownloadProgress(0);
    }
  }, [downloadProduct]);

  return (
    <MerchantLayout>
      <ToastContainer />
      <ConfirmationModal
        open={open}
        handleConfirm={() => handleDelete()}
        handleClose={() => setOpen(!open)}
        title="Konfirmasi"
        description="Apakah anda yakin ingin menghapus produk ini?"
      ></ConfirmationModal>
      <Dialog
        open={inputByExcel}
        handleClose={() => {
          setInputByExcel(false);
          setFileExcel(null);
          setFileExcelValidation("");
        }}
      >
        <Box
          width={"100%"}
          height={"200px"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          <Box
            sx={{
              height: "200px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {query === "success" ? (
              <NormalText fontSize="16px" fontWeight="700">
                Upload Berhasil!
              </NormalText>
            ) : query === "failed" ? (
              <NormalText fontSize="16px" fontWeight="700">
                Upload Gagal
              </NormalText>
            ) : query === "idle" ? (
              <Stack
                width={"100%"}
                height={"100%"}
                direction={"column"}
                justifyContent={"space-between"}
              >
                <Stack
                  width={"100%"}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <NormalText fontWeight="800" fontSize="16px">
                    Tambah Produk
                  </NormalText>
                  <IconButton
                    onClick={() => {
                      setInputByExcel(false);
                      setFileExcel(null);
                      setFileExcelValidation("");
                    }}
                  >
                    <Close />
                  </IconButton>
                </Stack>
                <Stack>
                  <FileUpload
                    file={fileExcel}
                    onChange={(e: any) => {
                      setFileExcel(e.target.files[0]);
                    }}
                    validationText={fileExcelValidation}
                  />
                </Stack>
                <Stack direction={"row"} justifyContent={"flex-end"}>
                  <Button variant="primary" onClick={handleUploadFile}>
                    Simpan
                  </Button>
                </Stack>
              </Stack>
            ) : query === "error" ? (
              <NormalText fontSize="16px" fontWeight="700">
                Upload Error!
              </NormalText>
            ) : (
              <>
                <Fade
                  in={query === "progress"}
                  style={{
                    transitionDelay: query === "progress" ? "800ms" : "0ms",
                  }}
                  unmountOnExit
                >
                  <CircularProgress />
                </Fade>
                <NormalText fontSize="16px" fontWeight="700" className="mt-3">
                  Loading
                </NormalText>
              </>
            )}
          </Box>
        </Box>
      </Dialog>
      <Dialog open={downloadProduct}>
        <Stack
          direction={"column"}
          alignItems="center"
          justifyContent="center"
          height={"100px"}
          marginTop={"2rem"}
        >
          <NormalText fontWeight="800" margin="0 0 0.5rem 0">
            Mengunduh
          </NormalText>
          <div
            className="progress"
            role="progressbar"
            style={{
              position: "relative",
              width: "100%",
            }}
            aria-label="Animated striped example"
          >
            <div
              className="progress-bar progress-bar-striped bg-success progress-bar-animated"
              style={{
                width: `${downloadProgress ? downloadProgress : 0}%`,
                height: "100px",
              }}
            ></div>
          </div>
          <NormalText margin="0.5rem 0 0 0">{downloadProgress}%</NormalText>
        </Stack>
      </Dialog>

      <Tables
        records={products?.data ?? []}
        columns={columns}
        title="Daftar Produk"
        isSearch={true}
        addButton={checkPermission("Product", "is_add")}
        titleAdd="Tambah Produk"
        filter={
          <Stack direction={"row"} alignItems={"center"}>
            <NormalText
              fontSize={"16px"}
              wordBreak={"break-word"}
              margin={"0 10px 0 0"}
            >
              Filter Status
            </NormalText>
            <FormControl sx={{ minWidth: 250 }}>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ height: 40 }}
              >
                <MenuItem value={"true"}>Aktif</MenuItem>;
                <MenuItem value={"false"}>Tidak Aktif</MenuItem>;
              </Select>
            </FormControl>
          </Stack>
        }
        handleAddButton={() => navigate("/products/product/form")}
        onChangeSearch={(e) => setFilter(e.target.value)}
        valueSearch={filter}
        progressPending={loading}
        paginationPerPage={limit}
        totalRow={
          products && products.meta && products.meta.total
            ? products.meta.total
            : 0
        }
        onChangePage={(page) => setPage(page)}
        onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
          setLimit(currentRowsPerPage)
        }
        secondButton={
          checkPermission("Product", "is_add") ? (
            <Button
              variant="primary"
              width="25%"
              onClick={() => setInputByExcel(true)}
            >
              Tambah Produk by Excel
            </Button>
          ) : null
        }
        thirdButton={
          checkPermission("Product", "is_add") ? (
            <Button
              variant="primary"
              width="20%"
              onClick={() => getTemplate()}
              background="#21a421 !important"
              disabled={downloadTemplate === true}
            >
              <Stack direction={"row"} alignItems={"center"}>
                {downloadTemplate ? (
                  <CircularProgress
                    sx={{
                      width: "20px!important",
                      height: "20px!important",
                      color: "#ffffff",
                    }}
                  />
                ) : null}
                <span className={downloadTemplate ? "ml-2" : ""}>
                  Unduh Template
                </span>
              </Stack>
            </Button>
          ) : null
        }
        fourthButton={
          checkPermission("Product", "is_add") ? (
            <Button
              variant="primary"
              width="25%"
              onClick={() => exportProducts()}
              background="#21a421 !important"
              disabled={downloadProduct === true}
            >
              <Stack direction={"row"} alignItems={"center"}>
                {downloadProduct ? (
                  <CircularProgress
                    sx={{
                      width: "20px!important",
                      height: "20px!important",
                      color: "#ffffff",
                    }}
                  />
                ) : null}

                <span className={downloadProduct ? "ml-2" : ""}>
                  Unduh Daftar Produk
                </span>
              </Stack>
            </Button>
          ) : null
        }
      />
    </MerchantLayout>
  );
};

export default ProductList;
