//import { encryptTransform } from "redux-persist-transform-encrypt";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import { persistCombineReducers } from "redux-persist";
import { AuthReducer } from "../auth";
import { RoleReducer } from "../role";
import { ListUserReducer } from "../user";
import { FeatureReducer } from "../feature";
import { AddUserReducer } from "../addUser";
import { EditUserReducer } from "../editUser";
import { DeleteUserReducer } from "../deleteUser";
import { CustomizeReducer } from "../customize/customize.reducer";
import { StoreListReducer } from "../storelist";
import { BrandsReducer } from "../brands";
import { CategoryReducer } from "../category";
import { SubCategoryReducer } from "../subCategory";
import { SubSubCategoryReducer } from "../subCategoryLevel3";
import { SequenceReducer } from "../sequence";
import { BannerReducer } from "../banner";
import { AppSettingReducer } from "../appsetting";
import { PuplistsReducer } from "../puplist";
import { PuplistsSetupReducer } from "../puplistsetup";
import { OrderReducer } from "../order";
import { StoreAccessReducer } from "../storeAccess";
import { OrderReportReducer } from "../orderReport";
import { LoyaltyReducer } from "../loyaltySettings";
import { DeliveryReducer } from "../deliverySettings";
import { ProfileReducer } from "../profile";
import StoreState from "../state";
import { AppOptionReducer } from "../appOption/appoption.reducer";
import { SesiKasirReducer } from "../sesiKasir";
import { ClosingReportReducer } from "../closingCashierReport";
import { PreAuthReducer } from "../preAuth";
import { NotificationReducer } from "../notification/notification.reducer";


const AppReducer = persistCombineReducers<StoreState>(
  {
    keyPrefix: "@",
    key: "sandboxdata",
    stateReconciler: autoMergeLevel2,
    storage,
    timeout: 0,
    serialize: true,
    // transforms: process.env.NODE_ENV === 'production' ? [
    //     encryptTransform({
    //         secretKey: process.env.REACT_APP_REDUX_SECRET_KEY ?? 'sandbox-secret--key',
    //         onError: err => {
    //             if (process.env.NODE_ENV === 'development') console.error(err)
    //         }
    //     })
    // ] : undefined,
  },
  {
    Auth: AuthReducer,
    Role: RoleReducer,
    User: ListUserReducer,
    Feature: FeatureReducer,
    AddUser: AddUserReducer,
    EditUser: EditUserReducer,
    DeleteUser: DeleteUserReducer,
    Customize: CustomizeReducer,
    StoreList: StoreListReducer,
    Brand: BrandsReducer,
    Category: CategoryReducer,
    SubCategory: SubCategoryReducer,
    Sequence: SequenceReducer,
    Banner: BannerReducer,
    SubSubCategory: SubSubCategoryReducer,
    AppSetting: AppSettingReducer,
    Puplist: PuplistsReducer,
    PuplistsSetup: PuplistsSetupReducer,
    Order: OrderReducer,
    StoreAccess: StoreAccessReducer,
    OrderReport: OrderReportReducer,
    LoyaltySetting: LoyaltyReducer,
    DeliverySetting: DeliveryReducer,
    AppOption: AppOptionReducer,
    Profile: ProfileReducer,
    SesiKasir: SesiKasirReducer,
    ClosingReport: ClosingReportReducer,
    PreAuth: PreAuthReducer,
    Notification: NotificationReducer,
  }
);

export default AppReducer;
