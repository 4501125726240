import { createAsyncThunk } from "@reduxjs/toolkit";
import { CityState } from "./types";
import axios from 'axios'

export const getAllCity = createAsyncThunk('cities/get', async (province_id: number) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/master/city/${province_id}/province`, {
            headers: {
                api_key: process.env.REACT_APP_API_KEY,
            }
        })

        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as CityState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as CityState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as CityState;

    }
})