import React, { useState, useEffect } from 'react';
import { Stack } from '../../../../components/styled/layout.styled';
import UserGroups from '../layout';
import { useNavigate } from 'react-router-dom';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import styles from './styles';
import {
  Box,
  Autocomplete,
  Button as ButtonMUI,
  TextField as TextFieldMUI,
  FormHelperText,
} from '@mui/material';
import { NormalText } from 'src/components/styled/text.styled';
import TextField from 'src/components/Inputs/TextField';
// import { Button } from "src/components/styled/button.styled";
import { createUser, addUserData } from 'src/redux/addUser';
import { getRole, RoleData } from 'src/redux/role';
import useRedux from 'src/redux/useRedux';
import PopUp from 'src/components/Modal/modal';
import { updateProfileUser } from 'src/redux/profile';
import { AuthLogout } from 'src/redux/auth';

const ProfilPage = () => {
  const navigate = useNavigate();

  const {
    thunkDispatch,
    storeState: { Auth, Profile, AddUser },
  } = useRedux();

  const [roles, setRoles] = React.useState<RoleData[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState('');
  const [dataAdd, setDataAdd] = useState({
    firstName: Auth?.data?.profile_data?.first_name ?? '',
    lastName: Auth?.data?.profile_data?.last_name ?? '',
    email: Auth?.data?.profile_data?.email ?? '',
    role: {
      id: null,
      name: '',
      description: '',
    },
  });
  const [loading, setLoading] = useState(true);
  const [errorValidasi, setErrorValidasi] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: '',
  });

  useEffect(() => {
    thunkDispatch(getRole({ offset: 0, limit: 0, filter: '' }))
      .unwrap()
      .then((res) => {
        if (res && res.status == 'success') {
          setRoles(res.data.data);
        }
      })
      .catch((err) => {
        setRoles([]);
        console.error(err);
      });
  }, []);

  const handleAddUser = () => {
    let dataObject = {
      data: {
        first_name: dataAdd.firstName,
        last_name: dataAdd.lastName,
        email: dataAdd.email,
      },
      id_user: Auth?.data?.profile_data?.store_access?.user_merchant_id ?? '',
    };
    thunkDispatch(updateProfileUser(dataObject))
      .unwrap()
      .then((res: any) => {
        if (res && res.status == 'success') {
          setLoading(false);
          setOpenModal(true);
          setStatusModal('success');
        } else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal('failed');
        }
      })
      .catch((err) => {
        setLoading(false);
        setStatusModal('failed');
        setOpenModal(true);
        console.error(err);
      });
    setLoading(false);
  };

  const handleLogout = () => {
    thunkDispatch(AuthLogout());
    navigate('/login');
  };

  const handleValidate = () => {
    if (!dataAdd.firstName || !dataAdd.lastName || !dataAdd.email) {
      const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      setErrorValidasi({
        ...errorValidasi,
        firstName: !dataAdd.firstName ? 'Nama depan wajib diisi' : '',
        lastName: !dataAdd.lastName ? 'Nama belakang wajib diisi' : '',
        email: !dataAdd.email
          ? 'Email wajib diisi'
          : regex.test(dataAdd.email) === false
          ? 'Format data harus email (contoh : email@sandbox.co.id)'
          : '',
      });
    } else if (dataAdd.firstName.length > 50 || dataAdd.lastName.length > 50) {
      setErrorValidasi({
        ...errorValidasi,
        firstName:
          dataAdd.firstName.length > 50 ? 'Maks 50 karakter nama depan' : '',
        lastName:
          dataAdd.lastName.length > 50 ? 'Maks 50 karakter nama belakang' : '',
        email: '',
        role: '',
      });
    } else {
      setErrorValidasi({
        ...errorValidasi,
        firstName: '',
        lastName: '',
        email: '',
        role: '',
      });
      handleAddUser();
    }
  };

  return (
    <MerchantLayout>
      <PopUp open={openModal} handleClose={() => setOpenModal(!openModal)}>
        <NormalText
          textAlign="center"
          fontWeight={'bold'}
          fontSize={'20px'}
          margin={'0 0 20px 0'}
        >
          {statusModal === 'success' ? 'Sukses' : 'Gagal'}
        </NormalText>
        <NormalText textAlign="center" fontSize={'14px'} margin={'0 0 20px 0'}>
          {statusModal === 'success' ? (
            <span>Sukses Update Profile user</span>
          ) : Profile?.error?.response?.data?.message ? (
            <span>{Profile.error.response.data.message}</span>
          ) : (
            <span>
              Gagal mengupdate profile, <br /> mohon periksa kembali form data
              anda
            </span>
          )}
        </NormalText>
        <Box textAlign={'center'} margin={'20px 0 0 0 '}>
          <ButtonMUI
            variant="contained"
            onClick={() =>
              statusModal === 'success'
                ? Auth.data &&
                  Auth.data.profile_data &&
                  Auth.data.profile_data.email
                  ? Auth.data.profile_data.email !== dataAdd.email
                    ? handleLogout()
                    : navigate('/')
                  : navigate('/')
                : setOpenModal(!openModal)
            }
            sx={{ width: 100, height: 40, marginRight: 3 }}
            color="error"
          >
            Oke
          </ButtonMUI>
        </Box>
      </PopUp>
      <UserGroups tabActive={0}>
        <Stack direction="column">
          <Box sx={styles.boxStyled}>
            <NormalText
              textAlign="start"
              fontWeight={'bold'}
              fontSize={'18px'}
              margin={'0 0 20px 0'}
            >
              Profil User
            </NormalText>
            <form>
              <NormalText
                textAlign="left"
                margin={'20px 0 5px 0'}
                fontSize={'14px'}
              >
                Nama Depan *
              </NormalText>
              <TextField
                type={'text'}
                style={{ height: 45 }}
                value={dataAdd.firstName}
                onChange={(event: any) =>
                  setDataAdd({
                    ...dataAdd,
                    firstName: event.target.value,
                  })
                }
                validationType={errorValidasi.firstName ? 'ERROR' : ''}
                validationText={errorValidasi.firstName}
                placeholder="Masukkan nama depan anda "
              />
              <NormalText
                textAlign="left"
                margin={'20px 0 5px 0'}
                fontSize={'14px'}
              >
                Nama Belakang *
              </NormalText>
              <TextField
                type={'text'}
                style={{ height: 45 }}
                value={dataAdd.lastName}
                onChange={(event: any) =>
                  setDataAdd({
                    ...dataAdd,
                    lastName: event.target.value,
                  })
                }
                validationType={errorValidasi.lastName ? 'ERROR' : ''}
                validationText={errorValidasi.lastName}
                placeholder="Masukkan nama belakang anda"
              />
              <NormalText
                textAlign="left"
                margin={'20px 0 5px 0'}
                fontSize={'14px'}
              >
                Email *
              </NormalText>
              <TextField
                type={'email'}
                value={dataAdd.email}
                style={{ height: 45 }}
                onChange={(event: any) =>
                  setDataAdd({
                    ...dataAdd,
                    email: event.target.value,
                  })
                }
                validationType={errorValidasi.email ? 'ERROR' : ''}
                validationText={errorValidasi.email}
                placeholder="Masukkan email anda"
              />
              {/* <TextField
                label={'Role *'}
                disabled
                placeholder={'Silahkan Pilih Role anda'}
                value={dataAdd.role.name}
              ></TextField> */}
              <Box textAlign={'start'} margin={'20px 0 0 0 '}>
                {/* <Button
                  variant={"danger"}
                  margin={"0 10px 0 0"}
                  onClick={() => navigate("/users/list")}
                >
                  CANCEL
                </Button> */}
                <ButtonMUI
                  variant="contained"
                  onClick={() => navigate('/')}
                  sx={{ width: 100, height: 40, marginRight: 3 }}
                  color="error"
                >
                  Batal
                </ButtonMUI>
                <ButtonMUI
                  variant="contained"
                  onClick={() => handleValidate()}
                  sx={{ width: 100, height: 40 }}
                >
                  Simpan
                </ButtonMUI>
                {/* <Button variant={"primary"} onClick={() => (handleValidate())}>
                  Save
                </Button> */}
              </Box>
            </form>
          </Box>
        </Stack>
      </UserGroups>
    </MerchantLayout>
  );
};

export default ProfilPage;
