import { ClosingResponseProps, ClosingReportState } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import { getList } from "./closingCashierReportService";
// create user
export const initialState: ClosingReportState = {
  data: {} as ClosingResponseProps,
  status: "loading",
  error: {},
};

export const ClosingReportSlice = createSlice({
  name: "Closing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getList.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(getList.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      });
  },
});

export const ClosingReportReducer = ClosingReportSlice.reducer;
