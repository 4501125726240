import React from 'react';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import { useNavigate, useParams } from 'react-router-dom';
import Card from 'src/components/Cards/card';
import { NormalText } from 'src/components/styled/text.styled';
import { Button } from 'src/components/styled/button.styled';
import TextField from 'src/components/Inputs/TextField';
import Dropdown, { OptionProps } from 'src/components/Inputs/Dropdown';
import { Stack, Switch } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import useRedux from 'src/redux/useRedux';
import { getAllStore, ListStoreData } from 'src/redux/storelist';
import {
  showTableDetails,
  createNewTables,
  CreateTableProps,
} from 'src/redux/tablelist';
import { getAllTableType, TableTypeData } from 'src/redux/tabletype';
import CheckPermission from 'src/helper/checkPermission';

const TableForm = () => {
  const navigate = useNavigate();
  const { thunkDispatch } = useRedux();
  const { id } = useParams();
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [stores, setStores] = React.useState<ListStoreData[]>();
  const [tableTypes, setTableTypes] = React.useState<TableTypeData[]>();
  const [payload, setPayload] = React.useState<CreateTableProps>({
    name: '',
    store_id: 0,
    table_type_id: 0,
    status: true,
  });

  const handleSubmit = () => {
    setIsSubmit(true);
    if (payload.table_type_id && payload.name && payload.store_id) {
      thunkDispatch(createNewTables(payload))
        .unwrap()
        .then((res) => {
          if (res && res.status === 'success') {
            toast.success('Berhasil menambahkan meja');
            setTimeout(() => {
              navigate('/stores/table/list');
            }, 1500);
          }
        });
    } else {
      toast.warning('Silahkan cek kembali inputan anda!');
    }
  };

  React.useEffect(() => {
    if (id) {
      thunkDispatch(showTableDetails(Number(id)))
        .unwrap()
        .then((res) => {
          if (res && res.status === 'success') {
            console.log;
          }
        });
    }
    thunkDispatch(getAllStore({ offset: 0, limit: 0 }))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          setStores(res.data.data);
        }
      });
    thunkDispatch(getAllTableType({ offset: 0, limit: 0 }))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          setTableTypes(res.data.data);
        }
      });
  }, []);

  return (
    <MerchantLayout>
      <ToastContainer />
      <Card>
        <NormalText fontWeight="bold" fontSize="20px" margin="0 0 20px 0">
          Tambah Meja Baru
        </NormalText>
        <TextField
          isRequired
          label={'Nama'}
          validationText={
            isSubmit && !payload.name
              ? 'Nama wajib diisi'
              : isSubmit && payload.name.length > 50
              ? 'Maksimal 50 karakter untuk nama'
              : ''
          }
          validationType={
            isSubmit && !payload.name
              ? 'ERROR'
              : isSubmit && payload.name.length > 50
              ? 'ERROR'
              : ''
          }
          value={payload.name}
          onChange={(e: any) => {
            setPayload({ ...payload, name: e.target.value });
          }}
        ></TextField>
        <Dropdown
          label={'Toko'}
          validationType={isSubmit && !payload.store_id ? 'ERROR' : ''}
          validationText={'Toko wajib diisi'}
          isRequired
          options={
            stores && stores.length > 0
              ? stores.map((item) => {
                  return {
                    label: item.store_name,
                    value: item.id,
                  } as OptionProps;
                })
              : []
          }
          value={payload.store_id}
          onChange={(value: any) => {
            setPayload({ ...payload, store_id: value });
          }}
        />
        <Dropdown
          label={'Tipe'}
          validationType={isSubmit && !payload.table_type_id ? 'ERROR' : ''}
          validationText={'Tipe meja wajib diisi'}
          isRequired
          options={
            tableTypes && tableTypes.length > 0
              ? tableTypes.map((item) => {
                  return {
                    label: `${item.name} (${item.total_person})`,
                    value: item.id,
                  } as OptionProps;
                })
              : []
          }
          value={payload.table_type_id}
          onChange={(value: any) => {
            setPayload({ ...payload, table_type_id: value });
          }}
        />
        <NormalText fontSize="12px">Status *</NormalText>
        <Switch
          checked={payload.status}
          onChange={(e: any) =>
            setPayload({ ...payload, status: e.target.checked })
          }
        />
        <Stack direction={'row'} spacing={2} margin={'20px 0 20px 0'}>
          <Button
            variant="danger"
            onClick={() => navigate('/stores/table/list')}
          >
            Batal
          </Button>
          {CheckPermission('Table Merchant', 'is_add') && (
            <Button variant="primary" onClick={() => handleSubmit()}>
              Simpan
            </Button>
          )}
        </Stack>
      </Card>
    </MerchantLayout>
  );
};

export default TableForm;
