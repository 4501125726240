import React from "react";
import Tables from "src/components/Table/tableData";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import { IconButton, Grid } from "@mui/material";
import { Edit, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  getAllProductStore,
  ProductResponseProps,
} from "src/redux/productStore";
import useRedux from "src/redux/useRedux";
import { ToastContainer, toast } from "react-toastify";
import checkPermission from "src/helper/checkPermission";

const ProductStoreList = () => {
  const navigate = useNavigate();
  const { thunkDispatch } = useRedux();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState("");
  const [status, setStatus] = React.useState("true");
  const [loading, setLoading] = React.useState(true);
  const [productStores, setProductStore] =
    React.useState<ProductResponseProps>();

  const columns = [
    {
      name: "No",
      selector: (_row: any, index: number) => index + 1,
      sortable: true,
    },
    {
      name: "Nama Produk",
      selector: (row: any) => row.product_name,
      sortable: true,
    },
    {
      name: "Daftar Toko",
      cell: (row: any) => {
        return row.productStore && row.productStore.length > 0
          ? row.productStore
              .map((item: any) => {
                return item.store_name;
              })
              .join(", ")
          : "";
      },
    },
    {
      name: "Aksi",
      center: true,
      cell: (item: any) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {checkPermission("Product Store", "is_add") && (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  navigate(`/products/store-product/${item.product_id}/assign`);
                }}
              >
                <Edit />
              </IconButton>
            )}
            <IconButton
              aria-label="edit"
              color="warning"
              onClick={() => {
                navigate(`/products/store-product/${item.product_id}/view`);
              }}
            >
              <Search />
            </IconButton>
          </Grid>
        );
      },
    },
  ];

  const fetchProduct = () => {
    setLoading(true);
    thunkDispatch(
      getAllProductStore({
        limit: limit,
        offset: page - 1,
        filter: filter ?? "",
      })
    )
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setLoading(false);
          setProductStore(res.data);
        } else {
          setLoading(false);
        }
      });
  };

  React.useEffect(() => {
    fetchProduct();
  }, [limit, page, filter, status]);

  return (
    <MerchantLayout>
      <ToastContainer />
      <Tables
        records={productStores?.data ?? []}
        columns={columns}
        title="Produk Toko"
        isSearch={true}
        onChangeSearch={(e) => setFilter(e.target.value)}
        valueSearch={filter}
        progressPending={loading}
        paginationPerPage={limit}
        totalRow={
          productStores && productStores.meta && productStores.meta.total
            ? productStores.meta.total
            : 0
        }
        onChangePage={(page) => setPage(page)}
        onChangeRowsPerPage={(currentRowsPerPage) =>
          setLimit(currentRowsPerPage)
        }
      />
    </MerchantLayout>
  );
};

export default ProductStoreList;
