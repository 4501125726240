import { CategoryState, CategoryResponseProps } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { getCategory, createCategory, removeCategory, updateCategory } from './categoryServices';

export const initialState: CategoryState = {
    data: {} as CategoryResponseProps,
    status: "loading",
    error: {}
}

export const CategorySlice = createSlice({
    name: 'role',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCategory.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getCategory.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(getCategory.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(createCategory.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(createCategory.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(createCategory.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(removeCategory.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(removeCategory.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(removeCategory.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(updateCategory.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(updateCategory.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(updateCategory.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
    }
})

export const CategoryReducer = CategorySlice.reducer