import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import styles from './styles';
import {
  Box,
  Button as ButtonMUI,
  FormHelperText,
  Switch,
  Grid,
  Stack,
  CircularProgress,
} from '@mui/material';
import { NormalText } from 'src/components/styled/text.styled';
import TextField from 'src/components/Inputs/TextField';
import { createBanner, updateBanner } from 'src/redux/banner';
import useRedux from 'src/redux/useRedux';
import 'react-datepicker/dist/react-datepicker.css';
import { uploadImage } from 'src/helper/uploadFile';
import moment from 'moment-timezone';
import Confirmation from 'src/components/Modal/confirmation';
import { toast, ToastContainer } from 'react-toastify';
import InputDateTime from 'src/components/Inputs/DateTimePicker';
import './stylesDate.css';
import styled from 'styled-components';

interface RouteState {
  data: any;
  statusUrl: string;
}

const UploadLabel = styled.label`
  min-width: 9%;
  height: 40px;
  background-color: white;
  color: black;
  padding: 10px;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 2px;
  border: 1px solid #1985d2;
  text-align: center;
  &:hover {
    background-color: #1985d2;
    color: #fff;
  }
`;

const AddBrandPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { data, statusUrl } = location.state as RouteState;
  const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState('');

  const [dataAdd, setDataAdd] = useState({
    name: statusUrl === 'edit' ? data?.banner_name ?? '' : '',
    dateFrom: data && data.date_from ? data.date_from : null,
    dateTo: data && data.date_to ? data.date_to : null,

    imageWeb: statusUrl === 'edit' ? data?.banner_image_web ?? '' : '',
    imageWebName: statusUrl === 'edit' ? data?.banner_image_web ?? '' : '',
    imageMobileName:
      statusUrl === 'edit' ? data?.banner_image_mobile ?? '' : '',
    imageMobile: statusUrl === 'edit' ? data?.banner_image_mobile ?? '' : '',
    imagePotrait: statusUrl === 'edit' ? data?.banner_image_kiosk ?? '' : '',
    imagePotraitName:
      statusUrl === 'edit' ? data?.banner_image_kiosk ?? '' : '',
    status: statusUrl === 'edit' ? data?.is_active : false,
  });
  const [loading, setLoading] = useState(true);
  const [loadingImg, setLoadingImg] = useState(false);
  const [loadingImgMobile, setLoadingImgMobile] = useState(false);
  const [loadingImgPotrait, setLoadingImgPotrait] = useState(false);
  const [errorValidasi, setErrorValidasi] = useState({
    name: '',
    imageWeb: '',
    imageMobile: '',
    imagePotrait: '',
    status: '',
    dateTo: '',
    dateFrom: '',
  });

  const {
    thunkDispatch,
    storeState: { Auth, Role, Banner },
  } = useRedux();

  const handleEditBanner = () => {
    let dataObject = {
      banner_name: dataAdd.name,
      banner_image_web: dataAdd.imageWeb,
      banner_image_mobile: dataAdd.imageMobile,
      banner_image_kiosk: dataAdd.imagePotrait,
      is_active: dataAdd.status,
      is_deleted: false,
      date_from: dataAdd.dateFrom,
      date_to: dataAdd.dateTo,
    };
    let dataTemp = {
      id: data?.id ?? '',
      data: dataObject,
      access_token: Auth.data.access_token,
    };
    thunkDispatch(updateBanner(dataTemp))
      .unwrap()
      .then(res => {
        if (res && res.status == 'success') {
          setLoading(false);
          setOpenModal(true);
          setStatusModal('success');
        } else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal('failed');
        }
      })
      .catch(err => {
        setLoading(false);
        setStatusModal('failed');
        setOpenModal(true);
        console.error(err);
      });
    setLoading(false);
  };

  const handleCreateBanner = () => {
    let dataObject = {
      banner_name: dataAdd.name,
      banner_image_web: dataAdd.imageWeb,
      banner_image_mobile: dataAdd.imageMobile,
      banner_image_kiosk: dataAdd.imagePotrait,
      is_active: dataAdd.status,
      is_deleted: false,
      date_from: dataAdd.dateFrom,
      date_to: dataAdd.dateTo,
    };
    let dataTemp = {
      id: '',
      data: dataObject,
      access_token: Auth.data.access_token,
    };
    thunkDispatch(createBanner(dataTemp))
      .unwrap()
      .then(res => {
        if (res && res.status == 'success') {
          setLoading(false);
          setOpenModal(true);
          setStatusModal('success');
        } else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal('failed');
        }
      })
      .catch(err => {
        setLoading(false);
        setStatusModal('failed');
        setOpenModal(true);
        console.error(err);
      });
  };

  const handleValidate = () => {
    if (
      !dataAdd.name ||
      !dataAdd.imageWeb ||
      !dataAdd.imagePotrait ||
      !dataAdd.dateFrom ||
      !dataAdd.dateTo
    ) {
      toast.warning('Silahkan cek kembali inputan anda');
      setErrorValidasi({
        ...errorValidasi,
        name: !dataAdd.name ? 'Nama Banner Wajib Diisi' : '',
        imageWeb: !dataAdd.imageWeb ? 'Image Web Wajib Diisi' : '',
        imageMobile: '',
        imagePotrait: !dataAdd.imagePotrait ? 'Image Potrait Wajib Diisi' : '',
        dateTo: !dataAdd.dateTo ? 'Waktu dan tanggal wajib diisi' : '',
        dateFrom: !dataAdd.dateFrom ? 'Waktu dan tanggal wajib diisi' : '',
      });
    } else if (dataAdd.name.length > 50) {
      toast.warning('Silahkan cek kembali inputan anda');
      setErrorValidasi({
        ...errorValidasi,
        name: dataAdd.name.length > 50 ? 'Nama Banner Maks 50 karakter' : '',
        imageWeb: '',
        imageMobile: '',
        imagePotrait: '',
        dateTo: '',
        dateFrom: '',
      });
    } else {
      setErrorValidasi({
        ...errorValidasi,
        name: '',
        imageWeb: '',
        imageMobile: '',
        imagePotrait: '',
        status: '',
        dateTo: '',
        dateFrom: '',
      });
      statusUrl === 'edit' ? handleEditBanner() : handleCreateBanner();
    }
  };

  const handleImage = (e: any, data: string) => {
    if (e.target.files && e.target.files[0]) {
      let imageName = e.target.files[0];
      let fileSize = imageName.size / (1024 * 1024);
      var img = new Image();
      img.src = window.URL.createObjectURL(imageName);
      img.onload = function () {
        var width = img.naturalWidth;
        var height = img.naturalHeight;

        var ValidHeight = 0;
        var ValidWidth = 0;

        if (data === 'imageWeb') {
          ValidWidth = 1080;
          ValidHeight = 300;
        }

        if (data === 'imageMobile') {
          ValidWidth = 360;
          ValidHeight = 120;
        }

        if (data === 'imagePotrait') {
          ValidWidth = 1080;
          ValidHeight = 1720;
        }

        if (width > ValidWidth || height > ValidHeight || fileSize > 5) {
          setErrorValidasi({
            ...errorValidasi,
            [data]: `Resolusi gambar maks. ${ValidWidth}x${ValidHeight} & ukuran maks. 5MB`,
          });
        } else {
          handleUploadFile(imageName, data).then((res: any) => {
            console.log('OK');
          });
          setErrorValidasi({
            ...errorValidasi,
            [data]: '',
          });
        }
      };
    }
  };

  const handleUploadFile = async (file: any, typeUpload: string) => {
    typeUpload === 'imageWeb'
      ? setLoadingImg(true)
      : typeUpload === 'imageMobile'
      ? setLoadingImgMobile(true)
      : setLoadingImgPotrait(true);
    let formdata = new FormData();
    formdata.append('image_count', '1');
    formdata.append('image-1', file);
    await uploadImage(formdata, 'banner')
      .then(res => {
        if (res.status === 200) {
          setLoadingImg(false);
          setLoadingImgMobile(false);
          setLoadingImgPotrait(false);
          setDataAdd({
            ...dataAdd,
            [typeUpload]: res.data.data['image-1'],
            [`${typeUpload}Name`]: res.data.data['image-1'],
          });
        } else {
          setLoadingImg(false);
          setLoadingImgMobile(false);
          setLoadingImgPotrait(false);
        }
      })
      .catch(err => {
        setLoadingImg(false);
        setLoadingImgMobile(false);
        setLoadingImgPotrait(false);
        console.error(err);
        setErrorValidasi({
          ...errorValidasi,
          [typeUpload]: 'Gagal mengupload image',
        });
      });
  };

  React.useEffect(() => {
    setDataAdd({
      name: statusUrl === 'edit' ? data?.banner_name ?? '' : '',
      dateFrom: data && data.date_from ? data.date_from : '',
      dateTo: data && data.date_to ? data.date_to : '',
      imageWeb: statusUrl === 'edit' ? data?.banner_image_web ?? '' : '',
      imageWebName: statusUrl === 'edit' ? data?.banner_image_web ?? '' : '',
      imageMobileName:
        statusUrl === 'edit' ? data?.banner_image_mobile ?? '' : '',
      imageMobile: statusUrl === 'edit' ? data?.banner_image_mobile ?? '' : '',
      imagePotrait: statusUrl === 'edit' ? data?.banner_image_kiosk ?? '' : '',
      imagePotraitName:
        statusUrl === 'edit' ? data?.banner_image_kiosk ?? '' : '',
      status: statusUrl === 'edit' ? data?.is_active : false,
    });
  }, []);

  return (
    <MerchantLayout>
      <ToastContainer />
      <Confirmation
        handleConfirm={() =>
          statusModal === 'success'
            ? navigate('/informasi/banner')
            : setOpenModal(false)
        }
        open={openModal}
        handleClose={() => setOpenModal(false)}
        hasCancel={false}
        title={statusModal === 'success' ? 'Sukses' : 'Gagal'}
        description={
          statusModal === 'success'
            ? statusUrl === 'edit'
              ? 'Berhasil mengupdate data banner'
              : 'Berhasil menambahkan banner baru'
            : Banner?.error?.response?.data?.message
            ? Banner.error.response.data.message
            : statusUrl === 'edit'
            ? 'Gagal mengupate banner, silahkan periksa kembali data anda'
            : 'Gagal menambah banner, silahkan periksa kembali data anda'
        }></Confirmation>
      <Stack direction="column">
        <Box sx={styles.boxStyled}>
          <NormalText
            textAlign="start"
            fontWeight={'bold'}
            fontSize={'18px'}
            margin={'0 0 20px 0'}>
            {statusUrl === 'edit' ? 'Edit Banner' : 'Tambah Banner'}
          </NormalText>
          <form>
            <NormalText
              textAlign="left"
              margin={'20px 0 5px 0'}
              fontSize={'14px'}>
              Nama Banner *
            </NormalText>
            <TextField
              type={'text'}
              style={{ height: 45 }}
              value={dataAdd.name}
              onChange={(event: any) =>
                setDataAdd({
                  ...dataAdd,
                  name: event.target.value,
                })
              }
              validationType={errorValidasi.name ? 'ERROR' : ''}
              validationText={errorValidasi.name}
              placeholder="Masukkan nama banner anda"
            />
            <NormalText
              textAlign="left"
              margin={'20px 0 5px 0'}
              fontSize={'14px'}>
              Gambar Web *
            </NormalText>
            <form>
              <UploadLabel
                htmlFor="imageUploadWeb"
                style={{
                  backgroundColor: loadingImg ? '#ccc' : '',
                  borderColor: loadingImg ? '#ccc' : '',
                }}>
                {loadingImg ? <CircularProgress size={15} /> : null}{' '}
                {loadingImg ? '' : 'Pilih Gambar'}
              </UploadLabel>
              <input
                accept="image/*"
                id="imageUploadWeb"
                multiple
                type="file"
                style={{ display: 'none' }}
                onChange={(e: any) => handleImage(e, 'imageWeb')}
              />
              {dataAdd.imageWeb && (
                <img
                  src={dataAdd.imageWeb}
                  style={{ height: 110, width: 310, marginLeft: '30px' }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              )}
            </form>
            <FormHelperText style={{ color: '#1565c0' }}>
              Resolusi gambar maks. 1080x300 Pixels {'&'} ukuran maks. 5MB
            </FormHelperText>
            <FormHelperText style={{ color: '#f12147' }}>
              {errorValidasi.imageWeb}
            </FormHelperText>
            <NormalText
              textAlign="left"
              margin={'20px 0 5px 0'}
              fontSize={'14px'}>
              Gambar Mobile
            </NormalText>
            <form>
              <UploadLabel
                htmlFor="imageUploadMobile"
                style={{
                  backgroundColor: loadingImgMobile ? '#ccc' : '',
                  borderColor: loadingImgMobile ? '#ccc' : '',
                }}>
                {loadingImgMobile ? <CircularProgress size={15} /> : null}{' '}
                {loadingImgMobile ? '' : 'Pilih Gambar'}
              </UploadLabel>
              <input
                accept="image/*"
                id="imageUploadMobile"
                multiple
                type="file"
                onChange={(e: any) => handleImage(e, 'imageMobile')}
                style={{ display: 'none' }}
              />
              {dataAdd.imageMobile && (
                <img
                  src={dataAdd.imageMobile}
                  style={{ height: 60, width: 180, marginLeft: '30px' }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              )}
            </form>
            <FormHelperText style={{ color: '#1565c0' }}>
              Resolusi gambar maks. 360x120 Pixels {'&'} ukuran maks. 5MB
            </FormHelperText>
            <FormHelperText style={{ color: '#f12147' }}>
              {errorValidasi.imageMobile}
            </FormHelperText>
            <NormalText
              textAlign="left"
              margin={'20px 0 5px 0'}
              fontSize={'14px'}>
              Gambar Potrait *
            </NormalText>
            <form>
              <UploadLabel
                htmlFor="imageUploadPotrait"
                style={{
                  backgroundColor: loadingImgPotrait ? '#ccc' : '',
                  borderColor: loadingImgPotrait ? '#ccc' : '',
                }}>
                {loadingImgPotrait ? <CircularProgress size={15} /> : null}{' '}
                {loadingImgPotrait ? '' : 'Pilih Gambar'}
              </UploadLabel>
              <input
                accept="image/*"
                id="imageUploadPotrait"
                multiple
                type="file"
                onChange={(e: any) => handleImage(e, 'imagePotrait')}
                style={{ display: 'none' }}
              />
              {dataAdd.imagePotrait && (
                <img
                  src={dataAdd.imagePotrait}
                  style={{ height: 240, width: 140, marginLeft: '30px' }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              )}
            </form>
            <FormHelperText style={{ color: '#1565c0' }}>
              Resolusi gambar maks. 1080x1720 Pixels {'&'} ukuran maks. 5MB
            </FormHelperText>
            <FormHelperText style={{ color: '#f12147' }}>
              {errorValidasi.imagePotrait}
            </FormHelperText>
            <FormHelperText style={{ color: '#f12147' }}>
              {errorValidasi.status}
            </FormHelperText>
            <Grid container>
              <Grid item xs={12} sm={12} md={4}>
                <NormalText
                  textAlign="left"
                  margin={'20px 0 5px 0'}
                  fontSize={'14px'}>
                  Tanggal dan Waktu Dari *
                </NormalText>
                <FormHelperText style={{ color: '#f12147' }}>
                  {errorValidasi.dateFrom}
                </FormHelperText>
                <InputDateTime
                  value={
                    dataAdd.dateFrom
                      ? moment(dataAdd.dateFrom).format('YYYY-MM-DD[T]HH:mm:ss')
                      : null
                  }
                  onChange={e => {
                    console.log('CAHNGE', e);
                    console.log(
                      'CAHNGE PARSED',
                      moment(e).format('YYYY-MM-DD[T]HH:mm:ss'),
                    );
                    setDataAdd({
                      ...dataAdd,
                      dateFrom: moment(e).format('YYYY-MM-DD[T]HH:mm:ss'),
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <NormalText
                  textAlign="left"
                  margin={'20px 0 5px 0'}
                  fontSize={'14px'}>
                  Tanggal dan Waktu Sampai *
                </NormalText>
                <FormHelperText style={{ color: '#f12147' }}>
                  {errorValidasi.dateTo}
                </FormHelperText>
                <InputDateTime
                  value={
                    dataAdd.dateTo
                      ? moment(dataAdd.dateTo).format('YYYY-MM-DD[T]HH:mm:ss')
                      : null
                  }
                  onChange={(e: any) => {
                    setDataAdd({
                      ...dataAdd,
                      dateTo: moment(e).format('YYYY-MM-DD[T]HH:mm:ss'),
                    });
                  }}
                />
              </Grid>
            </Grid>
            <Stack
              direction={'row'}
              spacing={2}
              alignItems={'center'}
              margin={'20px 0 10px 0'}>
              <NormalText textAlign="left" fontSize={'14px'}>
                Status *
              </NormalText>
              <Switch
                checked={dataAdd.status}
                onChange={(e: any) =>
                  setDataAdd({
                    ...dataAdd,
                    status: e.target.checked,
                  })
                }
              />
            </Stack>
            <Box textAlign={'start'} margin={'20px 0 0 0 '}>
              <ButtonMUI
                variant="contained"
                onClick={() => navigate('/informasi/banner')}
                sx={{ width: 100, height: 40, marginRight: 3 }}
                color="error">
                Kembali
              </ButtonMUI>
              <ButtonMUI
                variant="contained"
                onClick={() => handleValidate()}
                sx={{ width: 100, height: 40 }}>
                Save
              </ButtonMUI>
            </Box>
          </form>
        </Box>
      </Stack>
    </MerchantLayout>
  );
};

export default AddBrandPage;
