import { createAsyncThunk } from "@reduxjs/toolkit";
import { TableListState, CreateTableProps, TableDetailState } from "./types";
import { PaginationProps } from "src/redux/types";
import ApiService from "src/services/ApiService";
import { getToken } from 'src/helper/generalFunction';

export const getAllTables = createAsyncThunk('tablelist/get', async (props: PaginationProps & { storeId?: number }) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.get(`${process.env.REACT_APP_API_URL}/merchant/tables?storeId=${props.storeId}&limit=${props.limit ?? 10}&offset=${props.offset ?? 0}${props.filter ? '&filter=' + props.filter : ''}`, config)

        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as TableListState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as TableListState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as TableListState;

    }
})

export const showTableDetails = createAsyncThunk('tables/show', async (store_id: number) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.get(`${process.env.REACT_APP_API_URL}/merchant/tables/${store_id}`, config)

        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as TableDetailState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as TableDetailState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as TableDetailState;

    }
})

export const createNewTables = createAsyncThunk('tables/post', async (props: CreateTableProps) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.post(process.env.REACT_APP_API_URL + '/merchant/tables', props, config)
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as TableListState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as TableListState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as TableListState;

    }
})

export const updateTables = createAsyncThunk('tables/put', async (props: CreateTableProps) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const { name, status, table_type_id, store_id } = props
        const response = await ApiService.put(`${process.env.REACT_APP_API_URL}/merchant/tables/${props.id}`, { name, status, table_type_id, store_id }, config)
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as TableListState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as TableListState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as TableListState;

    }
})

export const removeTable = createAsyncThunk('tables/delete', async (type_id: number) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.delete(`${process.env.REACT_APP_API_URL}/merchant/tables/${type_id}`, config)
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as TableListState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as TableListState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as TableListState;

    }
})

export const changeAvailablity = createAsyncThunk('changeAvailable/put', async ({id, is_available}: {id: number, is_available: boolean}) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.put(`${process.env.REACT_APP_API_URL}/merchant/${id}/change-availability-table`, { is_available }, config)
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as TableListState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as TableListState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as TableListState;

    }
})