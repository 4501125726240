import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import Tables from "src/components/Table/tableData";
import {
  Switch,
  IconButton,
  Grid,
  Select,
  FormControl,
  Stack,
  MenuItem,
} from "@mui/material";
import { Delete, Edit, Search } from "@mui/icons-material";
import {
  DeleteBannerProps,
  getBanner,
  removeBanner,
  BannerData,
  updateStatusBanner,
  UpdateStatusBannerProps,
} from "src/redux/banner";
import useRedux from "src/redux/useRedux";
import { NormalText } from "src/components/styled/text.styled";
import ConfirmationModal from "src/components/Modal/confirmation";
import checkPermission from "src/helper/checkPermission";
import moment, { locale } from "moment";

const statusFilter = [
  {
    id: 1,
    label: "Aktif",
  },
  {
    id: 0,
    label: "Non Aktif",
  },
];

const BannerPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<BannerData[]>([]);
  const [dataFirst, setDataFirst] = useState<BannerData[]>([]);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [idUser, setIdUser] = useState(0);
  const [statusDel, setStatusDel] = useState("");
  const [status, setStatus] = useState("");
  const [update, setUpdate] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [totalData, setTotalData] = useState(0);

  const handleDeleteUser = (id: any) => {
    setIdUser(id);
    setOpenConfirmation(true);
  };

  const columns = [
    {
      name: "Nama Banner",
      selector: (row: any) => row.banner_name,
      sortable: true,
      center: true,
    },
    {
      name: "Dari Tanggal",
      cell: (row: any) =>
        moment(row.date_from).format("DD MMMM YYYY HH:mm"),
      sortable: true,
      center: true,
    },
    {
      name: "Sampai Tanggal",
      cell: (row: any) =>
        moment(row.date_to).format("DD MMMM YYYY HH:mm"),
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      center: true,
      cell: (row: any) => {
        return (
          <Switch
            checked={row.is_active}
            onChange={(e: any) => handleUpdate(e.target.checked, row.id)}
            disabled={checkPermission("Banner", "is_edit") ? false : true}
          />
        );
      },
    },
    {
      name: "Aksi",
      center: true,
      cell: (row: any) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {checkPermission("Banner", "is_edit") && (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() =>
                  navigate("/informasi/banner/edit-banner", {
                    state: { data: row, statusUrl: "edit" },
                  })
                }
              >
                <Edit />
              </IconButton>
            )}
            <IconButton
              aria-label="search"
              color="warning"
              onClick={() =>
                navigate("/informasi/banner/view-banner", {
                  state: { data: row, status: "view" },
                })
              }
            >
              <Search />
            </IconButton>
            {checkPermission("Banner", "is_delete") && (
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => handleDeleteUser(row.id)}
              >
                <Delete />
              </IconButton>
            )}
          </Grid>
        );
      },
    },
  ];

  const {
    thunkDispatch,
    storeState: { Auth, Banner },
  } = useRedux();

  const getAll = () => {
    let dataObject = {
      offset: offset - 1,
      limit: limit,
      filter: filter,
    };
    thunkDispatch(getBanner(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setData(res.data.data);
          setDataFirst(res.data.data);
          setTotalData(
            res.data && res.data.meta && res.data.meta.total
              ? res.data.meta.total
              : 0
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
    // if(Brand.data && Brand.data.data) setData(Brand.data.data)
    setLoading(false);
  };

  React.useEffect(() => {
    getAll();
  }, [filter, limit, offset]);

  const handleDelete = () => {
    let dataDelete = {
      banner_id: idUser,
      access_token: Auth.data.access_token,
    };
    thunkDispatch(removeBanner(dataDelete as DeleteBannerProps))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setOpenConfirmation(!openConfirmation);
          setOpenModal(true);
          getAll();
          setStatusDel("success");
          setLoading(false);
        } else {
          setOpenConfirmation(!openConfirmation);
          setLoading(false);
          setOpenModal(true);
          setStatusDel("failed");
        }
      })
      .catch((err) => {
        setOpenConfirmation(!openConfirmation);
        setLoading(false);
        setStatusDel("failed");
        console.error(err);
      });
    setLoading(false);
  };

  const handleUpdate = (val: boolean, id: number) => {
    let dataUpdate = {
      banner_id: id,
      access_token: Auth.data.access_token,
      data: {
        is_active: val,
      },
    };
    thunkDispatch(updateStatusBanner(dataUpdate as UpdateStatusBannerProps))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setUpdate(true);
          getAll();
          setStatusDel("success");
          setLoading(false);
        } else {
          setLoading(false);
          setUpdate(true);
          setStatusDel("failed");
        }
      })
      .catch((err) => {
        setLoading(false);
        setUpdate(true);
        setStatusDel("failed");
        console.error(err);
      });
    setLoading(false);
  };

  useEffect(() => {
    getAll();
  }, []);

  const Filter = (val: any) => {
    let tempVal = val !== "" ? (val == 1 ? true : false) : null;
    let res =
      tempVal !== null && dataFirst.length != 0
        ? dataFirst.filter((word: any) => word.is_active == tempVal)
        : dataFirst;
    setData(res);
    setStatus(val);
  };

  return (
    <MerchantLayout>
      <ConfirmationModal
        title="Konfirmasi"
        titleStyle={{ fontWeight: "bold" }}
        description="Apakah anda yakin ingin menghapus banner ini?"
        open={openConfirmation}
        handleConfirm={() => handleDelete()}
        handleClose={() => setOpenConfirmation(!openConfirmation)}
      ></ConfirmationModal>
      <ConfirmationModal
        title={update ? "Update Banner" : "Hapus Banner"}
        hasCancel={false}
        open={openModal || update}
        handleClose={() => {
          setOpenModal(false);
          setUpdate(false);
          setStatusDel("");
        }}
        handleConfirm={() => {
          setOpenModal(false);
          setUpdate(false);
          setStatusDel("");
        }}
        description={
          statusDel == "success"
            ? `Sukses ${update ? "mengubah status" : "menghapus"} banner`
            : Banner?.error?.response?.data?.message
            ? Banner.error.response.data.message
            : `Gagal ${update ? "mengubah status" : "menghapus"} banner`
        }
      />
      <Tables
        records={data}
        columns={columns}
        title="Daftar Banner"
        isSearch={true}
        addButton={checkPermission("Banner", "is_add")}
        titleAdd="Tambah Banner"
        onChangeSearch={(e) => setFilter(e.target.value)}
        valueSearch={filter}
        handleAddButton={() =>
          navigate("/informasi/banner/add-banner", {
            state: { data: null, statusUrl: "add" },
          })
        }
        progressPending={loading}
        paginationPerPage={limit}
        totalRow={totalData}
        onChangePage={(page) => setOffset(page)}
        onChangeRowsPerPage={(currentRowsPerPage) =>
          setLimit(currentRowsPerPage)
        }
        filter={
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <NormalText textAlign="start" fontSize={"16px"}>
              Filter Status
            </NormalText>
            <FormControl sx={{ minWidth: 250 }}>
              <Select
                value={status}
                onChange={(e) => Filter(e.target.value)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ height: 40 }}
              >
                <MenuItem value="">
                  <em>Pilih Status</em>
                </MenuItem>
                {statusFilter.map((value, index) => {
                  return <MenuItem value={value.id}>{value.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Stack>
        }
      />
    </MerchantLayout>
  );
};

export default BannerPage;
