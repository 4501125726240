import React, { useState, useEffect, useRef } from "react";
import { records } from "./dummy";
import { useNavigate } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import { Accordion, AccordionSummary, AccordionDetails, Button as ButtonMUI, Box, Select, MenuItem } from "@mui/material";
import { 
  ListSequenceProps,
  getList,
  updateList,
  sequenceResponseProps,
  sequenceProps } from "src/redux/sequence";
import useRedux from "src/redux/useRedux";
import { NormalText } from "src/components/styled/text.styled";
import styles from "./styles";
import MinimizeIcon from '@mui/icons-material/Minimize';
import AddIcon from '@mui/icons-material/Add';
import PopUp from "src/components/Modal/modal";


interface RouteState {
  id: number;
  sequence: number;
};


const UserListPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<sequenceResponseProps>(
    {} as sequenceResponseProps
  );
  const [loading , setLoading] = useState(true)
  const [openModal, setOpenModal] = useState(false);
  const [itemList, setItemList] = useState<sequenceProps[]>([]);
  const dragItem = useRef<any>(0);
  const dragOverItem = useRef<any>(0);
  const [expanded, setExpanded] = useState(true);
  const [statusDel, setStatusDel] = useState("");

  const {
    thunkDispatch,
    storeState: { Auth, Sequence },
  } = useRedux();

  const getAll = () => {
    let data = {
      access_token: Auth.data.access_token,
    }
    thunkDispatch(getList(data as ListSequenceProps))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setData(res.data);
          setItemList(res.data.data)
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
    setLoading(false);
  };

  useEffect(() => {
    getAll()
  }, []);

  const convertData = (data: any) => {
    let res : any = [];
    data.length != 0 && data.map((item: any, i: number) => 
      res.push({
        id : item.id,
        sequence: i + 1
      })
    )
    return res;
  }

  const handleUpdate = () => {
    let temp = convertData(itemList)
    let dataObject = {
      data: temp,
      access_token: Auth.data.access_token,
    }
    thunkDispatch(updateList(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setOpenModal(true);
          setStatusDel('success');
          setLoading(false);
        } else {
          setOpenModal(true);
          setStatusDel('failed');
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setStatusDel('failed');
        console.error(err);
      });
    setLoading(false);
  }

  const dragStart = (e: any, position: any) => {
    dragItem.current = position;
    console.log(e.target.innerHTML);
  };

  const dragEnter = (e: any, position: any) => {
    dragOverItem.current = position;
    console.log(e.target.innerHTML);
  };

  const drop = (e : any) => {
    const copyListItems = [...itemList];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setItemList(copyListItems);
  };
  
  return (
    <MerchantLayout>
      <PopUp open={openModal} 
        handleClose={() => {
          setOpenModal(false)
          setStatusDel('')
          }}>
          <NormalText
            textAlign="center"
            fontWeight={"bold"}
            fontSize={"20px"}
            margin={"0 0 20px 0"}
          >
            Update Sequence
          </NormalText>
          <NormalText textAlign="center" fontSize={"14px"} margin={"0 0 20px 0"}>
            {statusDel == 'success' ? `Sukses mengupdate sequence` :
            Sequence?.error?.response?.data?.message ? (
              <span>{Sequence.error.response.data.message}</span>
            ) : `Gagal mengupdate sequence`}
          </NormalText>
          <Box textAlign={"center"} margin={"20px 0 0 0 "}>
            <ButtonMUI
                variant="contained"
                onClick={() => {
                  statusDel == 'success' ? navigate("/products/categories") : null
                  setOpenModal(false)
                  setStatusDel('')
                }}
                sx={{ width: 100, height: 40, marginRight: 3 }}
                color="error"
            >
                Ok
            </ButtonMUI>
          </Box>
        </PopUp>
    <Box sx={[styles.boxStyled, styles.width100p, styles.padding20]}>
      <NormalText
        textAlign="start"
        fontWeight={"bold"}
        fontSize={"18px"}
        margin={"0 0 20px 0"}
      >
        Sequence Sub Category
      </NormalText>
      <Box textAlign={"center"} margin={"20px 0 0 0 "}>
        <Accordion
          disableGutters
          elevation={0}
          square
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
          style={{ border: `1px solid #f39c12` }}
        >
          <AccordionSummary
            style={{ backgroundColor: "#f39c12" }}
            expandIcon={expanded ? <MinimizeIcon sx={{ fontSize: "14px", color : 'white' }} /> : <AddIcon sx={{ fontSize: "14px", color : 'white' }}/>}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <NormalText textAlign="start" fontSize={"18px"} color={"#ffffff"}>
              Sequence
            </NormalText>
          </AccordionSummary>
          <AccordionDetails
            style={{ padding: 10, borderTop: "1px solid rgba(0, 0, 0, .125)" }}
          >
            {itemList.length != 0 && itemList.map((item, index) => (
              <div
                style={{
                  backgroundColor: "white",
                  marginLeft: 20,
                  marginRight: 20,
                  textAlign: "center",
                  fontSize: "16px",
                  border: "1px #ccc solid",
                  padding: 10,
                }}
                key={index}
                draggable
                onDragStart={(e: any) => dragStart(e, index)}
                onDragEnter={(e: any) => dragEnter(e, index)}
                onDragEnd={drop}
                onDragOver={(e: any) => e.preventDefault()}
              >
                {item.subscategory_name}
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
        <Box textAlign={"start"} margin={"20px 0 0 0 "}>
            <ButtonMUI
              variant="contained"
              sx={{ width: 100, height: 40, marginRight: 3 }}
              color="error"
              onClick={() => navigate("/products/categories")}
            >
              Kembali
            </ButtonMUI>
            <ButtonMUI
              variant="contained"
              sx={{ width: 100, height: 40, marginRight: 3 }}
              color="primary"
              onClick={() => handleUpdate()}
            >
              Simpan
            </ButtonMUI>
        </Box>
      </Box>
    </Box>
  </MerchantLayout>  
  );
};

export default UserListPage;
