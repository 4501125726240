import { StoreAccessResponseProps, StoreAccessState } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import {
  getList,
  createAssign,
  updateAssign,
  deleteAssign,
} from "./storeAccessService";
// create user
export const initialState: StoreAccessState = {
  data: {} as StoreAccessResponseProps,
  status: "loading",
  error: {},
};

export const StoreAccessSlice = createSlice({
  name: "StoreAccess",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getList.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(getList.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      })
      .addCase(createAssign.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createAssign.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(createAssign.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      })
      .addCase(updateAssign.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAssign.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(updateAssign.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      })
      .addCase(deleteAssign.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteAssign.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(deleteAssign.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      });
  },
});

export const StoreAccessReducer = StoreAccessSlice.reducer;
