import React from "react";
import { Stack, Autocomplete, TextField, Grid } from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";
import useRedux from "src/redux/useRedux";
import { getAllStore, ListStoreData } from "src/redux/storelist";

interface FilterStoreProps {
  value: any;
  onChange: any;
  filterType?: "table" | "form" | "report";
}

const FilterStore = ({
  value,
  onChange,
  filterType = "table",
}: FilterStoreProps) => {
  const { thunkDispatch } = useRedux();
  const [stores, setStores] = React.useState<ListStoreData[]>();

  React.useEffect(() => {
    thunkDispatch(getAllStore({ limit: 0, offset: 0 }))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setStores(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return filterType === "table" ? (
    <Stack direction={"column"} alignItems={"start"} spacing={1}>
      <NormalText textAlign="start" fontSize={"14px"}>
        Filter Berdasarkan Toko
      </NormalText>
      <Autocomplete
        id="combo-box-store-list"
        options={stores && stores.length > 0 ? stores : []}
        getOptionLabel={(option) => option.store_name || ""}
        onChange={(event: any, value: any) => {
          return onChange(value.id);
        }}
        disableClearable
        sx={{ width: "250px" }}
        defaultChecked
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ fontSize: 12 }}
            size="small"
            placeholder="Pilih Toko"
            value={value}
          />
        )}
      />
    </Stack>
  ) : filterType === "report" ? (
    <Grid container margin={"20px 0 0 0"}>
      <Grid item xs={12} sm={12} md={2}>
        <NormalText fontSize="15px" fontWeight="bold">
          Pilih Toko
        </NormalText>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Autocomplete
          id="combo-box-store-list"
          options={stores && stores.length > 0 ? stores : []}
          getOptionLabel={(option) => option.store_name || ""}
          onChange={(event: any, value: any) => {
            return onChange({ id: value.id, store_name: value.store_name });
          }}
          disableClearable
          sx={{ width: "250px" }}
          defaultChecked
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ fontSize: 12 }}
              size="small"
              placeholder="Pilih Toko"
              value={value}
            />
          )}
        />
      </Grid>
    </Grid>
  ) : (
    <Grid container margin={"20px 0 0 0"}>
      <Grid item xs={12} sm={12} md={2}>
        <NormalText fontSize="15px" fontWeight="bold">
          Pilih Toko
        </NormalText>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Autocomplete
          id="combo-box-store-list"
          options={stores && stores.length > 0 ? stores : []}
          getOptionLabel={(option) => option.store_name || ""}
          onChange={(event: any, value: any) => {
            return onChange(value.id);
          }}
          disableClearable
          sx={{ width: "250px" }}
          defaultChecked
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ fontSize: 12 }}
              size="small"
              placeholder="Pilih Toko"
              value={value}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default FilterStore;
