import { createAsyncThunk } from "@reduxjs/toolkit";
import { FeeSettingState, CreateFeeSettingTypes, SandboxFeeState, FeeProps } from "./types";
import ApiService from "src/services/ApiService";
import axios from "axios";
import { getToken } from 'src/helper/generalFunction';
import { PaginationProps } from "../types";

export const getFeeSetting = createAsyncThunk('feesetting/get', async (props: FeeProps) => {
    try {
        const config = {
            headers: {
                token: getToken(),
                api_key: process.env.REACT_APP_API_KEY,
            }
        };
        const response = await ApiService.get(`${process.env.REACT_APP_API_URL}/merchant/feesetting?limit=${props.limit ?? 10}&offset=${props.offset ?? 0}${props.filter ? '&filter=' + props.filter : ''}${props.user_type ? '&user_type=' + props.user_type : ''}${props.store_id ? '&store_id=' + props.store_id : ''}`, config)
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as FeeSettingState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as FeeSettingState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as FeeSettingState;

    }
})

export const createFeeSetting = createAsyncThunk('feesetting/post', async (props: CreateFeeSettingTypes) => {
    try {
        const { payment_fee_type, platform_fee_type, service, tax, updated_by, user_type, store_id } = props;

        const response = await axios.post(process.env.REACT_APP_API_URL + '/merchant/feesetting', { payment_fee_type, platform_fee_type, service, tax, updated_by, user_type, store_id }, {
            headers: {
                api_key: process.env.REACT_APP_API_KEY,
                token: getToken()
            }
        })
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as FeeSettingState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as FeeSettingState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as FeeSettingState;

    }
});


// Fetch config from cms
export const getSandboxFeeConfig = createAsyncThunk('sandboxfeeconfig/get', async () => {
    try {
        const config = {
            headers: {
                token: getToken(),
                api_key: process.env.REACT_APP_API_KEY,
            }
        };
        const response = await ApiService.get(`${process.env.REACT_APP_API_URL}/master/feesettingconfig`, config)
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as SandboxFeeState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as SandboxFeeState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as SandboxFeeState;

    }
})