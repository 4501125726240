import { Box } from "@mui/material";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { TransactionChartProps } from "src/redux/transaction";

const TransactionChart = ({
  transactions,
}: {
  transactions: TransactionChartProps[];
}) => {
  const renderCustomAxisTick = ({
    x,
    y,
    payload,
  }: {
    x: any;
    y: any;
    payload: any;
  }) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg">
        <g>
          <text x={x - 12} y={y + 12}>
            {moment(payload.value).format("MMM")}
          </text>
        </g>
      </svg>
    );
  };

  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer width={"100%"}>
        <LineChart
          data={transactions}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="date" tick={renderCustomAxisTick} />
          <YAxis />
          <Tooltip
            content={(v: any) => (
              <CustomTooltip
                active={v.active}
                payload={v.payload}
                label={v.label}
              />
            )}
          />
          <Legend
            formatter={(value, entry, index) => {
              return (
                <p>
                  {value === "total_transaction" ? "Transaksi" : "Pelanggan"}
                </p>
              );
            }}
          />
          <Line
            type="monotone"
            dataKey="total_transaction"
            stroke="#8884d8"
            textAnchor="Total Transaksi"
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey="total_member"
            stroke="#6af25b"
            textAnchor="Total Pelanggan"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const CustomTooltip = ({
  payload,
  label,
  active,
}: {
  payload: any;
  label: any;
  active: any;
}) => {
  if (active) {
    return (
      <Box sx={{ background: "#e4e4e454", padding: "10px" }}>
        <p style={{ margin: "0 0 -7px 0" }}>{`${moment(label).format(
          "MMMM"
        )}`}</p>
        <p style={{ color: payload && payload.length > 0 ? payload[0].stroke : 'yellow', margin: "0 0 -7px 0" }}>
          {payload && payload[0].textAnchor} : {payload[0].value}
        </p>
        <p style={{ color: payload.length > 1 ? payload[1].stroke : 'black', margin: "0 0 -7px 0" }}>
          {payload && payload.length > 1 ? payload[1].textAnchor : 'blue'} : {payload[1].value}
        </p>
      </Box>
    );
  }

  return null;
};

export default TransactionChart;
