import React, { ReactNode } from "react";
import { Box, Modal } from "@mui/material";

interface Props {
  open: boolean;
  handleClose?: React.MouseEventHandler<HTMLDivElement>;
  children?: ReactNode;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  maxWidth: "100%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  "&:focus": {
    border: "none !important",
  },
};

const PopUp = ({ open, handleClose, children }: Props) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-description"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{children}</Box>
    </Modal>
  );
};

export default PopUp;
