import { ListUserState , UserResponseProps } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { getListUser } from './userService';

export const initialState: ListUserState = {
    data: {} as UserResponseProps,
    status: "loading",
    error: {}
}

export const ListUserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getListUser.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(getListUser.fulfilled, (state, action) => {
            if (action.payload) {
                state.data = action.payload.data;
                state.status = action.payload.status;
                state.error = action.payload.error
            }
        })
        .addCase(getListUser.rejected, (state, action) => {
            state.status = 'error';
            state.error = action.error
        })

    }
})

export const ListUserReducer = ListUserSlice.reducer