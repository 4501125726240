import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomerTotalState, CustomerState } from "./types";
import ApiService from "src/services/ApiService";
import { getToken } from 'src/helper/generalFunction';

export const getCustomerList = createAsyncThunk('customer/list', async ({ limit, offset, status, filter }: { limit: number, offset: number, status?: 'ACTIVE' | 'INACTIVE' | "", filter?: string }) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.get(`${process.env.REACT_APP_API_URL}/merchant/customer?limit=${limit ?? 10}&offset=${offset ?? 0}${status ? `&status=${status}` : ''}${filter ? `&keyword=${filter}` : ''}`, config)

        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as CustomerState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as CustomerState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as CustomerState;

    }
})

export const updateCustomerStatus = createAsyncThunk('customer/update', async ({ id, status }: { id: number, status: boolean }) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.put(`${process.env.REACT_APP_API_URL}/merchant/customer/${id}/change-status`, { status: status }, config)

        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as CustomerState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as CustomerState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as CustomerState;

    }
})

export const getCustomerTotal = createAsyncThunk('customer/dashboard/total', async ({ start_date, end_date }: { start_date: string, end_date: string }) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.get(`${process.env.REACT_APP_API_URL}/merchant/dashboard/total-customer?start_date=${start_date}&end_date=${end_date}`, config)

        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as CustomerTotalState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as CustomerTotalState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as CustomerTotalState;

    }
})