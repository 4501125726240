import React, { useEffect } from "react";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import { useNavigate, useParams } from "react-router-dom";
import useRedux from "src/redux/useRedux";
import Card from "src/components/Cards/card";
import { NormalText } from "src/components/styled/text.styled";
import { Button } from "src/components/styled/button.styled";
import TextField from "src/components/Inputs/TextField";
import Dropdown, { OptionProps } from "src/components/Inputs/Dropdown";
import { Stack, Box, Switch, Grid, Link } from "@mui/material";
import { discounttypes } from "src/constants/general";
import { getAllStore, ListStoreData } from "src/redux/storelist";
import { checkHasOrder, convDate, formatCurrency, getToken } from "src/helper/generalFunction";
import {
  ProductHistoryStoreType,
  getDetailProductHistoryStore,
} from "src/redux/productHistoryStore";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { IconButton } from "@mui/material";
import { Delete, Edit, Search } from "@mui/icons-material";
import checkPermission from "src/helper/checkPermission";
import ApiService from "src/services/ApiService";
import Tables from "src/components/Table/tableData";
import moment from "moment";

const initial = {
  id: "",
  store_id: "",
  productHistory_id: "",
  is_active: true,
  store_price: "",
  discount_type: "",
  discount: "",
  final_price: 0,
  stock: 0,
};

const HistoryStoreProduct = () => {
  const navigate = useNavigate();
  const { key } = useParams();
  const { thunkDispatch } = useRedux();
  const [page, setPage] = React.useState(0);
  const [submit, setSubmit] = React.useState(false);
  const [limit, setLimit] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [isCreate, setIsCreate] = React.useState(true);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [deleteID, setDeleteID] = React.useState(0);
  const [stores, setStores] = React.useState<ListStoreData[]>();
  const [productHistory, setProductHistory] = React.useState<any>();
  const [payload, setPayload] = React.useState(initial);

  const fetchDetail = () => {
    thunkDispatch(getDetailProductHistoryStore(Number(key)))
      .unwrap()
      .then((res) => {
        if (res) {
          console.log(res);
          setProductHistory(res.data.data);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const config = {
      headers: {
        token: getToken(),
      },
    };
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/stock-history?product_store_id=${key}`,
        config
      );
      setProductHistory(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(productHistory);

  const handleUpdateClick = (store: any) => {
    setIsCreate(false);
    setPayload(store);
    setOpen(true);
  };

  const columns = [
    {
      name: "Tanggal",
      cell: (row: any) => {
        return row.created_at ? moment(row.created_at).format('DD-MM-YYYY') : '-';
      },
      sortable: true,
    },
    {
      name: "Nama Produk",
      cell: (row: any) => {
        return row.product_name ? row.product_name : "-";
      },
      sortable: true,
    },
    {
      name: "Stok",
      cell: (row: any) => {
        return row.stock ? row.stock : "-";
      },
    },
    {
      name: "Stok Change",
      cell: (row: any) => {
        return row.stock_changes ? row.stock_changes : "-";
      },
    },
    {
      name: "Note",
      cell: (row: any) => {
        return checkHasOrder(row.notes) ? (<Link href={`/orders/${row.notes}/detail`}>
          <span>{row.notes}</span>
        </Link>) : row.notes;      },
    },
  ];

  const dummy = [
    {
      id: 0,
      product_name: "-",
      store_name: "- ",
      product_store_id: "-",
      stock: "-",
      stock_changes: "-",
      notes: "-",
      created_at: "-",
    },
  ];
  return (
    <MerchantLayout>
      <ToastContainer />
      <Card>
        <div>
          <Tables
            records={productHistory?.data ?? []}
            columns={columns}
            title="History Produk Toko"
            isSearch={false}
            // onChangeSearch={(e) => setFilter(e.target.value)}
            // valueSearch={filter}
            // progressPending={loading}
            paginationPerPage={limit}
            totalRow={
              productHistory &&
              productHistory?.meta &&
              productHistory?.meta.total
                ? productHistory?.meta.total
                : 0
            }
            onChangePage={(page) => setPage(page)}
            onChangeRowsPerPage={(currentRowsPerPage) =>
              setLimit(currentRowsPerPage)
            }
            
          />
          <Button onClick={() => navigate(-1)}>Kembali</Button>
        </div>
      </Card>
    </MerchantLayout>
  );
};

export default HistoryStoreProduct;
