import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  StoreAccessState,
  StoreAccessStateV2,
  updateStoreAccessProps,
  DeleteAssignProps,
} from "./types";
import axios from "axios";
import { getToken } from "src/helper/generalFunction";

export interface ListStoreAccessProps {
  offset?: number;
  limit?: number;
  filter?: string;
}

export const getList = createAsyncThunk(
  "StoreAccess/list",
  async (props: ListStoreAccessProps) => {
    const config = {
      headers: {
        api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
        token: getToken(),
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchant/assign/store/list?limit=${
          props.limit
        }&offset=${props.offset}${
          props.filter ? `&filter=${props.filter}` : ""
        }`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as StoreAccessState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as StoreAccessState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as StoreAccessState;
    }
  }
);

export const createAssign = createAsyncThunk(
  "StoreAccess/create",
  async (props: updateStoreAccessProps) => {
    const config = {
      headers: {
        api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
        token: getToken(),
      },
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/merchant/assign/store/access`,
        props.data,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as StoreAccessState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as StoreAccessState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as StoreAccessState;
    }
  }
);

export const updateAssign = createAsyncThunk(
  "StoreAccess/update",
  async (props: updateStoreAccessProps) => {
    const config = {
      headers: {
        api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
        token: getToken(),
      },
    };
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/merchant/assign/store/access/${props.id_assign}/update`,
        props.data,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as StoreAccessState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as StoreAccessState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as StoreAccessState;
    }
  }
);

export const deleteAssign = createAsyncThunk(
  "StoreAccess/delete",
  async (props: DeleteAssignProps) => {
    const config = {
      headers: {
        api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
        token: getToken(),
      },
    };
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/merchant/assign/store/access/${props.id_assign}/delete`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as StoreAccessState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as StoreAccessState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as StoreAccessState;
    }
  }
);

export const detailStoreAccess = createAsyncThunk(
  "StoreAccess/id/detail",
  async (user_merchant_id: any) => {
    const config = {
      headers: {
        api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
        token: getToken(),
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchant/assign/store/${user_merchant_id}/detail`,
        config
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as StoreAccessStateV2;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as StoreAccessStateV2;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as StoreAccessStateV2;
    }
  }
);
