import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClosingReportState } from './types';
import axios from 'axios';
import { getToken } from 'src/helper/generalFunction';

export interface ListClosingProps {
  offset?: number;
  limit?: number;
  start_date?: any;
  end_date?: any;
  store_id?: string;
  filter?: string;
}

export const getList = createAsyncThunk(
  'closingcashierreport/list',
  async (props: ListClosingProps) => {
    const config = {
      headers: {
        api_key: 'Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e',
        token: getToken(),
      },
    };
    // {{LOCAL_URL}}/merchant/close-pos/report?store_id=1&session_code=100001&offset=0&limit=10&start_date=2022-01-01&end_date=2022-07-30;
    let url = `/merchant/close-pos/report?limit=${props.limit}&offset=${
      props.offset
    }${props.start_date ? `&start_date=${props.start_date}` : ''}${
      props.end_date ? `&end_date=${props.end_date}` : ''
    }${props.filter ? `&session_code=${props.filter}` : ''}${
      props.store_id && props.store_id !== '0'
        ? `&store_id=${props.store_id}`
        : ''
    }`;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${url}`,
        config,
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as ClosingReportState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as ClosingReportState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as ClosingReportState;
    }
  },
);

export interface downloadClosingProps {
  start_date?: any;
  end_date?: any;
  store_id?: string;
}

export const downloadReport = createAsyncThunk(
  'closingcashierreport/download',
  async (props: downloadClosingProps) => {
    const config = {
      headers: {
        api_key: 'Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e',
        token: getToken(),
      },
    };
    let url = `/merchant/Closing/reports/data/download?${
      props.start_date ? `&start_date=${props.start_date}` : ''
    }${props.end_date ? `&end_date=${props.end_date}` : ''}${
      props.store_id && props.store_id !== '0'
        ? `&store_id=${props.store_id}`
        : ''
    }`;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${url}`,
        config,
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as ClosingReportState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as ClosingReportState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as ClosingReportState;
    }
  },
);
