import React from "react";
import Tables from "src/components/Table/tableData";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import { IconButton, Grid } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { NormalText } from "src/components/styled/text.styled";
import ConfirmationModal from "src/components/Modal/confirmation";
import styled from "styled-components";
import {
  getAllTableType,
  removeTableType,
  TableTypesResponse,
} from "src/redux/tabletype";
import { toast, ToastContainer } from "react-toastify";
import useRedux from "src/redux/useRedux";
import CheckPermission from "src/helper/checkPermission";

const TableTypeList = () => {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const { thunkDispatch } = useRedux();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filter, setFilter] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [tableTypes, setTableTypes] = React.useState<TableTypesResponse>();
  const [deleteId, setDeleteId] = React.useState(0);

  const columns = [
    {
      name: "Tipe",
      selector: (row: any) => row.name,
      sortable: true,
    },
    {
      name: "Jumlah Orang",
      selector: (row: any) => row.total_person,
      sortable: true,
    },
    {
      name: "Aksi",
      center: true,
      cell: (item: any) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {CheckPermission('Table Type', 'is_edit') && (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => navigate(`/stores/tabletype/${item.id}/update`)}
              >
                <Edit />
              </IconButton>
            )}
            {CheckPermission('Table Type', 'is_delete') && (
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => {
                  setDeleteId(item.id);
                  setOpen(!open);
                }}
              >
                <Delete />
              </IconButton>
            )}
          </Grid>
        );
      },
    },
  ];

  const fetchTableTypes = () => {
    setLoading(true);
    thunkDispatch(
      getAllTableType({ offset: page, limit: rowsPerPage, filter: filter })
    )
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setTableTypes(res.data);
        }
      });
    setLoading(false);
  };

  const handleDelete = () => {
    thunkDispatch(removeTableType(deleteId))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          fetchTableTypes();
          toast.success("Sukses menghapus tipe meja");
          setOpen(!open);
        }
      });
  };

  React.useEffect(() => {
    fetchTableTypes();
  }, [rowsPerPage, filter, page]);

  return (
    <MerchantLayout>
      <ToastContainer />
      <ConfirmationModal
        open={open}
        description={"Yakin ingin menghapus tipe meja ini?"}
        handleConfirm={() => handleDelete()}
        handleClose={() => setOpen(!open)}
      />
      <Tables
        records={tableTypes?.data}
        columns={columns}
        customTitle
        title={
          <>
            <NormalText textAlign="start" fontWeight={"bold"} fontSize={"18px"}>
              Daftar Tipe Meja
            </NormalText>
            {/* <Badge>Anda dapat menambahkan 3 tipe meja</Badge> */}
          </>
        }
        isSearch={true}
        addButton={CheckPermission('Table Type', 'is_add')}
        titleAdd="Tambah Tipe"
        handleAddButton={() => {
          navigate("/stores/tabletype/form");
          // if (tableTypes && tableTypes.data && tableTypes.data.length < 3) {
          // } else {
          //   toast.warning("Anda telah menambahkan 3 meja.");
          // }
        }}
        onChangeSearch={(e) => setFilter(e.target.value)}
        valueSearch={filter}
        onChangePage={(page) => setPage(page)}
        progressPending={loading}
        totalRow={tableTypes?.meta.total ?? 0}
        paginationPerPage={rowsPerPage}
        onChangeRowsPerPage={(currentRowsPerPage) =>
          setRowsPerPage(currentRowsPerPage)
        }
      />
    </MerchantLayout>
  );
};

const Badge = styled.div`
  background: #009d0e;
  font-size: 10px !important;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 20px;
  color: white;
`;

const dummy = [
  {
    id: "1",
    type: "Small",
    pax: "1-2",
  },
  {
    id: "2",
    type: "Medium",
    pax: "4-6",
  },
  {
    id: "3",
    type: "Big",
    pax: "6-8",
  },
];

export default TableTypeList;
