import * as React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

interface DatePickerProps {
  value: any;
  onChange: any;
  minDate?: Date;
  disableFuture?: boolean;
  disabled?: boolean;
  title?: string;
}

export default function InputDate({
  value,
  onChange,
  minDate,
  disableFuture,
  disabled,
  title
}: DatePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div title={title}>
        <DesktopDatePicker
          value={value ?? null}
          disabled={disabled}
          disableFuture={disableFuture}
          onError={(ev) => console.log("Error callback", ev)}
          minDate={minDate}
          onChange={onChange}
          onMonthChange={(ev) => console.log("EVENT ON Month Change", ev)}
          InputProps={{
            sx: { height: 45 },
          }}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
            <TextField {...params} />
          )}
        />
      </div>
    </LocalizationProvider>
  );
}
