import React, { useState } from 'react';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import { NormalText } from 'src/components/styled/text.styled';
import { Button } from 'src/components/styled/button.styled';
import Tables from 'src/components/Table/tableData';
import { Box, Stack, Grid, Typography } from '@mui/material';
import InputDate from 'src/components/Inputs/DatePicker';
import { IconArrowDownCircle, IconArrowUpCircle } from '@tabler/icons';
import Dropdown from 'src/components/Inputs/Dropdown';
import OrderGroups from '../layout';
import { formatCurrency, getToken } from 'src/helper/generalFunction';
import useRedux from 'src/redux/useRedux';
import {
  getTransactions,
  TransactionData,
  TransactionResponse,
  getActiveBalance,
} from 'src/redux/transaction';
import moment from 'moment';
import 'moment/locale/id';
import axios, { AxiosRequestConfig } from 'axios';
import FilterStore from 'src/views/Order/storeFilter';
import { toast } from 'react-toastify';
moment.locale('id');

const TransactionReportPage = () => {
  const {
    thunkDispatch,
    storeState: {
      Auth: {
        status,
        data: { user_type, profile_data },
      },
      AppOption: { selected_store },
    },
  } = useRedux();
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [filterStore, setFilterStore] = useState({ id: 0, store_name: '' });
  const [transactions, setTransactions] = useState<TransactionResponse>();
  const [filterPayload, setFilterPayload] = React.useState<{
    start_date?: Date;
    end_date?: Date;
    status?: 'IN' | 'OUT' | '';
  }>({
    start_date: undefined,
    end_date: undefined,
    status: '',
  });
  const [download, setDownload] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [balances, setBalances] = React.useState({
    active: '',
    total_balance: '',
  });

  const handleExport = async () => {
    setDownload(true);
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = {
      'Content-Type': 'blob',
      api_key: `${process.env.REACT_APP_API_KEY}`,
      token: getToken(),
    };
    let startDate = filterPayload.start_date
      ? moment(filterPayload.start_date).format('YYYY-MM-DD')
      : null;
    let endDate = filterPayload.end_date
      ? moment(filterPayload.end_date).format('YYYY-MM-DD')
      : null;
    let storeId = String(filterStore?.id) ?? '';
    if (user_type && user_type !== 'merchant') {
      storeId = String(
        selected_store && selected_store.id ? selected_store.id : ''
      );
    }
    let url = `/merchant/finance/transaction/download?${
      startDate ? `&start_date=${startDate}` : ''
    }${endDate ? `&end_date=${endDate}` : ''}${
      filterPayload.status ? `&status=${filterPayload.status}` : ''
    }${storeId ? `&store_id=${storeId}` : ''}`;
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}${url}`,
      responseType: 'arraybuffer',
      headers,
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        setDownloadProgress(percentage);
      },
    };

    try {
      const response = await axios(config);
      const outputFilename =
        user_type !== 'merchant'
          ? `Laporan Transaksi - ${selected_store.name} - ${
              filterPayload.start_date
                ? ' - ' +
                  moment(filterPayload.start_date).format('DD MMMM YYYY')
                : ''
            }${
              filterPayload?.end_date
                ? ' - ' + moment(filterPayload.end_date).format('DD MMMM YYYY')
                : ''
            }.xlsx`
          : `Laporan Transaksi - ${profile_data.name}${
              filterStore && filterStore.store_name
                ? ' - ' + filterStore.store_name
                : ''
            }${
              filterPayload.start_date
                ? ' - ' +
                  moment(filterPayload.start_date).format('DD MMMM YYYY')
                : ''
            }${
              filterPayload?.end_date
                ? ' - ' + moment(filterPayload.end_date).format('DD MMMM YYYY')
                : ''
            }${
              !startDate && !endDate
                ? ' - ' + moment().format('DD MMMM YYYY')
                : ''
            }.xlsx`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      alert(error);
      setDownload(false);
    }
  };

  const fetchTransactions = () => {
    setLoading(true);
    let storeId = String(filterStore?.id ?? '');
    if (
      status &&
      status === 'success' &&
      user_type &&
      user_type !== 'merchant'
    ) {
      storeId = String(
        selected_store && selected_store.id ? selected_store.id : ''
      );
    }
    thunkDispatch(
      getTransactions({
        end_date: filterPayload.end_date
          ? moment(filterPayload.end_date).format('YYYY-MM-DD')
          : '',
        limit: limit,
        offset: offset,
        start_date: filterPayload.start_date
          ? moment(filterPayload.start_date).format('YYYY-MM-DD')
          : null,
        status: filterPayload.status,
        store_id: storeId,
        status_order: 'CLOSED',
      })
    )
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          setLoading(false);
          setTransactions(res.data);
        } else {
          setLoading(false);
        }
      });
  };

  const getBalance = () => {
    let data = {
      store_id: '',
      start_date: filterPayload.start_date
        ? moment(filterPayload.start_date).format('YYYY-MM-DD')
        : '',
      end_date: filterPayload.end_date
        ? moment(filterPayload.end_date).format('YYYY-MM-DD')
        : '',
      status: filterPayload.status,
      status_order: 'CLOSED',
    };

    if (
      status &&
      status === 'success' &&
      user_type &&
      user_type !== 'merchant'
    ) {
      data.store_id = String(
        selected_store && selected_store.id ? selected_store.id : ''
      );
    }

    thunkDispatch(getActiveBalance(data))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          setBalances({
            active: res.data.data.active_balance,
            total_balance: res.data.data.total_active_balance,
          });
        }
      })
      .catch((err) => {
        console.log('ERR', err);
      });
  };

  const handleFilter = async () => {
    setLoading(true);
    let payloadTransaction = {
      end_date: filterPayload.end_date
        ? moment(filterPayload.end_date).format('YYYY-MM-DD')
        : '',
      limit: limit,
      offset: offset,
      start_date: filterPayload.start_date
        ? moment(filterPayload.start_date).format('YYYY-MM-DD')
        : null,
      status: filterPayload.status,
      store_id:
        selected_store && selected_store.id
          ? String(selected_store.id)
          : filterStore && filterStore.id
          ? String(filterStore.id)
          : '',
      status_order: 'CLOSED',
    };

    let payloadBalance = {
      end_date: filterPayload.end_date
        ? moment(filterPayload.end_date).format('YYYY-MM-DD')
        : '',
      start_date: filterPayload.start_date
        ? moment(filterPayload.start_date).format('YYYY-MM-DD')
        : '',
      status: filterPayload.status,
      store_id:
        selected_store && selected_store.id
          ? String(selected_store.id)
          : filterStore && filterStore.id
          ? String(filterStore.id)
          : '',
      status_order: 'CLOSED',
    };

    try {
      const transaction = await thunkDispatch(
        getTransactions(payloadTransaction)
      ).unwrap();
      const activeBalance = await thunkDispatch(
        getActiveBalance(payloadBalance)
      ).unwrap();

      if (transaction) {
        setTransactions(transaction.data);
      }
      if (activeBalance) {
        setBalances({
          active: activeBalance.data.data.filtered_active_balance,
          total_balance: activeBalance.data.data.total_active_balance,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getBalance();
  }, [selected_store]);

  React.useEffect(() => {
    fetchTransactions();
  }, [limit, offset, selected_store]);

  const columns = [
    {
      name: '#ID Transaksi',
      selector: (row: TransactionData) => row.reference_number,
      sortable: true,
    },
    {
      name: 'Tanggal',
      selector: (row: TransactionData) => row.date,
      sortable: true,
    },
    {
      name: 'Tipe',
      cell: (row: TransactionData) => {
        return row.transaction_type === 'TRANSACTION'
          ? 'Transaksi'
          : 'Penarikan Dana';
      },
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row: TransactionData) => {
        return row.status === 'IN' ? (
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <IconArrowDownCircle color={'green'} />
            <NormalText color={'green'}>Masuk</NormalText>
          </Stack>
        ) : (
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <IconArrowUpCircle color={'red'} />
            <NormalText color={'red'}>Keluar</NormalText>
          </Stack>
        );
      },
    },
    {
      name: 'Total',
      cell: (row: TransactionData) => formatCurrency(Number(row.amount)),
      sortable: false,
    },
  ];

  React.useEffect(() => {
    if (downloadProgress === 100) {
      setTimeout(() => {
        setDownload(false);
        setDownloadProgress(0);
      }, 500);
    }
  }, [downloadProgress]);

  return (
    <MerchantLayout>
      <OrderGroups tabActive={1}>
        <Box
          sx={{ background: 'white', borderRadius: '10px' }}
          padding={'20px'}
        >
          <NormalText fontSize="20px" fontWeight="bold" margin={'0 0 20px 0'}>
            Saldo anda:
            <>
              {/* {status &&
              status === 'success' &&
              user_type &&
              user_type !== 'merchant'
                ? formatCurrency(Number(balances.active))
                : formatCurrency(Number(balances.total_balance))} */}

              {user_type === 'merchant' && filterStore.id
                ? formatCurrency(Number(balances.active))
                : user_type === 'merchant' && !filterStore.id
                ? formatCurrency(Number(balances.total_balance))
                : null}

              {user_type !== 'merchant'
                ? formatCurrency(Number(balances.active))
                : null}
            </>
          </NormalText>
          <Grid container>
            <Grid item md={2} xs={12} sm={12}>
              <NormalText fontSize="15px" fontWeight="bold">
                Pilih Tanggal
              </NormalText>
            </Grid>
            <Grid md={6}>
              <Stack direction={'row'} spacing={2} alignItems={'çenter'}>
                <InputDate
                  value={filterPayload.start_date}
                  disableFuture
                  onChange={(v: any) =>
                    setFilterPayload({ ...filterPayload, start_date: v })
                  }
                />
                <InputDate
                  disableFuture
                  minDate={filterPayload.start_date ?? undefined}
                  value={filterPayload.end_date}
                  onChange={(v: any) => {
                    if (filterPayload.start_date) {
                      if (moment(v).isAfter(filterPayload.start_date)) {
                        setFilterPayload({ ...filterPayload, end_date: v });
                      } else {
                        toast.warning(
                          'Tanggal akhir tidak boleh melebihi tanggal awal'
                        );
                      }
                    } else {
                      toast.warning(
                        'Silahkan pilih tanggal awal terlebih dahulu'
                      );
                    }
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container margin={'20px 0 0 0'}>
            <Grid item xs={12} sm={12} md={2}>
              <NormalText fontSize="15px" fontWeight="bold">
                Pilih Status
              </NormalText>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box width={'250px'}>
                <Dropdown
                  onChange={(e: any) =>
                    setFilterPayload({ ...filterPayload, status: e })
                  }
                  value={filterPayload.status}
                  options={[
                    { label: 'Masuk', value: 'IN' },
                    { label: 'Keluar', value: 'OUT' },
                  ]}
                ></Dropdown>
              </Box>
            </Grid>
          </Grid>
          {user_type && user_type === 'merchant' && (
            <FilterStore
              value={filterStore}
              onChange={(val: any) => setFilterStore(val)}
              filterType={'report'}
            />
          )}
          <Grid container margin={'20px 0 0 0'}>
            <Grid item xs={12} sm={12} md={2}></Grid>
            <Grid item xs={12} sm={12} md={10}>
              <Stack direction={'row'}>
                <Button
                  variant="primary"
                  onClick={() => {
                    handleFilter();
                  }}
                >
                  Filter
                </Button>
                <Button
                  variant="primary"
                  background="#21a421 !important"
                  margin="0 0 0 10px"
                  onClick={() => {
                    handleExport().then((res) => {
                      console.log('BLOG', res);
                    });
                  }}
                  disabled={download === true}
                >
                  Download Excel
                </Button>
                {download ? (
                  <div className="ml-3">
                    <Typography fontSize={'0.8rem'} className="mb-1">
                      Mempersiapkan laporan anda ...
                    </Typography>
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Animated striped example"
                    >
                      <div
                        className="progress-bar progress-bar-striped bg-success progress-bar-animated"
                        style={{
                          width: `${downloadProgress ? downloadProgress : 0}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
          <Tables
            progressPending={loading}
            hasContainer={false}
            records={
              transactions && transactions.data && transactions.data.length > 0
                ? transactions.data
                : []
            }
            columns={columns}
            totalRow={transactions ? transactions.meta.total : 0}
            paginationPerPage={limit}
            onChangePage={(page) => setOffset(page - 1)}
            onChangeRowsPerPage={(currentRowsPerPage) =>
              setLimit(currentRowsPerPage)
            }
          />
        </Box>
      </OrderGroups>
    </MerchantLayout>
  );
};

export default TransactionReportPage;
