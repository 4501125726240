import { createSlice } from "@reduxjs/toolkit"
import { createAsyncThunk } from "@reduxjs/toolkit";

export interface NotificationState {
    notifications: {
        count_notif: number;
    }
}

export const initialState: NotificationState = {
    notifications: {} as {
        count_notif: number
    }
}

export const NotificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateNotification.fulfilled, (state, action) => {
                if (action.payload) {
                    state.notifications = action.payload.notifications
                }
            })
            .addCase(updateNotification.rejected, (state, action) => {
                state.notifications = {
                    count_notif: 0,
                }
            })
    }
})

interface NotificationProps {
    count_notif: number;
}

export const updateNotification = createAsyncThunk('notification/update', async ({ count_notif}: NotificationProps) => {
    return {
        notifications: {
            count_notif: count_notif,
        }
    } as NotificationState;
})

export const NotificationReducer = NotificationSlice.reducer