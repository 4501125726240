import { ProductHistorysState, ProductHistoryResponseProps } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { getDetailProductHistoryStore } from './productHistory.store.services';

export const initialState: ProductHistorysState = {
    data: {} as ProductHistoryResponseProps,
    status: "loading",
    error: {}
}

export const ProductHistoryStoreSlice = createSlice({
    name: 'productHistoryStore',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDetailProductHistoryStore.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getDetailProductHistoryStore.fulfilled, (state: any, action) => {
                if (action.payload) {
                    state.data = action.payload;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(getDetailProductHistoryStore.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
    }
})

export const ProductHistoryStoreReducer = ProductHistoryStoreSlice.reducer