export const discounttypes = [
  {
    value: "percent",
    label: "Presentase",
  },
  {
    value: "discount",
    label: "Potongan Harga",
  },
];

export const voucherTypes = [
  {
    value: "FREE DELIVERY",
    label: "Gratis Ongkos Kirim",
  },
  {
    value: "DISCOUNT",
    label: "Potongan Harga",
  },
];

export const valueText = [
  { value: "NOMINAL", label: "Rupiah" },
  { value: "PERCENT", label: "Persen" },
];
