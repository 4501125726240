import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useRedux from "src/redux/useRedux";

import {
  Card,
  Stack,
  Button,
  Grid,
  CircularProgress,
  Box,
} from "@mui/material";
import TextField from "src/components/Inputs/TextField";
import { NormalText } from "src/components/styled/text.styled";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import { valueText, voucherTypes } from "src/constants/general";
import Dropdown from "src/components/Inputs/Dropdown";
import CurrencyFormat from "react-currency-format";
import { createVoucher } from "src/redux/voucher";
import { toast } from "react-toastify";
import Editor from "src/components/Editor";

const CreateVoucherPage = () => {
  const navigate = useNavigate();
  const { thunkDispatch } = useRedux();
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [payload, setPayload] = useState({
    value: "",
    purchasePrice: "",
    expiryDay: "",
    name: "",
    code: "",
    description: "",
    voucherType: "",
    valueText: "",
    howToUse: "",
  });

  const handleSubmit = () => {
    setIsSubmit(true);
    setLoading(true);
    if (
      !payload.value ||
      !payload.purchasePrice ||
      !payload.expiryDay ||
      !payload.name ||
      payload.name.length > 128 ||
      !payload.code ||
      payload.code.length > 45 ||
      !payload.description ||
      !payload.voucherType ||
      !payload.valueText ||
      !payload.howToUse
    ) {
      setLoading(false);
      return;
    }

    thunkDispatch(createVoucher(payload))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          toast.success("Berhasil membuat voucher baru");
          setLoading(false);
          setIsSubmit(false);
          setTimeout(() => {
            navigate("/products/vouchers");
          }, 1000);
        } else {
          toast.error("Gagal membuat voucher baru!");
          setLoading(false);
        }
      });
  };

  return (
    <MerchantLayout>
      <Card sx={{ padding: "2%" }}>
        <NormalText fontSize="20px" fontWeight="bold" margin="0 0 20px 0">
          Tambah Voucher
        </NormalText>

        <TextField
          label={"Nama Voucher"}
          isRequired
          validationText={
            isSubmit && !payload.name
              ? "Nama voucher wajib diisi!"
              : payload.name.length > 128
              ? "Maksimal 128 karakter"
              : ""
          }
          validationType={
            isSubmit && !payload.name
              ? "ERROR"
              : payload.name.length > 128
              ? "ERROR"
              : ""
          }
          value={payload.name}
          onChange={(e: any) => {
            setPayload({ ...payload, name: e.target.value });
          }}
        ></TextField>

        <TextField
          label={"Deskripsi"}
          isRequired
          validationText="Deskripsi wajib diisi!"
          validationType={isSubmit && !payload.description ? "ERROR" : ""}
          value={payload.description}
          onChange={(e: any) => {
            setPayload({ ...payload, description: e.target.value });
          }}
        ></TextField>

        <TextField
          label={"Kode Voucher"}
          isRequired
          validationText={
            isSubmit && !payload.code
              ? "Kode voucher wajib diisi!"
              : payload.code.length > 45
              ? "Maksimal 45 karakter"
              : ""
          }
          validationType={
            isSubmit && !payload.code
              ? "ERROR"
              : payload.code.length > 45
              ? "ERROR"
              : ""
          }
          value={payload.code}
          onChange={(e: any) => {
            setPayload({ ...payload, code: e.target.value });
          }}
        ></TextField>

        <CurrencyFormat
          label={"Harga Voucher"}
          isRequired
          validationText="Harga voucher wajib diisi!"
          validationType={isSubmit && !payload.purchasePrice ? "ERROR" : ""}
          customInput={TextField}
          min={0}
          thousandSeparator={true}
          value={payload.purchasePrice}
          onValueChange={(e: any) => {
            let value = e.value;
            if (Number(value) < 0) {
              value = 0;
            }
            setPayload({ ...payload, purchasePrice: value });
          }}
        />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Dropdown
              value={payload.valueText}
              validationText="Tipe nilai voucher wajib diisi!"
              validationType={isSubmit && !payload.valueText ? "ERROR" : ""}
              onChange={(e: any) => setPayload({ ...payload, valueText: e })}
              label="Tipe Nilai Voucher"
              options={valueText}
              isRequired
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <CurrencyFormat
              label={"Nilai Voucher"}
              isRequired
              validationText={
                isSubmit && !payload.valueText
                  ? "Nilai voucher wajib diisi!"
                  : payload.valueText === "PERCENT" &&
                    Number(payload.value) >= 100
                  ? "Presentase nilai voucher tidak boleh melebihi 99"
                  : ""
              }
              disabled={!payload.valueText}
              validationType={
                (payload.valueText === "PERCENT" &&
                  Number(payload.value) >= 100) ||
                (isSubmit && !payload.valueText)
                  ? "ERROR"
                  : ""
              }
              customInput={TextField}
              min={0}
              thousandSeparator={true}
              value={payload.value}
              onValueChange={(e: any) => {
                // setPayload({ ...payload, purchasePrice: e.value });
                let val = Number(e.value);
                if (payload.valueText === "PERCENT") {
                  if (Number(val) >= 100) {
                    val = 99;
                  }
                } else if (payload.valueText === "NOMINAL") {
                  if (val > Number(e.value)) {
                    val = Number(e.value);
                  }
                }

                if (val < 0) {
                  val = 0;
                }
                setPayload({ ...payload, value: String(val) });
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Dropdown
              isRequired
              validationText="Tipe voucher wajib diisi!"
              validationType={isSubmit && !payload.voucherType ? "ERROR" : ""}
              value={payload.voucherType}
              onChange={(e: any) => setPayload({ ...payload, voucherType: e })}
              label="Tipe Voucher"
              options={voucherTypes}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label={"Masa Berlaku (hari)"}
              isRequired
              validationText="Masa berlaku wajib diisi!"
              validationType={isSubmit && !payload.expiryDay ? "ERROR" : ""}
              value={payload.expiryDay}
              onChange={(e: any) => {
                setPayload({ ...payload, expiryDay: e.target.value });
              }}
            ></TextField>
          </Grid>
        </Grid>

        <Box marginTop={"5px"}>
          <NormalText fontSize={"12px"} margin="0 0 10px 0">
            {/* {label} {isRequired ? "*" : ""} */}
            Cara Penggunaan *
          </NormalText>

          <Editor
            value={payload.howToUse}
            onChange={(value: any) =>
              setPayload({
                ...payload,
                howToUse: value,
              })
            }
          ></Editor>

          {isSubmit && !payload.howToUse ? (
            <NormalText
              color={"#f12147"}
              fontSize="12px"
              fontStyle="normal"
              fontWeight="400"
              margin={"5px 0 5px 0"}
            >
              Cara penggunaan wajib diisi!
            </NormalText>
          ) : null}
        </Box>

        <Stack direction={"row"} spacing={2} margin={"20px 0 20px 0"}>
          <Button
            sx={{
              textTransform: "none",
              background: "#e56c75",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#e56c75",
                borderColor: "#e56c75",
                boxShadow: "none",
              },
            }}
            onClick={() => navigate("/products/vouchers")}
            className="px-4"
          >
            Batal
          </Button>
          <Button
            sx={{
              textTransform: "none",
              background: "#007bff",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#007bff",
                borderColor: "#007bff",
                boxShadow: "none",
              },
            }}
            className="px-4"
            onClick={() => handleSubmit()}
            disabled={loading}
          >
            {loading ? <CircularProgress size={15} className="mr-2" /> : null}
            Simpan
          </Button>
        </Stack>
      </Card>
    </MerchantLayout>
  );
};

export default CreateVoucherPage;
