import { createSlice } from "@reduxjs/toolkit"
import { createAsyncThunk } from "@reduxjs/toolkit";

export interface CustomizeProps {
    SIDEBAR_OPEN: boolean
}

export const initialState: CustomizeProps = {
    SIDEBAR_OPEN: false
}

export const customizeSlice = createSlice({
    name: 'customize',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(openSidebar.fulfilled, (state, action) => {
                if (action.payload) {
                    state.SIDEBAR_OPEN = action.payload.SIDEBAR_OPEN
                }
            })
            .addCase(openSidebar.rejected, (state, action) => {
                state.SIDEBAR_OPEN = false
            })

    }
})

export const openSidebar = createAsyncThunk('customize', async (props: CustomizeProps) => {
    return {
        SIDEBAR_OPEN: props.SIDEBAR_OPEN
    } as CustomizeProps;
})

export const CustomizeReducer = customizeSlice.reducer