import React from "react";
// import ReactDatatable from '@ashvin27/react-datatable';
import DataTable from "react-data-table-component";
import { Box, InputBase, Grid } from "@mui/material";
import styles from "./styles";
import { NormalText } from "src/components/styled/text.styled";
import { styled, alpha } from "@mui/material/styles";
import { Button } from "src/components/styled/button.styled";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  PaginationChangePage,
  ExpandableRowsComponent,
} from "react-data-table-component/dist/src/DataTable/types";

interface Props {
  columns?: any;
  records?: any;
  title?: any;
  hasContainer?: boolean;
  customTitle?: boolean;
  isSearch?: boolean;
  addButton?: boolean;
  titleAdd?: string;
  handleAddButton?(): any;
  onChangeSearch?(e: any): any;
  valueSearch?: any;
  progressPending?: boolean;
  paginationPerPage?: number;
  onChangeRowsPerPage?(currentRowsPerPage: number, currentPage: number): any;
  onChangePage?: PaginationChangePage | undefined;
  totalRow?: number;
  filter?: React.ReactNode | undefined;
  filterByProduct?: React.ReactNode | undefined;
  expandableRows?: boolean | false;
  expandableRowDisabled?(e: any): any | false;
  expandableRowsComponent?: ExpandableRowsComponent<any> | undefined;
  secondButton?: React.ReactNode | undefined;
  thirdButton?: React.ReactNode | undefined;
  fourthButton?: React.ReactNode | undefined;
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "5px",
  border: "1px solid #e6e6e6",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  marginTop: "10px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "10px",
    maxWidth: "unset",
    width: "100%",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    fontSize: "14px",
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
      width: "100%",
    },
  },
}));

const Tables = ({
  records,
  columns,
  title,
  isSearch,
  addButton,
  customTitle,
  hasContainer = true,
  titleAdd,
  handleAddButton,
  onChangeSearch,
  valueSearch,
  progressPending,
  paginationPerPage,
  onChangeRowsPerPage,
  onChangePage,
  totalRow,
  filter,
  filterByProduct,
  expandableRows,
  expandableRowDisabled,
  expandableRowsComponent,
  secondButton,
  thirdButton,
  fourthButton,
}: Props) => {
  return (
    <Box
      sx={[
        hasContainer ? styles.boxStyled : {},
        hasContainer ? styles.width100p : {},
        hasContainer ? styles.padding20 : {},
      ]}
    >
      <Grid container spacing={0} style={{ marginBottom: 20 }}>
        <Grid
          item
          xs={
            fourthButton && thirdButton && secondButton
              ? 6
              : !fourthButton && thirdButton && secondButton
              ? 8
              : !fourthButton && !thirdButton && secondButton
              ? 7
              : 9
          }
        >
          {customTitle ? (
            title
          ) : (
            <NormalText
              textAlign="start"
              fontWeight={"bold"}
              fontSize={"18px"}
              margin={"0 0 20px 0"}
            >
              {title}
            </NormalText>
          )}
        </Grid>
        <Grid
          item
          xs={
            fourthButton && thirdButton && secondButton
              ? 6
              : !fourthButton && thirdButton && secondButton
              ? 4
              : !fourthButton && !thirdButton && secondButton
              ? 5
              : 3
          }
          container
          direction="row"
          justifyContent={
            thirdButton && secondButton
              ? "space-between"
              : !thirdButton && secondButton
              ? "flex-end"
              : "flex-end"
          }
          alignItems="flex-start"
          style={{ paddingRight: 0 }}
        >
          {" "}
          {fourthButton && <>{fourthButton}</>}
          {thirdButton && <>{thirdButton}</>}
          {secondButton && <>{secondButton}</>}
          {addButton && (
            <Button
              onClick={handleAddButton}
              variant="primary"
              width={
                thirdButton && secondButton
                  ? "20%"
                  : !thirdButton && secondButton
                  ? "50%"
                  : "50%"
              }
              margin={secondButton ? "0 0px 0 0px" : "0 0 0 0"}
            >
              {titleAdd}
            </Button>
          )}
        </Grid>
      </Grid>
      <Box sx={[styles.width98p, styles.margin10]}>
        <Grid
          container
          direction="row"
          justifyContent={filter ? "space-between" : "flex-end"}
          alignItems="center"
          spacing={2}
        >
          {filter && (
            <Grid
              item
              xs={12}
              sm={12}
              md={filterByProduct ? 4 : 8}
              alignItems={"center"}
            >
              {filter}
            </Grid>
          )}
          {filterByProduct && (
            <Grid item xs={12} sm={12} md={4} alignItems={"center"}>
              {filterByProduct}
            </Grid>
          )}
          {isSearch && (
            <Grid xs={12} sm={12} md={filterByProduct ? 4 : 2}>
              <Search>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  value={valueSearch}
                  onChange={onChangeSearch}
                />
              </Search>
            </Grid>
          )}
        </Grid>
        <Box sx={[styles.boxStyled]} margin={"10px 0 0 0"}>
          <DataTable
            noTableHead={false}
            columns={columns}
            data={records}
            responsive
            pagination
            striped
            noDataComponent={
              <p style={{ margin: "20px 0", color: "grey" }}>
                Oops, belum ada data
              </p>
            }
            noHeader={false}
            paginationTotalRows={totalRow}
            progressPending={progressPending}
            progressComponent={<CircularProgress />}
            paginationPerPage={paginationPerPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={onChangePage}
            paginationServer
            expandableRows={expandableRows}
            persistTableHead
            expandableRowDisabled={expandableRowDisabled}
            expandableRowsComponent={expandableRowsComponent}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Tables;
