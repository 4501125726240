import React from "react";
import { Stack, Autocomplete, TextField, Grid } from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";
import useRedux from "src/redux/useRedux";
import { getAllStore, ListStoreData } from "src/redux/storelist";

interface SelectStoreProps {
  value: number;
  onChange: any;
}

const SelectStore = ({
  value,
  onChange,
}: SelectStoreProps) => {
  const { thunkDispatch } = useRedux();
  const [stores, setStores] = React.useState<ListStoreData[]>();

  React.useEffect(() => {
    thunkDispatch(getAllStore({ limit: 0, offset: 0 }))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setStores(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Stack margin={"20px 0 10px 0"}>
      <NormalText fontSize="12px" fontWeight="400">
        Pilih Toko
      </NormalText>
      <Autocomplete
        id="combo-box-store-list"
        options={stores && stores.length > 0 ? stores : []}
        getOptionLabel={(option) => option.store_name || ""}
        onChange={(event: any, value: any) => {
          return onChange(value.id);
        }}
        disableClearable
        sx={{ width: "100%" }}
        defaultChecked
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ fontSize: 12 }}
            size="small"
            placeholder="Pilih Toko"
            value={value}
          />
        )}
      />
    </Stack>
  );
};

export default SelectStore;
