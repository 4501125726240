import axios from "axios";
import { removeAllState } from "src/helper/generalFunction";
// import { getToken, isAuthenticated } from 'src/helper/generalFunction';
import AuthService from "./AuthService";

const ApiService = () => {
  const auth = new AuthService();
  const token = auth.getToken();
  let instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      api_key: process.env.REACT_APP_API_KEY,
    },
  });

  if (auth.isAuthenticated()) {
    instance.defaults.headers.common["token"] = token;
  }

  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err.response.status === 401) {
        console.log("Got Error 401");
      }
      if (err.response.status === 503) {
        console.log("Got Error 503");
      }
      if (err.response.status === 500) {
        console.log("Got Error 503");
      }
      return Promise.reject(err);
    }
  );
  return instance;
};

export default ApiService();
