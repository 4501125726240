import React from "react";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import UserGroups from "../layout";
import { Box, Checkbox, Stack, Switch } from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";
import { Button } from "src/components/styled/button.styled";
import InputField from "src/components/Inputs/InputField";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useRedux from "src/redux/useRedux";
import { showRole, ShowRoleProps } from "src/redux/role/roleService";
import "react-toastify/dist/ReactToastify.css";

interface FeaturesProps {
  feature_id: number;
  feature_name: string;
  key: string;
  is_view: boolean;
  is_add: boolean;
  is_edit: boolean;
  is_delete: boolean;
}

const ViewRolePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: { Auth },
  } = useRedux();

  const [payload, setPayload] = React.useState({
    name: "",
    description: "",
    is_cashier: false,
    features: [],
  });
  const [features, setFeatures] = React.useState([] as FeaturesProps[]);

  React.useEffect(() => {
    thunkDispatch(
      showRole({
        role_id: id,
        access_token: Auth.data.access_token,
      } as unknown as ShowRoleProps)
    )
      .unwrap()
      .then((res) => {
        var result: any = {
          name: res?.data?.data?.name,
          description: res?.data?.data?.description,
          features: res?.data?.data?.features,
          is_cashier: res?.data?.data?.is_cashier,
        };
        setPayload({
          name: result.name,
          description: result.description,
          features: result.features,
          is_cashier: result.is_cashier ?? false,
        });
        console.log(payload);
        setFeatures(result.features);
      })
      .catch((err: any) => console.log("Err", err));
  }, []);

  return (
    <MerchantLayout>
      <UserGroups tabActive={1}>
        <Box
          sx={{
            height: "100%",
            backgroundColor: "white",
            justifyContent: "center",
            border: "0 solid rgba(0,0,0,.125)",
            borderRadius: "0.25rem",
            padding: "10px",
            boxShadow: "0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%)",
          }}
        >
          <NormalText margin="20px 0 20px 0" fontWeight="bold" fontSize="20px">
            Detail Role
          </NormalText>
          <NormalText margin="0 0 10px 0">Role *</NormalText>
          <InputField
            disabled
            value={payload.name}
            onChange={(e: any) =>
              setPayload({ ...payload, name: e.target.value })
            }
          />
          <NormalText margin="10px 0 10px 0">Deskripsi Singkat *</NormalText>
          <InputField
            disabled
            value={payload.description}
            onChange={(e: any) =>
              setPayload({ ...payload, description: e.target.value })
            }
          />
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <NormalText>Akses POS?</NormalText>
            <Switch
              disabled
              value={payload.is_cashier}
              checked={payload.is_cashier}
              onChange={(e) =>
                setPayload({ ...payload, is_cashier: !payload.is_cashier })
              }
            />
          </Stack>
          <Box sx={{ margin: "15px 0 20px 0" }}>
            <NormalText>Daftar Fitur diaktifkan *</NormalText>
            <TableContainer sx={{ margin: "10px 0 0 0" }}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead sx={{ background: "#f1f1f1", fontWeight: "bold" }}>
                  <TableRow>
                    <TableCell>Menu</TableCell>
                    <TableCell align="right">Lihat</TableCell>
                    <TableCell align="right">Buat</TableCell>
                    <TableCell align="right">Ubah</TableCell>
                    <TableCell align="right">Hapus</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {features.map((row, index) => (
                    <TableRow
                      key={row.feature_id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.feature_name === "Transaction"
                          ? "Balance"
                          : row.feature_name}
                      </TableCell>
                      <TableCell align="right">
                        <Checkbox
                          disabled
                          checked={row.is_view}
                          onClick={() => {
                            let temp = [...features];
                            temp[index] = {
                              ...temp[index],
                              is_view: !row.is_view,
                            };
                            setFeatures(temp);
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Checkbox
                          disabled
                          checked={row.is_add}
                          onClick={() => {
                            let temp = [...features];
                            temp[index].is_add = !row.is_add;
                            setFeatures(temp);
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Checkbox
                          disabled
                          checked={row.is_edit}
                          onClick={() => {
                            let temp = [...features];
                            temp[index].is_edit = !row.is_edit;
                            setFeatures(temp);
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Checkbox
                          disabled
                          checked={row.is_delete}
                          onClick={() => {
                            let temp = [...features];
                            temp[index].is_delete = !row.is_delete;
                            setFeatures(temp);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Button
            variant="secondary"
            margin="0 10px 0 0"
            onClick={() => navigate("/users/roles")}
          >
            Kembali
          </Button>
        </Box>
      </UserGroups>
    </MerchantLayout>
  );
};

export default ViewRolePage;
