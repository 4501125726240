import { createAsyncThunk } from "@reduxjs/toolkit";
import { OrderDetailState, OrderState, updateOrderProps } from "./types";
import axios from "axios";
import { getToken } from "src/helper/generalFunction";

export interface ListOrderProps {
  offset?: number;
  limit?: number;
  filter?: string;
  store_id?: string;
  status?: string;
  product_type?: string;
}

export const getList = createAsyncThunk(
  "order/list",
  async (props: ListOrderProps) => {
    const config = {
      headers: {
        api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
        token: getToken(),
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchant/orders/list?limit=${
          props.limit
        }&offset=${props.offset}&filter=${props.filter}&status_order=${
          props.status
        }${props.store_id ? "&store_id=" + props.store_id : ""}${
          props.product_type ? "&product_type=" + props.product_type : ""
        }`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as OrderState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as OrderState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as OrderState;
    }
  }
);

export const updateStatus = createAsyncThunk(
  "Order/update",
  async (props: updateOrderProps) => {
    const config = {
      headers: {
        api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
        token: getToken(),
      },
    };
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/merchant/order/changestatus`,
        props.data,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as OrderState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as OrderState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as OrderState;
    }
  }
);

export const getDetailOrder = createAsyncThunk(
  "orders/detail",
  async ({ code }: { code: string }) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchant/order/print-invoice/${code}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as OrderDetailState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as OrderDetailState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as OrderDetailState;
    }
  }
);
