import { createAsyncThunk } from "@reduxjs/toolkit";
import { FeatureState } from "./types";
import axios from "axios";

export const getFeatures = createAsyncThunk('features/get', async (access_token: string) => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/master/feature/list?isMerchant=true', {
            headers: {
                api_key: 'Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e',
                token: access_token
            }
        })
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as FeatureState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as FeatureState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as FeatureState;

    }
})