import React, { useState, useEffect } from "react";
import { Stack } from "../../../components/styled/layout.styled";
import { useNavigate, useLocation } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import styles from "./styles";
import {
  Box,
  Button as ButtonMUI,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
  TextField as TextFieldMUI,
  Autocomplete,
  Switch,
} from "@mui/material";
import CurrencyFormat from "react-currency-format";
import { NormalText } from "src/components/styled/text.styled";
import TextField from "src/components/Inputs/TextField";

import {
  editDelivery,
  createDeliverySetting,
} from "src/redux/deliverySettings";
import useRedux from "src/redux/useRedux";
import PopUp from "src/components/Modal/modal";
import {
  getSubSubCategory,
  SubCategoryData,
} from "src/redux/subCategoryLevel3";
import { getAllStore, ListStoreData } from "src/redux/storelist";
import Dropdown, { OptionProps } from "src/components/Inputs/Dropdown";

interface RouteState {
  data: any;
  statusUrl: string;
}

let valueType = [
  {
    value: "tarif_km",
    name: "Tarif per KM",
  },
  {
    value: "tarif_flat",
    name: "Tarif Flat",
  },
];

const AddBrandPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { data, statusUrl } = location.state as RouteState;
  const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState("");
  const [dataAdd, setDataAdd] = useState({
    store_id: statusUrl === "edit" ? data?.store.id : 0,
    calculate_method: statusUrl === "edit" ? data?.calculateMethod : 0,
    max_range_delivery: statusUrl === "edit" ? data?.maxRangeDelivery : 0,
    km_price_value: statusUrl === "edit" ? data?.kmPriceValue : 0,
    deliver_fee: statusUrl === "edit" ? data?.deliverFee : 0,
    enable_flat_price: statusUrl === "edit" ? data?.enableFlatPrice : false,
    flat_price_value: statusUrl === "edit" ? data?.flatPriceValue : false,
    max_range_flat_price:
      statusUrl === "edit" ? data?.maxRangeFlatPrice : false,
  });
  const [loading, setLoading] = useState(true);
  const [errorValidasi, setErrorValidasi] = useState({
    store_id: "",
    max_range_delivery: "",
    calculate_method: "",
    km_price_value: "",
    deliver_fee: "",
    enable_flat_price: false,
    flat_price_value: "",
    max_range_flat_price: "",
  });
  const [store, setStore] = useState<ListStoreData[]>([]);

  React.useEffect(() => {
    thunkDispatch(getAllStore({ offset: 0, limit: 0 }))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setStore(res.data.data);
        }
      });
  }, []);

  const {
    thunkDispatch,
    storeState: { Auth, DeliverySetting },
  } = useRedux();

  const handleCreateDelivery = () => {
    const params = {
      store_id: dataAdd.store_id,
      calculate_method: dataAdd.calculate_method,
      max_range_delivery: dataAdd.max_range_delivery * 1,
      km_price_value:
        dataAdd.calculate_method === "tarif_km"
          ? dataAdd.km_price_value * 1
          : 0,
      deliver_fee:
        dataAdd.calculate_method === "tarif_flat" ? dataAdd.deliver_fee * 1 : 0,
      enable_flat_price: dataAdd.enable_flat_price,
      flat_price_value: dataAdd.enable_flat_price
        ? dataAdd.flat_price_value * 1
        : 0,
      max_range_flat_price: dataAdd.enable_flat_price
        ? dataAdd.max_range_flat_price * 1
        : 0,
    };

    // return console.log(params);
    thunkDispatch(createDeliverySetting(params))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("success");
        } else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("failed");
        }
      })
      .catch((err) => {
        setLoading(false);
        setStatusModal("failed");
        setOpenModal(true);
        console.error(err);
      });
    setLoading(false);
  };

  const handleEditDelivery = () => {
    let dataObject = {
      store_id: dataAdd.store_id,
      calculate_method: dataAdd.calculate_method,
      max_range_delivery: dataAdd.max_range_delivery * 1,
      km_price_value:
        dataAdd.calculate_method === "tarif_km"
          ? dataAdd.km_price_value * 1
          : 0,
      deliver_fee:
        dataAdd.calculate_method === "tarif_flat" ? dataAdd.deliver_fee * 1 : 0,
      enable_flat_price: dataAdd.enable_flat_price,
      flat_price_value: dataAdd.enable_flat_price
        ? dataAdd.flat_price_value * 1
        : 0,
      max_range_flat_price: dataAdd.enable_flat_price
        ? dataAdd.max_range_flat_price * 1
        : 0,
    };
    thunkDispatch(editDelivery(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("success");
        } else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("failed");
        }
      })
      .catch((err) => {
        setLoading(false);
        setStatusModal("failed");
        setOpenModal(true);
        console.error(err);
      });
    setLoading(false);
  };

  const handleValidate = () => {
    if (
      !dataAdd.store_id ||
      !dataAdd.max_range_delivery ||
      !dataAdd.calculate_method ||
      (!dataAdd.km_price_value && !dataAdd.deliver_fee) ||
      (dataAdd.enable_flat_price && !dataAdd.flat_price_value) ||
      (dataAdd.enable_flat_price && !dataAdd.max_range_flat_price)
    ) {
      setErrorValidasi({
        ...errorValidasi,
        store_id: !dataAdd.store_id ? "Toko Wajib Diisi" : "",
        calculate_method: !dataAdd.calculate_method
          ? "Metode perhitungan wajib diisi"
          : "",
        max_range_delivery: !dataAdd.max_range_delivery
          ? "Jangkauan maksimal wajib diisi"
          : "",
        km_price_value:
          !dataAdd.km_price_value || dataAdd.deliver_fee
            ? "Tarif per KM wajib diisi"
            : "",
        deliver_fee:
          !dataAdd.km_price_value || !dataAdd.deliver_fee
            ? "Tarif wajib diisi"
            : "",
        flat_price_value:
          dataAdd.enable_flat_price && !dataAdd.flat_price_value
            ? "Tarif dasar flat wajib diisi"
            : "",
        max_range_flat_price:
          dataAdd.enable_flat_price && !dataAdd.max_range_flat_price
            ? "Jangkauan tarif dasar wajib diisi"
            : "",
      });
    } else {
      setErrorValidasi({
        ...errorValidasi,
        store_id: "",
        calculate_method: "",
        max_range_delivery: "",
        km_price_value: "",
        deliver_fee: "",
        flat_price_value: "",
        max_range_flat_price: "",
      });
      statusUrl === "edit" ? handleEditDelivery() : handleCreateDelivery();
    }
  };

  return (
    <MerchantLayout>
      <PopUp open={openModal} handleClose={() => setOpenModal(!openModal)}>
        <NormalText
          textAlign="center"
          fontWeight={"bold"}
          fontSize={"20px"}
          margin={"0 0 20px 0"}
        >
          {statusModal === "success" ? "Sukses" : "Gagal"}
        </NormalText>
        <NormalText textAlign="center" fontSize={"14px"} margin={"0 0 20px 0"}>
          {statusModal === "success" ? (
            statusUrl === "edit" ? (
              <span>Berhasil mengupdate data pengiriman</span>
            ) : (
              <span>
                Berhasil menambahkan <br /> pengiriman baru
              </span>
            )
          ) : DeliverySetting?.error?.response?.data?.message ? (
            <span>{DeliverySetting.error.response.data.message}</span>
          ) : statusUrl === "edit" ? (
            <span>
              Gagal mengupate pengiriman, <br /> silahkan periksa kembali data
              anda
            </span>
          ) : (
            <span>
              Gagal menambah pengiriman, <br /> silahkan periksa kembali data
              anda
            </span>
          )}
        </NormalText>
        <Box textAlign={"center"} margin={"20px 0 0 0 "}>
          <ButtonMUI
            variant="contained"
            onClick={() =>
              statusModal === "success"
                ? navigate("/setting/deliverysetting")
                : setOpenModal(!openModal)
            }
            sx={{ width: 100, height: 40, marginRight: 3 }}
            color="error"
          >
            Oke
          </ButtonMUI>
        </Box>
      </PopUp>
      <Stack direction="column">
        <Box sx={styles.boxStyled}>
          <NormalText
            textAlign="start"
            fontWeight={"bold"}
            fontSize={"18px"}
            margin={"0 0 20px 0"}
          >
            {statusUrl === "edit" ? "Edit Pengiriman" : "Tambah Pengiriman"}
          </NormalText>
          <form>
            <Dropdown
              value={dataAdd.store_id}
              onChange={(e: any) => {
                setDataAdd({ ...dataAdd, store_id: e });
              }}
              label="Pilih Toko"
              isRequired
              options={
                store
                  ? store.map((item) => {
                      return {
                        label: item.store_name,
                        value: item.id,
                      } as OptionProps;
                    })
                  : []
              }
            />
            <FormHelperText style={{ color: "#f12147" }}>
              {errorValidasi.store_id}
            </FormHelperText>
            <NormalText
              textAlign="left"
              fontWeight="bold"
              margin={"20px 0 20px 0"}
              fontSize={"14px"}
            >
              Pengaturan Pengiriman
            </NormalText>
            <NormalText fontSize={"12px"}>Jangkauan Maksimal *</NormalText>
            <TextField
              type="number"
              value={dataAdd.max_range_delivery}
              onChange={(e: any) => {
                setDataAdd({
                  ...dataAdd,
                  max_range_delivery: e.target.value,
                });
              }}
            />
            {errorValidasi.max_range_delivery ? (
              <NormalText fontSize={"12px"} color={"#f12147"}>
                {errorValidasi.max_range_delivery}
              </NormalText>
            ) : (
              <FormHelperText style={{ color: "#656464" }}>
                Maksimum jangkauan yang bisa ditempuh dari toko dalam satuan
                kilometer
              </FormHelperText>
            )}
            <NormalText fontSize={"12px"} margin="8px 0px 0px 0px ">
              Metode Perhitungan *
            </NormalText>
            <FormControl sx={{ minWidth: "100%" }}>
              <Select
                value={dataAdd.calculate_method}
                onChange={(e) =>
                  setDataAdd({
                    ...dataAdd,
                    calculate_method: e.target.value,
                  })
                }
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ height: 40 }}
              >
                <MenuItem value="">
                  <em>Pilih Metode Perhitungan</em>
                </MenuItem>
                {valueType.map((value, index) => {
                  return <MenuItem value={value.value}>{value.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
            {errorValidasi.calculate_method ? (
              <NormalText fontSize={"12px"} color={"#f12147"}>
                {errorValidasi.calculate_method}
              </NormalText>
            ) : (
              <FormHelperText style={{ color: "#656464" }}>
                Pilih metode perhitungan tarif sesuai dengan kebutuhan
              </FormHelperText>
            )}

            <NormalText fontSize={"12px"} margin="8px 0px 0px 0px ">
              {`Tarif ${
                dataAdd.calculate_method === "tarif_km"
                  ? "per KM (Rp)"
                  : dataAdd.calculate_method === "tarif_flat"
                  ? "Flat (Rp)"
                  : "(Rp)"
              } *`}{" "}
            </NormalText>
            <TextField
              type="number"
              value={
                dataAdd.calculate_method === "tarif_km"
                  ? dataAdd.km_price_value
                  : dataAdd.calculate_method === "tarif_flat"
                  ? dataAdd.deliver_fee
                  : 0
              }
              onChange={(e: any) => {
                if (dataAdd.calculate_method === "tarif_km") {
                  setDataAdd({
                    ...dataAdd,
                    km_price_value: e.target.value,
                  });
                }
                if (dataAdd.calculate_method === "tarif_flat") {
                  setDataAdd({
                    ...dataAdd,
                    deliver_fee: e.target.value,
                  });
                }
              }}
              disabled={
                dataAdd.calculate_method === "tarif_km"
                  ? false
                  : dataAdd.calculate_method === "tarif_flat"
                  ? false
                  : true
              }
            />
            {dataAdd.calculate_method !== "tarif_km" &&
            errorValidasi.km_price_value ? (
              <NormalText fontSize={"12px"} color={"#f12147"}>
                {errorValidasi.calculate_method}
              </NormalText>
            ) : dataAdd.calculate_method !== "tarif_km" &&
              errorValidasi.deliver_fee ? (
              <NormalText fontSize={"12px"} color={"#f12147"}>
                {errorValidasi.calculate_method}
              </NormalText>
            ) : (
              <FormHelperText style={{ color: "#656464" }}>
                {dataAdd.calculate_method !== "tarif_km"
                  ? "Masukkan tarif ongkos kirim per KM (kilometer). Cukup angka, tanpa simbol mata uang atau titik."
                  : dataAdd.calculate_method !== "tarif_flat"
                  ? "Masukkan tarif ongkos kirim flat. Cukup angka, tanpa simbol mata uang atau titik."
                  : ""}
              </FormHelperText>
            )}
            <div
              style={{ display: "flex", marginTop: 12, alignItems: "center" }}
            >
              <NormalText fontSize={"14px"} fontWeight="600">
                Aktifkan Tarif Dasar
              </NormalText>
              <Switch
                style={{}}
                checked={dataAdd.enable_flat_price}
                onChange={() => {
                  setDataAdd({
                    ...dataAdd,
                    enable_flat_price: !dataAdd.enable_flat_price,
                  });
                }}
              />
            </div>
            {dataAdd.enable_flat_price ? (
              <>
                <NormalText fontSize={"12px"} margin="8px 0px 0px 0px ">
                  Tarif Dasar Flat (Rp) *
                </NormalText>
                <TextField
                  type="number"
                  value={dataAdd.flat_price_value}
                  onChange={(e: any) => {
                    setDataAdd({
                      ...dataAdd,
                      flat_price_value: e.target.value,
                    });
                  }}
                />
                {errorValidasi.flat_price_value ? (
                  <NormalText fontSize={"12px"} color={"#f12147"}>
                    {errorValidasi.flat_price_value}
                  </NormalText>
                ) : (
                  <FormHelperText style={{ color: "#656464" }}>
                    Masukkan tarif dasar dengan perhitungan flat hingga
                    jangkauan __ KM. Isi dengan angka 0 jika anda ingin
                    menerapkan free ongkir untuk __ KM pertama. Jangkauan bisa
                    diatur pada kolom selanjutnya.
                  </FormHelperText>
                )}
                <NormalText fontSize={"12px"} margin="8px 0px 0px 0px ">
                  Jangkauan Tarif Dasar *
                </NormalText>
                <TextField
                  type="number"
                  value={dataAdd.max_range_flat_price}
                  onChange={(e: any) => {
                    setDataAdd({
                      ...dataAdd,
                      max_range_flat_price: e.target.value,
                    });
                  }}
                />
                {errorValidasi.max_range_flat_price ? (
                  <NormalText fontSize={"12px"} color={"#f12147"}>
                    {errorValidasi.max_range_flat_price}
                  </NormalText>
                ) : (
                  <FormHelperText style={{ color: "#656464" }}>
                    Maksimum jangkauan untuk penerapan perhitungan tarif dasar
                    dalam satuan kilometer.
                  </FormHelperText>
                )}
              </>
            ) : (
              <></>
            )}
            <Box textAlign={"start"} margin={"20px 0 0 0 "}>
              <ButtonMUI
                variant="contained"
                onClick={() => navigate("/setting/deliverysetting")}
                sx={{ width: 100, height: 40, marginRight: 3 }}
                color="error"
              >
                Kembali
              </ButtonMUI>
              <ButtonMUI
                variant="contained"
                onClick={() => handleValidate()}
                sx={{ width: 100, height: 40 }}
              >
                Save
              </ButtonMUI>
            </Box>
          </form>
        </Box>
      </Stack>
    </MerchantLayout>
  );
};

export default AddBrandPage;
