const styles = {
    boxStyled: {
        height: "100%",
        backgroundColor: "white",
        justifyContent: "center",
        border: "0 solid rgba(0,0,0,.125)",
        borderRadius: "0.25rem",
    },
    width98p: {
        width: "98%",
    },
    width100p: {
        width: "100%",
    },
    padding20: {
        padding: '20px',
    },
    margin10: {
        margin: '10px'
    },
};

export default styles;
