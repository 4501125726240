import { addUserResponseProps, AddUserState} from './types'
import { createSlice } from '@reduxjs/toolkit'
import { createUser } from './userService';
// create user
export const initialState: AddUserState = {
    data: {} as addUserResponseProps,
    status: "loading",
    error: {}
}

export const createUserSlice = createSlice({
    name: 'addUser',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(createUser.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(createUser.fulfilled, (state, action) => {
            if (action.payload) {
                state.data = action.payload.data;
                state.status = action.payload.status;
                state.error = action.payload.error
            }
        })
        .addCase(createUser.rejected, (state, action) => {
            state.status = 'error';
            state.error = action.error
        })

    }
})

export const AddUserReducer = createUserSlice.reducer