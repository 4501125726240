import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProductAddOnsState, CreateproductAddOns, ProductAddOnDataDetailState } from "./types";
import { PaginationProps } from "src/redux/types";
import ApiService from "src/services/ApiService";
import { getToken } from 'src/helper/generalFunction';

export const getProductAddons = createAsyncThunk('products-addons/get', async (props: PaginationProps) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.get(`${process.env.REACT_APP_API_URL}/merchant/productassign/list?limit=${props.limit ?? 10}&offset=${props.offset ?? 0}${props.filter ? '&filter=' + props.filter : ''}`, config)

        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as ProductAddOnsState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as ProductAddOnsState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as ProductAddOnsState;

    }
})

export const getDetailProductAddOns = createAsyncThunk('products-addons/show', async (props: PaginationProps & { product_id: number }) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.get(`${process.env.REACT_APP_API_URL}/merchant/productassign/${props.product_id}/data/detail?limit=${props.limit ?? 5}&offset=${props.offset ?? 0}`, config)

        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as ProductAddOnDataDetailState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as ProductAddOnDataDetailState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as ProductAddOnDataDetailState;

    }
})

export const assignProductStore = createAsyncThunk('products-addons/assign', async (props: CreateproductAddOns) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.post(process.env.REACT_APP_API_URL + '/merchant/productassign',
            props, config)

        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as ProductAddOnsState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as ProductAddOnsState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as ProductAddOnsState;

    }
})

export const updateProductStore = createAsyncThunk('products-addons/update', async (props: CreateproductAddOns) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.put(process.env.REACT_APP_API_URL + '/merchant/productassign/' + props.id + '/update', {
            product_assign_id: props.product_assign_id,
            product_id: props.product_id,
            is_active: props.is_active,
            product_type: props.product_type
        }, config)

        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as ProductAddOnsState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as ProductAddOnsState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as ProductAddOnsState;

    }
})


export interface UpdateStatusProps {
    id: number;
    is_active: boolean;
}

export const updateStatus = createAsyncThunk('product-addons/updatestatus', async (props: UpdateStatusProps) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const { is_active, id } = props
        const response = await ApiService.put(`${process.env.REACT_APP_API_URL}/merchant/productstore/${id}/status`, { is_active }, config)
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as ProductAddOnsState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as ProductAddOnsState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as ProductAddOnsState;

    }
})

export const removeProductStore = createAsyncThunk('products-addon/delete', async (product_store_id: number) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.delete(`${process.env.REACT_APP_API_URL}/merchant/productassign/${product_store_id}/delete`, config)
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as ProductAddOnsState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as ProductAddOnsState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as ProductAddOnsState;

    }
})
