import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import {
  Box,
  Button as ButtonMUI,
  FormHelperText,
  Grid,
  Stack,
  CircularProgress,
} from "@mui/material";
import TextField from "src/components/Inputs/TextField";
import UserGroups from "../layout";
import useRedux from "src/redux/useRedux";
import { uploadImage } from "src/helper/uploadFile";
import {
  getAppSetting,
  createAppSetting,
  updateAppSetting,
  AppSettingData,
  CreateAppSettingTypes,
} from "src/redux/appsetting";
// import PopUp from "src/components/Modal/modal";
import { NormalText } from "src/components/styled/text.styled";
import Confirmation from "src/components/Modal/confirmation";
import { SketchPicker } from "react-color";
import checkPermission from "src/helper/checkPermission";
import styled from "styled-components";
import KioskGuideImage from "src/assets/media/images/kiosk_guide.png";
import MobileGuideImage from "src/assets/media/images/mobile_guide.png";
import {
  getPaymentMethodList,
  IPaymentMethod,
} from "src/redux/paymentmethod/paymentmethod.services";
import { toast } from "react-toastify";

const UploadLabel = styled.label`
  width: 9%;
  height: 40px;
  background-color: white;
  color: black;
  padding: 10px;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 2px;
  border: 1px solid #1985d2;
  text-align: center;
  &:hover {
    background-color: #1985d2;
    color: #fff;
  }
`;

const Page = () => {
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: { AppSetting, Auth },
  } = useRedux();
  const [displayPrimaryColorPicker, setDisplayPrimaryColorPicker] =
    useState(false);
  const [displaySecondaryColorPicker, setDisplaySecondaryColorPicker] =
    useState(false);
  const [displayKioskPrimaryPicker, setDisplayKioskPrimaryPicker] =
    useState(false);
  const [displayKioskSecondPicker, setDisplayKioskSecondPicker] =
    useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const [loadingImgIcon, setLoadingImgIcon] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState("");
  const [merchantId, setMerchantId] = useState("");
  const [pointOfSales, setPointOfSales] = useState<IPaymentMethod[]>();

  const [dataFirst, setDataFirst] = useState<AppSettingData>();
  const [kioskDataFirst, setKioskDataFirst] = useState<AppSettingData>();
  const [data, setData] = useState({
    name: "",
    primaryColor: "#ffc535",
    secondaryColor: "#ffffff",
    appLogo: "",
    favIcon: "",
    threshold: 100,
  });
  const [errorValidasi, setErrorValidasi] = useState({
    name: "",
    primaryColor: "",
    secondaryColor: "",
    appLogo: "",
    favIcon: "",
    threshold: "",
  });

  const [kioskPayload, setKioskPayload] = useState({
    name: "",
    primaryColor: "#ffc535",
    secondaryColor: "#000000",
    appLogo: "",
    favIcon: "",
    threshold: 0,
  });
  const [kioskValidasi, setKioskValidasi] = useState({
    name: "",
    primaryColor: "",
    secondaryColor: "",
    appLogo: "",
    favIcon: "",
    threshold: "",
  });

  const handleClick = () => {
    setDisplayPrimaryColorPicker(!displayPrimaryColorPicker);
  };

  const handleClickSecondary = () => {
    setDisplaySecondaryColorPicker(!displaySecondaryColorPicker);
  };

  const handleClose = () => {
    setDisplayPrimaryColorPicker(false);
    setDisplaySecondaryColorPicker(false);
  };

  const getAll = () => {
    thunkDispatch(getAppSetting("MOBILEQR"))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setMerchantId(String(res.data.data.merchant_id));
          setData({
            ...data,
            name: res.data.data.merchant_name ?? "",
            primaryColor: res.data.data.primary_color ?? "",
            secondaryColor: res.data.data.secondary_color ?? "",
            appLogo: res.data.data.app_logo ?? "",
            favIcon: res.data.data.favicon ?? "",
            threshold: 100,
          });
          setDataFirst(res.data.data);
          thunkDispatch(getAppSetting("KIOSK"))
            .unwrap()
            .then((res) => {
              if (res && res.status == "success") {
                res.data.data &&
                  setKioskPayload({
                    ...kioskPayload,
                    name: res.data.data.merchant_name ?? "",
                    primaryColor: res.data.data.primary_color ?? "",
                    secondaryColor: res.data.data.secondary_color ?? "",
                    appLogo: res.data.data.app_logo ?? "",
                    favIcon: res.data.data.favicon ?? "",
                    threshold: 100,
                  });
                setKioskDataFirst(res.data.data);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    getAll();
    if (merchantId) {
      thunkDispatch(
        getPaymentMethodList({
          merchant_id: merchantId,
          device: "MINIPOS",
        })
      )
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            if (res.data.data) {
              setPointOfSales(res.data.data);
            }
          }
        });
    }
  }, [merchantId]);

  const handleImage = (e: any, data: string, device: "KIOSK" | "MOBILEQR") => {
    if (e.target.files && e.target.files[0]) {
      let imageName = e.target.files[0];
      let fileSize =
        data === "applogo"
          ? imageName.size / (1024 * 1024)
          : imageName.size / (1024 * 1024);
      var img = new Image();
      img.src = window.URL.createObjectURL(imageName);
      img.onload = function () {
        var width = img.naturalWidth;
        var height = img.naturalHeight;
        var ValidHeight = data === "applogo" ? 300 : 300;
        var ValidWidth = data === "applogo" ? 300 : 300;
        if (
          data === "applogo" &&
          (width > ValidWidth || height > ValidHeight || fileSize > 2)
        ) {
          if (device === "KIOSK") {
            setKioskValidasi({
              ...kioskValidasi,
              appLogo: `Resolusi gambar maks. ${ValidHeight}x${ValidWidth} & ukuran maks. 2MB`,
            });
          } else {
            setErrorValidasi({
              ...errorValidasi,
              appLogo: `Resolusi gambar maks. ${ValidHeight}x${ValidWidth} & ukuran maks. 2MB`,
            });
          }
        } else if (
          data === "favicon" &&
          (width > ValidWidth || height > ValidHeight || fileSize > 100)
        ) {
          if (device === "KIOSK") {
            setKioskValidasi({
              ...kioskValidasi,
              favIcon: `Resolusi gambar maks. ${ValidHeight}x${ValidWidth} & ukuran maks. 2MB`,
            });
          } else {
            setErrorValidasi({
              ...errorValidasi,
              favIcon: `Resolusi gambar maks. ${ValidHeight}x${ValidWidth} & ukuran maks. 2MB`,
            });
          }
        } else {
          handleUploadFile(imageName, data, device).catch();
          if (device === "KIOSK") {
            setKioskValidasi({
              ...kioskValidasi,
              appLogo: "",
              favIcon: "",
            });
          } else {
            setErrorValidasi({
              ...errorValidasi,
              appLogo: "",
              favIcon: "",
            });
          }
        }
      };
    }
  };

  const handleUploadFile = async (
    file: any,
    typeUpload: string,
    device: "KIOSK" | "MOBILEQR"
  ) => {
    let nameUpload = typeUpload === "applogo" ? "appLogo" : "favIcon";
    typeUpload === "applogo" ? setLoadingImg(true) : setLoadingImgIcon(true);
    let formdata = new FormData();
    formdata.append("image_count", "1");
    formdata.append("image-1", file);
    await uploadImage(formdata, "setting")
      .then((res) => {
        if (res.status === 200) {
          setLoadingImg(false);
          setLoadingImgIcon(false);
          if (device === "KIOSK") {
            setKioskPayload({
              ...kioskPayload,
              [nameUpload]: res.data.data["image-1"],
            });
          } else {
            setData({
              ...data,
              [nameUpload]: res.data.data["image-1"],
            });
          }
        }
        setLoadingImg(false);
        setLoadingImgIcon(false);
      })
      .catch(() => {
        setLoadingImg(false);
        setLoadingImgIcon(false);
        if (device === "KIOSK") {
          setKioskValidasi({
            ...kioskValidasi,
            [nameUpload]: "Gagal mengupload image",
          });
        } else {
          setErrorValidasi({
            ...errorValidasi,
            [nameUpload]: "Gagal mengupload image",
          });
        }
      });
  };

  const handleAdd = async () => {
    let kiosk: CreateAppSettingTypes = {
      id: kioskDataFirst?.id ?? "",
      merchant_name: kioskPayload.name,
      primary_color: kioskPayload.primaryColor,
      secondary_color: kioskPayload.secondaryColor,
      app_logo: kioskPayload.appLogo,
      favicon: kioskPayload.favIcon,
      threshold: kioskPayload.threshold,
      is_deleted: false,
    };

    let mob: CreateAppSettingTypes = {
      id: dataFirst?.id ?? "",
      merchant_name: data.name,
      primary_color: data.primaryColor,
      secondary_color: data.secondaryColor,
      app_logo: data.appLogo,
      favicon: data.favIcon,
      threshold: data.threshold,
      is_deleted: false,
    };
    await createOrUpdate({ type: "create", kiosk: kiosk, mobileQr: mob });
  };

  const createOrUpdate = async ({
    type,
    kiosk,
    mobileQr,
  }: {
    type: "create" | "update";
    kiosk: CreateAppSettingTypes;
    mobileQr: CreateAppSettingTypes;
  }) => {
    const payload = {
      app_setting_kiosk: {
        kiosk_name: kiosk.merchant_name,
        primary_color: kiosk.primary_color,
        secondary_color: kiosk.secondary_color,
        app_logo: kiosk.app_logo,
        favicon: kiosk.favicon,
      },
      app_setting_mobile_qr: {
        mobile_qr_name: mobileQr.merchant_name,
        primary_color: mobileQr.primary_color,
        secondary_color: mobileQr.secondary_color,
        app_logo: mobileQr.app_logo,
        favicon: mobileQr.favicon,
        threshold: mobileQr.threshold,
      },
    };

    if (type === "create") {
      thunkDispatch(createAppSetting(payload))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            toast.success("Berhasil menyimpan pengaturan aplikasi");
          } else {
            toast.error(res?.data);
          }
        });
    } else if (type === "update") {
      thunkDispatch(
        updateAppSetting({ props: payload, setting_id: kiosk.id ?? "" })
      )
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            toast.success("Berhasil menyimpan pengaturan aplikasi");
          } else {
            toast.error(res?.data);
          }
        });
    } else {
      alert("Something went wrong");
    }
  };

  const handleUpdate = async () => {
    let kiosk: CreateAppSettingTypes = {
      id: kioskDataFirst?.id ?? "",
      merchant_name: kioskPayload.name,
      primary_color: kioskPayload.primaryColor,
      secondary_color: kioskPayload.secondaryColor,
      app_logo: kioskPayload.appLogo,
      favicon: kioskPayload.favIcon,
      threshold: kioskPayload.threshold,
      is_deleted: false,
    };

    let mob: CreateAppSettingTypes = {
      id: dataFirst?.id ?? "",
      merchant_name: data.name,
      primary_color: data.primaryColor,
      secondary_color: data.secondaryColor,
      app_logo: data.appLogo,
      favicon: data.favIcon,
      threshold: data.threshold,
      is_deleted: false,
    };
    await createOrUpdate({ type: "update", kiosk: kiosk, mobileQr: mob });
  };

  const handleValidate = async () => {
    if (
      !data.name ||
      !data.primaryColor ||
      !data.secondaryColor ||
      !data.appLogo ||
      !kioskPayload.name ||
      !kioskPayload.primaryColor ||
      !kioskPayload.secondaryColor ||
      !kioskPayload.appLogo
    ) {
      setErrorValidasi({
        ...errorValidasi,
        name: !data.name ? "Nama Merchant Wajib Diisi" : "",
        primaryColor: !data.primaryColor ? "Primary Color Wajib Diisi" : "",
        secondaryColor: !data.secondaryColor
          ? "Secondary Color Wajib Diisi"
          : "",
        appLogo: !data.appLogo ? "App Logo Wajib Diisi" : "",
        favIcon: "",
        // threshold: !data.threshold ? "threshold Wajib Diisi" : "",
      });
      setKioskValidasi({
        ...kioskValidasi,
        name: !kioskPayload.name ? "Nama Merchant Wajib Diisi" : "",
        primaryColor: !kioskPayload.primaryColor
          ? "Primary Color Wajib Diisi"
          : "",
        secondaryColor: !kioskPayload.secondaryColor
          ? "Secondary Color Wajib Diisi"
          : "",
        appLogo: !kioskPayload.appLogo ? "App Logo Wajib Diisi" : "",
        favIcon: "",
        // threshold: !kioskPayload.threshold ? "threshold Wajib Diisi" : "",
      });
      toast.warning("Silahkan cek kembali inputan anda");
    } else if (data.name.length > 50 || kioskPayload.name.length > 50) {
      toast.warning("Silahkan cek kembali inputan anda");
      setErrorValidasi({
        ...errorValidasi,
        name: data.name.length > 50 ? "Maksimal 50 karakter nama merchant" : "",
        primaryColor: "",
        secondaryColor: "",
        appLogo: "",
        favIcon: "",
        threshold: "",
      });
      setKioskValidasi({
        ...kioskValidasi,
        name:
          kioskPayload.name.length > 50
            ? "Maksimal 50 karakter nama merchant"
            : "",
        primaryColor: "",
        secondaryColor: "",
        appLogo: "",
        favIcon: "",
        threshold: "",
      });
    } else {
      setErrorValidasi({
        ...errorValidasi,
        name: "",
        primaryColor: "",
        secondaryColor: "",
        appLogo: "",
        favIcon: "",
        threshold: "",
      });
      setKioskValidasi({
        ...kioskValidasi,
        name: "",
        primaryColor: "",
        secondaryColor: "",
        appLogo: "",
        favIcon: "",
        threshold: "",
      });
      dataFirst ? await handleUpdate() : await handleAdd();
    }
  };

  const handleDeleteFavicon = (device: any) => {
    if (device === "KIOSK") {
      setKioskPayload({ ...kioskPayload, favIcon: "" });
    } else {
      setData({ ...data, favIcon: "" });
    }
  };

  return (
    <MerchantLayout>
      <Confirmation
        handleConfirm={() => {
          if (statusModal === "success") {
            getAll();
          }
          setOpenModal(false);
        }}
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
        hasCancel={false}
        title={statusModal === "success" ? "Sukses" : "Gagal"}
        description={
          statusModal === "success"
            ? "Berhasil menyimpan data app setting"
            : AppSetting?.error?.response?.data?.message
            ? AppSetting.error.response.data.message
            : "Gagal menyimpan data, silahkan periksa kembali data anda"
        }
      ></Confirmation>
      <UserGroups tabActive={0}>
        <NormalText
          textAlign="start"
          fontWeight={"bold"}
          fontSize={"18px"}
          margin={"10px 0 20px 0"}
        >
          Pengaturan KIOSK
        </NormalText>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              type={"text"}
              label={"Nama Merchant"}
              isRequired
              value={kioskPayload.name}
              onChange={(event: any) =>
                setKioskPayload({
                  ...kioskPayload,
                  name: event.target.value,
                })
              }
              validationType={
                kioskValidasi.name || kioskPayload.name.length > 32
                  ? "ERROR"
                  : ""
              }
              validationText={kioskValidasi.name}
              placeholder="Masukkan nama merchant anda"
            />
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <NormalText
                  textAlign="left"
                  margin={"20px 0 5px 0"}
                  fontSize={"14px"}
                >
                  Primary Color *
                </NormalText>
                <Stack direction={"row"} alignItems={"center"}>
                  <div
                    style={{
                      padding: "5px",
                      background: "#fff",
                      borderRadius: "1px",
                      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                      display: "inline-block",
                      cursor: "pointer",
                      height: "30px",
                      margin: "0 20px 0 20px",
                    }}
                    onClick={() =>
                      setDisplayKioskPrimaryPicker(!displayKioskPrimaryPicker)
                    }
                  >
                    <div
                      style={{
                        width: "36px",
                        height: "20px",
                        borderRadius: "2px",
                        background: kioskPayload.primaryColor,
                      }}
                    />
                  </div>
                  {displayKioskPrimaryPicker ? (
                    <div style={{ position: "absolute", zIndex: "2" }}>
                      <div
                        style={{
                          position: "fixed",
                          top: "0px",
                          right: "0px",
                          bottom: "0px",
                          left: "0px",
                        }}
                        onClick={() =>
                          setDisplayKioskPrimaryPicker(
                            !displayKioskPrimaryPicker
                          )
                        }
                      />
                      <SketchPicker
                        color={kioskPayload.primaryColor}
                        onChangeComplete={(color: any) =>
                          setKioskPayload({
                            ...kioskPayload,
                            primaryColor: color.hex,
                          })
                        }
                      />
                    </div>
                  ) : null}
                  <TextField
                    type={"text"}
                    label={""}
                    isRequired
                    value={kioskPayload.primaryColor}
                    onChange={(event: any) =>
                      setKioskPayload({
                        ...kioskPayload,
                        primaryColor: event.target.value,
                      })
                    }
                    style={{
                      height: "30px",
                      width: "90px",
                      margin: "5px 0 0 0px",
                    }}
                    validationType={kioskValidasi.primaryColor ? "ERROR" : ""}
                    validationText={kioskValidasi.primaryColor}
                    placeholder="Masukkan primary color anda"
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <NormalText
                  textAlign="left"
                  margin={"20px 0 5px 0"}
                  fontSize={"14px"}
                >
                  Secondary Color *
                </NormalText>
                <Stack direction={"row"} alignItems={"center"}>
                  <div
                    style={{
                      padding: "5px",
                      background: "#fff",
                      borderRadius: "1px",
                      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                      display: "inline-block",
                      cursor: "pointer",
                      height: "30px",
                      margin: "0 20px 0 20px",
                    }}
                    onClick={() =>
                      setDisplayKioskSecondPicker(!displayKioskSecondPicker)
                    }
                  >
                    <div
                      style={{
                        width: "36px",
                        height: "20px",
                        borderRadius: "2px",
                        background: kioskPayload.secondaryColor,
                      }}
                    />
                  </div>
                  {displayKioskSecondPicker ? (
                    <div style={{ position: "absolute", zIndex: "2" }}>
                      <div
                        style={{
                          position: "fixed",
                          top: "0px",
                          right: "0px",
                          bottom: "0px",
                          left: "0px",
                        }}
                        onClick={() =>
                          setDisplayKioskSecondPicker(!displayKioskSecondPicker)
                        }
                      />
                      <SketchPicker
                        color={kioskPayload.secondaryColor}
                        onChangeComplete={(color: any) =>
                          setKioskPayload({
                            ...kioskPayload,
                            secondaryColor: color.hex,
                          })
                        }
                      />
                    </div>
                  ) : null}
                  <TextField
                    type={"text"}
                    label={""}
                    style={{
                      height: "30px",
                      width: "90px",
                      margin: "5px 0 0 0px",
                    }}
                    isRequired
                    value={kioskPayload.secondaryColor}
                    onChange={(event: any) =>
                      setKioskPayload({
                        ...kioskPayload,
                        secondaryColor: event.target.value,
                      })
                    }
                    validationType={kioskValidasi.secondaryColor ? "ERROR" : ""}
                    validationText={kioskValidasi.secondaryColor}
                    placeholder="Masukkan secondary color anda"
                  />
                </Stack>
              </Grid>
            </Grid>

            <NormalText
              textAlign="start"
              fontWeight={"bold"}
              fontSize={"18px"}
              margin={"35px 0 20px 0"}
            >
              Gambar
            </NormalText>
            <Box>
              <NormalText
                textAlign="left"
                margin={"20px 0 5px 0"}
                fontSize={"14px"}
              >
                App Logo *
              </NormalText>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <UploadLabel
                    htmlFor="appLogo"
                    style={{
                      width: "100%",
                      backgroundColor: loadingImg ? "#ccc" : "",
                      borderColor: loadingImg ? "#ccc" : "",
                      cursor: "pointer",
                    }}
                  >
                    {loadingImg ? <CircularProgress size={15} /> : null}{" "}
                    {loadingImg ? "" : "Pilih Gambar"}
                  </UploadLabel>
                  <input
                    accept="image/*"
                    id="appLogo"
                    multiple
                    type="file"
                    onChange={(e: any) => handleImage(e, "applogo", "KIOSK")}
                    style={{ display: "none" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  {kioskPayload.appLogo && (
                    <img
                      src={kioskPayload.appLogo}
                      style={{ height: 150, width: 150, marginLeft: "30px" }}
                      className="brand-image"
                      alt="image brand"
                      loading="lazy"
                    />
                  )}
                </Grid>
              </Grid>
              <FormHelperText style={{ color: "#1565c0" }}>
                Resolusi gambar maks. 300x300 Pixels {"&"} ukuran maks. 2MB
              </FormHelperText>
              <FormHelperText style={{ color: "#f12147" }}>
                {kioskValidasi.appLogo}
              </FormHelperText>
            </Box>

            {/* Start Favicon*/}
            <Box>
              <NormalText
                textAlign="left"
                margin={"20px 0 5px 0"}
                fontSize={"14px"}
              >
                Favicon
              </NormalText>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <UploadLabel
                    htmlFor="favicon"
                    style={{
                      width: "100%",
                      backgroundColor: loadingImg ? "#ccc" : "",
                      borderColor: loadingImg ? "#ccc" : "",
                      cursor: "pointer",
                    }}
                  >
                    {loadingImg ? <CircularProgress size={15} /> : null}{" "}
                    {loadingImg ? "" : "Pilih Gambar"}
                  </UploadLabel>
                  {kioskPayload && kioskPayload.favIcon ? (
                    <UploadLabel
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(229, 108, 117)",
                        borderColor: "rgb(229, 108, 117)",
                        color: "rgb(255, 255, 255)",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDeleteFavicon("KIOSK")}
                    >
                      Hapus Gambar
                    </UploadLabel>
                  ) : null}
                  <input
                    accept="image/*"
                    id="favicon"
                    multiple
                    type="file"
                    onChange={(e: any) => handleImage(e, "favicon", "KIOSK")}
                    style={{ display: "none" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  {kioskPayload.favIcon && (
                    <img
                      src={kioskPayload.favIcon}
                      style={{ height: 50, width: 50, marginLeft: "30px" }}
                      className="brand-image"
                      alt="image brand"
                      loading="lazy"
                    />
                  )}
                </Grid>
              </Grid>
              <FormHelperText style={{ color: "#1565c0" }}>
                Resolusi gambar maks. 300x300 Pixels {"&"} ukuran maks. 2MB
              </FormHelperText>
              <FormHelperText style={{ color: "#f12147" }}>
                {kioskValidasi.favIcon}
              </FormHelperText>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} textAlign={"center"}>
            <NormalText fontWeight={"bold"} textAlign={"center"}>
              Styling Guide
            </NormalText>
            <Stack
              height={"375px"}
              width={"100%"}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <img
                src={KioskGuideImage}
                style={{
                  height: "100%",
                  width: "auto",
                  userSelect: "none",
                }}
                alt={"Kiosk Guide"}
              />
            </Stack>
          </Grid>
        </Grid>
        <hr />
        <NormalText
          textAlign="start"
          fontWeight={"bold"}
          fontSize={"18px"}
          margin={"10px 0 20px 0"}
        >
          Pengaturan POS
        </NormalText>
        {pointOfSales &&
          pointOfSales.map((item, index) => (
            <Stack direction={"row"} alignItems={"end"} spacing={2}>
              <TextField
                key={index + "-pos"}
                label={item.payment_name}
                disabled
                value={
                  item.type_payment === "PAYMENT_GATEWAY"
                    ? "Payment Gateway"
                    : item.type_payment === "PENDING_PAYMENT"
                    ? "Pending Payment"
                    : item.type_payment === "DIRECT_PAYMENT"
                    ? "Direct Payment"
                    : item.type_payment
                }
              />
              <ButtonMUI
                variant={"contained"}
                sx={{ marginBottom: "8px !important" }}
              >
                <a
                  style={{ color: "white" }}
                  href={"mailto:matori.azis11@gmail.com"}
                >
                  Ubah?
                </a>
              </ButtonMUI>
            </Stack>
          ))}
        <hr />
        {/* Start Mobile QR */}
        <NormalText
          textAlign="start"
          fontWeight={"bold"}
          fontSize={"18px"}
          margin={"10px 0 20px 0"}
        >
          Pengaturan Mobile QR
        </NormalText>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              type={"text"}
              label={"Nama Merchant"}
              isRequired
              value={data.name}
              onChange={(event: any) =>
                setData({
                  ...data,
                  name: event.target.value,
                })
              }
              validationType={
                errorValidasi.name || data.name.length > 32 ? "ERROR" : ""
              }
              validationText={errorValidasi.name}
              placeholder="Masukkan nama merchant anda"
            />
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <NormalText
                  textAlign="left"
                  margin={"20px 0 5px 0"}
                  fontSize={"14px"}
                >
                  Primary Color *
                </NormalText>
                <Stack direction={"row"} alignItems={"center"}>
                  <div
                    style={{
                      padding: "5px",
                      background: "#fff",
                      borderRadius: "1px",
                      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                      display: "inline-block",
                      cursor: "pointer",
                      height: "30px",
                      margin: "0 20px 0 20px",
                    }}
                    onClick={() => handleClick()}
                  >
                    <div
                      style={{
                        width: "36px",
                        height: "20px",
                        borderRadius: "2px",
                        background: data.primaryColor,
                      }}
                    />
                  </div>
                  {displayPrimaryColorPicker ? (
                    <div style={{ position: "absolute", zIndex: "2" }}>
                      <div
                        style={{
                          position: "fixed",
                          top: "0px",
                          right: "0px",
                          bottom: "0px",
                          left: "0px",
                        }}
                        onClick={() => handleClose()}
                      />
                      <SketchPicker
                        color={data.primaryColor}
                        onChangeComplete={(color: any) =>
                          setData({
                            ...data,
                            primaryColor: color.hex,
                          })
                        }
                      />
                    </div>
                  ) : null}
                  <TextField
                    type={"text"}
                    label={""}
                    isRequired
                    value={data.primaryColor}
                    onChange={(event: any) =>
                      setData({
                        ...data,
                        primaryColor: event.target.value,
                      })
                    }
                    style={{
                      height: "30px",
                      width: "90px",
                      margin: "5px 0 0 0px",
                    }}
                    validationType={errorValidasi.primaryColor ? "ERROR" : ""}
                    validationText={errorValidasi.primaryColor}
                    placeholder="Masukkan primary color anda"
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <NormalText
                  textAlign="left"
                  margin={"20px 0 5px 0"}
                  fontSize={"14px"}
                >
                  Secondary Color *
                </NormalText>
                <Stack direction={"row"} alignItems={"center"}>
                  <div
                    style={{
                      padding: "5px",
                      background: "#fff",
                      borderRadius: "1px",
                      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                      display: "inline-block",
                      cursor: "pointer",
                      height: "30px",
                      margin: "0 20px 0 20px",
                    }}
                    onClick={() => handleClickSecondary()}
                  >
                    <div
                      style={{
                        width: "36px",
                        height: "20px",
                        borderRadius: "2px",
                        background: data.secondaryColor,
                      }}
                    />
                  </div>
                  {displaySecondaryColorPicker ? (
                    <div style={{ position: "absolute", zIndex: "2" }}>
                      <div
                        style={{
                          position: "fixed",
                          top: "0px",
                          right: "0px",
                          bottom: "0px",
                          left: "0px",
                        }}
                        onClick={() => handleClose()}
                      />
                      <SketchPicker
                        color={data.secondaryColor}
                        onChangeComplete={(color: any) =>
                          setData({
                            ...data,
                            secondaryColor: color.hex,
                          })
                        }
                      />
                    </div>
                  ) : null}
                  <TextField
                    type={"text"}
                    label={""}
                    style={{
                      height: "30px",
                      width: "90px",
                      margin: "5px 0 0 0px",
                    }}
                    isRequired
                    value={data.secondaryColor}
                    onChange={(event: any) =>
                      setData({
                        ...data,
                        secondaryColor: event.target.value,
                      })
                    }
                    validationType={errorValidasi.secondaryColor ? "ERROR" : ""}
                    validationText={errorValidasi.secondaryColor}
                    placeholder="Masukkan secondary color anda"
                  />
                </Stack>
              </Grid>
            </Grid>

            <NormalText
              textAlign="start"
              fontWeight={"bold"}
              fontSize={"18px"}
              margin={"35px 0 20px 0"}
            >
              Gambar
            </NormalText>
            <Box>
              <NormalText
                textAlign="left"
                margin={"20px 0 5px 0"}
                fontSize={"14px"}
              >
                App Logo *
              </NormalText>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <UploadLabel
                    htmlFor="mobileQrAppLogo"
                    style={{
                      width: "100%",
                      backgroundColor: loadingImg ? "#ccc" : "",
                      borderColor: loadingImg ? "#ccc" : "",
                      cursor: "pointer",
                    }}
                  >
                    {loadingImg ? <CircularProgress size={15} /> : null}{" "}
                    {loadingImg ? "" : "Pilih Gambar"}
                  </UploadLabel>
                  <input
                    accept="image/*"
                    id="mobileQrAppLogo"
                    type="file"
                    onChange={(e: any) => handleImage(e, "applogo", "MOBILEQR")}
                    style={{ display: "none" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  {data.appLogo && (
                    <img
                      src={data.appLogo}
                      style={{ height: 150, width: 150, marginLeft: "30px" }}
                      className="brand-image"
                      alt="image brand"
                      loading="lazy"
                    />
                  )}
                </Grid>
              </Grid>
              <FormHelperText style={{ color: "#1565c0" }}>
                Resolusi gambar maks. 300x300 Pixels {"&"} ukuran maks. 2MB
              </FormHelperText>
              <FormHelperText style={{ color: "#f12147" }}>
                {errorValidasi.appLogo}
              </FormHelperText>
            </Box>

            {/* Start Favicon*/}
            <Box>
              <NormalText
                textAlign="left"
                margin={"20px 0 5px 0"}
                fontSize={"14px"}
              >
                Favicon
              </NormalText>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <UploadLabel
                    htmlFor="mobileQrFavicon"
                    style={{
                      width: "100%",
                      backgroundColor: loadingImg ? "#ccc" : "",
                      borderColor: loadingImg ? "#ccc" : "",
                      cursor: "pointer",
                    }}
                  >
                    {loadingImg ? <CircularProgress size={15} /> : null}{" "}
                    {loadingImg ? "" : "Pilih Gambar"}
                  </UploadLabel>
                  {data && data.favIcon ? (
                    <UploadLabel
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(229, 108, 117)",
                        borderColor: "rgb(229, 108, 117)",
                        color: "rgb(255, 255, 255)",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDeleteFavicon("MOBILEQR")}
                    >
                      Hapus Gambar
                    </UploadLabel>
                  ) : null}
                  <input
                    accept="image/*"
                    id="mobileQrFavicon"
                    multiple
                    type="file"
                    onChange={(e: any) => handleImage(e, "favicon", "MOBILEQR")}
                    style={{ display: "none" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  {data.favIcon && (
                    <img
                      src={data.favIcon}
                      style={{ height: 50, width: 50, marginLeft: "30px" }}
                      className="brand-image"
                      alt="image brand"
                      loading="lazy"
                    />
                  )}
                </Grid>
              </Grid>
              <FormHelperText style={{ color: "#1565c0" }}>
                Resolusi gambar maks. 300x300 Pixels {"&"} ukuran maks. 2MB
              </FormHelperText>
              <FormHelperText style={{ color: "#f12147" }}>
                {errorValidasi.favIcon}
              </FormHelperText>
              {/* <NormalText
                textAlign="start"
                fontWeight={"bold"}
                fontSize={"18px"}
                margin={"35px 0 20px 0"}
              >
                Radius Toko
              </NormalText>
              <TextField
                type={"number"}
                label={"Thesrhold"}
                isRequired
                value={data.threshold}
                onChange={(event: any) =>
                  setData({
                    ...data,
                    threshold: event.target.value,
                  })
                }
                validationType={errorValidasi.threshold ? "ERROR" : ""}
                validationText={errorValidasi.threshold}
                placeholder="Masukkan thesrhold anda"
              /> */}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} textAlign={"center"}>
            <NormalText fontWeight={"bold"} textAlign={"center"}>
              Styling Guide
            </NormalText>
            <Stack
              height={"375px"}
              width={"100%"}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <img
                src={MobileGuideImage}
                style={{
                  width: "auto",
                  height: "100%",
                  userSelect: "none",
                }}
                alt={"Mobile QR Guide"}
              />
            </Stack>
          </Grid>
        </Grid>
        <hr />
        {((!dataFirst && checkPermission("App Setting", "is_add")) ||
          (dataFirst && checkPermission("App Setting", "is_edit"))) && (
          <ButtonMUI
            variant="contained"
            onClick={async () => await handleValidate()}
            sx={{ width: 100, height: 40 }}
          >
            Simpan
          </ButtonMUI>
        )}
      </UserGroups>
    </MerchantLayout>
  );
};

export default Page;
