import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";

var firebaseConfig = {
  apiKey: "AIzaSyDUoq04uCHWLIQ4BFDj0U2e1ZexLw0FmUM",
  authDomain: "sandbox-5cd2f.firebaseapp.com",
  projectId: "sandbox-5cd2f",
  storageBucket: "sandbox-5cd2f.appspot.com",
  messagingSenderId: "752778506275",
  appId: "1:752778506275:web:156ca989fc437dc6f20196",
  measurementId: "G-RN5JCHQ5XB",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (topic, condition) => {
  return getToken(messaging, {
    vapidKey:
      "BGBjhLTlKRrByQ1in4q1p7bls4Ou_5VNikTxTj4r7NgmbISwhDZYZSsktr8HtFSpNozDFAo0jWMCGViOm8FoGck",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        // Track the token -> client mapping, by sending to backend server

        // set topic(subscribe) or unset topic(unsubscribe)
        if (condition === "SUBSCRIBE") {
          subscribeTokenToTopic(currentToken, "store" + topic);
        }
        if (condition === "UNSUBSCRIBE") {
          unsubscribeTokenFromTopic(currentToken, "store" + topic);
        }
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const subscribeTokenToTopic = (token, topic) => {
  fetch("https://iid.googleapis.com/iid/v1/" + token + "/rel/topics/" + topic, {
    method: "POST",
    headers: new Headers({
      Authorization:
        "key=" +
        `AAAAr0UYcCM:APA91bHI3wsdRuJisUPp6lHuZEI8LkWkEyhPsI3wc9_tjnmVlv6q8N6sKcZWqRH4ZXH3aA7QNKOBWqaEsNzSBmDPmirdKMngK5sFZx_OfdAS-ILlCeusmba1SNAT_G9qs6AeKzCkTV_5	
      `,
    }),
  })
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        throw (
          "Error subscribing to topic: " +
          response.status +
          " - " +
          response.text()
        );
      }
      console.log('Subscribed to "' + topic + '"');
    })
    .catch((error) => {
      console.error(error);
    });
};

export const unsubscribeTokenFromTopic = (token, topic) => {
  fetch("https://iid.googleapis.com/iid/v1/" + token + "/rel/topics/" + topic, {
    method: "DELETE",
    headers: new Headers({
      Authorization:
        "key=" +
        `AAAAr0UYcCM:APA91bHI3wsdRuJisUPp6lHuZEI8LkWkEyhPsI3wc9_tjnmVlv6q8N6sKcZWqRH4ZXH3aA7QNKOBWqaEsNzSBmDPmirdKMngK5sFZx_OfdAS-ILlCeusmba1SNAT_G9qs6AeKzCkTV_5	
      `,
    }),
  })
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        throw (
          "Error unsubscribing to topic: " +
          response.status +
          " - " +
          response.text()
        );
      }
      console.log('Unsubscribed from "' + topic + '"');
    })
    .catch((error) => {
      console.error(error);
    });
};
