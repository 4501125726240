import { PuplistState, PuplistResponseProps } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { getPuplist, createPuplist, removePuplist, updatePuplist } from './puplistServices';

export const initialState: PuplistState = {
    data: {} as PuplistResponseProps,
    status: "loading",
    error: {}
}

export const PuplistSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPuplist.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getPuplist.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(getPuplist.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(createPuplist.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(createPuplist.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(createPuplist.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(removePuplist.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(removePuplist.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(removePuplist.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(updatePuplist.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(updatePuplist.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(updatePuplist.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
    }
})

export const PuplistsReducer = PuplistSlice.reducer