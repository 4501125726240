import React, { useState, useEffect } from 'react';
import { Stack } from '../../../../components/styled/layout.styled';
import UserGroups from '../layout';
import { useNavigate } from 'react-router-dom';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import styles from './styles';
import {
  Box,
  Autocomplete,
  Button as ButtonMUI,
  TextField as TextFieldMUI,
  FormHelperText,
} from '@mui/material';
import { NormalText } from 'src/components/styled/text.styled';
import TextField from 'src/components/Inputs/TextField';
import useRedux from 'src/redux/useRedux';
import PopUp from 'src/components/Modal/modal';
import { ProvincesData, getAllProvinces } from 'src/redux/regions/province';
import { CityData, getAllCity } from 'src/redux/regions/city';
import { getAllSubUrban, SubUrbanData } from 'src/redux/regions/suburban';
import { getAllArea, AreaData } from 'src/redux/regions/area';
import { updateProfileMerchant } from 'src/redux/profile';

const ProfilPage = () => {
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: { Auth, Profile },
  } = useRedux();

  const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState('');
  const [provinces, setProvinces] = React.useState<ProvincesData[]>([]);
  const [cities, setCities] = React.useState<CityData[]>([]);
  const [listSubUrban, setListSubUrban] = React.useState<SubUrbanData[]>([]);
  const [areas, setAreas] = React.useState<AreaData[]>([]);
  const [dataAdd, setDataAdd] = useState({
    name: Auth?.data?.profile_data?.name ?? '',
    merchantUrl: Auth?.data?.profile_data?.merchant_url_page ?? '',
    email: Auth?.data?.profile_data?.email ?? '',
    telepon: Auth?.data?.profile_data?.phone ?? '',
    alamat: Auth?.data?.profile_data?.address ?? '',
    kodePos: Auth?.data?.profile_data?.postal_code ?? '',
    provinsi: {
      id: 0,
      province_name: '',
      description: '',
    },
    kota: {
      id: 0,
      city_name: '',
      description: '',
    },
    kecamatan: {
      id: 0,
      suburbName: '',
      description: '',
    },
    kelurahan: {
      id: 0,
      area_name: '',
      description: '',
    },
  });
  const [loading, setLoading] = useState(true);
  const [errorValidasi, setErrorValidasi] = useState({
    name: '',
    merchantUrl: '',
    email: '',
    telepon: '',
    alamat: '',
    provinsi: '',
    kota: '',
    kecamatan: '',
    kelurahan: '',
    kodePos: '',
  });

  const fetchCities = (prov_id: number) => {
    thunkDispatch(getAllCity(prov_id))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          console.log('C', res.data.data);
          setCities(res.data.data.cities);
        }
      });
  };

  const fetchSuburban = (city_id: number) => {
    thunkDispatch(getAllSubUrban(city_id))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          setListSubUrban(res.data.data.suburbs);
        }
      });
  };

  const fetchArea = (suburb_id: number) => {
    thunkDispatch(getAllArea(suburb_id))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          setAreas(res.data.data.areas);
        }
      });
  };

  React.useEffect(() => {
    thunkDispatch(getAllProvinces())
      .unwrap()
      .then((res: any) => {
        if (res && res.status === 'success') {
          setProvinces(res.data.data);
        }
      });
  }, []);

  const handleAddUser = () => {
    let dataObject = {
      data: {
        email: dataAdd.email,
        name: dataAdd.name,
        merchant_url: dataAdd.merchantUrl,
        phone: dataAdd.telepon,
        address: dataAdd.alamat,
        province_id: dataAdd.provinsi.id,
        city_id: dataAdd.kota.id,
        district_id: dataAdd.kecamatan.id,
        subdistrict_id: dataAdd.kelurahan.id,
        postal_code: dataAdd.kodePos,
      },
      id_user: 0,
    };
    thunkDispatch(updateProfileMerchant(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == 'success') {
          setLoading(false);
          setOpenModal(true);
          setStatusModal('success');
        } else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal('failed');
        }
      })
      .catch((err) => {
        setLoading(false);
        setStatusModal('failed');
        setOpenModal(true);
        console.error(err);
      });
    setLoading(false);
  };

  const handleValidate = () => {
    if (
      !dataAdd.name ||
      !dataAdd.email ||
      !dataAdd.telepon ||
      !dataAdd.alamat ||
      !dataAdd.provinsi.province_name ||
      !dataAdd.kota.city_name ||
      !dataAdd.kecamatan.suburbName ||
      !dataAdd.kelurahan.area_name ||
      !dataAdd.kodePos
    ) {
      const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      setErrorValidasi({
        ...errorValidasi,
        name: '',
        merchantUrl: !dataAdd.merchantUrl ? 'Merchant URL wajib diisi' : '',
        email: !dataAdd.email
          ? 'Email wajib diisi'
          : regex.test(dataAdd.email) === false
          ? 'Format data harus email (contoh : email@sandbox.co.id)'
          : '',
        telepon: !dataAdd.telepon ? 'Telepon wajib diisi' : '',
        alamat: !dataAdd.alamat ? 'Alamat wajib diisi' : '',
        provinsi: !dataAdd.provinsi.province_name ? 'Provinsi wajib diisi' : '',
        kota: !dataAdd.kota.city_name ? 'kota/kabupaten wajib diisi' : '',
        kecamatan: !dataAdd.kecamatan.suburbName ? 'kecamatan wajib diisi' : '',
        kelurahan: !dataAdd.kelurahan.area_name ? 'kelurahan wajib diisi' : '',
        kodePos: !dataAdd.kodePos ? 'Kode Pos wajib diisi' : '',
      });
    } else {
      setErrorValidasi({
        ...errorValidasi,
        name: '',
        merchantUrl: '',
        email: '',
        telepon: '',
        alamat: '',
        provinsi: '',
        kota: '',
        kecamatan: '',
        kelurahan: '',
        kodePos: '',
      });
      handleAddUser();
    }
  };

  return (
    <MerchantLayout>
      <PopUp open={openModal} handleClose={() => setOpenModal(!openModal)}>
        <NormalText
          textAlign="center"
          fontWeight={'bold'}
          fontSize={'20px'}
          margin={'0 0 20px 0'}
        >
          {statusModal === 'success' ? 'Sukses' : 'Gagal'}
        </NormalText>
        <NormalText textAlign="center" fontSize={'14px'} margin={'0 0 20px 0'}>
          {statusModal === 'success' ? (
            <span>Sukses Update Profile Merchant</span>
          ) : Profile?.error?.response?.data?.message ? (
            <span>{Profile.error.response.data.message}</span>
          ) : (
            <span>
              Gagal mengupdate profile, <br /> mohon periksa
              kembali form data anda
            </span>
          )}
        </NormalText>
        <Box textAlign={'center'} margin={'20px 0 0 0 '}>
          <ButtonMUI
            variant="contained"
            onClick={() =>
              statusModal === 'success'
                ? navigate('/')
                : setOpenModal(!openModal)
            }
            sx={{ width: 100, height: 40, marginRight: 3 }}
            color="error"
          >
            Oke
          </ButtonMUI>
        </Box>
      </PopUp>
      <UserGroups tabActive={0}>
        <Stack direction="column">
          <Box sx={styles.boxStyled}>
            <NormalText
              textAlign="start"
              fontWeight={'bold'}
              fontSize={'18px'}
              margin={'0 0 20px 0'}
            >
              Profil Merchant
            </NormalText>
            <form>
              <NormalText
                textAlign="start"
                fontWeight={'bold'}
                fontSize={'15px'}
                margin={'10px 0 20px 0'}
              >
                Informasi Akun
              </NormalText>
              <NormalText
                textAlign="left"
                margin={'20px 0 5px 0'}
                fontSize={'14px'}
              >
                Email *
              </NormalText>
              <TextField
                type={'email'}
                value={dataAdd.email}
                style={{ height: 45 }}
                onChange={(event: any) =>
                  setDataAdd({
                    ...dataAdd,
                    email: event.target.value,
                  })
                }
                validationType={errorValidasi.email ? 'ERROR' : ''}
                validationText={errorValidasi.email}
                placeholder="Masukkan email anda"
              />
              <NormalText
                textAlign="left"
                margin={'20px 0 5px 0'}
                fontSize={'14px'}
              >
                Nama *
              </NormalText>
              <TextField
                type={'text'}
                style={{ height: 45 }}
                value={dataAdd.name}
                onChange={(event: any) =>
                  setDataAdd({
                    ...dataAdd,
                    name: event.target.value,
                  })
                }
                validationType={errorValidasi.name ? 'ERROR' : ''}
                validationText={errorValidasi.name}
                placeholder="Masukkan nama anda "
              />
              <NormalText
                textAlign="left"
                margin={'20px 0 5px 0'}
                fontSize={'14px'}
              >
                Merchant URL
              </NormalText>
              <TextField
                type={'text'}
                style={{ height: 45 }}
                value={dataAdd.merchantUrl}
                onChange={(event: any) =>
                  setDataAdd({
                    ...dataAdd,
                    merchantUrl: event.target.value,
                  })
                }
                validationType={errorValidasi.merchantUrl ? 'ERROR' : ''}
                validationText={errorValidasi.merchantUrl}
                placeholder="Masukkan merchant URL anda"
              />
              <NormalText
                textAlign="left"
                margin={'20px 0 5px 0'}
                fontSize={'14px'}
              >
                Telepon *
              </NormalText>
              <TextField
                type={'tel'}
                value={dataAdd.telepon}
                style={{ height: 45 }}
                onChange={(event: any) =>
                  setDataAdd({
                    ...dataAdd,
                    telepon: event.target.value,
                  })
                }
                validationType={errorValidasi.telepon ? 'ERROR' : ''}
                validationText={errorValidasi.telepon}
                placeholder="Masukkan telepon anda"
              />
              <NormalText
                textAlign="start"
                fontWeight={'bold'}
                fontSize={'15px'}
                margin={'10px 0 20px 0'}
              >
                Informasi Perusahaan
              </NormalText>
              <NormalText
                textAlign="left"
                margin={'20px 0 5px 0'}
                fontSize={'14px'}
              >
                Alamat *
              </NormalText>
              <TextField
                type={'text'}
                value={dataAdd.alamat}
                style={{ height: 45 }}
                onChange={(event: any) =>
                  setDataAdd({
                    ...dataAdd,
                    alamat: event.target.value,
                  })
                }
                validationType={errorValidasi.alamat ? 'ERROR' : ''}
                validationText={errorValidasi.alamat}
                placeholder="Masukkan alamat anda"
              />
              <NormalText
                textAlign="left"
                margin={'20px 0 5px 0'}
                fontSize={'14px'}
              >
                Provinsi *
              </NormalText>
              <Autocomplete
                id="combo-box-demo"
                // options={top100Films}
                options={provinces}
                getOptionLabel={(option) => option.province_name || ''}
                onChange={(event: any, value: any) => {
                  fetchCities(value.id);
                  setDataAdd({
                    ...dataAdd,
                    provinsi: value,
                  });
                }}
                sx={{ width: '100%' }}
                renderInput={(params) => (
                  <TextFieldMUI
                    {...params}
                    style={{ fontSize: 14 }}
                    size="small"
                    placeholder="Masukkan provinsi anda"
                    value={dataAdd.provinsi}
                  />
                )}
              />
              <FormHelperText style={{ color: '#f12147' }}>
                {errorValidasi.provinsi}
              </FormHelperText>
              <NormalText
                textAlign="left"
                margin={'20px 0 5px 0'}
                fontSize={'14px'}
              >
                Kota/Kabupaten *
              </NormalText>
              <Autocomplete
                id="combo-box-demo"
                options={cities}
                getOptionLabel={(option) => option.city_name || ''}
                onChange={(event: any, value: any) => {
                  fetchSuburban(value.id);
                  setDataAdd({
                    ...dataAdd,
                    kota: value,
                  });
                }}
                sx={{ width: '100%' }}
                renderInput={(params) => (
                  <TextFieldMUI
                    {...params}
                    style={{ fontSize: 14 }}
                    size="small"
                    placeholder="Masukkan kota/kabupaten anda"
                    value={dataAdd.kota}
                  />
                )}
              />
              <FormHelperText style={{ color: '#f12147' }}>
                {errorValidasi.kota}
              </FormHelperText>
              <NormalText
                textAlign="left"
                margin={'20px 0 5px 0'}
                fontSize={'14px'}
              >
                Kecamatan *
              </NormalText>
              <Autocomplete
                id="combo-box-demo"
                // options={top100Films}
                options={listSubUrban}
                getOptionLabel={(option) => option.suburbName || ''}
                onChange={(event: any, value: any) => {
                  fetchArea(value.id);
                  setDataAdd({
                    ...dataAdd,
                    kecamatan: value,
                  });
                }}
                sx={{ width: '100%' }}
                renderInput={(params) => (
                  <TextFieldMUI
                    {...params}
                    style={{ fontSize: 14 }}
                    size="small"
                    placeholder="Masukkan kecamatan anda"
                    value={dataAdd.kecamatan}
                  />
                )}
              />
              <FormHelperText style={{ color: '#f12147' }}>
                {errorValidasi.kecamatan}
              </FormHelperText>
              <NormalText
                textAlign="left"
                margin={'20px 0 5px 0'}
                fontSize={'14px'}
              >
                Kelurahan *
              </NormalText>
              <Autocomplete
                id="combo-box-demo"
                // options={top100Films}
                options={areas}
                getOptionLabel={(option) => option.area_name || ''}
                onChange={(event: any, value: any) => {
                  setDataAdd({
                    ...dataAdd,
                    kelurahan: value,
                  });
                }}
                sx={{ width: '100%' }}
                renderInput={(params) => (
                  <TextFieldMUI
                    {...params}
                    style={{ fontSize: 14 }}
                    size="small"
                    placeholder="Masukkan kelurahan anda"
                    value={dataAdd.kelurahan}
                  />
                )}
              />
              <FormHelperText style={{ color: '#f12147' }}>
                {errorValidasi.kelurahan}
              </FormHelperText>
              <NormalText
                textAlign="left"
                margin={'20px 0 5px 0'}
                fontSize={'14px'}
              >
                Kode Pos *
              </NormalText>
              <TextField
                type={'text'}
                value={dataAdd.kodePos}
                style={{ height: 45 }}
                onChange={(event: any) =>
                  setDataAdd({
                    ...dataAdd,
                    kodePos: event.target.value,
                  })
                }
                validationType={errorValidasi.kodePos ? 'ERROR' : ''}
                validationText={errorValidasi.kodePos}
                placeholder="Masukkan kode pos anda"
              />
              <Box textAlign={'start'} margin={'20px 0 0 0 '}>
                {/* <Button
                  variant={"danger"}
                  margin={"0 10px 0 0"}
                  onClick={() => navigate("/users/list")}
                >
                  CANCEL
                </Button> */}
                <ButtonMUI
                  variant="contained"
                  onClick={() => navigate('/')}
                  sx={{ width: 100, height: 40, marginRight: 3 }}
                  color="error"
                >
                  Batal
                </ButtonMUI>
                <ButtonMUI
                  variant="contained"
                  onClick={() => handleValidate()}
                  sx={{ width: 100, height: 40 }}
                >
                  Simpan
                </ButtonMUI>
                {/* <Button variant={"primary"} onClick={() => (handleValidate())}>
                  Save
                </Button> */}
              </Box>
            </form>
          </Box>
        </Stack>
      </UserGroups>
    </MerchantLayout>
  );
};

export default ProfilPage;
