import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  LoyaltyState,
  CreateLoyaltyTypes,
  EditLoyaltyTypes,
  DeleteLoyaltyProps,
} from './types';
import ApiService from 'src/services/ApiService';
import axios from 'axios';
import { getToken } from 'src/helper/generalFunction';

export interface ListProps {
  offset?: number;
  limit?: number;
  filter?: string;
}

export const getLoyaltySetting = createAsyncThunk(
  'loyaltySettings/get',
  async (props: ListProps) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/loyalty/point/list?limit=${
          props.limit ?? 0
        }&offset=${props.offset ?? 0}${
          props.filter ? '&filter=' + props.filter : ''
        }`,
        config,
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as LoyaltyState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as LoyaltyState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as LoyaltyState;
    }
  },
);

export const createBrand = createAsyncThunk(
  'loyaltySettings/post',
  async (props: CreateLoyaltyTypes) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL +
          '/merchant/loyalty/point/setting?subs_category_id=' +
          props.sub_category_id,
        props.data,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: getToken(),
          },
        },
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as LoyaltyState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as LoyaltyState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as LoyaltyState;
    }
  },
);

export const editLoyalty = createAsyncThunk(
  'loyaltySettings/put',
  async (props: EditLoyaltyTypes) => {
    try {
      const response = await axios.put(
        process.env.REACT_APP_API_URL +
          `/merchant/loyalty/point/${props.id}/setting?subs_category_id=` +
          props.sub_category_id,
        props.data,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: getToken(),
          },
        },
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as LoyaltyState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as LoyaltyState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as LoyaltyState;
    }
  },
);

export const deleteLoyalty = createAsyncThunk(
  'loyaltySettings/delete',
  async (props: DeleteLoyaltyProps) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_API_URL +
          `/merchant/loyalty/point/${props.id}/delete/setting`,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: getToken(),
          },
        },
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as LoyaltyState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as LoyaltyState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as LoyaltyState;
    }
  },
);
