import React, { useState, useEffect } from "react";
import { Stack } from "../../../components/styled/layout.styled";
import { useNavigate, useLocation } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import styles from "./styles";
import {
  Box,
  Button as ButtonMUI,
  FormHelperText,
  Switch,
  Grid,
} from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";
import TextField from "src/components/Inputs/TextField";
import { ListStoreData } from "src/redux/storelist";
import useRedux from "src/redux/useRedux";

interface RouteState {
  data: any;
  status: string;
  dataStore: any;
}

const AddBrandPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { data, status, dataStore } = location.state as RouteState;
  const [dataAdd, setDataAdd] = useState({
    name: data?.image_pupoint_setup ?? "",
    store: data?.store_id ?? "",
  });

  const {
    thunkDispatch,
    storeState: { Auth, StoreList },
  } = useRedux();

  const convertNamaStore = (id: number) => {
    let res =
      dataStore && dataStore.length !== 0
        ? dataStore.find((element: any) => element.id === id)
        : null;
    return res ? res.store_name : "";
  };

  return (
    <MerchantLayout>
      <Stack direction="column">
        <Box sx={styles.boxStyled}>
          <NormalText
            textAlign="start"
            fontWeight={"bold"}
            fontSize={"18px"}
            margin={"0 0 20px 0"}
          >
            Detail Pickup Point
          </NormalText>
          <form>
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Gambar Pup Setup *
            </NormalText>
            <img
              src={dataAdd.name}
              style={{ height: '20%', width: '20%', }}
              className="brand-image"
              alt="image name"
              loading="lazy"
            />
            <TextField
              label="Toko"
              isRequired
              id="outlined-read-only-input"
              readonly
              value={convertNamaStore(dataAdd.store)}
            />
            <Box textAlign={"start"} margin={"20px 0 0 0 "}>
              <ButtonMUI
                variant="contained"
                onClick={() => navigate("/stores/pickup-point-setup/list")}
                sx={{ width: 100, height: 40, marginRight: 3 }}
                color="error"
              >
                Kembali
              </ButtonMUI>
            </Box>
          </form>
        </Box>
      </Stack>
    </MerchantLayout>
  );
};

export default AddBrandPage;
