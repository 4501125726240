import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import {
  IconButton,
  Grid,
  Stack,
  Card,
  Button,
  InputBase,
  TextField as TextFieldMUI,
  Box,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import { Delete, Edit, Search } from "@mui/icons-material";
import {
  getVouchers,
  ListVoucherResponseProps,
  deleteVoucher,
  assignVoucherToStore,
  detailAssignVoucherToStore,
} from "src/redux/voucher";
import useRedux from "src/redux/useRedux";
import { NormalText } from "src/components/styled/text.styled";
import ConfirmationModal from "src/components/Modal/confirmation";
import checkPermission from "src/helper/checkPermission";
import SettingsIcon from "@mui/icons-material/Settings";

import { styled, alpha } from "@mui/material/styles";
import ListVoucher from "./listVoucher";
import { getAllStore } from "src/redux/storelist";
import { toast } from "react-toastify";
import Modal from "src/components/Modal/modal";
import TextField from "src/components/Inputs/TextField";

interface PayloadData {
  voucher_id: number;
  voucher_name?: string;
  store_id: StoreIdType[];
}

interface StoreIdType {
  id: number;
  store_name: string;
}

const VouchersPage = () => {
  const navigate = useNavigate();
  const { thunkDispatch } = useRedux();
  const [vouchers, setVouchers] = useState({} as ListVoucherResponseProps);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(5);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [idVoucher, setIdVoucher] = useState(0);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [payloadAssign, setPayloadAssign] = useState<PayloadData>({
    voucher_id: 0,
    voucher_name: "",
    store_id: [] || null,
  });
  const [isEditAssign, setIsEditAssign] = useState(false);
  const [stores, setStores] = useState<StoreIdType[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [errorValidasi, setErrorValidasi] = useState({
    store: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const columns = [
    {
      name: "Nama Voucher",
      selector: (row: any) => row.name,
      sortable: true,
      center: true,
    },
    {
      name: "Kode",
      selector: (row: any) => row.code,
      sortable: true,
      center: true,
    },
    {
      name: "Tipe Voucher",
      selector: (row: any) => row.voucherType,
      sortable: true,
      center: true,
    },
    {
      name: "Masa Berlaku (hari)",
      selector: (row: any) => row.expiryDay,
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      center: true,
      sortable: true,
      cell: (row: any) => {
        return (
          <NormalText>
            {row.available ? "Tersedia" : "Tidak Tersedia"}
          </NormalText>
        );
      },
    },
    {
      name: "Action",
      center: true,
      cell: (row: any) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton
              aria-label="pengaturan toko"
              title="pengaturan toko"
              color="primary"
              onClick={() => {
                setOpenModal(true);

                fetchAssignStores({
                  voucher_id: row.id,
                  voucher_name: row.name,
                });
              }}
            >
              <SettingsIcon />
            </IconButton>

            {checkPermission("Voucher", "is_edit") && (
              <IconButton
                aria-label="edit"
                title="edit"
                color="primary"
                onClick={() => {
                  navigate(`/products/vouchers/${row.id}/update`);
                }}
              >
                <Edit />
              </IconButton>
            )}
            <IconButton
              aria-label="search"
              title="detail"
              color="warning"
              onClick={() => {
                navigate(`/products/vouchers/${row.id}/view`);
              }}
            >
              <Search />
            </IconButton>
            {checkPermission("Voucher", "is_delete") && (
              <IconButton
                aria-label="delete"
                title="hapus"
                color="error"
                onClick={() => handleDeleteVoucher(row.id)}
              >
                <Delete />
              </IconButton>
            )}
          </Grid>
        );
      },
    },
  ];

  const handleDeleteVoucher = (id: any) => {
    setIdVoucher(id);
    setOpenConfirmation(true);
  };

  const getAll = () => {
    setLoading(true);
    let dataObject = {
      offset: offset - 1,
      limit: limit,
      filter: filter,
    };
    thunkDispatch(getVouchers(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setVouchers(res.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    thunkDispatch(deleteVoucher(idVoucher))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          toast.success("Berhasil menghapus voucher");
          setOpenConfirmation(!openConfirmation);
          getAll();
          setLoading(false);
        } else {
          toast.error("Gagal menghapus voucher!");
          setOpenConfirmation(!openConfirmation);
          setLoading(false);
        }
      })
      .catch((err) => {
        setOpenConfirmation(!openConfirmation);
        setLoading(false);

        console.error(err);
      });
  };

  const fetchStores = () => {
    setLoading(true);
    thunkDispatch(getAllStore({ filter: "", limit: 0, offset: 0 }))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          let data = res.data.data.map((x) => {
            return {
              id: x.id,
              store_name: x.store_name,
            };
          });

          setStores(data);
        }
      });
    setLoading(false);
  };

  const fetchAssignStores = ({ voucher_id, voucher_name }: any) => {
    thunkDispatch(detailAssignVoucherToStore(voucher_id))
      .unwrap()
      .then((res) => {
        let data: any = res?.data.data;

        if (res && res.status === "success") {
          setPayloadAssign({
            ...payloadAssign,
            voucher_name: voucher_name,
            voucher_id: data?.voucher_id ? data.voucher_id : 0,
            store_id: data?.store_id ? data.store_id : [],
          });
          if (data && data.store_id.length > 0) {
            setIsEditAssign(true);
          } else {
            setIsEditAssign(false);
          }
        }
      });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setPayloadAssign({ voucher_id: 0, store_id: [] });
  };

  const handleAssignStore = () => {
    setIsSubmitted(true);
    if (payloadAssign.store_id && payloadAssign.store_id.length === 0) {
      setErrorValidasi({ ...errorValidasi, store: "Toko wajib diisi." });
      setIsSubmitted(false);
      return;
    } else {
      setErrorValidasi({ ...errorValidasi, store: "" });
    }

    let payload = {
      voucher_id: payloadAssign.voucher_id,
      store_id: payloadAssign.store_id.map((x) => {
        return x.id;
      }),
      is_edit: isEditAssign,
    };

    thunkDispatch(assignVoucherToStore(payload))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          toast.success("Assign voucher berhasil");
          handleCloseModal();
          setIsSubmitted(false);
          setIsEditAssign(false);
        } else {
          toast.error("Assign voucher gagal!");
          setIsSubmitted(false);
        }
      });
  };

  useEffect(() => {
    getAll();
    fetchStores();
  }, [filter, limit, offset]);

  return (
    <MerchantLayout>
      <ConfirmationModal
        title="Konfirmasi"
        titleStyle={{ fontWeight: "bold" }}
        description="Apakah anda yakin ingin menghapus voucher ini?"
        open={openConfirmation}
        handleConfirm={() => handleDelete()}
        handleClose={() => setOpenConfirmation(!openConfirmation)}
      ></ConfirmationModal>
      <Modal handleClose={() => handleCloseModal()} open={openModal}>
        <Box sx={{ width: "800px" }}>
          <NormalText style={{ fontWeight: "bold", fontSize: "20px" }}>
            Assign Toko
          </NormalText>
          <form>
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Nama Voucher
            </NormalText>
            <TextField value={payloadAssign.voucher_name} readonly></TextField>

            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Pilih Toko *
            </NormalText>

            <>
              <Autocomplete
                id="combo-box-demo"
                multiple={true}
                options={stores}
                getOptionLabel={(option) => option.store_name || ""}
                value={payloadAssign.store_id}
                onChange={(event: any, value: any) => {
                  setPayloadAssign({
                    ...payloadAssign,
                    store_id: value,
                  });
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextFieldMUI
                    {...params}
                    style={{ fontSize: 14 }}
                    size="small"
                    placeholder="Silahkan pilih toko anda"
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
              <FormHelperText style={{ color: "#f12147" }}>
                {errorValidasi.store}
              </FormHelperText>
            </>

            <Box textAlign={"start"} margin={"20px 0 0 0 "}>
              <Button
                variant="contained"
                onClick={() => handleCloseModal()}
                sx={{ width: 100, height: 40, marginRight: 3 }}
                color="error"
              >
                Batal
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleAssignStore();
                }}
                sx={{ width: 100, height: 40 }}
                disabled={isSubmitted}
              >
                Simpan
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
      <Card sx={{ padding: "2%" }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mb-2"
        >
          <NormalText fontWeight="bold" fontSize="18px">
            Voucher
          </NormalText>
          <Button
            sx={{
              textTransform: "none",
              background: "#007bff",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#007bff",
                borderColor: "#007bff",
                boxShadow: "none",
              },
            }}
            onClick={() => {
              navigate("/products/vouchers/create");
            }}
            disabled={checkPermission("Voucher", "is_add") ? false : true}
          >
            Tambah Voucher
          </Button>
        </Stack>

        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          margin={"0 0 2% 0"}
        >
          <SearchBox>
            <StyledInputBase
              placeholder={"Cari voucher"}
              inputProps={{ "aria-label": "search" }}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </SearchBox>
        </Stack>

        <ListVoucher
          columns={columns}
          progressPending={loading}
          totalRow={
            vouchers && vouchers.meta && vouchers.meta.total
              ? vouchers.meta.total
              : 0
          }
          records={vouchers.data}
          paginationPerPage={limit}
          onChangePage={(page) => setOffset(page)}
          onChangeRowsPerPage={(currentRowsPerPage) =>
            setLimit(currentRowsPerPage)
          }
        />
      </Card>
    </MerchantLayout>
  );
};

export default VouchersPage;

const SearchBox = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "5px",
  border: "1px solid #e6e6e6",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "25%",
  marginTop: "10px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "10px",
    maxWidth: "unset",
    width: "100%",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    fontSize: "14px",
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
      width: "100%",
    },
  },
}));
