import { createAsyncThunk } from "@reduxjs/toolkit";
import { BannerState, CreateBannerTypes } from "./types";
import ApiService from "src/services/ApiService";
import axios from "axios";
import { getToken } from "src/helper/generalFunction";

export interface BannerListProps {
  offset?: number;
  limit?: number;
  filter?: string;
}

export const getBanner = createAsyncThunk(
  "banner/get",
  async (props: BannerListProps) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/banners/list?limit=${
          props.limit ?? 0
        }&offset=${props.offset ?? 0}${
          props.filter ? "&filter=" + props.filter : ""
        }`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as BannerState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as BannerState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as BannerState;
    }
  }
);

export const createBanner = createAsyncThunk(
  "banner/post",
  async (props: CreateBannerTypes) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/merchant/banners",
        props.data,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: props.access_token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as BannerState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as BannerState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as BannerState;
    }
  }
);

export const updateBanner = createAsyncThunk(
  "banner/update",
  async (props: CreateBannerTypes) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/merchant/banners/${props.id}/update`,
        props.data,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: props.access_token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as BannerState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as BannerState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as BannerState;
    }
  }
);

export interface DeleteBannerProps {
  banner_id: number;
  access_token: string;
}

export const removeBanner = createAsyncThunk(
  "banner/delete",
  async (props: DeleteBannerProps) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/merchant/banners/${props.banner_id}/delete`,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: props.access_token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as BannerState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as BannerState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as BannerState;
    }
  }
);

export interface UpdateStatusBannerProps {
  banner_id: number;
  data: any;
  access_token: string;
}

export const updateStatusBanner = createAsyncThunk(
  "banner/updatestatus",
  async (props: UpdateStatusBannerProps) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/merchant/banners/${props.banner_id}/status`,
        props.data,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: props.access_token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as BannerState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as BannerState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as BannerState;
    }
  }
);
