import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useRedux from "src/redux/useRedux";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import { NormalText } from "src/components/styled/text.styled";
import {
  IconButton,
  Grid,
  Card,
  Box,
  Stack,
  InputBase,
  Dialog,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { Button } from "src/components/styled/button.styled";
import {
  getAllStore,
  getAllStoreMultiMerchant,
  showDetailGroupStore,
  addGroupStoreMember,
  ListStoreDataShort,
  removeGroupStoreMember,
} from "src/redux/storelist";
import ViewMember from "./viewMember";
import UpdateMember from "./updateMember";
import { Delete } from "@mui/icons-material";
import QRCode from "qrcode.react";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import Dropdown, { OptionProps } from "src/components/Inputs/Dropdown";
import ConfirmationModal from "src/components/Modal/confirmation";
import { toast } from "react-toastify";

const SearchBox = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "5px",
  border: "1px solid #e6e6e6",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "25%",
  marginTop: "10px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "10px",
    maxWidth: "unset",
    width: "100%",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    fontSize: "14px",
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
      width: "100%",
    },
  },
}));

const GroupStoreDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    thunkDispatch,
    storeState: { Auth },
  } = useRedux();
  const [filter, setFilter] = useState("");
  const [stores, setStores] = useState<ListStoreDataShort[]>();
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [payload, setPayload] = useState({
    id: Number(id),
    group_code: "",
    group_name: "",
    group_logo: "",
    address: "",
    address_type: "false",
    phone: "",
    province_id: 0,
    city_id: 0,
    suburb_id: 0,
    area_id: 0,
    url_gmap: "",
    store: [],
  });

  const [payloadAddMember, setPayloadAddMember] = useState({
    qr_group_id: Number(id),
    store_id: 0,
  });
  const [editMember, setEditMember] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [openQR, setOpenQR] = useState(false);
  const [openAddMember, setOpenAddMember] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns = [
    {
      name: "Nama Toko",
      selector: (row: any) => row.store_name,
      sortable: true,
    },
    {
      name: "Alamat",
      selector: (row: any) => row.address,
      sortable: true,
      width: "15vw",
    },
    {
      name: "No. Telepon",
      selector: (row: any) => row.phone,
      sortable: true,
    },
  ];

  const columnsUpdate = [
    {
      name: "Nama Toko",
      selector: (row: any) => row.store_name,
      sortable: true,
    },
    {
      name: "Alamat",
      selector: (row: any) => row.address,
      sortable: true,
      width: "15vw",
    },
    {
      name: "No. Telepon",
      selector: (row: any) => row.phone,
      sortable: true,
    },
    {
      name: "Aksi",
      center: true,
      cell: (item: any) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton
              aria-label="delete"
              color="error"
              onClick={() => {
                setDeleteId(item.id);
                setDeleteModal(true);
              }}
            >
              <Delete />
            </IconButton>
          </Grid>
        );
      },
    },
  ];

  const fetchDetail = () => {
    thunkDispatch(showDetailGroupStore(Number(id)))
      .unwrap()
      .then((res) => {
        let detail: any = { ...res?.data.data };
        detail.address_type = detail.address_type === true ? "true" : "false";
        detail.province_id =
          detail.address_type === "true" ? detail.province.id : 0;
        detail.city_id = detail.address_type === "true" ? detail.city.id : 0;
        detail.suburb_id =
          detail.address_type === "true" ? detail.suburb.id : 0;
        detail.area_id = detail.address_type === "true" ? detail.area.id : 0;
        detail.phone = detail.address_type === "true" ? detail.phone : "";
        detail.address = detail.address_type === "true" ? detail.address : "";
        setPayload(detail);
        detail.store ? setMembers(detail.store) : setMembers([]);
      });
  };

  const downloadQR = () => {
    var canvas = document.querySelector(
      "#qrcode > canvas"
    ) as HTMLCanvasElement;
    console.log(canvas);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `QR Group - ${
      payload && payload.group_name ? payload.group_name : "Code"
    }.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const saveMember = () => {
    let bodyData: any = { qr_group_id: Number(id), store_id: [] };
    bodyData = {
      ...bodyData,
      store_id: [payloadAddMember.store_id],
    };

    thunkDispatch(addGroupStoreMember(bodyData))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          toast.success("Member berhasil ditambahkan");
          setOpenAddMember(false);
          fetchDetail();
        } else {
          toast.error("Member gagal ditambahkan, coba lagi!");
        }
        //console.log(res);
      });
  };

  const deleteMember = () => {
    thunkDispatch(removeGroupStoreMember(deleteId))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          toast.success("Member berhasil dihapus");
          setDeleteModal(false);
          fetchDetail();
        } else {
          toast.error("Member gagal dihapus, coba lagi!");
        }
      });
  };

  const URL_APP = {staging:'https://pagii-ordering-api.stg8.smtapps.net',production:'https://qr-order.sandbox.co.id'}

  useEffect(() => {
    fetchDetail();
  }, []);

  useEffect(() => {
    if (Auth.data.user_type === "user_merchant") {
      setStores(Auth?.data?.profile_data?.store_access?.data_store);
    } else {
      if (Auth?.data?.profile_data?.global_store_qr_group === true) {
        thunkDispatch(getAllStoreMultiMerchant({ offset: 0, limit: 0 }))
          .unwrap()
          .then((res) => {
            if (res && res.status === "success") {
              setStores(res.data.data);
            }
          });
      }

      if (Auth?.data?.profile_data?.global_store_qr_group === false) {
        thunkDispatch(getAllStore({ offset: 0, limit: 0 }))
          .unwrap()
          .then((res) => {
            if (res && res.status === "success") {
              setStores(res.data.data);
            }
          });
      }
    }
  }, []);

  return (
    <MerchantLayout>
      <ConfirmationModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleConfirm={() => deleteMember()}
        description={"Apakah anda yakin ingin menghapus ini?"}
      />

      <Dialog open={openQR} onClose={() => setOpenQR(false)}>
        <Stack
          height={"100%"}
          width={"100%"}
          direction={"column"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          position={"relative"}
        >
          <IconButton
            aria-label="close"
            sx={{ color: "#000000", position: "absolute", right: 0 }}
            onClick={() => setOpenQR(false)}
          >
            <CloseIcon />
          </IconButton>
          <Stack
            direction={"column"}
            alignItems={"center"}
            width={"100%"}
            padding={3}
          >
            <NormalText fontWeight="700" fontSize="14px">
              QR Code Group
            </NormalText>
            <NormalText fontWeight="700" fontSize="20px">
              {payload.group_name}
            </NormalText>
          </Stack>

          <Stack direction={"row"} justifyContent={"center"}>
            <div id="qrcode">
              <QRCode
                value={
                  URL_APP.staging +
                  "/qr-group/" +
                  payload.group_code
                }
                size={300}
                level={"H"}
                renderAs={"canvas"}
                includeMargin={true}
              />
            </div>
          </Stack>

          <Stack
            direction={"row"}
            paddingX={2}
            paddingY={2}
            justifyContent={"center"}
          >
            <Button variant="primary" onClick={downloadQR} width="100%">
              <Stack
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <DownloadIcon className="mr-2" />
                <NormalText fontSize="16px" fontWeight="700">
                  Download QR Group
                </NormalText>
              </Stack>
            </Button>
          </Stack>
        </Stack>
      </Dialog>

      <Dialog open={openAddMember} onClose={() => setOpenAddMember(false)}>
        <Stack
          height={"100%"}
          width={"100%"}
          direction={"column"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          position={"relative"}
        >
          <IconButton
            aria-label="close"
            sx={{ color: "#000000", position: "absolute", right: 0 }}
            onClick={() => setOpenAddMember(false)}
          >
            <CloseIcon />
          </IconButton>
          <Stack
            direction={"column"}
            alignItems={"center"}
            width={"100%"}
            padding={3}
          >
            <NormalText fontWeight="700" fontSize="14px">
              Tambah Anggota
            </NormalText>
          </Stack>

          <Stack
            direction={"column"}
            justifyContent={"center"}
            width={"100%"}
            paddingX={2}
          >
            <Dropdown
              value={payloadAddMember.store_id}
              onChange={(e: any) => {
                setPayloadAddMember({ ...payloadAddMember, store_id: e });
              }}
              label="Pilih Toko"
              isRequired
              validationText="Toko wajib diisi"
              // validationType={
              //   submitAssign && !payloadProductStore.store_id ? "ERROR" : ""
              // }
              options={
                stores
                  ? stores.map((item: any) => {
                      return {
                        label: item.store_name,
                        value:
                          Auth.data.user_type === "user_merchant"
                            ? item.store_id
                            : item.id,
                      } as OptionProps;
                    })
                  : []
              }
            />
          </Stack>

          <Stack
            direction={"row"}
            paddingX={2}
            paddingY={2}
            justifyContent={"flex-end"}
          >
            <Button onClick={() => setOpenAddMember(false)}>Batal</Button>
            <Button variant="primary" className="ml-2" onClick={saveMember}>
              Simpan
            </Button>
          </Stack>
        </Stack>
      </Dialog>

      <Card sx={{ padding: "2%" }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            position: "relative",
            margin: "0 0 2% 0",
            padding: "0 0 1% 0",
          }}
        >
          <NormalText fontWeight="bold" fontSize="20px">
            {payload.group_name}
          </NormalText>
          <Stack direction={"row"}>
            {/* <Button
              variant="primary"
              className="mr-3"
              onClick={() => setOpenQR(true)}
            >
              QR Group
            </Button> */}
            {editMember === false ? (
              <Button variant="primary" onClick={() => setEditMember(true)}>
                Atur Anggota
              </Button>
            ) : (
              <Button variant="primary" onClick={() => setOpenAddMember(true)}>
                Tambah Anggota
              </Button>
            )}
          </Stack>
        </Stack>

        {/* <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          margin={"0 0 2% 0"}
        >
          <SearchBox>
            <StyledInputBase
              placeholder="Cari toko..."
              inputProps={{ "aria-label": "search" }}
              value={filter}
              onChange={(e: any) => setFilter(e.target.value)}
            />
          </SearchBox>
        </Stack> */}

        <Stack>
          {editMember ? (
            <UpdateMember
              records={members}
              columns={columnsUpdate}
              // totalRow={
              //   members && members.meta && members.meta.total
              //     ? members.meta.total
              //     : 0
              // }
              paginationPerPage={rowsPerPage}
              onChangePage={(page) => setPage(page)}
              onChangeRowsPerPage={(currentRowsPerPage) =>
                setRowsPerPage(currentRowsPerPage)
              }
            ></UpdateMember>
          ) : (
            <ViewMember
              records={members}
              columns={columns}
              // totalRow={
              //   members && members.meta && members.meta.total
              //     ? members.meta.total
              //     : 0
              // }
              paginationPerPage={rowsPerPage}
              onChangePage={(page) => setPage(page)}
              onChangeRowsPerPage={(currentRowsPerPage) =>
                setRowsPerPage(currentRowsPerPage)
              }
            ></ViewMember>
          )}
        </Stack>

        <Stack direction={"row"} justifyContent={"flex-start"}>
          {editMember === true ? (
            <Button variant="primary" onClick={() => setEditMember(false)}>
              Kembali
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={() => navigate("/stores/store/list")}
            >
              Kembali
            </Button>
          )}
        </Stack>
      </Card>
    </MerchantLayout>
  );
};

export default GroupStoreDetail;
