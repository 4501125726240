import React, { useState } from "react";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import { useNavigate } from "react-router-dom";
import useRedux from "src/redux/useRedux";
import Card from "src/components/Cards/card";
import { NormalText } from "src/components/styled/text.styled";
import { Button } from "src/components/styled/button.styled";
import TextField from "src/components/Inputs/TextField";
import Dropdown, { OptionProps } from "src/components/Inputs/Dropdown";
import {
  Box,
  Button as ButtonMUI,
  FormHelperText,
  Grid,
  Stack,
  CircularProgress,
  Typography,
  Switch,
} from "@mui/material";
import { ProvincesData, getAllProvinces } from "src/redux/regions/province";
import { CityData, getAllCity } from "src/redux/regions/city";
import { getAllSubUrban, SubUrbanData } from "src/redux/regions/suburban";
import { getAllArea, AreaData } from "src/redux/regions/area";
import { updateAssign, detailStoreAccess } from "src/redux/storeAccess";
import { createNewStore } from "src/redux/storelist";
import { ToastContainer, toast } from "react-toastify";
import { uploadImage } from "src/helper/uploadFile";
import styled from "styled-components";

const UploadLabel = styled.label`
  width: 9%;
  height: 40px;
  background-color: white;
  color: black;
  padding: 10px;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 2px;
  border: 1px solid #1985d2;
  text-align: center;
  &:hover {
    background-color: #1985d2;
    color: #fff;
  }
`;

const StoreForm = () => {
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: { AppOption, Auth },
  } = useRedux();
  const [provinces, setProvinces] = React.useState<ProvincesData[]>();
  const [cities, setCities] = React.useState<CityData[]>();
  const [listSubUrban, setListSubUrban] = React.useState<SubUrbanData[]>();
  const [areas, setAreas] = React.useState<AreaData[]>();
  const [invalidUrl, setInvalidUrl] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [geolocation, setGeolocation] = React.useState({
    lat: "",
    long: "",
  });
  const [payload, setPayload] = React.useState({
    store_name: "",
    store_phone: "",
    address: "",
    province_id: 0,
    city_id: 0,
    suburb_id: 0,
    area_id: 0,
    google_maps_url: "",
    store_logo: "",
    store_banner: "",
    open_at: "",
    closed_at: "",
    status_open_store: false,
    is_publish: false
  });

  const [errorValidasi, setErrorValidasi] = React.useState({
    logoToko: "",
  });
  const [errorBanner, setErrorBanner] = React.useState({
    bannerToko: "",
  });
  const [loadingImg, setLoadingImg] = React.useState(false);
  const [subAccountData, setSubAccountData] = React.useState<any>();
  const [storeStatus, setStore] = useState(false);
  const [storeStatusPublish, setStorePublish] = useState(false);

  const fetchCities = (prov_id: number) => {
    thunkDispatch(getAllCity(prov_id))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          console.log("C", res.data.data);
          setCities(res.data.data.cities);
        }
      });
  };

  const fetchSuburban = (city_id: number) => {
    thunkDispatch(getAllSubUrban(city_id))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setListSubUrban(res.data.data.suburbs);
        }
      });
  };

  const fetchArea = (suburb_id: number) => {
    thunkDispatch(getAllArea(suburb_id))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setAreas(res.data.data.areas);
        }
      });
  };

  const validMapsUrl = (url: string) => {
    var regex = new RegExp("@(.*),(.*),");
    return url.match(regex);
  };

  const validPhone = () => {
    if (
      payload.store_phone &&
      String(payload.store_phone)[0] === "0" &&
      payload.store_phone.length >= 7
    ) {
      return true;
    } else {
      return false;
    }
  };

  const v2getLatLong = (url: string) => {
    var longlat = /\/\@(.*),(.*),/.exec(url);
    if (longlat) {
      console.log("#2", longlat);
    }
  };

  const getGolocation = (url: string) => {
    var lat_long_match = validMapsUrl(url);
    console.log("Maps URL", url);
    console.log("#1", lat_long_match);
    v2getLatLong(url);
    if (lat_long_match) {
      var lat = lat_long_match[1];
      var long = lat_long_match[2];
      setGeolocation({
        lat: lat,
        long: long,
      });
      setInvalidUrl(false);
    } else {
      setInvalidUrl(true);
    }
  };

  React.useEffect(() => {
    thunkDispatch(getAllProvinces())
      .unwrap()
      .then((res: any) => {
        if (res && res.status === "success") {
          setProvinces(res.data.data);
        }
      });
  }, []);

  const handleImage = (e: any, data: string) => {
    if (e.target.files && e.target.files[0]) {
      let imageName = e.target.files[0];
      let fileSize = imageName.size / (1024 * 1024);
      var img = new Image();
      img.src = window.URL.createObjectURL(imageName);
      img.onload = function () {
        let width = img.naturalWidth;
        let height = img.naturalHeight;

        let ValidHeight = 300;
        let ValidWidth = 300;

        if (width > ValidWidth || height > ValidHeight || fileSize > 2) {
          setErrorValidasi({
            ...errorValidasi,
            [data]: `Resolusi gambar maks. ${ValidWidth}x${ValidHeight} & ukuran maks. 2MB`,
          });
        } else {
          handleUploadFile(imageName, data).then((res: any) => {
            console.log("OK");
          });
          setErrorValidasi({
            ...errorValidasi,
            [data]: "",
          });
        }
      };
    }
  };

  const handleBanner = (e: any, data: string) => {
    if (e.target.files && e.target.files[0]) {
      let imageName = e.target.files[0];
      let fileSize = imageName.size / (1024 * 1024);
      var img = new Image();
      img.src = window.URL.createObjectURL(imageName);
      img.onload = function () {
        let width = img.naturalWidth;
        let height = img.naturalHeight;

        let ValidHeight = 1920;
        let ValidWidth = 1080;

        if (width > ValidWidth || height > ValidHeight || fileSize > 5) {
          setErrorBanner({
            ...errorBanner,
            [data]: `Resolusi gambar maks. ${ValidWidth}x${ValidHeight} & ukuran maks. 5MB`,
          });
        } else {
          handleUploadBanner(imageName, data).then((res: any) => {
            console.log("OK");
          });
          setErrorBanner({
            ...errorBanner,
            [data]: "",
          });
        }
      };
    }
  };

  const handleUploadFile = async (file: any, typeUpload: string) => {
    let nameUpload = typeUpload;
    setLoadingImg(true);
    let formdata = new FormData();
    formdata.append("image_count", "1");
    formdata.append("image-1", file);
    await uploadImage(formdata, "setting")
      .then((res) => {
        if (res.status === 200) {
          setLoadingImg(false);
          setPayload({
            ...payload,
            store_logo: res.data.data["image-1"],
          });
        }
        setLoadingImg(false);
      })
      .catch(() => {
        setLoadingImg(false);
        setErrorValidasi({
          ...errorValidasi,
          [nameUpload]: "Gagal mengupload image",
        });
      });
  };

  const handleUploadBanner = async (file: any, typeUpload: string) => {
    let nameUpload = typeUpload;
    setLoadingImg(true);
    let formdata = new FormData();
    formdata.append("image_count", "1");
    formdata.append("image-1", file);
    await uploadImage(formdata, "setting")
      .then((res) => {
        if (res.status === 200) {
          setLoadingImg(false);
          setPayload({
            ...payload,
            store_banner: res.data.data["image-1"],
          });
        }
        setLoadingImg(false);
      })
      .catch(() => {
        setLoadingImg(false);
        setErrorBanner({
          ...errorBanner,
          [nameUpload]: "Gagal mengupload image",
        });
      });
  };

  const handleSubmit = () => {
    setIsSubmit(true);
    
    if (
      payload.store_name &&
      payload.address &&
      payload.province_id != 0 &&
      payload.city_id != 0 &&
      payload.suburb_id != 0 &&
      payload.area_id != 0 &&
      payload.store_name.length <= 32 &&
      validPhone() &&
      validMapsUrl(payload.google_maps_url)
    ) {
      thunkDispatch(createNewStore(payload))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            if (Auth?.data?.user_type === "user_merchant") {
              assignStoreToAccount(res?.data?.data?.id);
            } else {
              toast.success("Berhasil menambahkan toko");
              setTimeout(() => {
                navigate("/stores/store/list");
              }, 1500);
            }
          } else {
            toast.error("Terjadi kesalahan, silahkan coba lagi");

            if(res && res.error && res.error.response && res.error.response.data && res.error.response.data.message){
              toast.error(res.error.response.data.message)
            }
            
          }
        })
        .catch((err) => {
          toast.error("Terjadi kesalahan, silahkan coba lagi");
          if (process.env.NODE_ENV !== "production") {
            console.log("Got Error", err);
          }
        });
    } else {
      toast.warning("Silahkan cek kembali inputan anda!");
    }
  };

  const getDataSubAccount = () => {
    thunkDispatch(
      detailStoreAccess(Auth.data.profile_data.store_access?.user_merchant_id)
    )
      .unwrap()
      .then((res: any) => {
        let subaccountData: any = res?.data?.data;
        subaccountData?.data_store
          ?.filter((store: any) => store?.is_active === true)
          ?.map((item: any) => {
            item["id"] = item.store_id;
            delete item.store_id;
            delete item.store_name;
            delete item.is_active;
          });

        setSubAccountData(subaccountData);
      });
  };

  const assignStoreToAccount = (store_id: any) => {
    // setSubAccountData((prevState: any) => ({
    //   ...prevState,
    //   data_store: [...prevState?.data_store, { id: 1 }],
    // }));
    let payload = subAccountData;
    payload?.data_store.push({ id: store_id });
    let dataUpdate = {
      id_assign: payload?.id,
      data: {
        user_merchant_id:
          Auth?.data?.profile_data?.store_access?.user_merchant_id ?? "",
        store_id: payload?.data_store,
      },
    };
    thunkDispatch(updateAssign(dataUpdate))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          toast.success("Berhasil menambahkan toko");
          toast.success("Toko berhasil di assign");
          setTimeout(() => {
            navigate("/stores/store/list");
          }, 1500);
        } else {
          toast.error(res?.status);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    if (Auth && Auth.data && Auth.data.user_type === "user_merchant") {
      getDataSubAccount();
    }
  }, []);

  return (
    <MerchantLayout>
      <ToastContainer />
      <Card>
        <NormalText fontWeight="bold" fontSize="20px" margin="0 0 20px 0">
          Tambah Toko Baru
        </NormalText>

        <TextField
          label={"Nama Toko"}
          isRequired
          validationText={
            isSubmit && !payload.store_name
              ? "Nama toko wajib diisi"
              : isSubmit && payload.store_name.length > 32
              ? "Maksimal karakter untuk nama toko 32 karakter"
              : ""
          }
          validationType={
            (isSubmit && !payload.store_name) ||
            (isSubmit && payload.store_name.length > 32)
              ? "ERROR"
              : ""
          }
          value={payload.store_name}
          onChange={(e: any) => {
            setPayload({ ...payload, store_name: e.target.value });
          }}
        ></TextField>
        <TextField
          label={"Alamat"}
          isRequired
          validationText="Alamat wajib diisi"
          validationType={isSubmit && !payload.address ? "ERROR" : ""}
          value={payload.address}
          onChange={(e: any) => {
            setPayload({ ...payload, address: e.target.value });
          }}
        ></TextField>
        <Box width={"300px"}>
          <Dropdown
            label={"Provinsi"}
            validationType={
              isSubmit && payload.province_id === 0 ? "ERROR" : ""
            }
            validationText={"Provinsi wajib diisi"}
            isRequired
            options={
              provinces
                ? provinces.map((item) => {
                    return {
                      label: item.province_name,
                      value: item.id,
                    } as OptionProps;
                  })
                : []
            }
            value={payload.province_id}
            onChange={(value: number) => {
              fetchCities(value);
              setListSubUrban([]);
              setAreas([]);
              setPayload({
                ...payload,
                province_id: value,
                city_id: 0,
                suburb_id: 0,
                area_id: 0,
              });
            }}
          />
          <Dropdown
            label={"Kota"}
            isRequired
            validationType={isSubmit && payload.city_id === 0 ? "ERROR" : ""}
            validationText={"Kota wajib diisi"}
            options={
              cities
                ? cities.map((item) => {
                    return {
                      label: item.city_name,
                      value: item.id,
                    } as OptionProps;
                  })
                : []
            }
            value={payload.city_id}
            onChange={(value: number) => {
              fetchSuburban(value);
              setAreas([]);
              setPayload({
                ...payload,
                city_id: value,
                suburb_id: 0,
                area_id: 0,
              });
            }}
          />
          <Dropdown
            label={"Kecamatan"}
            isRequired
            validationType={isSubmit && payload.suburb_id === 0 ? "ERROR" : ""}
            validationText={"Kecamatan wajib diisi"}
            options={
              listSubUrban
                ? listSubUrban.map((item) => {
                    return {
                      label: item.suburbName,
                      value: item.id,
                    } as OptionProps;
                  })
                : []
            }
            value={payload.suburb_id}
            onChange={(value: any) => {
              setPayload({ ...payload, suburb_id: value });
              fetchArea(value);
            }}
          />

          <Dropdown
            label={"Kelurahan"}
            isRequired
            validationType={isSubmit && payload.area_id === 0 ? "ERROR" : ""}
            validationText={"Kelurahan wajib diisi"}
            options={
              areas
                ? areas.map((item) => {
                    return {
                      label: item.area_name,
                      value: item.id,
                    } as OptionProps;
                  })
                : []
            }
            value={payload.area_id}
            onChange={(value: number) => {
              setPayload({ ...payload, area_id: value });
            }}
          />
          <TextField
            label={"Nomor Telepon"}
            isRequired
            type={"number"}
            variant={"tel"}
            validationText={
              isSubmit && !payload.store_phone
                ? "Nomor telepon wajib diisi"
                : (isSubmit &&
                    payload.store_phone &&
                    payload.store_phone.length < 7) ||
                  (isSubmit &&
                    payload.store_phone &&
                    String(payload.store_phone)[0] !== "0")
                ? "Panjang nomor telepon min. 7 digit dan diawali dengan angka nol"
                : ""
            }
            validationType={
              (isSubmit && !payload.store_phone) ||
              (isSubmit && payload.store_phone.length < 7) ||
              (isSubmit && String(payload.store_phone)[0] !== "0")
                ? "ERROR"
                : ""
            }
            value={payload.store_phone}
            onChange={(e: any) => {
              let v = e.target.value;
              if (e.target.value < 0) {
                v = 0;
              }
              setPayload({ ...payload, store_phone: v });
            }}
          ></TextField>
        </Box>
        <TextField
          label={"URL Google Maps"}
          isRequired
          validationText={
            invalidUrl
              ? "Url yang and masukan tidak valid"
              : isSubmit && !payload.google_maps_url
              ? "URL Google Maps wajib diisi"
              : ""
          }
          validationType={
            invalidUrl || (isSubmit && !payload.google_maps_url) ? "ERROR" : ""
          }
          value={payload.google_maps_url}
          onChange={(e: any) => {
            setPayload({ ...payload, google_maps_url: e.target.value });
            getGolocation(e.target.value);
          }}
        ></TextField>
        <TextField
          label={"Latitude"}
          isRequired
          readonly
          value={geolocation.lat}
        ></TextField>
        <TextField
          label={"Longitude"}
          isRequired
          readonly
          value={geolocation.long}
        ></TextField>
        <Box>
          <NormalText textAlign="left" margin={"7px 0 5px 0"} fontSize={"12px"}>
            Logo Toko
          </NormalText>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <UploadLabel
                htmlFor="logoToko"
                style={{
                  width: "100%",
                  backgroundColor: "#ccc",
                  borderColor: "#ccc",
                }}
              >
                {loadingImg ? <CircularProgress size={15} /> : null}{" "}
                {loadingImg ? "" : "Pilih Gambar"}
              </UploadLabel>
              <input
                accept="image/*"
                id="logoToko"
                multiple
                type="file"
                onChange={(e: any) => handleImage(e, "logoToko")}
                style={{ display: "none" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              {payload.store_logo && (
                <img
                  src={payload.store_logo}
                  style={{ height: 150, width: 150, marginLeft: "30px" }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              )}
            </Grid>
          </Grid>
          <FormHelperText style={{ color: "#1565c0" }}>
            Resolusi gambar maks. 1080x1920 Pixels {"&"} ukuran maks. 2MB
          </FormHelperText>
          <FormHelperText style={{ color: "#f12147" }}>
            {errorValidasi.logoToko}
          </FormHelperText>
        </Box>
        <Box>
          <NormalText textAlign="left" margin={"7px 0 5px 0"} fontSize={"12px"}>
            Banner Toko
          </NormalText>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <UploadLabel
                htmlFor="bannertoko"
                style={{
                  width: "100%",
                  backgroundColor: "#ccc",
                  borderColor: "#ccc",
                }}
              >
                {loadingImg ? <CircularProgress size={15} /> : null}{" "}
                {loadingImg ? "" : "Pilih Gambar"}
              </UploadLabel>
              <input
                accept="image/*"
                id="bannertoko"
                multiple
                type="file"
                onChange={(e: any) => handleBanner(e, "bannertoko")}
                style={{ display: "none" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              {payload.store_banner && (
                <img
                  src={payload.store_banner}
                  style={{ height: 150, width: 150, marginLeft: "30px" }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              )}
            </Grid>
          </Grid>
          <FormHelperText style={{ color: "#1565c0" }}>
            Resolusi gambar maks. 300x300 Pixels {"&"} ukuran maks. 2MB
          </FormHelperText>
          <FormHelperText style={{ color: "#f12147" }}>
            {errorBanner.bannerToko}
          </FormHelperText>
        </Box>

        <Typography variant="caption" gutterBottom>Status Toko
          <Stack direction="row" spacing={1} alignItems="center">
            <Switch onChange={(e) => setStore(prevCheck => !prevCheck)}/>
            <Typography>{storeStatus ? 'Buka' : 'Tutup'}</Typography>
          </Stack>
        </Typography>

        <Typography variant="caption" gutterBottom>Status di halaman bazar
          <Stack direction="row" spacing={1} alignItems="center">
            <Switch onChange={(e) => setStorePublish(prevCheck => !prevCheck)}/>
            <Typography>{storeStatusPublish ? 'Tampilkan' : 'Sembunyikan'}</Typography>
          </Stack>
        </Typography>

        <Stack direction={"row"} spacing={2} margin={"20px 0 20px 0"}>
          <Button
            variant="danger"
            onClick={() => navigate("/stores/store/list")}
          >
            Batal
          </Button>
          <Button variant="primary" onClick={() => handleSubmit()}>
            Simpan
          </Button>
        </Stack>
      </Card>
    </MerchantLayout>
  );
};

export default StoreForm;
