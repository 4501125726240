import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title?: string;
  meta?: string;
  description?: string;
  children: ReactNode;
}

const MainLayout = ({ title, children }: Props) => {
  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{title ?? 'Pagii Ordering Merchant'}</title>
        <link
          href="https://fonts.googleapis.com/css?family=Titillium+Web:200,200i,300,300i,400,400i,600,600i,700,700i,900"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat"
          rel="stylesheet"
        ></link>
      </Helmet>
      {children}
    </>
  );
};

export default MainLayout;
