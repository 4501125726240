import { sequenceResponseProps, SequenceState} from './types'
import { createSlice } from '@reduxjs/toolkit'
import { getList, updateList } from './sequenceService';
// create user
export const initialState: SequenceState = {
    data: {} as sequenceResponseProps,
    status: "loading",
    error: {}
}

export const SequenceSlice = createSlice({
    name: 'sequence',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getList.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(getList.fulfilled, (state, action) => {
            if (action.payload) {
                state.data = action.payload.data;
                state.status = action.payload.status;
                state.error = action.payload.error
            }
        })
        .addCase(getList.rejected, (state, action) => {
            state.status = 'error';
            state.error = action.error
        })
        .addCase(updateList.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(updateList.fulfilled, (state, action) => {
            if (action.payload) {
                state.data = action.payload.data;
                state.status = action.payload.status;
                state.error = action.payload.error
            }
        })
        .addCase(updateList.rejected, (state, action) => {
            state.status = 'error';
            state.error = action.error
        })
    }
})

export const SequenceReducer = SequenceSlice.reducer