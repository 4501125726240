import axios from 'axios'

export const getStore = async (token: any) => {
    const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`,
            token : token,
        }
    };
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/merchant/allstore`, config)

    return response;
}