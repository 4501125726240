import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

export default function HelpTooltip({
  text,
  margin,
}: {
  text: string | React.ReactNode;
  margin?: string;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div style={{ margin: margin ?? "" }}>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          background: "#f1f1f1",
          textAlign: "center",
          borderRadius: "50px",
          fontSize: "11px",
          fontWeight: "400",
          width: 15,
          height: 15,
        }}
      >
        ?
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        PaperProps={{
          sx: {
            borderRadius: "20px",
            maxWidth: "340px",
          },
        }}
      >
        {typeof text === "string" ? (
          <Typography sx={{ p: 1, fontSize: "10px" }}>{text}</Typography>
        ) : (
          text
        )}
      </Popover>
    </div>
  );
}
