import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import Tables from "src/components/Table/tableData";
import { Button } from "src/components/styled/button.styled";
import {
  Switch,
  IconButton,
  Grid,
  Button as ButtonMUI,
  Box,
} from "@mui/material";
import {
  Delete,
  Edit,
  Add,
  ArrowForwardIos,
  KeyboardArrowDown,
  Search,
} from "@mui/icons-material";
import {
  DeleteCategoryProps,
  getCategory,
  removeCategory,
  CategoryResponseProps,
  UpdateStatusCategoryProps,
  updateStatusCategory,
} from "src/redux/category";
import {
  DeleteSubCategoryProps,
  removeSubCategory,
  updateStatusSubCategory,
} from "src/redux/subCategory";
import {
  DeleteSubSubCategoryProps,
  removeSubSubCategory,
  updateStatusSubSubCategory,
} from "src/redux/subCategoryLevel3";
import useRedux from "src/redux/useRedux";
import PopUp from "src/components/Modal/modal";
import { NormalText } from "src/components/styled/text.styled";
import ConfirmationModal from "src/components/Modal/confirmation";
import checkPermission from "src/helper/checkPermission";
// import "./stylesCss.css";

const UserListPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<CategoryResponseProps>(
    {} as CategoryResponseProps
  );
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [idUser, setIdUser] = useState(0);
  const [statusDel, setStatusDel] = useState("");
  const [openModalSub, setOpenModalSub] = useState(false);
  const [deleteCategory, setDelete] = useState(false);
  const [deleteSub, setDeleteSub] = useState(false);
  const [deleteSubSub, setDeleteSubSub] = useState(false);
  const [showRowExpand, setShowRowExpand] = useState(false);
  const [update, setUpdate] = useState(false);
  const [updateSub, setUpdateSub] = useState(false);

  const handleDeleteUser = (id: any) => {
    setIdUser(id);
    setDelete(true);
  };

  const handleDeleteSubConf = (id: any, type: string) => {
    setIdUser(id);
    type === "level-3" ? setDeleteSubSub(true) : setDeleteSub(true);
  };

  const onClose = () => {
    setIdUser(0);
    setOpenModal(false);
    setStatusDel("");
    setOpenModalSub(false);
    setDelete(false);
    setDeleteSub(false);
    setDeleteSubSub(false);
    setUpdate(false);
    setUpdateSub(false);
  };

  const columns = [
    {
      name: "Nama Kategori",
      selector: (row: any) => {
        return <span style={{ fontSize: "14px" }}>{row.category_name}</span>;
      },
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      center: true,
      cell: (row: any) => {
        return (
          <Switch
            checked={row.is_active}
            onChange={(e: any) =>
              handleUpdateCategory(e.target.checked, row.id, "category")
            }
            disabled={checkPermission("Category", "is_edit") ? false : true}
          />
        );
      },
    },
    {
      name: "Aksi",
      center: true,
      cell: (row: any) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {checkPermission("Category", "is_edit") && (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() =>
                  navigate("/products/categories/edit-categories", {
                    state: { data: row, statusUrl: "edit" },
                  })
                }
              >
                <Edit />
              </IconButton>
            )}
            <IconButton
              aria-label="detail"
              color="success"
              onClick={() =>
                navigate("/products/categories/view-categories", {
                  state: { data: row, statusUrl: "view" },
                })
              }
            >
              <Search />
            </IconButton>
            {checkPermission("Category", "is_add") && (
              <IconButton
                aria-label="delete"
                color="warning"
                onClick={() =>
                  navigate("/products/categories/add-sub-categories", {
                    state: {
                      data: row,
                      statusUrl: "add-level-2",
                      categoryName: row.category_name,
                      subCategoryName: "",
                      idSub: "",
                    },
                  })
                }
              >
                <Add />
              </IconButton>
            )}
            {checkPermission("Category", "is_delete") && (
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => handleDeleteUser(row.id)}
              >
                <Delete />
              </IconButton>
            )}
          </Grid>
        );
      },
    },
  ];

  const handleUpdateCategory = (val: boolean, id: number, type: string) => {
    let dataUpdate = {
      id: id,
      access_token: Auth.data.access_token,
      data: {
        is_active: val,
      },
    };
    type === "category"
      ? thunkDispatch(
          updateStatusCategory(dataUpdate as UpdateStatusCategoryProps)
        )
          .unwrap()
          .then((res) => {
            if (res && res.status == "success") {
              setUpdate(true);
              getAll();
              setStatusDel("success");
              setLoading(false);
            } else {
              setLoading(false);
              setUpdate(true);
              getAll();
              setStatusDel("failed");
            }
          })
          .catch((err) => {
            setLoading(false);
            setUpdate(true);
            getAll();
            setStatusDel("failed");
            console.error(err);
          })
      : type === "subcategory"
      ? thunkDispatch(
          updateStatusSubCategory(dataUpdate as UpdateStatusCategoryProps)
        )
          .unwrap()
          .then((res) => {
            if (res && res.status == "success") {
              setUpdateSub(true);
              getAll();
              setStatusDel("success");
              setLoading(false);
            } else {
              setLoading(false);
              setUpdateSub(true);
              getAll();
              setStatusDel("failed");
            }
          })
          .catch((err) => {
            setLoading(false);
            setUpdate(true);
            getAll();
            setStatusDel("failed");
            console.error(err);
          })
      : thunkDispatch(
          updateStatusSubSubCategory(dataUpdate as UpdateStatusCategoryProps)
        )
          .unwrap()
          .then((res) => {
            if (res && res.status == "success") {
              setUpdateSub(true);
              getAll();
              setStatusDel("success");
              setLoading(false);
            } else {
              setLoading(false);
              setUpdateSub(true);
              setStatusDel("failed");
            }
          })
          .catch((err) => {
            setLoading(false);
            setUpdateSub(true);
            getAll();
            setStatusDel("failed");
            console.error(err);
          });
    setLoading(false);
  };

  const {
    thunkDispatch,
    storeState: { Auth },
  } = useRedux();

  const getAll = () => {
    setLoading(true);
    let data = {
      filter: filter,
      limit: limit,
      offset: offset - 1,
    };
    thunkDispatch(getCategory(data))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setData(res.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    getAll();
  }, [filter, limit, offset]);

  const handleDelete = () => {
    let dataDel = {
      category_id: idUser,
      access_token: Auth.data.access_token,
    };
    thunkDispatch(removeCategory(dataDel as DeleteCategoryProps))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          onClose();
          setOpenModal(true);
          getAll();
          setStatusDel("success");
          setLoading(false);
        } else if (res && res.status === "error") {
          setMessage(res.error.response.data.message);
          setOpenModal(true);
          getAll();
          setStatusDel("failed");
          setLoading(false);
        }
      })
      .catch((err) => {
        onClose();
        setLoading(false);
        setStatusDel("failed");
        console.error(err);
      });
    setLoading(false);
  };

  const handleDeleteSub = () => {
    let dataDel = {
      subcategory_id: idUser,
      access_token: Auth.data.access_token,
    };
    thunkDispatch(removeSubCategory(dataDel as DeleteSubCategoryProps))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          onClose();
          setOpenModalSub(true);
          getAll();
          setStatusDel("success");
          setLoading(false);
        } else if (res && res.status === "error") {
          setMessage(res.error.response.data.message);
          setOpenModalSub(true);
          getAll();
          setStatusDel("failed");
          setLoading(false);
        }
      })
      .catch((err) => {
        onClose();
        setLoading(false);
        setStatusDel("failed");
      });
    setLoading(false);
  };

  const handleDeleteSubSub = () => {
    let dataDel = {
      subcategory_id: idUser,
      access_token: Auth.data.access_token,
    };
    thunkDispatch(removeSubSubCategory(dataDel as DeleteSubSubCategoryProps))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          onClose();
          setOpenModalSub(true);
          getAll();
          setStatusDel("success");
          setLoading(false);
        } else if (res && res.status === "error") {
          setMessage(res.error.response.data.message);
          setOpenModalSub(true);
          getAll();
          setStatusDel("failed");
          setLoading(false);
        }
      })
      .catch(() => {
        onClose();
        setLoading(false);
        setStatusDel("failed");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAll();
  }, []);

  const ExpandedComponent = (row: any) => {
    return (
      <div>
        {row.data && row.data.subCategory && row.data.subCategory.length != 0
          ? row.data.subCategory.map((x: any, y: any) => {
              return (
                <>
                  <Grid
                    container
                    spacing={2}
                    style={{ margin: 10, marginLeft: 0 }}
                    key={y}
                  >
                    <Grid
                      item
                      sx={{ width: "5%" }}
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <IconButton
                        aria-label="delete"
                        color="default"
                        onClick={() => setShowRowExpand(!showRowExpand)}
                        disabled={
                          x.subsCategory && x.subsCategory.length != 0
                            ? false
                            : true
                        }
                      >
                        {showRowExpand ? (
                          <KeyboardArrowDown />
                        ) : (
                          <ArrowForwardIos sx={{ fontSize: "1rem" }} />
                        )}
                      </IconButton>
                    </Grid>
                    <Grid
                      item
                      sx={{ width: "35%", marginLeft: "-30px" }}
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <span style={{ fontSize: "14px" }}>
                        {x.subcategory_name}
                      </span>
                    </Grid>
                    <Grid
                      item
                      sx={{ width: "25%", marginLeft: "5px" }}
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Switch
                        checked={x.is_active}
                        onChange={(e: any) =>
                          handleUpdateCategory(
                            e.target.checked,
                            x.id,
                            "subcategory"
                          )
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ marginLeft: "25px", width: "35%" }}
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        onClick={() =>
                          navigate("/products/categories/edit-sub-categories", {
                            state: {
                              data: x,
                              statusUrl: "edit-level-2",
                              categoryName: row.data.category_name,
                              subCategoryName: "",
                              idSub: "",
                              idCategory: row.data.id,
                            },
                          })
                        }
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        aria-label="add"
                        color="success"
                        onClick={() =>
                          navigate("/products/categories/view-sub-categories", {
                            state: {
                              data: x,
                              statusUrl: "view-level-2",
                              categoryName: row.data.category_name,
                              subCategoryName: "",
                            },
                          })
                        }
                      >
                        <Search />
                      </IconButton>
                      <IconButton
                        aria-label="add"
                        color="warning"
                        onClick={() =>
                          navigate("/products/categories/add-sub-categories", {
                            state: {
                              data: x,
                              statusUrl: "add-level-3",
                              categoryName: row.data.category_name,
                              subCategoryName: x.subcategory_name,
                              idSub: x.id,
                              idCategory: row.data.id,
                            },
                          })
                        }
                      >
                        <Add />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => handleDeleteSubConf(x.id, "level-2")}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                  {showRowExpand &&
                  x.subsCategory &&
                  x.subsCategory.length !== 0
                    ? x.subsCategory.map((dataSub: any, index: number) => {
                        return (
                          <Grid container key={index}>
                            <Grid
                              item
                              sx={{ width: "49%", marginLeft: "-40px" }}
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <span style={{ fontSize: "14px" }}>
                                {dataSub.subscategory_name}
                              </span>
                            </Grid>
                            <Grid
                              item
                              sx={{ width: "26%", paddingLeft: "45px" }}
                            >
                              <Switch
                                checked={dataSub.is_active}
                                onChange={(e: any) =>
                                  handleUpdateCategory(
                                    e.target.checked,
                                    dataSub.id,
                                    "subsubcategory"
                                  )
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              sx={{ width: "25%", paddingLeft: "65px" }}
                            >
                              <IconButton
                                aria-label="edit"
                                color="primary"
                                onClick={() =>
                                  navigate(
                                    "/products/categories/add-sub-categories",
                                    {
                                      state: {
                                        data: dataSub,
                                        statusUrl: "edit-level-3",
                                        categoryName: row.data.category_name,
                                        subCategoryName: x.subcategory_name,
                                        idSub: x.id,
                                        idCategory: row.data.id,
                                      },
                                    }
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                aria-label="add"
                                color="success"
                                onClick={() =>
                                  navigate(
                                    "/products/categories/view-sub-categories",
                                    {
                                      state: {
                                        data: dataSub,
                                        status: "view-level-3",
                                        categoryName: row.data.category_name,
                                        subCategoryName: x.subcategory_name,
                                        idSub: x.id,
                                        idCategory: row.data.id,
                                      },
                                    }
                                  )
                                }
                              >
                                <Search />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                color="error"
                                onClick={() =>
                                  handleDeleteSubConf(dataSub.id, "level-3")
                                }
                              >
                                <Delete />
                              </IconButton>
                            </Grid>
                          </Grid>
                        );
                      })
                    : null}
                </>
              );
            })
          : null}
      </div>
    );
  };

  return (
    <MerchantLayout>
      <ConfirmationModal
        title="Konfirmasi"
        titleStyle={{ fontWeight: "bold" }}
        description={`Apakah anda yakin menghapus ${
          deleteSub || deleteSubSub ? "sub kategori" : "kategori"
        } ?`}
        open={deleteCategory || deleteSub || deleteSubSub}
        handleConfirm={() =>
          deleteSub
            ? handleDeleteSub()
            : deleteSubSub
            ? handleDeleteSubSub()
            : handleDelete()
        }
        handleClose={() => onClose()}
      ></ConfirmationModal>
      <PopUp
        open={openModal || openModalSub || update || updateSub}
        handleClose={() => {
          onClose();
        }}
      >
        <NormalText
          textAlign="center"
          fontWeight={"bold"}
          fontSize={"20px"}
          margin={"0 0 20px 0"}
        >
          {`${update || updateSub ? "Update" : "Hapus"} ${
            openModalSub || updateSub ? "Sub Kategori" : "Kategori"
          }`}
        </NormalText>
        <NormalText textAlign="center" fontSize={"14px"} margin={"0 0 20px 0"}>
          {statusDel == "success"
            ? `Sukses ${update || updateSub ? "mengupdate" : "menghapus"} ${
                openModalSub || updateSub ? "sub kategori" : "kategori"
              }`
            : `${message}`}
        </NormalText>
        <Box textAlign={"center"} margin={"20px 0 0 0 "}>
          <ButtonMUI
            variant="contained"
            onClick={() => {
              onClose();
            }}
            sx={{ width: 100, height: 40, marginRight: 3 }}
            color="error"
          >
            Ok
          </ButtonMUI>
        </Box>
      </PopUp>
      <Tables
        records={data.data}
        columns={columns}
        title="Daftar Kategori"
        isSearch={true}
        addButton={checkPermission("Category", "is_edit")}
        titleAdd="Tambah Kategori"
        onChangeSearch={(e) => setFilter(e.target.value)}
        valueSearch={filter}
        handleAddButton={() =>
          navigate("/products/categories/add-categories", {
            state: { data: null, statusUrl: "add" },
          })
        }
        progressPending={loading}
        paginationPerPage={limit}
        totalRow={data && data.meta && data.meta.total ? data.meta.total : 0}
        onChangePage={(page) => setOffset(page)}
        onChangeRowsPerPage={(currentRowsPerPage) =>
          setLimit(currentRowsPerPage)
        }
        expandableRows={true}
        expandableRowDisabled={(row) =>
          row.subCategory && row.subCategory.length != 0 ? false : true
        }
        expandableRowsComponent={ExpandedComponent}
        secondButton={
          checkPermission("Category", "is_edit") && (
            <Button
              onClick={() => navigate("/products/categories/sequence")}
              variant="primary"
              width={"40%"}
              margin={"0 10px 0 0"}
            >
              Sequence
            </Button>
          )
        }
      />
    </MerchantLayout>
  );
};

export default UserListPage;
