import React from "react";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import { Button } from "src/components/styled/button.styled";
import { useParams, useNavigate } from "react-router-dom";
import useRedux from "src/redux/useRedux";
import Card from "src/components/Cards/card";
import { NormalText } from "src/components/styled/text.styled";
import { Switch } from "@mui/material";
import { formatCurrency } from "src/helper/generalFunction";
import {
  getDetailProductStore,
  ProductStoreType,
} from "src/redux/productStore";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";

const DetailProductStore = () => {
  const { key } = useParams();
  const navigate = useNavigate();
  const { thunkDispatch } = useRedux();
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [product, setProduct] = React.useState<ProductStoreType>();

  const columns = [
    {
      name: "Toko",
      selector: (row: any) => row.store_name,
      sortable: true,
    },
    {
      name: "Harga (Rp)",
      cell: (row: any) => {
        return formatCurrency(row.store_price);
      },
      sortable: true,
    },
    {
      name: "Stok Produk",
      cell: (row: any) => {
        return row.stock ? row.stock : '-';
      },
      sortable: true,
    },
    {
      name: "Diskon",
      cell: (row: any) => {
        return (
          <NormalText>
            {row.discount_type === "discount" ? "Rp" : ""}{" "}
            {row.discount === "discount"
              ? formatCurrency(row.discount)
              : row.discount}
            {row.discount_type === "percent" ? "%" : ""}
          </NormalText>
        );
      },
    },
    {
      name: "Harga Diskon",
      cell: (row: any) => {
        return formatCurrency(row.final_price);
      },
    },
    {
      name: "Status",
      center: true,
      cell: (row: any) => {
        return (
          <Switch disabled checked={row.is_active} onChange={() => null} />
        );
      },
    },
  ];

  const fetchDetail = () => {
    thunkDispatch(getDetailProductStore(Number(key)))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setProduct(res.data.data);
          console.log(res);
        }
      });
  };

  React.useEffect(() => {
    fetchDetail();
  }, []);

  return (
    <MerchantLayout>
      <ToastContainer />
      <Card>
        <NormalText fontWeight="bold" fontSize="20px" margin="0 0 20px 0">
          Assign Produk Toko
        </NormalText>
        <NormalText fontSize="12px">Nama Produk</NormalText>
        <NormalText fontWeight="bold" fontSize="14px">
          {product?.product_name}
        </NormalText>
        <NormalText fontSize="12px" margin="10px 0 0 0 ">
          Harga Produk
        </NormalText>
        <NormalText fontWeight="bold" fontSize="14px">
          Rp. {Number(product?.product_detail.product_price)}
        </NormalText>

        <NormalText fontWeight="bold" fontSize="20px" margin="20px 0 10px 0">
          Daftar Produk Toko
        </NormalText>
        <DataTable
          columns={columns}
          data={
            product && product.productStore && product.productStore.length > 0
              ? product.productStore
              : []
          }
          responsive
          pagination
          striped
          paginationRowsPerPageOptions={[5, 10, 25, 50]}
          paginationTotalRows={
            product && product.productStore && product.productStore.length > 0
              ? product.productStore.length
              : 0
          }
          paginationPerPage={limit}
          onChangeRowsPerPage={(limit) => setLimit(limit)}
          onChangePage={(page) => setPage(page)}
        />
        <Button onClick={() => navigate("/products/store-product")}>
          Kembali
        </Button>
      </Card>
    </MerchantLayout>
  );
};

export default DetailProductStore;
