import { createAsyncThunk } from "@reduxjs/toolkit";
import { DeleteUserState, deleteUserProps } from "./types";
import axios from "axios";

export const deleteUser = createAsyncThunk('user/list/delete-user', async (props: deleteUserProps) => {
    const config = {
        headers:{
            api_key: 'Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e',
            token: props.access_token
        }
      };
    try {
        const response = await axios.delete(
                `${process.env.REACT_APP_API_URL}/merchant/users/${props.id}/delete`,
                config
            );
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as DeleteUserState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as DeleteUserState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as DeleteUserState;

    }
})