import axios from "axios";
import { getToken } from "src/helper/generalFunction";

export const uploadImage = async (body: any, typeUpload: any) => {
  const config = {
    headers: {
      api_key: `${process.env.REACT_APP_API_KEY}`,
    },
  };
  let response = await axios.post(
    `${process.env.REACT_APP_API_URL}/images/${typeUpload}/upload`,
    body,
    config
  );

  return response;
};

export const uploadFile = async (body: any) => {
  const config = {
    headers: {
      api_key: `${process.env.REACT_APP_API_KEY}`,
      token: getToken(),
    },
  };
  let response = await axios.post(
    `${process.env.REACT_APP_API_URL}/merchant/product/import/save`,
    body,
    config
  );

  return response;
};
