import { createAsyncThunk } from '@reduxjs/toolkit';
import { SesiKasirState } from './types';
import ApiService from 'src/services/ApiService';
import { getToken } from 'src/helper/generalFunction';

export interface ListProps {
  offset?: number;
  limit?: number;
  filter?: string;
  store_id?: any;
}
export const getSesiKasir = createAsyncThunk(
  'SesiKasir/get',
  async (props: ListProps) => {
    try {
      const config = {
        headers: {
          token: getToken(),
          api_key: process.env.REACT_APP_API_KEY,
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/cashier-history?limit=${
          props.limit ?? 0
        }&offset=${props.offset ?? 0}${
          props.filter ? '&filter=' + props.filter : ''
        }${props.store_id ? '&store_id=' + props.store_id : ''}`,
        config,
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as SesiKasirState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as SesiKasirState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as SesiKasirState;
    }
  },
);
