import React, { CSSProperties, ReactNode, useState } from "react";
import styled, { css } from "styled-components";
import { Stack, Box } from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";
import { IconStyled } from "./styles";
import { IconEye, IconEyeOff } from "@tabler/icons";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface Props {
  id?: string;
  IconComponent?: any;
  placeholder?: string;
  label?: string | ReactNode;
  minLength?: number;
  maxLength?: number;
  isRequired?: boolean;
  value?: string | number | readonly string[] | undefined;
  type?: string;
  variant?: string;
  margin?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onInvalid?: React.FormEventHandler<HTMLInputElement> | undefined;
  validationText?: string;
  validationType?: "ERROR" | "WARNING" | "INFO" | string | undefined;
  disabled?: boolean | undefined;
  readonly?: boolean | undefined;
  defaultValue?: any;
  pattern?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  style?: CSSProperties;
  copyUrl?: any;
}

const TextField = ({
  id,
  IconComponent,
  validationType,
  validationText,
  type,
  placeholder,
  onChange,
  onInvalid,
  disabled,
  readonly,
  style,
  variant,
  value,
  maxLength,
  minLength,
  isRequired,
  label,
  pattern,
  onKeyDown,
  copyUrl,
  ...params
}: Props) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  return (
    <Box
      flexDirection={"column"}
      margin={"0 0 10px 0"}
      sx={{ position: "relative" }}
    >
      {label && (
        <NormalText fontSize={"12px"} margin="0 0 5px 0">
          {label} {isRequired ? "*" : ""}
        </NormalText>
      )}
      <Stack direction={"row"}>
        <Input
          id={id}
          readOnly={readonly}
          required={isRequired}
          value={value}
          type={
            type && type === "tel"
              ? "number"
              : type === "password"
              ? passwordShown
                ? "text"
                : "password"
              : type
          }
          placeholder={placeholder}
          onChange={onChange}
          onInvalid={onInvalid}
          onKeyDown={onKeyDown}
          pattern={pattern}
          disabled={disabled}
          maxLength={maxLength}
          variant={variant}
          minLength={minLength}
          style={{ height: 40, ...style }}
          IconComponent={IconComponent}
          {...params}
        />
        {type === "password" && (
          <IconStyled
            style={{ right: "11%" }}
            onClick={togglePasswordVisiblity}
          >
            {passwordShown ? (
              <IconEye color={"black"} />
            ) : (
              <IconEyeOff color={"black"} />
            )}
          </IconStyled>
        )}
        {type === "copyStoreUrl" && (
          <Box
            sx={{
              position: "absolute",
              bottom: "15%",
              right: "1%",
              cursor: "pointer",
            }}
            onClick={() => {
              copyUrl(value);
            }}
          >
            <ContentCopyIcon />
          </Box>
        )}
        {IconComponent && <BoxIcon>{IconComponent}</BoxIcon>}
      </Stack>
      {validationType && (
        <Stack>
          <NormalText
            color={
              validationType === "ERROR"
                ? "#f12147"
                : validationType === "WARNING"
                ? "#E7B450"
                : validationType === "INFO"
                ? "#DDD8D8"
                : "white"
            }
            fontSize="12px"
            fontStyle="normal"
            fontWeight="400"
            margin={"0 0 5px 0"}
          >
            {validationText}
          </NormalText>
        </Stack>
      )}
    </Box>
  );
};

const BoxIcon = styled.div<Props>`
  width: 40px;
  background: #e9ecef;
  justify-content: center;
  padding: 5px;
  margin: 0;
  display: flex;
  align-items: center;
  border-radius: 0 5px 5px 0;
`;

const Input = styled.input<Props>`
  position: relative;
  background: transparent;
  border: 1px solid #ced4da;
  border-radius: ${({ IconComponent }) =>
    IconComponent ? "5px 0 0 5px" : "5px"};
  width: 100%;
  min-height: 30px;
  padding: 5px;

  ${(props) =>
    props.variant === "tel" &&
    css`
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      & {
        -moz-appearance: textfield;
      }
    `}

  ${(props) =>
    props.readOnly &&
    css`
      background: #f1f1f1;
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background: #f1f1f1;
    `}

  :active {
    border: 1px solid #80bdff;
  }

  :focus {
    outline: none;
    border: 1px solid #80bdff;
  }
`;

export default TextField;
