const styles = {
  boxStyled: {
    minHeight: "calc(100vh - 85px)",
    width: "100%",
    padding: "20px",
    backgroundColor: "white",
    justifyContent: "center",
    border: "0 solid rgba(0,0,0,.125)",
    borderRadius: "0.25rem",
    boxShadow: "0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%)",
  },
  inputRoot: {
    height: 33,
    fontSize: 14,
    padding: 0,
  },
};

export default styles;
