import React, { useState, useEffect } from 'react';
import { Stack } from '../../../components/styled/layout.styled';
import { useNavigate, useLocation } from 'react-router-dom';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import styles from './styles';
import {
  Box,
  Button as ButtonMUI,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
  TextField as TextFieldMUI,
  Autocomplete,
} from '@mui/material';
import CurrencyFormat from 'react-currency-format';
import { NormalText } from 'src/components/styled/text.styled';
import TextField from 'src/components/Inputs/TextField';
import { createBrand, editLoyalty } from 'src/redux/loyaltySettings';
import useRedux from 'src/redux/useRedux';
import PopUp from 'src/components/Modal/modal';
import {
  getSubSubCategory,
  SubCategoryData,
} from 'src/redux/subCategoryLevel3';

interface RouteState {
  data: any;
  statusUrl: string;
}

let valueType = [
  {
    value: 'Percentage',
    name: 'Percentage',
  },
  {
    value: 'Normal',
    name: 'Normal',
  },
];

const AddBrandPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { data, statusUrl } = location.state as RouteState;
  const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState('');
  const [dataAdd, setDataAdd] = useState({
    usage_type: statusUrl === 'edit' ? data?.usage_type : '',
    loyalty_usage_value: statusUrl === 'edit' ? data?.loyalty_usage_value : 0,
    max_loyalty_usage_value:
      statusUrl === 'edit' ? data?.max_loyalty_usage_value : 0,
    cashback_type: statusUrl === 'edit' ? data?.cashback_type : '',
    cashback_value: statusUrl === 'edit' ? data?.cashback_value : 0,
    max_cashback_value: statusUrl === 'edit' ? data?.max_cashback_value : 0,
    sub_category_id: statusUrl === 'edit' ? data?.subs_category_id : 0,
  });
  const [loading, setLoading] = useState(true);
  const [errorValidasi, setErrorValidasi] = useState({
    usage_type: '',
    loyalty_usage_value: '',
    max_loyalty_usage_value: '',
    cashback_type: '',
    cashback_value: '',
    max_cashback_value: '',
    sub_category_id: '',
  });
  const [sub, setSub] = useState<SubCategoryData[]>([]);

  useEffect(() => {
    let dataGet = {
      offset: 0,
      limit: 1000,
      filter: '',
    };
    thunkDispatch(getSubSubCategory(dataGet))
      .unwrap()
      .then((res) => {
        if (res && res.status == 'success') {
          setLoading(false);
          if (res.data.data && res.data.data.length !== 0) {
            setSub(res.data.data);
            if (statusUrl === 'edit' && data.subs_category_id) {
              let findIndex = res.data.data.findIndex(
                (element) => element.id == data.subs_category_id,
              );
              if (findIndex !== -1) {
                setDataAdd({
                  ...dataAdd,
                  sub_category_id: res.data.data[findIndex],
                });
              }
            }
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
    setLoading(false);
  }, []);

  const {
    thunkDispatch,
    storeState: { Auth, LoyaltySetting },
  } = useRedux();

  const handleAddNewBrand = () => {
    let dataObject = {
      data: {
        usage_type: dataAdd.usage_type,
        loyalty_usage_value: dataAdd.loyalty_usage_value,
        max_loyalty_usage_value: dataAdd.max_loyalty_usage_value,
        cashback_type: dataAdd.cashback_type,
        cashback_value: dataAdd.cashback_value,
        max_cashback_value: dataAdd.max_cashback_value,
      },
      sub_category_id: dataAdd.sub_category_id.id,
    };
    thunkDispatch(createBrand(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == 'success') {
          setLoading(false);
          setOpenModal(true);
          setStatusModal('success');
        } else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal('failed');
        }
      })
      .catch((err) => {
        setLoading(false);
        setStatusModal('failed');
        setOpenModal(true);
        console.error(err);
      });
    setLoading(false);
  };

  const handleEditBrand = () => {
    let dataObject = {
      data: {
        usage_type: dataAdd.usage_type,
        loyalty_usage_value: dataAdd.loyalty_usage_value,
        max_loyalty_usage_value: dataAdd.max_loyalty_usage_value,
        cashback_type: dataAdd.cashback_type,
        cashback_value: dataAdd.cashback_value,
        max_cashback_value: dataAdd.max_cashback_value,
      },
      sub_category_id: dataAdd.sub_category_id.id,
      id: data?.id ?? '',
    };
    thunkDispatch(editLoyalty(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == 'success') {
          setLoading(false);
          setOpenModal(true);
          setStatusModal('success');
        } else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal('failed');
        }
      })
      .catch((err) => {
        setLoading(false);
        setStatusModal('failed');
        setOpenModal(true);
        console.error(err);
      });
    setLoading(false);
  };

  const handleValidate = () => {
    if (
      !dataAdd.cashback_type ||
      !dataAdd.cashback_value ||
      !dataAdd.max_cashback_value ||
      !dataAdd.sub_category_id ||
      dataAdd.cashback_type === 'Percentage' && dataAdd.cashback_value > 100
    ) {
      setErrorValidasi({
        ...errorValidasi,
        cashback_type: !dataAdd.cashback_type
          ? 'Tipe Cashback Wajib Diisi'
          : '',
        cashback_value: !dataAdd.cashback_value
          ? 'Nilai Cashback Wajib Diisi'
          : dataAdd.cashback_type === 'Percentage' && dataAdd.cashback_value > 100
            ? 'Tipe cashback persentase tidak boleh melebihi 100'
            : '',
        max_cashback_value: !dataAdd.max_cashback_value
          ? 'Maks Nilai Cashback Wajib Diisi'
          : '',
        sub_category_id: !dataAdd.sub_category_id
          ? 'Nama Sub Kategori Wajib Diisi'
          : '',
      });
    } else {
      setErrorValidasi({
        ...errorValidasi,
        usage_type: '',
        loyalty_usage_value: '',
        max_loyalty_usage_value: '',
        cashback_type: '',
        cashback_value: '',
        max_cashback_value: '',
        sub_category_id: '',
      });
      statusUrl === 'edit' ? handleEditBrand() : handleAddNewBrand();
    }
  };

  return (
    <MerchantLayout>
      <PopUp open={openModal} handleClose={() => setOpenModal(!openModal)}>
        <NormalText
          textAlign="center"
          fontWeight={'bold'}
          fontSize={'20px'}
          margin={'0 0 20px 0'}
        >
          {statusModal === 'success' ? 'Sukses' : 'Gagal'}
        </NormalText>
        <NormalText textAlign="center" fontSize={'14px'} margin={'0 0 20px 0'}>
          {statusModal === 'success' ? (
            statusUrl === 'edit' ? (
              <span>Berhasil mengupdate data loyalti</span>
            ) : (
              <span>
                Berhasil menambahkan <br /> loyalti baru
              </span>
            )
          ) : LoyaltySetting?.error?.response?.data?.message ? (
            <span>{LoyaltySetting.error.response.data.message}</span>
          ) : statusUrl === 'edit' ? (
            <span>
              Gagal mengupate loyalti, <br /> silahkan periksa kembali data anda
            </span>
          ) : (
            <span>
              Gagal menambah loyalti, <br /> silahkan periksa kembali data anda
            </span>
          )}
        </NormalText>
        <Box textAlign={'center'} margin={'20px 0 0 0 '}>
          <ButtonMUI
            variant="contained"
            onClick={() =>
              statusModal === 'success'
                ? navigate('/setting/loyaltysetting')
                : setOpenModal(!openModal)
            }
            sx={{ width: 100, height: 40, marginRight: 3 }}
            color="error"
          >
            Oke
          </ButtonMUI>
        </Box>
      </PopUp>
      <Stack direction="column">
        <Box sx={styles.boxStyled}>
          <NormalText
            textAlign="start"
            fontWeight={'bold'}
            fontSize={'18px'}
            margin={'0 0 20px 0'}
          >
            {statusUrl === 'edit' ? 'Edit Loyalti' : 'Tambah Loyalti'}
          </NormalText>
          <form>
            <NormalText
              textAlign="left"
              margin={'20px 0 5px 0'}
              fontSize={'14px'}
            >
              Nama Sub Kategori *
            </NormalText>
            <Autocomplete
              id="combo-box-demo"
              options={sub}
              getOptionLabel={(option) => option.subscategory_name || ''}
              onChange={(event: any, value: any) =>
                setDataAdd({
                  ...dataAdd,
                  sub_category_id: value,
                })
              }
              value={dataAdd.sub_category_id}
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextFieldMUI
                  {...params}
                  style={{ fontSize: 14 }}
                  size="small"
                  placeholder="Masukkan sub kategori anda anda"
                  value={dataAdd.sub_category_id}
                />
              )}
            />
            <FormHelperText style={{ color: '#f12147' }}>
              {errorValidasi.sub_category_id}
            </FormHelperText>
            {/* <NormalText
              textAlign="start"
              fontWeight={"bold"}
              fontSize={"14px"}
              margin={"20px 0 20px 0"}
            >
              Pengaturan Pemakaian
            </NormalText> */}
            {/* <NormalText
              textAlign="start"
              fontSize={"16px"}
              margin={"0 20px 20px 0"}
            >
              Tipe Pemakaian
            </NormalText>
            <FormControl sx={{ minWidth: "100%" }}>
              <Select
                value={dataAdd.usage_type}
                onChange={(e) =>
                  setDataAdd({
                    ...dataAdd,
                    usage_type: e.target.value,
                  })
                }
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ height: 40 }}
              >
                <MenuItem value="">
                  <em>Tipe Pemakaian</em>
                </MenuItem>
                {valueType.map((value, index) => {
                  return <MenuItem value={value.value}>{value.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <FormHelperText style={{ color: "#f12147" }}>
              {errorValidasi.usage_type}
            </FormHelperText>
            <TextField
              type={"number"}
              label={"Nilai Pemakaian"}
              isRequired
              placeholder="Masukan nilai pemakaian anda"
              value={dataAdd.loyalty_usage_value}
              onChange={(event: any) =>
                setDataAdd({
                  ...dataAdd,
                  loyalty_usage_value: event.target.value,
                })
              }
              validationType={errorValidasi.loyalty_usage_value ? "ERROR" : ""}
              validationText={errorValidasi.loyalty_usage_value}
            />
            <TextField
              type={"number"}
              label={"Maksimal Nilai Pemakaian"}
              isRequired
              placeholder="Masukan maksimal nilai pemakaian anda"
              value={dataAdd.max_loyalty_usage_value}
              onChange={(event: any) =>
                setDataAdd({
                  ...dataAdd,
                  max_loyalty_usage_value: event.target.value,
                })
              }
              validationType={
                errorValidasi.max_loyalty_usage_value ? "ERROR" : ""
              }
              validationText={errorValidasi.max_loyalty_usage_value}
            /> */}
            <NormalText
              textAlign="left"
              fontWeight="bold"
              margin={'20px 0 20px 0'}
              fontSize={'14px'}
            >
              Pengaturan Cashback
            </NormalText>
            <NormalText fontSize={"12px"}>Nilai Cashback *</NormalText>
            <FormControl sx={{ minWidth: '100%' }}>
              <Select
                value={dataAdd.cashback_type}
                onChange={(e) =>
                  setDataAdd({
                    ...dataAdd,
                    cashback_type: e.target.value,
                  })
                }
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{ height: 40 }}
              >
                <MenuItem value="">
                  <em>Tipe Cashback</em>
                </MenuItem>
                {valueType.map((value, index) => {
                  return <MenuItem value={value.value}>{value.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <FormHelperText style={{ color: '#f12147' }}>
              {errorValidasi.cashback_type}
            </FormHelperText>
            <NormalText fontSize={"12px"}>Nilai Cashback *</NormalText>
            <CurrencyFormat
              customInput={TextField}
              thousandSeparator={true}
              value={dataAdd.cashback_value}
              onValueChange={(e: any) => {
                setDataAdd({
                  ...dataAdd,
                  cashback_value: e.value,
                })
              }}
            />
            {errorValidasi.cashback_value && (
              <NormalText fontSize={"12px"} color={"#f12147"}>
                {errorValidasi.cashback_value}
              </NormalText>
            )}
            <NormalText fontSize={"12px"}>Maksimal Nilai Cashback *</NormalText>
            <CurrencyFormat
              customInput={TextField}
              thousandSeparator={true}
              value={dataAdd.max_cashback_value ?? ''}
              min={0}
              onValueChange={(e: any) => {
                setDataAdd({
                  ...dataAdd,
                  max_cashback_value: e.value,
                })
              }}
            />
            {errorValidasi.max_cashback_value && (
              <NormalText fontSize={"12px"} color={"#f12147"}>
                {errorValidasi.max_cashback_value}
              </NormalText>
            )}
            <Box textAlign={'start'} margin={'20px 0 0 0 '}>
              <ButtonMUI
                variant="contained"
                onClick={() => navigate('/setting/loyaltysetting')}
                sx={{ width: 100, height: 40, marginRight: 3 }}
                color="error"
              >
                Kembali
              </ButtonMUI>
              <ButtonMUI
                variant="contained"
                onClick={() => handleValidate()}
                sx={{ width: 100, height: 40 }}
              >
                Save
              </ButtonMUI>
            </Box>
          </form>
        </Box>
      </Stack>
    </MerchantLayout>
  );
};

export default AddBrandPage;
