import React, { useState, useEffect } from "react";
import { Stack } from "../../../components/styled/layout.styled";
import UserGroups from "../layout";
import { useNavigate, useLocation } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import styles from "./styles";
import {
  Box,
  Autocomplete,
  Button as ButtonMUI,
  TextField,
} from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";
import useRedux from "src/redux/useRedux";

interface RouteState {
  data: any;
  status: string;
  role: any;
}

let temp = {
  id: null,
  name: "",
  description: "",
}

const ViewUserPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { data, status, role } = location.state as RouteState;
  const [dataAdd, setDataAdd] = useState({
    firstName: data?.first_name ?? "",
    lastName: data?.last_name ?? "",
    email: data?.email ?? "",
    role: role?? temp,
  });

  return (
    <MerchantLayout>
      <UserGroups tabActive={0}>
        <Stack direction="column">
          <Box sx={styles.boxStyled}>
            <NormalText
              textAlign="start"
              fontWeight={"bold"}
              fontSize={"18px"}
              margin={"0 0 20px 0"}
            >
              Detail User
            </NormalText>
            <form>
              <NormalText
                textAlign="left"
                margin={"20px 0 5px 0"}
                fontSize={"14px"}
              >
                First Name *
              </NormalText>
              <TextField
                id="outlined-read-only-input"
                sx={{ width: "100%" }}
                value={dataAdd.firstName}
                InputProps={{
                  readOnly: true,
                }}
              />
              <NormalText
                textAlign="left"
                margin={"20px 0 5px 0"}
                fontSize={"14px"}
              >
                Last Name *
              </NormalText>
              <TextField
                id="outlined-read-only-input"
                sx={{ width: "100%" }}
                value={dataAdd.lastName}
                InputProps={{
                  readOnly: true,
                }}
              />
              <NormalText
                textAlign="left"
                margin={"20px 0 5px 0"}
                fontSize={"14px"}
              >
                Email *
              </NormalText>
              <TextField
                id="outlined-read-only-input"
                sx={{ width: "100%" }}
                value={dataAdd.email}
                InputProps={{
                  readOnly: true,
                }}
              />
              <NormalText
                textAlign="left"
                margin={"20px 0 5px 0"}
                fontSize={"14px"}
              >
                Role *
              </NormalText>
              <TextField
                id="outlined-read-only-input"
                sx={{ width: "100%" }}
                value={dataAdd.role.name}
                InputProps={{
                  readOnly: true,
                }}
              />
              <Box textAlign={"start"} margin={"20px 0 0 0 "}>
                <ButtonMUI
                  variant="contained"
                  onClick={() => navigate("/users/list")}
                  sx={{ width: 100, height: 40, marginRight: 3 }}
                  color="primary"
                >
                  Back
                </ButtonMUI>
              </Box>
            </form>
          </Box>
        </Stack>
      </UserGroups>
    </MerchantLayout>
  );
};

export default ViewUserPage;
