import { editUserResponseProps, EditUserState} from './types'
import { createSlice } from '@reduxjs/toolkit'
import { editUser } from './userService';
// create user
export const initialState: EditUserState = {
    data: {} as editUserResponseProps,
    status: "loading",
    error: {}
}

export const editUserSlice = createSlice({
    name: 'editUser',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(editUser.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(editUser.fulfilled, (state, action) => {
            if (action.payload) {
                state.data = action.payload.data;
                state.status = action.payload.status;
                state.error = action.payload.error
            }
        })
        .addCase(editUser.rejected, (state, action) => {
            state.status = 'error';
            state.error = action.error
        })

    }
})

export const EditUserReducer = editUserSlice.reducer