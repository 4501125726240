import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import Tables from "src/components/Table/tableData";
import {
  Switch,
  IconButton,
  Grid,
  Button as ButtonMUI,
  Box,
} from "@mui/material";
import {
  Delete,
  Edit,
  Search,
  ForwardToInboxRounded,
} from "@mui/icons-material";
import UserGroups from "../layout";
import {
  getListUser,
  UserResponseProps,
  resendVerification,
} from "src/redux/user";
import { deleteUser } from "src/redux/deleteUser";
import useRedux from "src/redux/useRedux";
import PopUp from "src/components/Modal/modal";
import { NormalText } from "src/components/styled/text.styled";
import { getRole, RoleData } from "src/redux/role";
import ConfirmationModal from "src/components/Modal/confirmation";
import checkPermission from "src/helper/checkPermission";
import { toast, ToastContainer } from "react-toastify";

let temp = {
  id: null,
  name: "",
  description: "",
};

const UserListPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<UserResponseProps>({} as UserResponseProps);
  const [roles, setRoles] = React.useState<RoleData[]>([]);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [idUser, setIdUser] = useState(0);
  const [statusDel, setStatusDel] = useState("");
  const [openModalDel, setOpenModalDel] = useState(false);

  const handleDeleteUser = (id: any) => {
    setIdUser(id);
    setOpenModalDel(true);
  };

  const findRole = (data: any, id: any) => {
    let res = data.find((element: any) => element.id === id);
    return res ? res : temp;
  };

  const editViewUser = (statusUser: string, id: any, row: any) => {
    let roleFind = roles && roles.length != 0 ? findRole(roles, id) : temp;
    statusUser === "edit"
      ? navigate("/users/list/edit-user", {
          state: { data: row, status: "edit", role: roleFind },
        })
      : navigate("/users/list/view-user", {
          state: { data: row, status: "view", role: roleFind },
        });
  };

  const handleResend = (email: string) => {
    thunkDispatch(resendVerification({ email }))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          toast.success(res.data.message);
        } else if (res && res.status === "error") {
          if (res.error.response) {
            toast.error(res.error.response.data.message);
          } else {
            toast.error(res.error.message);
          }
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const columns = [
    {
      name: "Nama",
      selector: (row: any) => row.full_name,
      sortable: true,
      center: true,
    },
    {
      name: "Role",
      selector: (row: any) => row.role_name,
      center: true,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      selector: (row: any) => row.is_active,
      center: true,
      cell: (row: any) => {
        return <Switch checked={row.is_active} disabled />;
      },
    },
    {
      name: "Aksi",
      center: true,
      cell: (row: any) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="start"
          >
            {checkPermission("User", "is_edit") && !row.is_active && (
              <IconButton
                title="Kirim ulang email verifikasi akun"
                aria-label="resend"
                color="success"
                onClick={() => handleResend(row.email)}
              >
                <ForwardToInboxRounded />
              </IconButton>
            )}
            {checkPermission("User", "is_edit") && (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => editViewUser("edit", row.role_id, row)}
              >
                <Edit />
              </IconButton>
            )}
            <IconButton
              aria-label="search"
              color="warning"
              onClick={() => editViewUser("view", row.role_id, row)}
            >
              <Search />
            </IconButton>
            {checkPermission("User", "is_delete") && (
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => handleDeleteUser(row.id)}
              >
                <Delete />
              </IconButton>
            )}
          </Grid>
        );
      },
    },
  ];

  const {
    thunkDispatch,
    storeState: { Auth, User },
  } = useRedux();

  useEffect(() => {
    thunkDispatch(getRole({ limit: 0, offset: 0, filter: "" }))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setRoles(res.data.data);
        }
      })
      .catch((err) => {
        setRoles([]);
        console.error(err);
      });
  }, []);

  const handleGetList = () => {
    setLoading(true);
    let dataObject = {
      offset: offset - 1,
      limit: limit,
      filter: filter,
      access_token: Auth.data.access_token,
    };
    thunkDispatch(getListUser(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setData(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const handleDelete = () => {
    let dataObject = {
      id: idUser,
      access_token: Auth.data.access_token,
    };
    thunkDispatch(deleteUser(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setOpenModal(true);
          setOpenModalDel(false);
          setStatusDel("success");
          handleGetList();
          setLoading(false);
        } else {
          setOpenModal(true);
          setOpenModalDel(false);
          setStatusDel("failed");
          handleGetList();
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setStatusDel("failed");
        console.error(err);
      });
    setLoading(false);
  };

  useEffect(() => {
    handleGetList();
  }, [filter, offset, limit]);

  return (
    <MerchantLayout>
      <ToastContainer />
      <ConfirmationModal
        title="Konfirmasi"
        titleStyle={{ fontWeight: "bold" }}
        description="Apakah anda yakin ingin menghapus pengguna ini?"
        open={openModalDel}
        handleConfirm={() => handleDelete()}
        handleClose={() => setOpenModalDel(!openModalDel)}
      ></ConfirmationModal>
      <PopUp
        open={openModal}
        handleClose={() => {
          setOpenModal(!openModal);
          setStatusDel("");
        }}
      >
        <NormalText
          textAlign="center"
          fontWeight={"bold"}
          fontSize={"20px"}
          margin={"0 0 20px 0"}
        >
          {statusDel ? "Hapus Pengguna" : "Konfirmasi"}
        </NormalText>
        <NormalText textAlign="center" fontSize={"14px"} margin={"0 0 20px 0"}>
          {statusDel == "success"
            ? "Sukses menghapus pengguna"
            : statusDel == "failed"
            ? "Gagal menghapus pengguna"
            : "Apakah kamu yakin ingin menghapus pengguna?"}
        </NormalText>
        {statusDel ? (
          <Box textAlign={"center"} margin={"20px 0 0 0 "}>
            <ButtonMUI
              variant="contained"
              onClick={() => {
                setOpenModal(!openModal);
                setStatusDel("");
                handleGetList();
              }}
              sx={{ width: 100, height: 40, marginRight: 3 }}
              color="error"
            >
              Ok
            </ButtonMUI>
          </Box>
        ) : (
          <Box textAlign={"center"} margin={"20px 0 0 0 "}>
            <ButtonMUI
              variant="contained"
              onClick={() => {
                setOpenModal(!openModal);
                setStatusDel("");
              }}
              sx={{ width: 100, height: 40, marginRight: 3 }}
              color="error"
            >
              Tidak
            </ButtonMUI>
            <ButtonMUI
              variant="contained"
              onClick={() => handleDelete()}
              sx={{ width: 100, height: 40, marginRight: 3 }}
              color="primary"
            >
              Ya
            </ButtonMUI>
          </Box>
        )}
      </PopUp>
      <UserGroups tabActive={0}>
        <Tables
          records={data.data}
          columns={columns}
          title="List Pengguna"
          isSearch={true}
          addButton={checkPermission("User", "is_add") ? true : false}
          titleAdd={checkPermission("User", "is_add") ? "Tambah Pengguna" : ""}
          onChangeSearch={(e) => setFilter(e.target.value)}
          valueSearch={filter}
          handleAddButton={() => navigate("/users/list/add-user")}
          totalRow={data && data.meta && data.meta.total ? data.meta.total : 0}
          progressPending={loading}
          paginationPerPage={limit}
          onChangePage={(page, totalRows) => setOffset(page)}
          onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
            setLimit(currentRowsPerPage)
          }
        />
      </UserGroups>
    </MerchantLayout>
  );
};

export default UserListPage;
