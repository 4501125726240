import React from "react";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import UserGroups from "../layout";
import { Box, Checkbox, Stack, Switch } from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";
import { Button } from "src/components/styled/button.styled";
import InputField from "src/components/Inputs/InputField";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useRedux from "src/redux/useRedux";
import { getFeatures } from "src/redux/feature/feature.services";
import { createRole } from "src/redux/role/roleService";
import { CreateRoleTypes } from "src/redux/role/types";
import { toast } from "react-toastify";
import { isAuthenticated } from "src/helper/generalFunction";
import "react-toastify/dist/ReactToastify.css";

interface FeaturesProps {
  feature_id: number;
  feature_name: string;
  key: string;
  is_view: boolean;
  is_add: boolean;
  is_edit: boolean;
  is_delete: boolean;
}

const AddRolePage = () => {
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: { Auth },
  } = useRedux();

  const [payload, setPayload] = React.useState<CreateRoleTypes>({
    name: "",
    description: "",
    is_cashier: false,
    features: [],
  } as unknown as CreateRoleTypes);
  const [complete, setComplete] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [featuresEmpty, setFeaturesEmpty] = React.useState(true);
  const [features, setFeatures] = React.useState([] as FeaturesProps[]);
  const [selectAll, setSelectAll] = React.useState<boolean[]>([]);

  React.useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login");
    }
  }, []);

  const checkFeaturesEmpty = () => {
    if (
      features.find((x) => x.is_view || x.is_add || x.is_edit || x.is_delete)
    ) {
      setFeaturesEmpty(false);
      return false;
    } else {
      setFeaturesEmpty(true);
      return true;
    }
  };

  const handleSubmit = () => {
    setSubmitted(true);
    if (complete) {
      var reqPayload: any = {
        ...payload,
        key: payload.name.toLowerCase(),
        features: features,
        access_token: Auth.data.access_token,
      };
      thunkDispatch(createRole(reqPayload))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            toast.success("Berhasil menyimpan role");
            navigate("/users/roles");
          } else if (res && res.status === "error") {
            toast.error(res.error.response.data.message);
          }
        })
        .catch((err) => {
          toast.error("Gagal menyimpan role");
          console.log("Error", err);
        });
    } else {
      toast.warning("Silahkan cek kembali inputan anda");
    }
  };

  React.useEffect(() => {
    if (
      payload.name !== "" &&
      payload.description !== "" &&
      payload.name.length <= 50 &&
      payload.description.length <= 100
    ) {
      setComplete(true);
    } else {
      setComplete(false);
    }
  }, [payload]);

  React.useEffect(() => {
    thunkDispatch(getFeatures(Auth.data.access_token))
      .unwrap()
      .then((res) => {
        var temp: any = [];
        if (res && res.status === "success")
          res.data.data.forEach((item) => {
            temp.push({
              feature_id: item.id,
              feature_name: item.name,
              key: item.key,
              is_view: false,
              is_add: false,
              is_edit: false,
              is_delete: false,
            });
          });
        setFeatures(temp);
        var tempSelectAll = new Array<boolean>(temp.length).fill(false);
        setSelectAll(tempSelectAll);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <MerchantLayout>
      <UserGroups tabActive={1}>
        <Box
          sx={{
            height: "100%",
            backgroundColor: "white",
            justifyContent: "center",
            border: "0 solid rgba(0,0,0,.125)",
            borderRadius: "0.25rem",
            padding: "10px",
            boxShadow: "0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%)",
          }}
        >
          <NormalText margin="20px 0 20px 0" fontWeight="bold" fontSize="20px">
            Tambah Role Baru
          </NormalText>
          <NormalText margin="0 0 10px 0">Role *</NormalText>
          <InputField
            onChange={(e: any) =>
              setPayload({ ...payload, name: e.target.value })
            }
            validationText={
              payload.name === ""
                ? "Wajib diisi"
                : payload.name.length > 50
                ? "Maks 50 karakter nama role"
                : ""
            }
            validationType={
              (submitted && !payload.name) || payload.name.length > 50
                ? "ERROR"
                : ""
            }
          />
          <NormalText margin="10px 0 10px 0">Deskripsi singkat *</NormalText>
          <InputField
            onChange={(e: any) =>
              setPayload({ ...payload, description: e.target.value })
            }
            validationText={
              payload.description === ""
                ? "Wajib diisi"
                : payload.description.length > 100
                ? "Maks 100 karakter deksripsi singkat"
                : ""
            }
            validationType={
              (submitted && !payload.description) ||
              payload.description.length > 100
                ? "ERROR"
                : ""
            }
          />
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <NormalText>Akses POS?</NormalText>
            <Switch
              value={payload.is_cashier}
              onChange={(e) =>
                setPayload({ ...payload, is_cashier: !payload.is_cashier })
              }
            />
          </Stack>
          <Box sx={{ margin: "15px 0 20px 0" }}>
            <NormalText>Daftar Fitur diaktifkan *</NormalText>
            {featuresEmpty && !payload.is_cashier && (
              <NormalText color={"#f12147"} fontSize={"12px"}>
                Mohon memilih minimal 1 fitur
              </NormalText>
            )}
            <TableContainer sx={{ margin: "10px 0 0 0" }}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead sx={{ background: "#f1f1f1", fontWeight: "bold" }}>
                  <TableRow>
                    <TableCell>Menu</TableCell>
                    <TableCell align="right">Pilih Semua</TableCell>
                    <TableCell align="right">Lihat</TableCell>
                    <TableCell align="right">Buat</TableCell>
                    <TableCell align="right">Ubah</TableCell>
                    <TableCell align="right">Hapus</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {features.map((row, index) => (
                    <TableRow
                      key={row.feature_id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.feature_name === "Transaction"
                          ? "Balance"
                          : row.feature_name}
                      </TableCell>
                      <TableCell align="right">
                        <Checkbox
                          checked={
                            row.is_add &&
                            row.is_delete &&
                            row.is_edit &&
                            row.is_view
                              ? true
                              : selectAll[index]
                              ? selectAll[index]
                              : false
                          }
                          onClick={() => {
                            let temp = [...features];
                            let tempSelectAll = [...selectAll];
                            tempSelectAll[index] = !selectAll[index];
                            temp[index].is_add = !selectAll[index];
                            temp[index].is_edit = !selectAll[index];
                            temp[index].is_delete = !selectAll[index];
                            temp[index] = {
                              ...temp[index],
                              is_view: !selectAll[index],
                            };
                            setFeatures(temp);
                            setSelectAll(tempSelectAll);
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Checkbox
                          checked={row.is_view}
                          onClick={() => {
                            let temp = [...features];
                            temp[index] = {
                              ...temp[index],
                              is_view: !row.is_view,
                            };
                            setFeatures(temp);
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Checkbox
                          checked={row.is_add}
                          onClick={() => {
                            let temp = [...features];
                            temp[index].is_add = !row.is_add;
                            setFeatures(temp);
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Checkbox
                          checked={row.is_edit}
                          onClick={() => {
                            let temp = [...features];
                            temp[index].is_edit = !row.is_edit;
                            setFeatures(temp);
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Checkbox
                          checked={row.is_delete}
                          onClick={() => {
                            let temp = [...features];
                            temp[index].is_delete = !row.is_delete;
                            setFeatures(temp);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {submitted && !complete && !featuresEmpty && (
            <NormalText color={"#f12147"} fontSize={"12px"}>
              Form yang diisi belum lengkap
            </NormalText>
          )}
          <Button
            variant="danger"
            margin="10px 10px 0 0"
            onClick={() => navigate("/users/roles")}
          >
            Batal
          </Button>
          <Button
            variant={"primary"}
            onClick={() => {
              if (payload.is_cashier) {
                handleSubmit();
              } else {
                if (!checkFeaturesEmpty()) {
                  handleSubmit();
                } else {
                  toast.warning("Silahkan cek kembali inputan anda");
                }
              }
            }}
          >
            Simpan
          </Button>
        </Box>
      </UserGroups>
    </MerchantLayout>
  );
};

export default AddRolePage;
