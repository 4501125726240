import { createAsyncThunk } from "@reduxjs/toolkit";
import { CategoryState, CreateBrandTypes, ShowCategoryState } from "./types";
import ApiService from "src/services/ApiService";
import axios from "axios";
import { getToken } from 'src/helper/generalFunction';

export interface CategoryListProps {
    offset?: number;
    limit?: number;
    filter?: string;
}

export const getCategory = createAsyncThunk('category/get', async (props: CategoryListProps) => {
    try {
        const config = {
            headers:{
                token: getToken()
            }
        };        
        const response = await ApiService.get(`${process.env.REACT_APP_API_URL}/merchant/category/list?limit=${props.limit ?? 0}&offset=${props.offset ?? 0}${props.filter ? '&filter=' + props.filter : ''}`,config)
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as CategoryState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as CategoryState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as CategoryState;

    }
})

export interface ShowCategoryProps {
    category_id: number;
    access_token: string;
}

export const createCategory = createAsyncThunk('category/post', async (props: CreateBrandTypes) => {
    try {
        const { data } = props;
        const response = await axios.post(process.env.REACT_APP_API_URL + '/merchant/category', data, {
            headers: {
                api_key: process.env.REACT_APP_API_KEY,
                token: props.access_token,
                "Content-Type": 'application/json',
            }
        })
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as CategoryState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as CategoryState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as CategoryState;

    }
});

export const updateCategory = createAsyncThunk('category/update', async (props: CreateBrandTypes) => {
    try {
        const { data } = props;
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/merchant/category/${props.id}/update`, data, {
            headers: {
                api_key: process.env.REACT_APP_API_KEY,
                token: props.access_token,
                "Content-Type": 'application/json',
            }
        })
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as CategoryState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as CategoryState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as CategoryState;

    }
})

export interface DeleteCategoryProps {
    category_id: number;
    access_token: string;
}

export const removeCategory = createAsyncThunk('category/delete', async (props: DeleteCategoryProps) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/merchant/category/${props.category_id}/delete`, {
            headers: {
                api_key: process.env.REACT_APP_API_KEY,
                token: props.access_token,
                "Content-Type": 'application/json',
            }
        })
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as CategoryState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as CategoryState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as CategoryState;

    }
})


export interface UpdateStatusCategoryProps {
    id: number;
    access_token: string;
    data: any,
}

export const updateStatusCategory = createAsyncThunk('category/updateStatus', async (props: UpdateStatusCategoryProps) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/merchant/category/${props.id}/status`, props.data, {
            headers: {
                api_key: process.env.REACT_APP_API_KEY,
                token: props.access_token,
                "Content-Type": 'application/json',
            }
        })
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as CategoryState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as CategoryState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as CategoryState;

    }
})