// @ts-ignore: TS2786
import ReactQuill from 'react-quill';
import { styled } from '@mui/material/styles';
import { Box, SxProps, Typography } from '@mui/material';
import EditorToolbar, { formats } from './EditorToolbar';
import 'react-quill/dist/quill.snow.css'; // ES6

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  borderRadius: '5px',
  border: `solid 1px grey`,
  '&:hover': {
    border: `solid 1px black`,
  },
  '&:focus-within': {
    border: `solid 1px grey`,
  },
  '& .ql-container.ql-snow': {
    borderColor: 'transparent',
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily,
  },
  '& .ql-editor': {
    backgroundColor: 'white',
    minHeight: 200,
    '&.ql-blank::before': {
      fontStyle: 'normal',
      color: theme.palette.text.disabled,
    },
    '& pre.ql-syntax': {
      ...theme.typography.body2,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: 'white',
    },
  },
}));

// ----------------------------------------------------------------------

export interface EditorProps {
  id?: string;
  value?: any;
  label?: string;
  description?: string;
  onChange?: (content: string, delta: any, source: any, editor: any) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  simple?: boolean;
  sx?: SxProps;
  placeholder?: string;
  [key: string]: any;
}

export default function Editor({
  id = 'quill-editor',
  error,
  value,
  onChange,
  simple = false,
  helperText,
  sx,
  label,
  description,
  placeholder,
  ...other
}: EditorProps) {
  const modules = {
    toolbar: {
      container: `#${id}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <div>
      <Typography
        variant="subtitle1"
        lineHeight="36px"
        title={other?.required ? 'Required' : 'Optional'}
        fontWeight={600}
        fontSize="20px"
        ml="3px"
      >
        {label?.concat(other?.required ? '*' : '')}
      </Typography>
      {description && (
        <Typography
          variant="caption"
          lineHeight="21px"
          fontSize="12px"
          color="text.secondary"
          ml="3px"
          mb="1px"
        >
          {description}
        </Typography>
      )}
      <RootStyle
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
          }),
          ...sx,
        }}
      >
        <EditorToolbar id={id} isSimple={simple} />
        // @ts-ignore: TS2786
        <ReactQuill
          value={value ?? undefined}
          onChange={onChange}
          modules={modules}
          formats={formats}
          placeholder={placeholder}
          {...other}
        />
      </RootStyle>

      {helperText && helperText}
    </div>
  );
}
