import { useState, useEffect } from "react";
import MainLayout from "src/components/Layouts/MainLayout";
import { Grid, Stack, Box } from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";
import { Button } from "src/components/styled/button.styled";
import { useNavigate } from "react-router-dom";
import PopUp from "src/components/Modal/modal";
import styled from "styled-components";
import { AuthLoginProps } from "src/redux/auth/types";
import useRedux from "src/redux/useRedux";
import {
  PreAuthLogin,
  PreAuthForgotPassword,
  GetAccountList,
} from "src/redux/preAuth/preAuth.services";
import TextField from "src/components/Inputs/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faEnvelope } from "@fortawesome/free-solid-svg-icons";
// import LoginCover from "../../assets/media/images/login-cover.jpg";
import LoginCover from "../../assets/media/images/newBackgroundImage.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  isAuthenticated,
  validateEmail,
  getDecryptedUser,
} from "src/helper/generalFunction";
import InputPassword from "src/components/Inputs/InputPassword";

const Login = () => {
  const navigate = useNavigate();

  const {
    thunkDispatch,
    storeState: { Auth },
  } = useRedux();
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isNotRegistered, setIsNotRegistered] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [credentials, setCredentials] = useState<AuthLoginProps>({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/");
    }
  }, [Auth, thunkDispatch]);

  async function accountCheck() {
    try {
      const authLogin = await thunkDispatch(PreAuthLogin(credentials)).unwrap();
      const authLoginResponse = await authLogin;
      if (authLoginResponse.status === "error") {
        setIsLoginFailed(true);
        if (authLoginResponse.error.response) {
          setErrorMessage(authLoginResponse.error.response.data.message);
        } else {
          toast.error(authLoginResponse.error.message);
        }
        setLoading(false);
      } else {
        setIsLoginFailed(false);
        setLoading(false);
        accountList({
          token: authLoginResponse.data.access_token,
          user_type: authLoginResponse.data.user_type,
        });
      }
    } catch (error) {
      console.log("error");
      setLoading(false);
    }
  }

  async function accountList(token: any) {
    try {
      const getAccounts = await thunkDispatch(GetAccountList(token)).unwrap();
      const getAccountsResponse = await getAccounts;
      if (getAccountsResponse.accounts.status === 200) {
        if (getAccountsResponse.accounts.data.data.length) {
          setTimeout(() => {
            navigate("/choose-account");
          }, 1000);
        }
      } else {
        toast.error(getAccountsResponse.accounts.data.message);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const handleLogin = (e: any) => {
    e.preventDefault();
    setIsSubmitted(true);
    setLoading(true);
    if (
      credentials.email &&
      validateEmail(credentials.email) &&
      credentials.password.length >= 8 &&
      credentials.password
    ) {
      accountCheck();
      // thunkDispatch(AuthLogin(credentials))
      //   .unwrap()
      //   .then((res) => {
      //     if (res.status === "error") {
      //       setIsLoginFailed(true);
      //       if (res.error.response) {
      //         setErrorMessage(res.error.response.data.message);
      //       } else {
      //         toast.error(res.error.message);
      //       }
      //       setLoading(false);
      //     } else {
      //       return setTimeout(() => {
      //         setLoading(false);
      //         setIsLoginFailed(false);
      //       }, 500);
      //     }
      //   });
    }
  };

  const submitForgotPassword = (e: any) => {
    e.preventDefault();
    thunkDispatch(PreAuthForgotPassword(forgotEmail))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          toast.success(
            "Email pemulihan kata sandi telah dikirim ke email anda!"
          );
          setOpenForgotPassword(false);
        } else {
          setIsNotRegistered(true);
        }
      });
  };

  return (
    <MainLayout>
      <PopUp
        open={openForgotPassword}
        handleClose={() => setOpenForgotPassword(!openForgotPassword)}
      >
        <NormalText
          textAlign="center"
          fontWeight={"bold"}
          fontSize={"20px"}
          margin={"0 0 20px 0"}
        >
          Lupa kata sandi
        </NormalText>
        <NormalText textAlign="center" fontSize={"15px"} margin={"0 0 20px 0"}>
          Masukkan alamat email anda di bawah ini <br /> dan kami akan
          mengirimkan tautan untuk mereset <br /> kata sandi Anda
        </NormalText>
        <form onSubmit={submitForgotPassword}>
          <TextField
            IconComponent={
              <FontAwesomeIcon icon={faEnvelope} color={"#495057"} />
            }
            type={"email"}
            value={forgotEmail}
            onChange={(e: any) => setForgotEmail(e.target.value)}
            validationType={isNotRegistered ? "ERROR" : ""}
            validationText="Email tidak terdaftar"
            placeholder="Masukkan alamat email Anda"
          ></TextField>
          <Box textAlign={"center"} margin={"10px 0 0 0"}>
            <Button variant={"primary"}>Kirim Permintaan</Button>
          </Box>
        </form>
      </PopUp>
      <Box>
        <ToastContainer />
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <BoxStyled
              sx={{
                background: `url(${LoginCover}) no-repeat`,
                backgroundSize: "cover",
                height: "100vh",
              }}
            ></BoxStyled>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <BoxRight
              height="100vh"
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Stack direction={"column"} alignItems={"center"}>
                <Boxs>
                  <NormalText
                    textAlign="center"
                    fontSize="25px"
                    fontWeight="bold"
                  >
                    Login
                  </NormalText>
                  <NormalText
                    textAlign="center"
                    margin="0 0 15px 0"
                    wordBreak="break-word"
                    fontSize={"14px"}
                  >
                    Silakan masukkan informasi Anda di bawah ini untuk
                    melanjutkan
                  </NormalText>
                  {isLoginFailed && (
                    <NormalText
                      textAlign="center"
                      margin="0 0 15px 0"
                      fontSize={"15px"}
                      color={"#f12147"}
                    >
                      {/* Email atau password salah */}
                      {errorMessage}
                    </NormalText>
                  )}
                  <form onSubmit={handleLogin}>
                    <Stack direction={"column"}>
                      <TextField
                        IconComponent={
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            color={"#495057"}
                          />
                        }
                        value={credentials.email}
                        validationType={isSubmitted ? "ERROR" : ""}
                        validationText={
                          isSubmitted
                            ? credentials.email === ""
                              ? "Silahkan masukan email anda"
                              : !validateEmail(credentials.email) &&
                                credentials.email
                              ? "Silahkan masukan email yang valid"
                              : ""
                            : ""
                        }
                        onChange={(e) =>
                          setCredentials({
                            ...credentials,
                            email: e.target.value,
                          })
                        }
                        placeholder={"Email"}
                      ></TextField>
                      <TextField
                        IconComponent={
                          <FontAwesomeIcon icon={faLock} color={"#495057"} />
                        }
                        type={"password"}
                        validationText={
                          credentials.password.length < 8 &&
                          credentials.password
                            ? "Pasword minimal 8 karakter"
                            : credentials.password === ""
                            ? "Silahkan masukan password anda"
                            : ""
                        }
                        validationType={
                          isSubmitted
                            ? credentials.password.length < 8 ||
                              !credentials.password
                              ? "ERROR"
                              : ""
                            : ""
                        }
                        value={credentials.password}
                        onChange={(e) =>
                          setCredentials({
                            ...credentials,
                            password: e.target.value,
                          })
                        }
                        placeholder={"Kata sandi"}
                      ></TextField>
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <NormalText
                        onClick={() =>
                          setOpenForgotPassword(!openForgotPassword)
                        }
                        color={"#007bff"}
                        isPressable
                      >
                        Lupa kata sandi
                      </NormalText>
                      <Button
                        type="submit"
                        variant={loading ? "disabled" : "primary"}
                        disabled={loading}
                      >
                        {loading ? "Tunggu Sebentar" : "Masuk"}
                      </Button>
                    </Stack>
                  </form>
                </Boxs>
              </Stack>
            </BoxRight>
          </Grid>
        </Grid>
      </Box>
    </MainLayout>
  );
};

const Boxs = styled(Box)`
  @media only screen and (max-width: 786px) {
    width: 80%;
  }
`;

const BoxStyled = styled(Box)`
  @media only screen and (max-width: 786px) {
    height: 20vh;
  }
`;
const BoxRight = styled(Box)`
  @media only screen and (max-width: 900px) {
    height: 70vh;
    align-items: start;
    margin: 10vh 0 0 0;
  }
`;

export default Login;
