import React, { useState } from 'react';
import { Stack } from '../../../components/styled/layout.styled';
import { useNavigate, useLocation } from 'react-router-dom';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import styles from './styles';
import {
  Box,
  Button as ButtonMUI,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import { NormalText } from 'src/components/styled/text.styled';
import TextField from 'src/components/Inputs/TextField';
import useRedux from 'src/redux/useRedux';
import PopUp from 'src/components/Modal/modal';
import Dropdown, { OptionProps } from 'src/components/Inputs/Dropdown';
import { createPuplistSetup, updatePuplistSetup } from 'src/redux/puplistsetup';
import { uploadImage } from 'src/helper/uploadFile';
import styled from 'styled-components';

interface RouteState {
  data: any;
  statusUrl: string;
  dataStore: any;
}

const UploadLabel = styled.label`
  min-width: 9%;
  height: 40px;
  background-color: white;
  color: black;
  padding: 10px;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 2px;
  border: 1px solid #1985d2;
  text-align: center;
  &:hover {
    background-color: #1985d2;
    color: #fff;
  }
`;

const AddBrandPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { data, statusUrl, dataStore } = location.state as RouteState;

  const {
    thunkDispatch,
    storeState: { Auth, PuplistsSetup, StoreList },
  } = useRedux();

  const convertNamaStore = (id: number) => {
    let res =
      id && dataStore && dataStore.length !== 0
        ? dataStore.find((element: any) => element.id === id)
        : null;
    return res ? res.store_name : '';
  };

  const [openModal, setOpenModal] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const [statusModal, setStatusModal] = useState('');
  const [dataAdd, setDataAdd] = useState({
    name: statusUrl === 'edit' ? data?.image_pupoint_setup ?? '' : '',
    store: statusUrl === 'edit' ? data?.store_id ?? 0 : 0,
    store_name:
      statusUrl === 'edit' ? convertNamaStore(data?.store_id ?? null) : 0,
  });
  const [errorValidasi, setErrorValidasi] = useState({
    name: '',
    store: '',
    status: '',
  });

  const handleEditUser = () => {
    let dataObject = {
      id: data?.id ?? '',
      image_pupoint_setup: dataAdd.name,
      store_id: dataAdd.store,
    };
    thunkDispatch(updatePuplistSetup(dataObject))
      .unwrap()
      .then((res: any) => {
        if (res && res.status == 'success') {
          setOpenModal(true);
          setStatusModal('success');
        } else {
          setOpenModal(true);
          setStatusModal('failed');
        }
      })
      .catch((err: any) => {
        setStatusModal('failed');
        setOpenModal(true);
        console.error(err);
      });
  };

  const handleAddUser = () => {
    let dataObject = {
      id: '',
      image_pupoint_setup: dataAdd.name,
      store_id: dataAdd.store,
    };
    thunkDispatch(createPuplistSetup(dataObject))
      .unwrap()
      .then((res: any) => {
        if (res && res.status == 'success') {
          setOpenModal(true);
          setStatusModal('success');
        } else {
          setOpenModal(true);
          setStatusModal('failed');
        }
      })
      .catch((err: any) => {
        setStatusModal('failed');
        setOpenModal(true);
        console.error(err);
      });
  };

  const handleImage = (e: any, data: string) => {
    console.log('ASDASDASD')
    if (e.target.files && e.target.files[0]) {
      let imageName = e.target.files[0];
      let fileSize = imageName.size / (1024 * 1024);
      var img = new Image();
      img.src = window.URL.createObjectURL(imageName);
      img.onload = function () {
        var width = img.naturalWidth;
        var height = img.naturalHeight;
        var ValidHeight = data === 'name' ? 1755 : 540;
        var ValidWidth = data === 'name' ? 1080 : 960;
        console.log('fileSize', fileSize, height, width, 'valid', ValidHeight, ValidWidth)
        if (width > ValidWidth || height > ValidHeight || fileSize > 5) {
          setErrorValidasi({
            ...errorValidasi,
            [data]: `Resolusi gambar maks. ${ValidHeight}x${ValidWidth} & ukuran maks. 5MB`,
          });
        } else {
          handleUploadFile(imageName, data).then((res: any) => {
            console.log('OK');
          });
          setErrorValidasi({
            ...errorValidasi,
            [data]: '',
          });
        }
      };
    }
  };

  const handleUploadFile = async (file: any, typeUpload: string) => {
    setLoadingImg(true);
    let formdata = new FormData();
    formdata.append('image_count', '1');
    formdata.append('image-1', file);
    await uploadImage(formdata, 'pickuppoint')
      .then(res => {
        if (res.status === 200) {
          setLoadingImg(false);
          setDataAdd({
            ...dataAdd,
            [typeUpload]: res.data.data['image-1'],
          });
        }
        setLoadingImg(false);
      })
      .catch(err => {
        setLoadingImg(false);
        console.error(err);
        setErrorValidasi({
          ...errorValidasi,
          [typeUpload]: 'Gagal mengupload image',
        });
      });
  };

  const handleValidate = () => {
    if (!dataAdd.name || !dataAdd.store) {
      setErrorValidasi({
        ...errorValidasi,
        name: !dataAdd.name ? 'Image PuP Wajib Diisi' : '',
        store: !dataAdd.store ? 'Toko Wajib Diisi' : '',
        status: '',
      });
    } else {
      setErrorValidasi({
        ...errorValidasi,
        name: '',
        store: '',
        status: '',
      });
      statusUrl === 'edit' ? handleEditUser() : handleAddUser();
    }
  };

  return (
    <MerchantLayout>
      <PopUp open={openModal} handleClose={() => setOpenModal(!openModal)}>
        <NormalText
          textAlign="center"
          fontWeight={'bold'}
          fontSize={'20px'}
          margin={'0 0 20px 0'}>
          {statusModal === 'success' ? 'Sukses' : 'Gagal'}
        </NormalText>
        <NormalText textAlign="center" fontSize={'14px'} margin={'0 0 20px 0'}>
          {statusModal === 'success' ? (
            statusUrl === 'edit' ? (
              <span>Berhasil mengupdate data Pickup Point Setup</span>
            ) : (
              <span>
                Berhasil menambahkan <br /> Pickup Point Setup baru
              </span>
            )
          ) : PuplistsSetup?.error?.response?.data?.message ? (
            <span>{PuplistsSetup.error.response.data.message}</span>
          ) : statusUrl === 'edit' ? (
            <span>
              Gagal mengupate Pickup point Setup, <br /> silahkan periksa
              kembali data anda
            </span>
          ) : (
            <span>
              Gagal menambah Pickup point Setup, <br /> silahkan periksa kembali
              data anda
            </span>
          )}
        </NormalText>
        <Box textAlign={'center'} margin={'20px 0 0 0 '}>
          <ButtonMUI
            variant="contained"
            onClick={() =>
              statusModal === 'success'
                ? navigate('/stores/pickup-point-setup/list')
                : setOpenModal(!openModal)
            }
            sx={{ width: 100, height: 40, marginRight: 3 }}
            color="error">
            Oke
          </ButtonMUI>
        </Box>
      </PopUp>
      <Stack direction="column">
        <Box sx={styles.boxStyled}>
          <NormalText
            textAlign="start"
            fontWeight={'bold'}
            fontSize={'18px'}
            margin={'0 0 20px 0'}>
            {statusUrl === 'edit' ? 'Edit Pickup Point' : 'Tambah Pickup Point'}
          </NormalText>
          <form>
            <NormalText
              textAlign="left"
              margin={'20px 0 5px 0'}
              fontSize={'14px'}>
              Image Pup Setup *
            </NormalText>
            <form>
              <UploadLabel
                htmlFor="imageUpload"
                style={{
                  backgroundColor: loadingImg ? '#ccc' : '',
                  borderColor: loadingImg ? '#ccc' : '',
                }}>
                {loadingImg ? <CircularProgress size={15} /> : null}{' '}
                {loadingImg ? '' : 'Pilih Gambar'}
              </UploadLabel>
              <input
                accept="image/*"
                id="imageUpload"
                multiple
                type="file"
                onChange={(e: any) => handleImage(e, 'name')}
                style={{ display: 'none' }}
              />
              {dataAdd.name && (
                <img
                  src={dataAdd.name}
                  style={{ height: '20%', width: '20%', marginLeft: '30px' }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              )}
            </form>
            <FormHelperText style={{ color: '#1565c0' }}>
              Resolusi gambar maks. 1080x1755 Pixels {'&'} ukuran maks. 5MBdd
            </FormHelperText>
            <FormHelperText style={{ color: '#f12147' }}>
              {errorValidasi.name}
            </FormHelperText>
            <Dropdown
              label={'Toko'}
              validationType={errorValidasi.store ? 'ERROR' : ''}
              validationText={errorValidasi.store}
              isRequired
              options={
                dataStore && dataStore.length !== 0
                  ? dataStore.map((item: any) => {
                    return {
                      label: item.store_name,
                      value: item.id,
                    } as OptionProps;
                  })
                  : []
              }
              value={dataAdd.store}
              onChange={(value: number) => {
                setDataAdd({
                  ...dataAdd,
                  store: value,
                  store_name: convertNamaStore(value),
                });
              }}
            />
            <Box textAlign={'start'} margin={'20px 0 0 0 '}>
              <ButtonMUI
                variant="contained"
                onClick={() => navigate('/stores/pickup-point-setup/list')}
                sx={{ width: 100, height: 40, marginRight: 3 }}
                color="error">
                Kembali
              </ButtonMUI>
              <ButtonMUI
                variant="contained"
                onClick={() => handleValidate()}
                sx={{ width: 100, height: 40 }}>
                Save
              </ButtonMUI>
            </Box>
          </form>
        </Box>
      </Stack>
    </MerchantLayout>
  );
};

export default AddBrandPage;
