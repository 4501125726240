import React from 'react';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import {
  Box,
  Autocomplete,
  TextField,
  Stack,
  Typography,
  Button as ButtonMUI,
} from '@mui/material';
import { NormalText } from 'src/components/styled/text.styled';
import { getAllStore, ListStoreData } from 'src/redux/storelist';
import { getAllBanks, BankTypes } from 'src/redux/bank';
import { getActiveBalance } from 'src/redux/transaction';
import { createRequestWithdraw } from 'src/redux/withdraw';
import useRedux from 'src/redux/useRedux';
import CurrencyFormat from 'react-currency-format';
import TextInput from 'src/components/Inputs/TextField';
import { formatCurrency } from 'src/helper/generalFunction';
import { Button } from 'src/components/styled/button.styled';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import checkPermission from 'src/helper/checkPermission';
import Dialog from 'src/components/Modal/dialog';
import { store } from 'src/redux/store';

const RequestWithdrawPage = () => {
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: {
      Auth: {
        status,
        data: { user_type },
      },
      AppOption: { selected_store },
    },
  } = useRedux();
  const [stores, setStores] = React.useState<ListStoreData[]>();
  const [banks, setBanks] = React.useState<BankTypes[]>();
  const [submit, setSubmit] = React.useState(false);
  const [balances, setBalances] = React.useState({
    active: '',
    total_balance: '',
  });
  const [payload, setPayload] = React.useState({
    store_id: '',
    account_number: '',
    account_name: '',
    amount: '',
    bank_name: '',
  });
  const [withdrawConfirmModal, setWithdrawConfirmModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const validate = () => {
    setSubmit(true);
    if (
      payload.store_id &&
      payload.account_number &&
      payload.amount &&
      Number(payload.amount) >= 10000 &&
      Number(payload.amount) <=
        Number(
          user_type === 'merchant' ? balances.active : balances.total_balance
        )
    ) {
      return true;
    } else {
      console.log(
        'KURANG',
        balances.total_balance,
        balances.active,
        'CCC',
        user_type === 'merchant' ? balances.active : balances.total_balance,
        'AMOUNT',
        payload.amount
      );
      return false;
    }
  };

  const handleSubmit = () => {
    setLoading(true);

    if (validate()) {
      thunkDispatch(
        createRequestWithdraw({
          store_id: Number(payload.store_id),
          amount: Number(payload.amount),
          account_number: Number(payload.account_number),
        })
      )
        .unwrap()
        .then((res) => {
          if (res && res.status === 'success') {
            toast.success(res.data.message);
            setTimeout(() => {
              navigate('/finance/withdraw');
            }, 1000);
            setLoading(false);
          } else {
            console.log(res);
            setLoading(false);
          }
        });
    }
  };

  const handleModal = () => {
    setSubmit(true);
    if (validate()) {
      setWithdrawConfirmModal(true);
    } else {
      toast.error('Permintaan dibatalkan');
    }
  };

  React.useEffect(() => {
    thunkDispatch(getAllStore({ offset: 0, limit: 0 }))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          setStores(res.data.data);
        }
      });

    thunkDispatch(getAllBanks({ limit: 0, offset: 0 }))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          setBanks(
            res?.data?.data.filter((bank: any) => bank?.is_primary === true)
          );
        }
      });
  }, []);

  React.useEffect(() => {
    let store_id = '';
    if (
      status &&
      status === 'success' &&
      user_type &&
      user_type !== 'merchant'
    ) {
      setPayload({ ...payload, store_id: String(selected_store.id) });
      store_id = String(
        selected_store && selected_store.id ? selected_store.id : ''
      );
    } else {
      store_id = payload.store_id;
    }
    thunkDispatch(getActiveBalance({ store_id: store_id }))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          setBalances({
            active: res.data.data.active_balance,
            total_balance: res.data.data.total_active_balance,
          });
        }
      });
  }, [payload.store_id, selected_store]);

  React.useEffect(() => {
    if (banks && banks.length > 0) {
      setPayload({
        ...payload,
        account_name: banks[0].account_name,
        account_number: banks[0].account_number,
        bank_name: banks[0].bank_name,
      });
    }
  }, [banks]);

  return (
    <MerchantLayout>
      <Dialog open={withdrawConfirmModal}>
        <Stack direction="column" sx={{ padding: '2%' }}>
          <Typography
            textAlign={'center'}
            component={'p'}
            fontSize={'1rem'}
            fontWeight={'400'}
          >
            Apakah anda yakin ingin melakukan penarikan dana?
          </Typography>
          <Stack
            direction={'row'}
            justifyContent="center"
            spacing={2}
            margin={'1rem 0 0 0'}
          >
            <ButtonMUI
              sx={{
                textTransform: 'none',
                background: '0% 0% / 400% rgb(0, 123, 255)',
                color: '#ffffff',
                '&.Mui-disabled': {
                  background: '#85C1E9',
                  color: '#ffffff',
                },
                '&:hover': {
                  background: '#85C1E9',
                  color: '#ffffff',
                },
              }}
              onClick={handleSubmit}
              disabled={loading && submit}
            >
              Ya
            </ButtonMUI>
            <ButtonMUI
              sx={{
                textTransform: 'none',
                background: 'rgb(123, 123, 123)',
                color: '#ffffff',
                '&.Mui-disabled': {
                  background: 'rgb(123, 123, 123)',
                  color: '#ffffff',
                },
                '&:hover': {
                  background: 'rgb(123, 123, 123)',
                  color: '#ffffff',
                },
              }}
              onClick={() => setWithdrawConfirmModal(false)}
            >
              Tidak
            </ButtonMUI>
          </Stack>

          <Typography
            className="mt-3"
            component={'p'}
            fontSize={'0.8rem'}
            fontWeight={'400'}
          >
            Catatan:
          </Typography>

          <Typography component={'p'} fontSize={'0.8rem'} fontWeight={'400'}>
            Dana yang akan Anda terima adalah{' '}
            {formatCurrency(Number(payload.amount) - 10000)}
          </Typography>
        </Stack>
      </Dialog>
      <ToastContainer />
      <Box sx={{ background: 'white', borderRadius: '10px' }} padding={'20px'}>
        {checkPermission('Withdraw', 'is_add') ? (
          <Box>
            <NormalText
              fontSize={'20px'}
              fontWeight={'bold'}
              margin={'0 0 30px 0'}
            >
              Request Penarikan Dana
            </NormalText>
            <NormalText fontSize={'14px'} fontWeight={'bold'}>
              Saldo Sekarang
            </NormalText>
            <NormalText
              fontSize={'15px'}
              fontWeight={'bold'}
              margin={'10px 0 10px 0'}
            >
              {status &&
              status === 'success' &&
              user_type &&
              user_type === 'merchant'
                ? formatCurrency(Number(balances.total_balance ?? 0))
                : formatCurrency(Number(balances.active ?? 0))}
            </NormalText>
            <NormalText fontSize={'14px'} fontWeight={'bold'}>
              Notes:
            </NormalText>
            <ol>
              <li>Minimal penarikan dana adalah Rp. 20.000</li>
              <li>Biaya penarikan untuk Bank Lain sebesar Rp. 10.000</li>
              <li>Penarikan dana akan diproses maksimal 1x24 jam</li>
            </ol>
            <Box>
              {status &&
                status === 'success' &&
                user_type &&
                user_type === 'merchant' && (
                  <>
                    <NormalText fontSize={'12px'} margin={'0 0 5px 0'}>
                      Pilih Toko*
                    </NormalText>
                    <Autocomplete
                      id="combo-box-store-list"
                      options={stores && stores.length > 0 ? stores : []}
                      getOptionLabel={(option) => option.store_name || ''}
                      onChange={(event: any, value: any) =>
                        setPayload({ ...payload, store_id: value.id })
                      }
                      disableClearable
                      sx={{ width: '100%' }}
                      defaultChecked
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ fontSize: 12 }}
                          size="small"
                          placeholder="Pilih Toko"
                          value={payload.store_id}
                          defaultValue={payload.store_id}
                        />
                      )}
                    />
                    {submit && !payload.store_id && (
                      <NormalText color={'red'} fontSize={'10px'}>
                        Toko wajib diisi
                      </NormalText>
                    )}
                  </>
                )}
              <NormalText fontSize={'12px'} margin={'10px 0 5px 0'}>
                Nomor Rekening*
              </NormalText>
              <TextField
                style={{ fontSize: 12 }}
                sx={{ width: '100%' }}
                size="small"
                value={
                  payload.bank_name +
                  ' - ' +
                  payload.account_number +
                  ' - ' +
                  payload.account_name
                }
                disabled
              />
              {/* <Autocomplete
                id="combo-box-store-list"
                options={banks && banks.length > 0 ? banks : []}
                getOptionLabel={(option) =>
                  `${option.bank_name} - ${option.account_number} - ${option.account_name}` ||
                  ""
                }
                onChange={(event: any, value: any) =>
                  setPayload({
                    ...payload,
                    account_number: value.account_number,
                  })
                }
                disableClearable
                sx={{ width: "100%" }}
                defaultChecked
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ fontSize: 12 }}
                    size="small"
                    placeholder="Pilih Bank"
                    value={payload.account_number}
                    defaultValue={payload.account_number}
                  />
                )}
              /> */}
              {submit && !payload.account_number && (
                <NormalText color={'red'} fontSize={'10px'}>
                  Nomor rekening wajib diisi
                </NormalText>
              )}
              <NormalText fontSize={'12px'} margin={'10px 0 5px 0'}>
                Nominal (Rp)*
              </NormalText>
              <CurrencyFormat
                thousandSeparator
                customInput={TextInput}
                onValueChange={(e: any) => {
                  setPayload({ ...payload, amount: e.value });
                }}
              />
              {Number(payload.amount) >
              Number(
                user_type === 'merchant'
                  ? balances.total_balance
                  : balances.active
              ) ? (
                <NormalText color={'red'} fontSize={'10px'}>
                  Nominal tidak boleh melebihi saldo
                </NormalText>
              ) : submit && Number(payload.amount) < 10000 ? (
                <NormalText color={'red'} fontSize={'10px'}>
                  Minimal penarikan dana Rp. 10.000
                </NormalText>
              ) : submit && !payload.amount ? (
                <NormalText color={'red'} fontSize={'10px'}>
                  Silahkan isi nominal
                </NormalText>
              ) : (
                <></>
              )}
              <Stack direction={'row'} spacing={2} margin={'10px 0 0 0'}>
                <Button
                  variant="danger"
                  onClick={() => navigate('/finance/withdraw')}
                >
                  Batal
                </Button>
                <Button
                  variant="primary"
                  onClick={handleModal}
                  disabled={loading && submit}
                >
                  Buat Permintaan
                </Button>
              </Stack>
            </Box>
          </Box>
        ) : (
          <NormalText margin={'40px 0'} textAlign={'center'}>
            Kamu tidak mempunyai akses!
          </NormalText>
        )}
      </Box>
    </MerchantLayout>
  );
};

export default RequestWithdrawPage;
