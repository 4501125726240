import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import Tables from "src/components/Table/tableData";
import {
  Switch,
  IconButton,
  Grid,
  Button as ButtonMUI,
  Box,
  FormControl,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import { Delete, Edit, Search } from "@mui/icons-material";
import {
  DeletePuplistProps,
  getPuplist,
  removePuplist,
  PuplistData,
  UpdateStatusProps,
  updateStatus,
} from "src/redux/puplist";
import useRedux from "src/redux/useRedux";
import PopUp from "src/components/Modal/modal";
import { NormalText } from "src/components/styled/text.styled";
import ConfirmationModal from "src/components/Modal/confirmation";
import { getStore } from "./getStore";
import { ListStoreData } from "src/redux/storelist";
import checkPermission from "src/helper/checkPermission";

const statusFilter = [
  {
    id: 1,
    label: "Aktif",
  },
  {
    id: 0,
    label: "Non Aktif",
  },
];

const UserListPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<PuplistData[]>([]);
  const [dataFirst, setDataFirst] = useState<PuplistData[]>([]);
  const [offset, setOffset] = useState(1);
  const [storeId, setStoreId] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [idUser, setIdUser] = useState(0);
  const [statusDel, setStatusDel] = useState("");
  const [status, setStatus] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [update, setUpdate] = useState(false);
  const [listStore, setListStore] = useState<ListStoreData[]>([]);

  const handleDeleteUser = (id: any) => {
    setIdUser(id);
    setOpenConfirmation(true);
  };

  const convertNamaStore = (id: number) => {
    let res =
      listStore && listStore.length
        ? listStore.find((element: any) => element.id === id)
        : null;
    return res ? res.store_name : "";
  };

  const columns = [
    {
      name: "Nama",
      selector: (row: any) => row.pupoint_name,
      sortable: true,
      center: true,
    },
    {
      name: "Toko",
      selector: (row: any) => convertNamaStore(row.store_id),
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      selector: (row: any) => row.is_active,
      center: true,
      cell: (row: any) => {
        return (
          <Switch
            checked={row.is_active}
            onChange={(e: any) => handleUpdate(e.target.checked, row.id)}
            disabled={checkPermission("Pickup Point", "is_edit") ? false : true}
          />
        );
      },
    },
    {
      name: "Action",
      center: true,
      cell: (row: any) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {checkPermission("Pickup Point", "is_edit") && (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  console.log("EDIT ROW", row);
                  navigate("/stores/pickup-point-list/edit-pickup-point", {
                    state: {
                      data: row,
                      statusUrl: "edit",
                      dataStore: listStore,
                    },
                  });
                }}
              >
                <Edit />
              </IconButton>
            )}
            <IconButton
              aria-label="search"
              color="warning"
              onClick={() => {
                navigate("/stores/pickup-point-list/view-pickup-point", {
                  state: { data: row, status: "view", dataStore: listStore },
                });
              }}
            >
              <Search />
            </IconButton>
            {checkPermission("Pickup Point", "is_delete") && (
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => handleDeleteUser(row.id)}
              >
                <Delete />
              </IconButton>
            )}
          </Grid>
        );
      },
    },
  ];

  const {
    thunkDispatch,
    storeState: { Auth, Auth: { data: { user_type } }, Brand, AppOption: { selected_store } },
  } = useRedux();

  const getAll = () => {
    setLoading(true);
    let storeIdParam = Number(storeId ?? "");
    if (
      Auth && Auth.status &&
      Auth.status === "success" &&
      user_type &&
      user_type !== "merchant"
    ) {
      storeIdParam = Number(
        selected_store && selected_store.id ? selected_store.id : ""
      );
    }
    let dataObject = {
      offset: offset - 1,
      limit: limit,
      filter: filter,
      storeId: storeIdParam
    };
    thunkDispatch(getPuplist(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setData(res.data.data);
          setDataFirst(res.data.data);
          setTotalData(
            res.data && res.data.meta && res.data.meta.total
              ? res.data.meta.total
              : 0
          );
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDelete = () => {
    let dataDelete = {
      id: idUser,
      access_token: Auth.data.access_token,
    };
    thunkDispatch(removePuplist(dataDelete as DeletePuplistProps))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setOpenConfirmation(!openConfirmation);
          setOpenModal(true);
          getAll();
          setStatusDel("success");
          setLoading(false);
        } else {
          setOpenConfirmation(!openConfirmation);
          setLoading(false);
          setOpenModal(true);
          setStatusDel("failed");
        }
      })
      .catch((err) => {
        setOpenConfirmation(!openConfirmation);
        setLoading(false);
        setStatusDel("failed");
        console.error(err);
      });
    setLoading(false);
  };

  const getAllStore = async () => {
    await getStore(Auth.data.access_token).then((res: any) => {
      if (res.status === 200) {
        setListStore(res.data.data);
        if (res.data.data.length > 0) {
          setStoreId(res.data.data[0].id);
        }
      }
    });
  };

  useEffect(() => {
    getAll();
  }, [storeId, selected_store]);

  useEffect(() => {
    getAllStore();
  }, []);

  useEffect(() => {
    getAll();
  }, [filter, limit, offset]);

  const Filter = (val: any) => {
    let tempVal = val !== "" ? (val == 1 ? true : false) : null;
    let res =
      tempVal !== null && dataFirst.length != 0
        ? dataFirst.filter((word: any) => word.is_active == tempVal)
        : dataFirst;
    setData(res);
    setStatus(val);
  };

  const handleUpdate = (val: boolean, id: number) => {
    let dataUpdate = {
      id: id,
      access_token: Auth.data.access_token,
      data: {
        is_active: val,
      },
    };
    thunkDispatch(updateStatus(dataUpdate as UpdateStatusProps))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setUpdate(true);
          getAll();
          setStatusDel("success");
          setLoading(false);
        } else {
          setLoading(false);
          setUpdate(true);
          setStatusDel("failed");
        }
      })
      .catch((err) => {
        setLoading(false);
        setUpdate(true);
        setStatusDel("failed");
        console.error(err);
      });
    setLoading(false);
  };

  return (
    <MerchantLayout>
      <ConfirmationModal
        title="Konfirmasi"
        titleStyle={{ fontWeight: "bold" }}
        description="Apakah anda yakin ingin menghapus Pickup Point ini?"
        open={openConfirmation}
        handleConfirm={() => handleDelete()}
        handleClose={() => setOpenConfirmation(!openConfirmation)}
      ></ConfirmationModal>
      <PopUp
        open={openModal || update}
        handleClose={() => {
          setOpenModal(false);
          setStatusDel("");
          setUpdate(false);
        }}
      >
        <NormalText
          textAlign="center"
          fontWeight={"bold"}
          fontSize={"20px"}
          margin={"0 0 20px 0"}
        >
          {update ? "Update Pickup Point" : "Hapus Pickup Point"}
        </NormalText>
        <NormalText textAlign="center" fontSize={"14px"} margin={"0 0 20px 0"}>
          {statusDel == "success" ? (
            `Sukses ${update ? "mengubah status" : "menghapus"} Pickup Point`
          ) : Brand?.error?.response?.data?.message ? (
            <span>{Brand.error.response.data.message}</span>
          ) : (
            `Gagal ${update ? "mengubah status" : "menghapus"} Pickup Point`
          )}
        </NormalText>
        <Box textAlign={"center"} margin={"20px 0 0 0 "}>
          <ButtonMUI
            variant="contained"
            onClick={() => {
              setOpenModal(false);
              setStatusDel("");
              setUpdate(false);
            }}
            sx={{ width: 100, height: 40, marginRight: 3 }}
            color="error"
          >
            Ok
          </ButtonMUI>
        </Box>
      </PopUp>
      <Tables
        records={data}
        columns={columns}
        title="Daftar Pickup Point"
        isSearch={true}
        addButton={checkPermission("Pickup Point", "is_add")}
        titleAdd="Tambah Pickup Point"
        onChangeSearch={(e) => setFilter(e.target.value)}
        valueSearch={filter}
        handleAddButton={() =>
          navigate("/stores/pickup-point-list/add-pickup-point", {
            state: { data: null, statusUrl: "add", dataStore: listStore },
          })
        }
        progressPending={loading}
        paginationPerPage={limit}
        totalRow={totalData}
        onChangePage={(page) => setOffset(page)}
        onChangeRowsPerPage={(currentRowsPerPage) =>
          setLimit(currentRowsPerPage)
        }
        filter={
          <Grid container spacing={2}>
            {user_type && user_type === "merchant" && (
              <Grid item>
                <Stack direction={'row'} alignItems="center" spacing={2}>
                  <NormalText textAlign="start" fontSize={"16px"}>
                    Toko
                  </NormalText>
                  <FormControl sx={{ minWidth: 250 }}>
                    <Select
                      value={storeId}
                      onChange={(e) => setStoreId(Number(e.target.value))}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{ height: 40 }}
                    >
                      <MenuItem value="">
                        <em>Pilih Toko</em>
                      </MenuItem>
                      {listStore &&
                        listStore.length !== 0 &&
                        listStore.map((value, index) => {
                          return (
                            <MenuItem value={value.id}>{value.store_name}</MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
            )}
            <Grid item>
              <Stack direction={'row'} alignItems="center" spacing={2}>
                <NormalText
                  textAlign="start"
                  fontSize={"16px"}
                >
                  Status
                </NormalText>
                <FormControl sx={{ minWidth: 250 }}>
                  <Select
                    value={status}
                    onChange={(e) => Filter(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ height: 40 }}
                  >
                    <MenuItem value="">
                      <em>Pilih Status</em>
                    </MenuItem>
                    {statusFilter.map((value, index) => {
                      return <MenuItem value={value.id}>{value.label}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        }
      />
    </MerchantLayout>
  );
};

export default UserListPage;
