import { AppSettingState, AppSettingResponseProps } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { getAppSetting, createAppSetting, updateAppSetting } from './appsettingServices';

export const initialState: AppSettingState = {
    data: {} as AppSettingResponseProps,
    status: "loading",
    error: {}
}

export const AppSettingSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAppSetting.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getAppSetting.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(getAppSetting.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(createAppSetting.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(createAppSetting.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(createAppSetting.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(updateAppSetting.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(updateAppSetting.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(updateAppSetting.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
    }
})

export const AppSettingReducer = AppSettingSlice.reducer