import { createAsyncThunk } from "@reduxjs/toolkit";
import { SequenceState, updateSequenceProps } from "./types";
import axios from "axios";

export interface ListSequenceProps {
   access_token: string;
}

export const getList = createAsyncThunk('categories/sequence', async (props: ListSequenceProps) => {
    const config = {
        headers:{
            api_key: 'Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e',
            token: props.access_token
        }
      };
    try {
        const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/merchant/category/sequencelist`,
                config
            );
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as SequenceState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as SequenceState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as SequenceState;

    }
})

export const updateList = createAsyncThunk('categories/sequence/update', async (props: updateSequenceProps) => {
    const config = {
        headers:{
            api_key: 'Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e',
            token: props.access_token
        }
      };
    try {
        const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/merchant/category/sequence`,
                props.data,
                config
            );
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as SequenceState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as SequenceState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as SequenceState;

    }
})