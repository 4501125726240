import { useState } from "react";
import { Stack } from "../../../components/styled/layout.styled";
import { useNavigate, useLocation } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import styles from "./styles";
import {
  Box,
  Button as ButtonMUI,
  FormHelperText,
  Switch,
  CircularProgress,
} from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";
import TextField from "src/components/Inputs/TextField";
import { createBrand, updateBrand } from "src/redux/brands";
import useRedux from "src/redux/useRedux";
import { CreateBrandTypes } from "src/redux/brands";
import { uploadImage } from "src/helper/uploadFile";
import ConfirmationModal from "src/components/Modal/confirmation";
import styled from "styled-components";

interface RouteState {
  data: any;
  statusUrl: string;
}

const UploadLabel = styled.label`
  min-width: 9%;
  height: 40px;
  background-color: white;
  color: black;
  padding: 10px;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 2px;
  border: 1px solid #1985d2;
  text-align: center;
  &:hover {
    background-color: #1985d2;
    color: #fff;
  }
`;

const AddBrandPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { data, statusUrl } = location.state as RouteState;
  const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState("");
  const [imagePayload, setImagePayload] = useState({
    icon_web: "",
    icon_mobile: "",
    imageWeb: "",
    imageMobile: "",
  });
  const [loadingImg, setLoadingImg] = useState({
    icon_web: false,
    icon_mobile: false,
    imageWeb: false,
    imageMobile: false,
  });
  const [dataAdd, setDataAdd] = useState({
    name: statusUrl === "edit" ? data?.brand_name ?? "" : "",
    imageWeb: statusUrl === "edit" ? data?.image_web ?? "" : "",
    imageMobile: statusUrl === "edit" ? data?.image_mobile ?? "" : "",
    icon_mobile: statusUrl === "edit" ? data?.icon_mobile ?? "" : "",
    icon_web: statusUrl === "edit" ? data?.icon_mobile ?? "" : "",
    status: statusUrl === "edit" ? data?.is_active : true,
    brandType: statusUrl === "edit" ? data?.brand_type : "",
    brandDescription: statusUrl === "edit" ? data?.brand_description : "",
  });
  const [loading, setLoading] = useState(true);
  const [errorValidasi, setErrorValidasi] = useState({
    name: "",
    imageWeb: "",
    imageMobile: "",
    status: "",
    brand_type: "",
    brand_description: "",
    icon_web: "",
    icon_mobile: "",
  });

  const {
    thunkDispatch,
    storeState: { Auth, Brand },
  } = useRedux();

  const handleEditBrand = () => {
    let dataObject: CreateBrandTypes = {
      id: data?.id ?? "",
      brand_name: dataAdd.name,
      brand_description: dataAdd.brandDescription,
      brand_type: dataAdd.brandType,
      is_active: dataAdd.status,
      image_web: dataAdd.imageWeb,
      image_mobile: dataAdd.imageMobile,
      icon_web: dataAdd.icon_web,
      icon_mobile: dataAdd.icon_mobile,
      access_token: Auth.data.access_token,
    };
    thunkDispatch(updateBrand(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("success");
        } else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("failed");
        }
      })
      .catch((err) => {
        setLoading(false);
        setStatusModal("failed");
        setOpenModal(true);
        console.error(err);
      });
    console.log(Brand);
    setLoading(false);
  };

  const handleAddNewBrand = () => {
    let dataObject = {
      id: "",
      brand_name: dataAdd.name,
      is_active: true,
      brand_type: dataAdd.brandType,
      brand_description: dataAdd.brandDescription,
      image_web: dataAdd.imageWeb,
      image_mobile: dataAdd.imageMobile,
      icon_web: dataAdd.icon_web,
      icon_mobile: dataAdd.icon_mobile,
      access_token: Auth.data.access_token,
    };
    thunkDispatch(createBrand(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("success");
        } else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("failed");
        }
      })
      .catch((err) => {
        setLoading(false);
        setStatusModal("failed");
        setOpenModal(true);
        console.error(err);
      });
    console.log(Brand);
    setLoading(false);
  };

  const handleValidate = () => {
    if (
      !dataAdd.name ||
      dataAdd.name.length > 50 ||
      !dataAdd.brandType ||
      dataAdd.brandType.length > 50 ||
      !dataAdd.brandDescription
    ) {
      setErrorValidasi({
        ...errorValidasi,
        name: !dataAdd.name
          ? "Nama Brand Wajib Diisi"
          : dataAdd.name.length > 50
          ? "Maksimum karakter untuk nama brand adalah 50 karakter"
          : "",
        brand_type: !dataAdd.brandType
          ? "Tipe Brand Wajib Diisi"
          : dataAdd.brandType.length > 50
          ? "Maksimum karakter untuk tipe brand adalah 50 karakter"
          : "",
        brand_description: !dataAdd.brandDescription
          ? "Deskripsi Brand Wajib Diisi"
          : dataAdd.brandDescription.length > 50
          ? "Maksimum karakter untuk deskripsi singkat adalah 50 karakter"
          : "",
      });
    } else if (dataAdd.brandDescription.length > 50) {
      setErrorValidasi({
        ...errorValidasi,
        name: "",
        imageWeb: "",
        brand_description:
          "Maksimum karakter untuk deskripsi singkat adalah 50 karakter",
        imageMobile: "",
        icon_web: "",
        icon_mobile: "",
      });
    } else {
      setErrorValidasi({
        ...errorValidasi,
        name: "",
        imageWeb: "",
        imageMobile: "",
        icon_web: "",
        icon_mobile: "",
        status: "",
      });
      statusUrl === "edit" ? handleEditBrand() : handleAddNewBrand();
    }
  };

  const handleUploadFile = async (file: any, typeName: string) => {
    let formdata = new FormData();
    setLoadingImg({
      ...loadingImg,
      [typeName]: true,
    });
    formdata.append("image_count", "1");
    formdata.append("image-1", file);
    await uploadImage(formdata, "brand")
      .then((res) => {
        if (res.status === 200) {
          setLoadingImg({
            ...loadingImg,
            [typeName]: false,
          });
          setDataAdd({
            ...dataAdd,
            [typeName]: res.data.data["image-1"],
          });
        }
        setLoadingImg({
          ...loadingImg,
          [typeName]: false,
        });
      })
      .catch((err) => {
        setLoadingImg({
          ...loadingImg,
          [typeName]: false,
        });
        console.error(err);
        setErrorValidasi({
          ...errorValidasi,
          [typeName]: "Gagal mengupload image",
        });
      });
  };

  const handleImage = async (e: any, data: string) => {
    if (e.target.files && e.target.files[0]) {
      let imageName = e.target.files[0];
      var img = new Image();
      img.src = window.URL.createObjectURL(e.target.files[0]);
      let fileSize = imageName.size / (1024 * 1024);
      img.onload = function () {
        var width = img.naturalWidth;
        var height = img.naturalHeight;
        var ValidHeight = 0;
        var ValidWidth = 0;
        switch (data) {
          case "imageWeb":
            ValidHeight = 1080;
            ValidWidth = 1755;
            break;
          case "imageMobile":
            ValidHeight = 540;
            ValidWidth = 960;
            break;
          case "icon_web":
            ValidHeight = 1000;
            ValidWidth = 1000;
            break;
          case "icon_mobile":
            ValidHeight = 300;
            ValidWidth = 300;
            break;
        }

        if (width > ValidWidth || height > ValidHeight || fileSize > 5) {
          setErrorValidasi({
            ...errorValidasi,
            [data]: `Resolusi gambar maks. ${ValidWidth}x${ValidHeight} Pixels dan ukuran file maks. 5MB`,
          });
        } else {
          handleUploadFile(imageName, data).then((r) => {
            console.info("OK", r);
            console.info("Name", data);
          });

          setImagePayload({
            ...imagePayload,
            [data]: e.target.files[0],
          });
          setErrorValidasi({
            ...errorValidasi,
            [data]: "",
          });
        }
      };
    }
  };

  return (
    <MerchantLayout>
      <ConfirmationModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleConfirm={() =>
          statusModal === "success"
            ? navigate("/products/brands")
            : setOpenModal(!openModal)
        }
        title={statusModal === "success" ? "Sukses" : "Gagal"}
        description={
          statusModal === "success"
            ? statusUrl === "edit"
              ? "Berhasil mengupdate brand"
              : "Berhasil menambahkan brand baru"
            : Brand?.error?.response?.data?.message
            ? Brand.error.response.data.message
            : statusUrl === "edit"
            ? "Gagal mengupate brand, silahkan periksa kembali data anda"
            : "Gagal menambah brand, silahkan periksa kembali data anda"
        }
        hasCancel={false}
      />
      <Stack direction="column">
        <Box sx={styles.boxStyled}>
          <NormalText
            textAlign="start"
            fontWeight={"bold"}
            fontSize={"18px"}
            margin={"0 0 20px 0"}
          >
            {statusUrl === "edit" ? "Edit Brands" : "Tambah Brands"}
          </NormalText>
          <form>
            <TextField
              type={"text"}
              label={"Nama Brand"}
              isRequired
              value={dataAdd.name}
              onChange={(event: any) =>
                setDataAdd({
                  ...dataAdd,
                  name: event.target.value,
                })
              }
              validationType={errorValidasi.name ? "ERROR" : ""}
              validationText={errorValidasi.name}
              placeholder="Masukkan nama brand anda"
            />
            <TextField
              type={"text"}
              label={"Tipe Brand"}
              isRequired
              value={dataAdd.brandType}
              onChange={(event: any) =>
                setDataAdd({
                  ...dataAdd,
                  brandType: event.target.value,
                })
              }
              validationType={errorValidasi.brand_type ? "ERROR" : ""}
              validationText={errorValidasi.brand_type}
              placeholder="Masukan tipe brand anda"
            />
            <TextField
              type={"text"}
              label={"Deskripsi Singkat"}
              isRequired
              placeholder="Masukan deskripsi singkat brand anda"
              value={dataAdd.brandDescription}
              onChange={(event: any) =>
                setDataAdd({
                  ...dataAdd,
                  brandDescription: event.target.value,
                })
              }
              validationType={errorValidasi.brand_description ? "ERROR" : ""}
              validationText={errorValidasi.brand_description}
            />
            <NormalText
              textAlign="left"
              fontWeight="bold"
              margin={"20px 0 5px 0"}
              fontSize={"18px"}
            >
              Ikon Brand
            </NormalText>
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"12px"}
            >
              Gambar Web
            </NormalText>
            <form>
              <UploadLabel
                htmlFor="imageUploadWebBrand"
                style={{
                  backgroundColor: loadingImg.icon_web ? "#ccc" : "",
                  borderColor: loadingImg.icon_web ? "#ccc" : "",
                }}
              >
                {loadingImg.icon_web ? <CircularProgress size={15} /> : null}{" "}
                {loadingImg.icon_web ? "" : "Pilih Gambar"}
              </UploadLabel>
              <input
                accept="image/*"
                id="imageUploadWebBrand"
                multiple
                type="file"
                onChange={(e: any) => {
                  handleImage(e, "icon_web").then((r: any) => {
                    console.log("OK");
                  });
                }}
                style={{ display: "none" }}
              />
              {dataAdd.icon_web && (
                <img
                  src={dataAdd.icon_web}
                  style={{ height: 200, width: 200, marginLeft: "30px" }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              )}
            </form>
            <FormHelperText style={{ color: "#1565c0" }}>
              Resolusi gambar maks. 1000x1000 Pixels dan ukuran file maks. 5MB
            </FormHelperText>
            <FormHelperText style={{ color: "#f12147" }}>
              {errorValidasi.icon_web}
            </FormHelperText>
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"12px"}
            >
              Gambar Mobile
            </NormalText>
            <form>
              <UploadLabel
                htmlFor="imageUploadMobileBrand"
                style={{
                  backgroundColor: loadingImg.icon_mobile ? "#ccc" : "",
                  borderColor: loadingImg.icon_mobile ? "#ccc" : "",
                }}
              >
                {loadingImg.icon_mobile ? <CircularProgress size={15} /> : null}{" "}
                {loadingImg.icon_mobile ? "" : "Pilih Gambar"}
              </UploadLabel>
              <input
                accept="image/*"
                id="imageUploadMobileBrand"
                multiple
                type="file"
                style={{ display: "none" }}
                onChange={(e: any) => {
                  handleImage(e, "icon_mobile").then((r: any) => {
                    console.log("Ok");
                  });
                }}
              />
              {dataAdd.icon_mobile && (
                <img
                  src={dataAdd.icon_mobile}
                  style={{ height: 200, width: 200, marginLeft: "30px" }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              )}
            </form>
            <FormHelperText style={{ color: "#1565c0" }}>
              Resolusi gambar maks. 300x300 Pixels dan ukuran file maks. 5MB
            </FormHelperText>
            <FormHelperText style={{ color: "#f12147" }}>
              {errorValidasi.icon_mobile}
            </FormHelperText>
            <NormalText
              textAlign="left"
              fontWeight="bold"
              margin={"20px 0 5px 0"}
              fontSize={"18px"}
            >
              Banner Brand
            </NormalText>
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"12px"}
            >
              Gambar Web
            </NormalText>
            <form>
              <UploadLabel
                htmlFor="imageUploadWebBanner"
                style={{
                  backgroundColor: loadingImg.imageWeb ? "#ccc" : "",
                  borderColor: loadingImg.imageWeb ? "#ccc" : "",
                }}
              >
                {loadingImg.imageWeb ? <CircularProgress size={15} /> : null}{" "}
                {loadingImg.imageWeb ? "" : "Pilih Gambar"}
              </UploadLabel>
              <input
                accept="image/*"
                id="imageUploadWebBanner"
                multiple
                type="file"
                onChange={(e: any) => handleImage(e, "imageWeb")}
                style={{ display: "none" }}
              />
              {dataAdd.imageWeb && (
                <img
                  src={dataAdd.imageWeb}
                  style={{ height: 130, width: 340, marginLeft: "30px" }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              )}
            </form>
            <FormHelperText style={{ color: "#1565c0" }}>
              Resolusi gambar maks. 1755x1080 Pixels dan ukuran file maks. 5MB
            </FormHelperText>
            <FormHelperText style={{ color: "#f12147" }}>
              {errorValidasi.imageWeb}
            </FormHelperText>
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"12px"}
            >
              Gambar Mobile
            </NormalText>
            <form>
              <UploadLabel
                htmlFor="imageUploadMobileBanner"
                style={{
                  backgroundColor: loadingImg.imageMobile ? "#ccc" : "",
                  borderColor: loadingImg.imageMobile ? "#ccc" : "",
                }}
              >
                {loadingImg.imageMobile ? <CircularProgress size={15} /> : null}{" "}
                {loadingImg.imageMobile ? "" : "Pilih Gambar"}
              </UploadLabel>
              <input
                accept="image/*"
                id="imageUploadMobileBanner"
                multiple
                type="file"
                onChange={(e: any) => handleImage(e, "imageMobile")}
                style={{ display: "none" }}
              />
              {dataAdd.imageMobile && (
                <img
                  src={dataAdd.imageMobile}
                  style={{ height: 100, width: 200, marginLeft: "30px" }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              )}
            </form>
            <FormHelperText style={{ color: "#1565c0" }}>
              Resolusi gambar maks. 960x540 Pixels dan ukuran file maks. 5MB
            </FormHelperText>
            <FormHelperText style={{ color: "#f12147" }}>
              {errorValidasi.imageMobile}
            </FormHelperText>
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Status *
            </NormalText>
            <Switch
              checked={dataAdd.status}
              onChange={(e: any) =>
                setDataAdd({
                  ...dataAdd,
                  status: e.target.checked,
                })
              }
            />
            <FormHelperText style={{ color: "#f12147" }}>
              {errorValidasi.status}
            </FormHelperText>
            <Box textAlign={"start"} margin={"20px 0 0 0 "}>
              <ButtonMUI
                variant="contained"
                onClick={() => navigate("/products/brands")}
                sx={{ width: 100, height: 40, marginRight: 3 }}
                color="error"
              >
                Kembali
              </ButtonMUI>
              <ButtonMUI
                variant="contained"
                onClick={() => handleValidate()}
                sx={{ width: 100, height: 40 }}
              >
                Save
              </ButtonMUI>
            </Box>
          </form>
        </Box>
      </Stack>
    </MerchantLayout>
  );
};

export default AddBrandPage;
