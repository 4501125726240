import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuthState, AuthLoginProps, AuthData } from "./types";
import axios from "axios";

export const AuthLogin = createAsyncThunk(
  "auth/login",
  async (props: AuthLoginProps) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/merchants/session/login`,
        {
          email: props.email,
          password: props.password,
          device_model: "WEB",
          device_type: "WEB",
          device_id: 1,
        },
        {
          headers: {
            api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
          },
        }
      );

      if (response && response.data) {
        return {
          data: {
            ...response.data?.data,
            isAuthenticated: true,
          } as AuthData,
          status: "success",
          error: null,
        } as AuthState;
      } else {
        return {
          data: "Wrong email or password",
          status: "error",
          error: null,
        } as unknown as AuthState;
      }
    } catch (err) {
      return {
        data: "Something went wrong!",
        status: "error",
        error: err,
      } as unknown as AuthState;
    }
  }
);

export const AuthLogout = createAsyncThunk("auth/logout", async () => {
  return {
    data: "Logged out!",
    status: "error",
    error: null,
  } as unknown as AuthState;
});

export const AuthForgotPassword = createAsyncThunk(
  "auth/forgot-password",
  async (email: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/merchants/session/forgot_password`,
        {
          email: email,
        },
        {
          headers: {
            api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
          },
        }
      );

      if (response && response.status === 200) {
        return {
          data: {
            vaidEmail: true,
          },
          status: "success",
          error: null,
        } as unknown as AuthState;
      }
    } catch (err) {
      return {
        data: "Something went wrong!",
        status: "error",
        error: err,
      } as unknown as AuthState;
    }
  }
);

export const AuthLoginStep2 = createAsyncThunk(
  "auth/login_v2/step_2",
  async (props: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/merchants/session/login_v2/step_2`,
        {
          email: props.email,
          id: props.id,
          device_model: "WEB",
          device_type: "WEB",
          device_id: 1,
        },
        {
          headers: {
            api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
          },
        }
      );
      if (response && response.data) {
        return {
          data: {
            ...response.data?.data,
            isAuthenticated: true,
          } as AuthData,
          status: "success",
          error: null,
        } as AuthState;
      } else {
        return {
          data: "Wrong email or password",
          status: "error",
          error: null,
        } as unknown as AuthState;
      }

      // if (response && response.data) {
      //   return {
      //     data: {
      //       ...response.data?.data,
      //       isAuthenticated: true,
      //     } as AuthData,
      //     status: "success",
      //     error: null,
      //   } as AuthState;
      // } else {
      //   return {
      //     data: "Wrong email or password",
      //     status: "error",
      //     error: null,
      //   } as unknown as AuthState;
      // }
    } catch (err) {
      return {
        data: "Something went wrong!",
        status: "error",
        error: err,
      } as unknown as AuthState;
    }
  }
);
