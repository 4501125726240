import React, { ReactNode } from 'react';
import Helmet from 'react-helmet';
import Header from './Templates/Header';
import NewSideBar from './Templates/Sidebar/newSidebar';
import { Container } from './merchant.styles';
import useWindowDimensions from '../../hooks/useWIndowDimensions';
import useRedux from 'src/redux/useRedux';
import { openSidebar } from 'src/redux/customize/customize.reducer';
import Dialog from 'src/components/Modal/dialog';
import { NormalText } from '../styled/text.styled';
import { AuthLogout } from 'src/redux/auth';
import {
  Box,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@mui/material';
import { selectStore } from 'src/redux/appOption/appoption.reducer';
import { updateNotification } from 'src/redux/notification/notification.reducer';
import { Button } from '../styled/button.styled';
import { toast, ToastContainer } from 'react-toastify';
import { isAuthenticated, removeAllState } from 'src/helper/generalFunction';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './merchant.layout.css';

interface Props {
  title?: String;
  children?: ReactNode;
}

const MerchantLayout = ({ children, title }: Props) => {
  const navigate = useNavigate();
  const [selectStoreModal, setSelectStoreModal] = React.useState(false);
  const [optionStore, setOptionStore] = React.useState('');
  const { width } = useWindowDimensions();
  const {
    thunkDispatch,
    storeState: {
      Customize,
      AppOption: { selected_store },
      Auth: {
        status,
        data: { user_type, profile_data },
      },
    },
  } = useRedux();

  const handleClick = () => {
    thunkDispatch(openSidebar({ SIDEBAR_OPEN: !Customize.SIDEBAR_OPEN }))
      .unwrap()
      .then(() => null);
  };

  const handleChange = () => {
    let props: { id: number; name: string } = {
      id: Number(optionStore.split('@')[0]),
      name: optionStore.split('@')[1],
    };
    thunkDispatch(selectStore(props));
    setSelectStoreModal(false);
    toast.success(
      `Akses toko telah dirubah ke ${props.name}. Refresh halaman jika data tidak berubah.`
    );

    //reset notification
    let prop: { count_notif: number } = {
      count_notif: 0,
    };
    thunkDispatch(updateNotification(prop));
  };

  React.useEffect(() => {
    if (!isAuthenticated()) {
      toast.warning('Session anda telah habis, silahkan login ulang!');
      setTimeout(() => {
        removeAllState();
        navigate('/login');
      }, 3000);
    }
  }, []);

  React.useEffect(() => {
    if (
      status &&
      status === 'success' &&
      user_type &&
      user_type !== 'merchant'
    ) {
      if (selected_store && !selected_store.id) {
        setSelectStoreModal(true);
      }
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{title ?? 'Pagii Ordering Merchant'}</title>
      </Helmet>
      {status &&
        status === 'success' &&
        user_type &&
        user_type !== 'merchant' && (
          <Dialog
            open={selectStoreModal}
            handleClose={() =>
              selected_store && !selected_store.id
                ? null
                : setSelectStoreModal(false)
            }
          >
            <Box textAlign={'center'}>
              <NormalText
                fontSize={'20px'}
                fontWeight={'bold'}
                margin={'20px 0'}
              >
                Silahkan Pilih Akses Toko
              </NormalText>
              <FormControl focused>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={optionStore}
                  onChange={(e) => setOptionStore(e.target.value)}
                >
                  {status === 'success' &&
                    profile_data &&
                    profile_data.store_access &&
                    profile_data.store_access.data_store &&
                    profile_data.store_access.data_store.length > 0 &&
                    profile_data.store_access.data_store.map((item, index) => (
                      <FormControlLabel
                        key={'pickstore-' + index}
                        value={item.store_id + '@' + item.store_name}
                        control={<Radio />}
                        label={item.store_name}
                      />
                    ))}
                </RadioGroup>
              </FormControl>
              <Stack
                direction={'row'}
                justifyContent={'center'}
                spacing={1}
                margin={'20px 0 0 0'}
              >
                <Button variant={'primary'} onClick={() => handleChange()}>
                  Masuk
                </Button>
                <Button
                  variant="danger"
                  onClick={() => thunkDispatch(AuthLogout())}
                >
                  Logout
                </Button>
              </Stack>
            </Box>
          </Dialog>
        )}
      <Header
        onHamburgerClick={() => handleClick()}
        open={Customize.SIDEBAR_OPEN}
      />
      {/* <Sidebar sidebar={Customize.SIDEBAR_OPEN} /> */}
      <NewSideBar
        sidebar={Customize.SIDEBAR_OPEN}
        handleClickSideBar={() => handleClick()}
        handleSetStoreClick={() => {
          if (user_type && user_type !== 'merchant') {
            if (selected_store && selected_store.id && selected_store.name) {
              setOptionStore(selected_store.id + '@' + selected_store.name);
            }
            setSelectStoreModal(true);
          }
        }}
      />
      <Container
        open={Customize.SIDEBAR_OPEN}
        className="content-wrapper"
        onClick={() => {
          if (!Customize.SIDEBAR_OPEN && width && width <= 991) handleClick();
        }}
      >
        {children}
      </Container>
    </>
  );
};

export default MerchantLayout;
