import React from "react";
import Tables from "src/components/Table/tableData";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import {
  IconButton,
  Stack,
  Switch,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { NormalText } from "src/components/styled/text.styled";
import ConfirmationModal from "src/components/Modal/confirmation";
import { toast } from "react-toastify";
import useRedux from "src/redux/useRedux";
import {
  CreateTableProps,
  getAllTables,
  TableListResponse,
  updateTables,
  removeTable,
  changeAvailablity,
} from "src/redux/tablelist";
import { getAllStore, ListStoreData } from "src/redux/storelist";
import CheckPermission from "src/helper/checkPermission";

const TableList = () => {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [storeId, setStoreId] = React.useState(1);
  const { thunkDispatch, storeState: { Auth: {
    status,
    data: { user_type },
  }, AppOption: { selected_store }, } } = useRedux();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filter, setFilter] = React.useState("");
  const [stores, setStores] = React.useState<ListStoreData[]>();
  const [tables, setTables] = React.useState<TableListResponse>();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(0);

  const columns = [
    {
      name: "Nama",
      selector: (row: any) => row.name,
      sortable: true,
    },
    {
      name: "Toko",
      selector: (row: any) => row.store.name,
      sortable: true,
    },
    {
      name: "Tipe",
      selector: (row: any) => row.table_type.name,
      sortable: true,
    },
    {
      name: "Ketersediaan",
      center: true,
      cell: (item: any) => {
        return (
          <Switch
            disabled={!CheckPermission('Table Merchant', 'is_edit')}
            checked={item.is_available}
            onChange={() => {
              let is_available = item.is_available ? false : true;
              thunkDispatch(
                changeAvailablity({
                  id: item.id,
                  is_available: is_available,
                })
              )
                .unwrap()
                .then((res) => {
                  if (res && res.status === "success") {
                    toast.success("Berhasil mengubah status");
                    fetchTables();
                  }
                });
            }}
          />
        );
      },
    },
    {
      name: "Status",
      center: true,
      cell: (item: any) => {
        return (
          <Switch
            disabled={!CheckPermission('Table Merchant', 'is_edit')}
            checked={item.status}
            onChange={(e: any) => {
              let reqBody: CreateTableProps = {
                name: item.name,
                store_id: item.store.id,
                table_type_id: item.table_type.id,
                status: e.target.checked,
                id: item.id,
              };
              thunkDispatch(updateTables(reqBody))
                .unwrap()
                .then((res) => {
                  if (res && res.status === "success") {
                    toast.success("Berhasil mengubah status");
                    fetchTables();
                  }
                });
            }}
          />
        );
      },
    },
    {
      name: "Aksi",
      center: true,
      cell: (item: any) => {
        return (
          <Stack direction="row" justifyContent="center" alignItems="center">
            {CheckPermission('Table Merchant', 'is_edit') && (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => navigate(`/stores/table/${item.id}/update`)}
              >
                <Edit />
              </IconButton>
            )}
            {CheckPermission('Table Merchant', 'is_delete') && (
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => {
                  setDeleteId(item.id);
                  setOpen(!open);
                }}
              >
                <Delete />
              </IconButton>
            )}
          </Stack>
        );
      },
    },
  ];

  const fetchTables = () => {
    setLoading(true);
    let storeIdParam = Number(storeId ?? "");
    if (
      status &&
      status === "success" &&
      user_type &&
      user_type !== "merchant"
    ) {
      storeIdParam = Number(
        selected_store && selected_store.id ? selected_store.id : ""
      );
    }
    thunkDispatch(
      getAllTables({
        filter,
        offset: page,
        limit: rowsPerPage,
        storeId: storeIdParam,
      })
    )
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setTables(res.data);
        }
      });
    setLoading(false);
  };

  const handleDelete = () => {
    thunkDispatch(removeTable(deleteId))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          toast.success("Berhasil menghapus meja");
          fetchTables();
          setOpen(!open);
        }
      });
  };

  React.useEffect(() => {
    thunkDispatch(getAllStore({ offset: 0, limit: 0 }))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setStores(res.data.data);
          if (res.data.data.length > 0) {
            setStoreId(res.data.data[0].id);
          }
        }
      });
  }, []);

  React.useEffect(() => {
    fetchTables();
  }, [rowsPerPage, filter, page, storeId]);

  return (
    <MerchantLayout>
      <ConfirmationModal
        open={open}
        description={"Yakin ingin menghapus tipe meja ini?"}
        handleConfirm={() => handleDelete()}
        handleClose={() => setOpen(!open)}
      />
      <Tables
        records={tables?.data ?? []}
        columns={columns}
        title={"Daftar Meja"}
        isSearch={true}
        addButton={CheckPermission('Table Merchant', 'is_add')}
        filter={
          user_type && user_type === "merchant" ? (
            <Stack direction={"row"} alignItems={"center"}>
              <NormalText
                fontSize={"16px"}
                wordBreak={"break-word"}
                margin={"0 10px 0 0"}
              >
                Filter Toko
              </NormalText>
              <FormControl sx={{ minWidth: 250 }}>
                <Select
                  value={storeId}
                  onChange={(e) => setStoreId(Number(e.target.value))}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ height: 40 }}
                >
                  {stores && stores.length > 0
                    ? stores.map((item, x) => (
                      <MenuItem key={"storefilter-" + x} value={item.id}>
                        {item.store_name}
                      </MenuItem>
                    ))
                    : []}
                </Select>
              </FormControl>
            </Stack>
          ) : null
        }
        titleAdd="Tambah Meja"
        handleAddButton={() => navigate("/stores/table/form")}
        onChangeSearch={(e) => setFilter(e.target.value)}
        valueSearch={filter}
        onChangePage={(page) => setPage(page)}
        progressPending={loading}
        totalRow={tables?.meta.total ?? 0}
        paginationPerPage={rowsPerPage}
        onChangeRowsPerPage={(currentRowsPerPage) =>
          setRowsPerPage(currentRowsPerPage)
        }
      />
    </MerchantLayout>
  );
};

export default TableList;
