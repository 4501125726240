import { useState } from "react";
import { NormalText } from "src/components/styled/text.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HeaderContainer } from "./header.styles";
import NotificationSection from "./Notification";
import styled from "styled-components";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "src/components/Modal/confirmation";
import "src/assets/css/adminlte.css";
import useRedux from "src/redux/useRedux";
import { AuthLogout } from "src/redux/auth";
import { useNavigate } from "react-router-dom";
import ClickAwayListener from "@mui/base/ClickAwayListener";

export interface HeaderProps {
  open?: Boolean;
  onHamburgerClick?: any;
}

const Header = ({ open }: HeaderProps) => {
  const navigate = useNavigate();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const handleLogout = () => {
    thunkDispatch(AuthLogout())
      .unwrap()
      .then(() => {
        setConfirmOpen(false);
      });
  };

  const [dropdown, setDropdown] = useState(false);

  const {
    thunkDispatch,
    storeState: { Auth },
  } = useRedux();

  return (
    <HeaderContainer
      open={open}
      className={`main-header navbar navbar-expand navbar-white navbar-light`}
    >
      {/* <ul className="navbar-nav">
        <li className="nav-item" onClick={onHamburgerClick}>
          <a className="nav-link" role="button">
            <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
          </a>
        </li>
      </ul> */}

      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <a className="nav-link" href="#" role="button">
            <i className="fas fa-search"></i>
          </a>
          <div className="navbar-search-block">
            <form className="form-inline">
              <div className="input-group input-group-sm">
                <input
                  className="form-control form-control-navbar"
                  type="search"
                  placeholder="Search"
                />
                <div className="input-group-append">
                  <button className="btn btn-navbar" type="submit">
                    <i className="fas fa-search"></i>
                  </button>
                  <button className="btn btn-navbar" type="button">
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li>
        <List className="nav-item">
          <a className="nav-link">
            <NormalText color={"rgba(0, 0, 0, 0.5)"} isPressable>
              Panduan Merchant
            </NormalText>
          </a>
        </List>
        {/* <List className="nav-item">
          <a className="nav-link">
            <NormalText color={"rgba(0, 0, 0, 0.5)"} isPressable>
              Kontak CS
            </NormalText>
          </a>
        </List> */}
        <List className="nav-item">
          <a className="nav-link" onClick={() => navigate("/help")}>
            <NormalText color={"rgba(0, 0, 0, 0.5)"} isPressable>
              Bantuan
            </NormalText>
          </a>
        </List>
        <li className="nav-item">
          <ClickAwayListener onClickAway={() => setDropdown(false)}>
            <div className="dropdown">
              <a className="nav-link" onClick={() => setDropdown(!dropdown)}>
                <NormalText color={"rgba(0, 0, 0, 0.5)"} isPressable>
                  {" "}
                  {Auth &&
                  Auth.data &&
                  Auth.data.profile_data &&
                  Auth.data.profile_data?.name || Auth.data.profile_data?.full_name
                    ? Auth.data.profile_data.name || Auth.data.profile_data.full_name 
                    : ""}
                </NormalText>
              </a>
              <div
                id="myDropdown"
                style={{
                  background: "#ffffff",
                  borderRadius: "8px",
                  position: "absolute",
                  top: "40px",
                  right: "0",
                  width: "200px",
                  height: "100px",
                  cursor: "pointer",
                  boxShadow: "0 1px 8px rgba(0, 0, 0, 0.3)",
                  opacity: dropdown ? 1 : 0,
                  visibility: dropdown ? "visible" : "hidden",
                  transform: dropdown ? "translateY(0)" : "translateY(-20px)",
                  transition:
                    "opacity 0.4s ease, transform 0.4s ease, visibility 0.4s",
                }}
              >
                <div style={{ flexDirection: "column" }}>
                  <a
                    className="nav-link"
                    onClick={() =>
                      Auth.data &&
                      Auth.data.user_type &&
                      Auth.data.user_type === "merchant"
                        ? navigate("/profile/merchant/profil")
                        : navigate("/profile/user/profil")
                    }
                  >
                    <NormalText color={"rgba(0, 0, 0, 0.5)"} isPressable>
                      Profil
                    </NormalText>
                  </a>
                  <a
                    className="nav-link"
                    onClick={() =>
                      Auth.data &&
                      Auth.data.user_type &&
                      Auth.data.user_type === "merchant"
                        ? navigate("/profile/merchant/change-password")
                        : navigate("/profile/user/change-password")
                    }
                  >
                    <NormalText color={"rgba(0, 0, 0, 0.5)"} isPressable>
                      Ubah Kata Sandi
                    </NormalText>
                  </a>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </li>
        <li className="nav-item">
          <NotificationSection />
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            onClick={() => setConfirmOpen(!confirmOpen)}
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faPowerOff} />
          </a>
        </li>
      </ul>

      {/* Modal Logout */}
      <ConfirmationModal
        title="Konfirmasi"
        titleStyle={{ fontWeight: "bold" }}
        description="Apakah kamu yakin ingin keluar?"
        open={confirmOpen}
        handleClose={() => setConfirmOpen(!confirmOpen)}
        handleConfirm={() => handleLogout()}
      ></ConfirmationModal>
    </HeaderContainer>
  );
};

const List = styled.li`
  transition: ease-in-out 0.3s;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export default Header;
