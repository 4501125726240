import { useState, useEffect } from "react";
import { Grid, Box, Stack } from "@mui/material";
import styled from "styled-components";
import { NormalText } from "src/components/styled/text.styled";
import Card from "src/components/Cards/card";
import InputPassword from "src/components/Inputs/InputPassword";
import { Button } from "src/components/styled/button.styled";
import MainLayout from "src/components/Layouts/MainLayout";
import { useNavigate, useParams } from "react-router-dom";
import ApiService from "src/services/ApiService";
import { ToastContainer, toast } from "react-toastify";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const { key } = useParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const [payloads, setPayloads] = useState({
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    setIsSuccess(
      payloads.confirmPassword === payloads.password &&
        payloads.password !== "" &&
        payloads.password.length >= 8
    );
  }, [payloads]);

  const handleSubmit = async () => {
    try {
      const response = await ApiService.patch(
        "/merchants/session/reset_password",
        {
          key: key,
          confirm_password: payloads.confirmPassword,
          password: payloads.password,
        }
      );
      if (response.status === 200) {
        toast.success("Sukses mengganti password");
      } else {
        toast.error("Terjadi Kesalahan atau token expired");
        navigate("/login");
      }
    } catch (err) {
      toast.error("Terjadi Kesalahan atau token expired");
      navigate("/login");
    }
  };

  return (
    <MainLayout>
      <ToastContainer />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} padding={"10px"}>
          <Box padding={"10px"} margin={"20px 0 0 20px"}>
            <img
              src={"https://i.ibb.co/PtbPbyW/sanbox-logo.png"}
              alt={"Sandbox Logo"}
            ></img>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <StackStyled
            direction={"column"}
            alignItems={"center"}
            margin={"2% 0 0 0"}
            padding={"30px"}
          >
            <NormalText fontWeight="bold" fontSize="20px" margin="0 0 20px 0">
              Reset Password
            </NormalText>
            <Card
              width={"600px"}
              background={"#fdfdfd"}
              padding={"20px"}
              style={{ border: "1px solid grey" }}
            >
              <NormalText
                textAlign="left"
                margin={"0 0 20px 0"}
                fontSize={"14px"}
              >
                Silahkan masukan password baru
              </NormalText>
              <InputPassword
                value={payloads.password}
                onChange={(e) =>
                  setPayloads({ ...payloads, password: e.target.value })
                }
                validationType={
                  payloads.password !== "" && payloads.password.length < 8
                    ? "ERROR"
                    : ""
                }
                validationText={"Password minimum 8 characters"}
                placeholder={"Password"}
              />
              <InputPassword
                value={payloads.confirmPassword}
                onChange={(e) =>
                  setPayloads({ ...payloads, confirmPassword: e.target.value })
                }
                validationType={
                  payloads.confirmPassword !== "" &&
                  payloads.password !== payloads.confirmPassword
                    ? "ERROR"
                    : ""
                }
                validationText={"Password confirmation not match"}
                placeholder={"Konfirmasi Password"}
              />
              <Button
                onClick={() => (isSuccess ? handleSubmit() : null)}
                variant={isSuccess ? "primary" : "disabled"}
                width={"100%"}
              >
                Change Password
              </Button>
            </Card>
          </StackStyled>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

const StackStyled = styled(Stack)`
  @media only screen and (max-width: 600px) {
    margin: unset;
    padding: 30px;
  }
`;

export default ResetPasswordPage;
