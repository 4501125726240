import { createAsyncThunk } from "@reduxjs/toolkit";
import { SubSubCategoryState, CreateSubSubCategoryTypes } from "./types";
import ApiService from "src/services/ApiService";
import axios from "axios";
import { getToken } from "src/helper/generalFunction";

export interface SubCategoryListProps {
  offset?: number;
  limit?: number;
  filter?: string;
}

export const getSubSubCategory = createAsyncThunk(
  "subcategory/get",
  async (props: SubCategoryListProps) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/subscategory/data/list`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as SubSubCategoryState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as SubSubCategoryState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as SubSubCategoryState;
    }
  }
);

export interface ShowCategoryProps {
  subcategory_id: number;
  access_token: string;
}

export const createSubSubCategory = createAsyncThunk(
  "subcategory/post",
  async (props: CreateSubSubCategoryTypes) => {
    try {
      const { data, id, idSub } = props;
      const response = await axios.post(
        process.env.REACT_APP_API_URL +
          "/merchant/subscategory/" +
          id +
          "/" +
          idSub,
        data,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: props.access_token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as SubSubCategoryState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as SubSubCategoryState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as SubSubCategoryState;
    }
  }
);

export const updateSubSubCategory = createAsyncThunk(
  "subcategory/update",
  async (props: CreateSubSubCategoryTypes) => {
    try {
      const { data } = props;
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/merchant/subscategory/${props.id}/update`,
        data,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: props.access_token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as SubSubCategoryState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as SubSubCategoryState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as SubSubCategoryState;
    }
  }
);

export interface DeleteSubSubCategoryProps {
  subcategory_id: number;
  access_token: string;
}

export const removeSubSubCategory = createAsyncThunk(
  "subcategory/delete",
  async (props: DeleteSubSubCategoryProps) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/merchant/subscategory/${props.subcategory_id}/delete`,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: props.access_token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as SubSubCategoryState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as SubSubCategoryState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as SubSubCategoryState;
    }
  }
);

export interface UpdateStatusSubSubCategoryProps {
  id: number;
  access_token: string;
  data: any;
}

export const updateStatusSubSubCategory = createAsyncThunk(
  "subcategory/updateStatus",
  async (props: UpdateStatusSubSubCategoryProps) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/merchant/subscategory/${props.id}/status`,
        props.data,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: props.access_token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as SubSubCategoryState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as SubSubCategoryState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as SubSubCategoryState;
    }
  }
);
