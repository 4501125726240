import { createAsyncThunk } from "@reduxjs/toolkit";
import { ListUserState, UserListProps } from "./types";
import axios from "axios";
import { getToken } from "src/helper/generalFunction";

export const getListUser = createAsyncThunk('user/list', async (props: UserListProps) => {
    const config = {
        headers: {
            api_key: 'Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e',
            token: props.access_token
        }
    };
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/merchant/users/list?offset=${props.offset}&limit=${props.limit}&filter=${props.filter}`,
            config
        );
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as ListUserState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as ListUserState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as ListUserState;

    }
})

interface ResendProps {
    email: string;
}

export const resendVerification = createAsyncThunk('user/resend/verify', async ({ email }: ResendProps) => {
    const config = {
        headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: getToken()
        }
    };
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/resend/account-activation?email=${email}`, null,
            config
        );
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as ListUserState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as ListUserState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as ListUserState;

    }
})

