import { AuthState, AuthData } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import { AuthLogin, AuthLogout, AuthLoginStep2 } from "./authService";
import { removeAllState } from "src/helper/generalFunction";

export const initialState: AuthState = {
  data: {} as AuthData,
  status: "loading",
  error: {},
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AuthLogin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(AuthLogin.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(AuthLogin.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      })
      .addCase(AuthLogout.fulfilled, (state, action) => {
        removeAllState().then(() => {
          window.location.href = "/login";
        });
      })
      .addCase(AuthLoginStep2.pending, (state) => {
        state.status = "loading";
      })
      .addCase(AuthLoginStep2.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(AuthLoginStep2.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      });
  },
});

export const AuthReducer = AuthSlice.reducer;
