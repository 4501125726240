import { createAsyncThunk } from '@reduxjs/toolkit';
import { BrandState, CreateBrandTypes, ShowBrandState } from './types';
import ApiService from 'src/services/ApiService';
import axios from 'axios';
import { getToken } from 'src/helper/generalFunction';

export interface RoleListProps {
  offset?: number;
  limit?: number;
  filter?: string;
  isActive?: string;
}

export const getBrand = createAsyncThunk(
  'brands/get',
  async (props: RoleListProps) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/brand/list?limit=${
          props.limit ?? 0
        }&offset=${props.offset ?? 0}${
          props.filter ? '&filter=' + props.filter : ''
        }${props.isActive ? '&isActive=' + props.isActive : ''}`,
        config,
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as BrandState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as BrandState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as BrandState;
    }
  },
);

export interface ShowRoleProps {
  brand_id: number;
  access_token: string;
}

export const createBrand = createAsyncThunk(
  'brands/post',
  async (props: CreateBrandTypes) => {
    try {
      const {
        image_web,
        image_mobile,
        icon_web,
        icon_mobile,
        brand_type,
        brand_name,
        brand_description,
        is_active,
      } = props;

      const response = await axios.post(
        process.env.REACT_APP_API_URL + '/merchant/brand',
        {
          image_web,
          image_mobile,
          icon_web,
          icon_mobile,
          brand_type,
          brand_name,
          brand_description,
          is_active,
        },
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: getToken(),
          },
        },
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as BrandState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as BrandState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as BrandState;
    }
  },
);

export const updateBrand = createAsyncThunk(
  'brands/update',
  async (props: CreateBrandTypes) => {
    try {
      const {
        image_web,
        image_mobile,
        icon_web,
        icon_mobile,
        brand_type,
        brand_name,
        brand_description,
        is_active,
      } = props;
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/merchant/brand/${props.id}/update`,
        {
          image_web,
          image_mobile,
          icon_web,
          icon_mobile,
          brand_type,
          brand_name,
          brand_description,
          is_active,
        },
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: getToken(),
          },
        },
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as BrandState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as BrandState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as BrandState;
    }
  },
);

export interface DeleteBrandProps {
  brand_id: number;
  access_token: string;
}

export const removeBrand = createAsyncThunk(
  'brands/delete',
  async (props: DeleteBrandProps) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/merchant/brand/${props.brand_id}/delete`,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: getToken(),
            'Content-Type': 'application/json',
          },
        },
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as BrandState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as BrandState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as BrandState;
    }
  },
);

export interface UpdateStatusProps {
  id: number;
  data: any;
  access_token: string;
}

export const updateStatus = createAsyncThunk(
  'banner/updatestatus',
  async (props: UpdateStatusProps) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/merchant/brand/${props.id}/status`,
        props.data,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: props.access_token,
            'Content-Type': 'application/json',
          },
        },
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as BrandState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as BrandState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as BrandState;
    }
  },
);
