import { LoyaltyState, LoyaltyResponseProps } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import { getLoyaltySetting, createBrand } from "./loyaltySettingsServices";

export const initialState: LoyaltyState = {
  data: {} as LoyaltyResponseProps,
  status: "loading",
  error: {},
};

export const LoyaltySlice = createSlice({
  name: "role",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLoyaltySetting.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getLoyaltySetting.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(getLoyaltySetting.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      })
      .addCase(createBrand.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createBrand.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(createBrand.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      });
  },
});

export const LoyaltyReducer = LoyaltySlice.reducer;
