import { createAsyncThunk } from "@reduxjs/toolkit";
import { PuplistState, CreatePuplistTypes } from "./types";
import ApiService from "src/services/ApiService";
import axios from "axios";
import { getToken } from "src/helper/generalFunction";

export interface ListProps {
  offset?: number;
  limit?: number;
  filter?: string;
  storeId?: string | number
}

export const getPuplist = createAsyncThunk(
  "Puplists/get",
  async (props: ListProps) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/pickuppoint/listdata?limit=${
          props.limit ?? 0
        }&offset=${props.offset ?? 0}${
          props.filter ? "&filter=" + props.filter : ""
        }${
          props.storeId ? "&store_id=" + props.storeId : ""
        }`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as PuplistState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as PuplistState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as PuplistState;
    }
  }
);

export interface ShowRoleProps {
  brand_id: number;
  access_token: string;
}

export const createPuplist = createAsyncThunk(
  "Puplists/post",
  async (props: CreatePuplistTypes) => {
    try {
      const { pupoint_name, store_id, is_active } = props;

      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/merchant/pickuppoint",
        { pupoint_name, store_id, is_active },
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: getToken(),
          },
        }
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as PuplistState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as PuplistState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as PuplistState;
    }
  }
);

export const updatePuplist = createAsyncThunk(
  "Puplists/update",
  async (props: CreatePuplistTypes) => {
    try {
      const { pupoint_name, store_id, is_active } = props;
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/merchant/pickuppoint/${props.id}/update`,
        { pupoint_name, store_id, is_active },
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: getToken(),
          },
        }
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as PuplistState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as PuplistState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as PuplistState;
    }
  }
);

export interface DeletePuplistProps {
  id: number;
  access_token: string;
}

export const removePuplist = createAsyncThunk(
  "Puplists/delete",
  async (props: DeletePuplistProps) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/merchant/pickuppoint/${props.id}/delete`,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: getToken(),
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as PuplistState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as PuplistState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as PuplistState;
    }
  }
);

export interface UpdateStatusProps {
  id: number;
  data: any;
  access_token: string;
}

export const updateStatus = createAsyncThunk(
  "pickpup/updatestatus",
  async (props: UpdateStatusProps) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/merchant/pickuppoint/${props.id}/status`,
        props.data,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: props.access_token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as PuplistState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as PuplistState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as PuplistState;
    }
  }
);
