import React from "react";
import { NormalText } from "../../components/styled/text.styled";
import useRedux from "src/redux/useRedux";
import { useNavigate } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import { isAuthenticated } from "src/helper/generalFunction";
import {
  Grid,
  InputBase,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import {
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "15px",
  border: "1px solid #e6e6e6",
  backgroundColor: "#fefefe",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  padding: "10px",
  width: "100%",
  marginTop: "10px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "10px",
    maxWidth: "100%",
    width: "100%",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    fontSize: "14px",
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
      width: "100%",
    },
  },
}));

const HelpPage = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const {
    storeState: {
      Auth: { data },
    },
  } = useRedux();

  React.useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login");
    }
  }, []);

  return (
    <MerchantLayout>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <NormalText fontWeight="600" fontSize="24px" position="center">
            How can we help you?
          </NormalText>
        </Grid>
        <Grid item xs={12} style={{ width: "80%" }}>
          <Search>
            <SearchIcon />
            <StyledInputBase
              placeholder="Enter question,keyword or topic"
              inputProps={{ "aria-label": "search" }}
              // value={valueSearch}
              // onChange={onChangeSearch}
            />
          </Search>
        </Grid>
      </Grid>
      <NormalText fontSize="16px" margin="20px 0 20px 10px">
        Most helpful articles
      </NormalText>
      <Grid
        container
        direction="row"
        spacing={4}
        sx={{ marginLeft: "-12px" }}
        wrap="wrap"
      >
        <Grid item xs={4}>
          <NormalText fontSize="14px" color="#ff9500">
            Something looks unfamiliar in merchant's page. Is it okay?
          </NormalText>
        </Grid>
        <Grid item xs={4}>
          <NormalText fontSize="14px" color="#ff9500">
            I want my product to be featured. What do you need?
          </NormalText>
        </Grid>
        <Grid item xs={4}>
          <NormalText fontSize="14px" color="#ff9500">
            I want my product to be featured. What do you need?
          </NormalText>
        </Grid>
      </Grid>
      <NormalText fontSize="16px" margin="20px 0 20px 10px">
        Merchant
      </NormalText>
      <div style={{ marginLeft: "20px", marginRight: "20px" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <NormalText>General settings</NormalText>
          </AccordionSummary>
          <AccordionDetails>
            <NormalText>
              Nulla facilisi. Phasellus sollicitudin nulla et quam mattis
              feugiat. Aliquam eget maximus est, id dignissim quam.
            </NormalText>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <NormalText>Users</NormalText>
            <NormalText>You are currently not an owner</NormalText>
          </AccordionSummary>
          <AccordionDetails>
            <NormalText>
              Donec placerat, lectus sed mattis semper, neque lectus feugiat
              lectus, varius pulvinar diam eros in elit. Pellentesque convallis
              laoreet laoreet.
            </NormalText>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <NormalText>Advanced settings</NormalText>
          </AccordionSummary>
          <AccordionDetails>
            <NormalText>
              Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
              sit amet egestas eros, vitae egestas augue. Duis vel est augue.
            </NormalText>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <NormalText>Personal data</NormalText>
          </AccordionSummary>
          <AccordionDetails>
            <NormalText>
              Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
              sit amet egestas eros, vitae egestas augue. Duis vel est augue.
            </NormalText>
          </AccordionDetails>
        </Accordion>
      </div>
    </MerchantLayout>
  );
};

export default HelpPage;
