import { createSlice } from "@reduxjs/toolkit"
import { createAsyncThunk } from "@reduxjs/toolkit";

export interface AppOptionState {
    selected_store: {
        id: number | null;
        name: string;
    }
}

export const initialState: AppOptionState = {
    selected_store: {} as {
        id: number | null
        name: string
    }
}

export const appOptionSlice = createSlice({
    name: 'appoptions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(selectStore.fulfilled, (state, action) => {
                if (action.payload) {
                    state.selected_store = action.payload.selected_store
                }
            })
            .addCase(selectStore.rejected, (state, action) => {
                state.selected_store = {
                    id: null,
                    name: ""
                }
            })

    }
})

interface SelectStoreProps {
    id: number | null;
    name: string;
}

export const selectStore = createAsyncThunk('appoption/select/store', async ({ id, name }: SelectStoreProps) => {
    return {
        selected_store: {
            id: id,
            name: name
        }
    } as AppOptionState;
})

export const AppOptionReducer = appOptionSlice.reducer