import styled from "styled-components";
import { Button, Stack } from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";

interface Props {
  file: any;
  onChange: any;
  validationText?: string;
}

const FileUpload = ({ file, onChange, validationText }: Props) => {
  return (
    <Wrapper>
      <Stack direction={"row"} alignItems={"center"}>
        <Button
          sx={{ position: "relative", textTransform: "none" }}
          variant="contained"
          onClick={() => console.log("oi")}
        >
          <NormalText fontSize="12px" fontWeight="600">
            Pilih File
          </NormalText>
          <StyledInput
            type={"file"}
            onChange={onChange}
            accept={".xlsx,.xls,.xlt,.xlw"}
            key={Date.now()}
          ></StyledInput>
        </Button>
        {file ? (
          <div className="ml-3 w-50">
            <NormalText fontSize="12px" fontWeight="600">
              {file?.name?.length > 20
                ? file.name.slice(0, 20) + "..."
                : file.name}
            </NormalText>
          </div>
        ) : (
          <div className="ml-3 w-50">
            <NormalText fontSize="12px" fontWeight="600">
              Tidak ada file yang dipilih
            </NormalText>
          </div>
        )}
      </Stack>
      <NormalText
        margin="5px 0 0 0"
        fontSize="12px"
        fontWeight="400"
        color="red"
      >
        {validationText}
      </NormalText>
    </Wrapper>
  );
};

const StyledInput = styled.input`
  font-size: 12px;
  position: absolute;
  height: 30px;
  top: 10px;
  left: 10px;
  z-index: 10;
  right: 10px;
  opacity: 0;
  border-radius: 3px;
`;
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  background: #f1f1f1;
  position: absolute;
  bottom: 10px;
  opacity: 0.8;
  left: 10px;
  z-index: 9;
  width: 120px;
  align-items: center;
  border-radius: 0 0 3px 3px;
`;

const TextFile = styled.div`
  font-size: 12px;
  color: #000000;
  opacity: 1;
  font-weight: bold;
`;

export default FileUpload;
