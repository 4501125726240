import { ReactNode } from "react";
import styled from "styled-components";

interface CardProps {
  children?: ReactNode;
  borderRadius?: string;
  width?: string;
  background?: string;
  padding?: string;
  style?: React.CSSProperties;
}

const Card = ({
  children,
  borderRadius,
  width,
  background,
  padding,
  style,
}: CardProps) => {
  return (
    <CardContainer
      borderRadius={borderRadius}
      width={width}
      background={background}
      padding={padding}
      style={style}
    >
      {children}
    </CardContainer>
  );
};

const CardContainer = styled.div<CardProps>`
  height: 100%;
  background-color: white;
  justify-content: center;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 20px;
  box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
`;

export default Card;
