import { PuplistSetupState, PuplistSetupResponseProps } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { getPuplistSetup, createPuplistSetup, removePuplistSetup, updatePuplistSetup } from './puplistsetupServices';

export const initialState: PuplistSetupState = {
    data: {} as PuplistSetupResponseProps,
    status: "loading",
    error: {}
}

export const PuplistSetupSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPuplistSetup.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getPuplistSetup.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(getPuplistSetup.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(createPuplistSetup.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(createPuplistSetup.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(createPuplistSetup.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(removePuplistSetup.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(removePuplistSetup.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(removePuplistSetup.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(updatePuplistSetup.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(updatePuplistSetup.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(updatePuplistSetup.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
    }
})

export const PuplistsSetupReducer = PuplistSetupSlice.reducer