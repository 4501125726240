import { createAsyncThunk } from "@reduxjs/toolkit";
import { PuplistSetupState, CreatePuplistSetupTypes } from "./types";
import ApiService from "src/services/ApiService";
import axios from "axios";
import { getToken } from "src/helper/generalFunction";

export interface ListProps {
  offset?: number;
  limit?: number;
  filter?: string;
  storeId?: number | string
}

export const getPuplistSetup = createAsyncThunk(
  "PuplistSetups/get",
  async (props: ListProps) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${
          process.env.REACT_APP_API_URL
        }/merchant/setup/pickuppoint/listdata?limit=${
          props.limit ?? 0
        }&offset=${props.offset ?? 0}${
          props.filter ? "&filter=" + props.filter : ""
        }${props.storeId ? "&store_id=" + props.storeId : "" }`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as PuplistSetupState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as PuplistSetupState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as PuplistSetupState;
    }
  }
);

export interface ShowRoleProps {
  brand_id: number;
  access_token: string;
}

export const createPuplistSetup = createAsyncThunk(
  "PuplistSetups/post",
  async (props: CreatePuplistSetupTypes) => {
    try {
      const { image_pupoint_setup, store_id } = props;

      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/merchant/setup/pickuppoint",
        { image_pupoint_setup, store_id },
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: getToken(),
          },
        }
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as PuplistSetupState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as PuplistSetupState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as PuplistSetupState;
    }
  }
);

export const updatePuplistSetup = createAsyncThunk(
  "PuplistSetups/update",
  async (props: CreatePuplistSetupTypes) => {
    try {
      const { image_pupoint_setup, store_id } = props;
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/merchant/setup/pickuppoint/${props.id}/update`,
        { image_pupoint_setup, store_id },
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: getToken(),
          },
        }
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as PuplistSetupState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as PuplistSetupState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as PuplistSetupState;
    }
  }
);

export interface DeletePuplistSetupProps {
  id: number;
  access_token: string;
}

export const removePuplistSetup = createAsyncThunk(
  "PuplistSetups/delete",
  async (props: DeletePuplistSetupProps) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/merchant/setup/pickuppoint/${props.id}/delete`,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: getToken(),
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as PuplistSetupState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as PuplistSetupState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as PuplistSetupState;
    }
  }
);
