import React from 'react';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import { useNavigate, useParams } from 'react-router-dom';
import Card from 'src/components/Cards/card';
import { NormalText } from 'src/components/styled/text.styled';
import { Button } from 'src/components/styled/button.styled';
import { Stack, Grid, IconButton } from '@mui/material';
import useRedux from 'src/redux/useRedux';
import { getDetailOrder, OrderDetailProps } from 'src/redux/order';
import PrintIcon from '@mui/icons-material/Print';
import {
  titleCase,
  formatNumber,
  formatCurrency,
} from 'src/helper/generalFunction';
import Invoice from './invoice';
import jsPDF from 'jspdf';
import { renderToString } from 'react-dom/server';

const DetailOrder = () => {
  const navigate = useNavigate();
  const { thunkDispatch } = useRedux();
  const { code } = useParams();
  const [detail, setDetail] = React.useState<OrderDetailProps>();

  const print = (data: any) => {
    let camel = titleCase(data.customer_name);
    data = { ...data, customer_name: camel };

    const string = renderToString(<Invoice {...data} />);
    const pdf = new jsPDF({
      unit: 'pt',
    });
    // pdf.setFont('arial', 'bold');
    pdf.setFontSize(10);

    pdf.html(string, {
      callback: function (pdf) {
        // pdf.save(`newOrder-${data.order_number}`);
        pdf.autoPrint();
        pdf.output('dataurlnewwindow');
        pdf.setDisplayMode('original');
      },
    });
  };

  React.useEffect(() => {
    thunkDispatch(getDetailOrder({ code: code ?? '' }))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') setDetail(res.data.data);
      });
  }, [code]);

  console.log(detail);
  

  return (
    <MerchantLayout>
      <Card>
        <Stack
          direction={'row'}
          justifyContent="space-between"
          alignItems="center"
        >
          <NormalText fontWeight="bold" fontSize="20px" margin="0 0 20px 0">
            Detail Order
          </NormalText>
          <Button
            background={'#27AE60'}
            textColor="#fff"
            onClick={() => print(detail)}
          >
            {' '}
            <PrintIcon /> Cetak
          </Button>
        </Stack>
        <Grid container>
          <Grid item xs={12} sm={12} md={4} sx={{ wordBreak: 'break-word' }}>
            <div>
              <NormalText fontSize={'11px'} margin="0 0 5px 0">
                Nama Pelanggan
              </NormalText>
              <NormalText fontSize={'14px'} margin="0 0 5px 0">
                {detail?.customer_name ? detail?.customer_name : '-'}
              </NormalText>
            </div>
            <div>
              <NormalText fontSize={'11px'} margin="0 0 5px 0">
                Nomor Telepon Pelanggan
              </NormalText>
              <NormalText fontSize={'14px'} margin="0 0 5px 0">
                {detail?.customer_phone ? detail?.customer_phone : '-'}
              </NormalText>
            </div>
            <div>
              <NormalText fontSize={'11px'} margin="0 0 5px 0">
                Alamat Pelanggan
              </NormalText>
              <NormalText fontSize={'14px'} margin="0 0 5px 0">
                {detail?.customer_billing_address !== '-'
                  ? detail?.customer_billing_address
                  : detail?.customer_shipping_address !== '-'
                  ? detail?.customer_shipping_address
                  : detail?.destination_address !== '='
                  ? detail?.destination_address
                  : '-'}
              </NormalText>
            </div>
            <div>
              <NormalText fontSize={'11px'} margin="0 0 5px 0">
                Kode Pemesanan
              </NormalText>
              <NormalText fontSize={'14px'} margin="0 0 5px 0">
                {detail?.order_number}
              </NormalText>
            </div>
            <div>
              <NormalText fontSize={'11px'} margin="0 0 5px 0">
                Tanggal Order
              </NormalText>
              <NormalText fontSize={'14px'} margin="0 0 5px 0">
                {detail?.order_date} {detail?.order_time}
              </NormalText>
            </div>
            <div>
              <NormalText fontSize={'11px'} margin="0 0 5px 0">
                Kasir
              </NormalText>
              <NormalText fontSize={'14px'} margin="0 0 5px 0">
                {detail?.cashier_name}
              </NormalText>
            </div>
            {/* <div>
              <NormalText fontSize={'11px'} margin="0 0 5px 0">
                Nomor Antrian
              </NormalText>
              <NormalText fontSize={'14px'} margin="0 0 5px 0">
                {detail?.order_queue}
              </NormalText>
            </div> */}
            <div>
              <NormalText fontSize={'11px'} margin="0 0 5px 0">
                Tipe Order
              </NormalText>
              <NormalText fontSize={'14px'} margin="0 0 5px 0">
                {detail?.order_type}
              </NormalText>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div>
              <NormalText
                fontSize={'16px'}
                fontWeight="bold"
                margin="0 0 5px 0"
              >
                Produk
              </NormalText>
            </div>
            <div>
              <ol style={{ marginLeft: '-20px' }}>
                {detail &&
                  detail.order_detail &&
                  detail.order_detail.length > 0 &&
                  detail.order_detail.map((item) => (
                    <>
                      <li>
                        <NormalText fontSize="14px">
                          {item.product_name}
                        </NormalText>
                        <NormalText fontSize="12px">
                          Harga Produk: {formatCurrency(item.product_price * 1)}
                        </NormalText>
                        <NormalText fontSize="12px">
                          Quantity: {item.qty}
                        </NormalText>
                      </li>
                      {item.order_detail_add_on &&
                      item.order_detail_add_on.length > 0 ? (
                        <>
                          <NormalText fontSize="12px">Add-On: </NormalText>
                          <ul>
                            {item.order_detail_add_on.map((addon) => (
                              <li>{addon.product_name}</li>
                            ))}
                          </ul>
                        </>
                      ) : null}
                    </>
                  ))}
              </ol>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div>
              <NormalText
                fontSize={'16px'}
                fontWeight="bold"
                margin="0 0 5px 0"
              >
                Pembayaran
              </NormalText>
            </div>
            <div>
              <NormalText fontSize={'11px'} margin="0 0 5px 0">
                Metode Pembayaran
              </NormalText>
              <NormalText fontSize={'14px'} margin="0 0 5px 0">
                {detail?.payment_method === 'virtual_account'
                  ? 'Virtual Account'
                  : detail?.payment_method === 'qr_code'
                  ? 'QR Code'
                  : detail?.payment_method === 'cash'
                  ? 'Cash'
                  : detail?.payment_method}
              </NormalText>
            </div>
            <div>
              <NormalText fontSize={'11px'} margin="0 0 5px 0">
                Sub Total
              </NormalText>
              <NormalText fontSize={'14px'} margin="0 0 5px 0">
                {formatCurrency(Number(detail?.sub_total))}
              </NormalText>
            </div>
            <div>
              <NormalText fontSize={'11px'} margin="0 0 5px 0">
                Loyalty Point
              </NormalText>
              <NormalText fontSize={'14px'} margin="0 0 5px 0">
                {Number(detail?.loyalty_usage) > 0
                  ? `-${formatNumber(Number(detail?.loyalty_usage))}`
                  : formatNumber(Number(detail?.loyalty_usage))}
              </NormalText>
            </div>
            <div>
              <NormalText fontSize={'11px'} margin="0 0 5px 0">
                Pajak ({(Number(detail?.tax) / Number(detail?.sub_total)) * 100}%)
              </NormalText>
              <NormalText fontSize={'14px'} margin="0 0 5px 0">
                {formatCurrency(Number(detail?.tax))}
              </NormalText>
            </div>
            <div>
              <NormalText fontSize={'11px'} margin="0 0 5px 0">
                Biaya Layanan ({detail?.service_percentage}%)
              </NormalText>
              <NormalText fontSize={'14px'} margin="0 0 5px 0">
                {formatCurrency(Number(detail?.service_fee))}
              </NormalText>
            </div>
            {Number(detail?.delivery_fee) > 0 ? (
              <div>
                <NormalText fontSize={"11px"} margin="0 0 5px 0">
                  Biaya Pengiriman
                </NormalText> 
                <NormalText fontSize={"14px"} margin="0 0 5px 0">
                  {formatCurrency(Number(detail?.delivery_fee))}
                </NormalText>
              </div>
            ) : (
              <></>
            )}
            {detail?.payment_fee_type === 'OWNER' ? (
              <div>
                <NormalText fontSize={'11px'} margin="0 0 5px 0">
                  Biaya Pembayaran
                </NormalText>
                <NormalText fontSize={'14px'} margin="0 0 5px 0">
                  {formatCurrency(Number(detail?.payment_fee_owner))}
                </NormalText>
              </div>
            ) : (
              <div>
                <NormalText fontSize={'11px'} margin="0 0 5px 0">
                  Biaya Pembayaran
                </NormalText>
                <NormalText fontSize={'14px'} margin="0 0 5px 0">
                  {formatCurrency(Number(detail?.payment_fee_customer))}
                </NormalText>
              </div>
            )}
            {detail?.device_type !== 'MINIPOS' ? (
              <div>
                <NormalText fontSize={'11px'} margin="0 0 5px 0">
                  Biaya Platform
                </NormalText>
                <NormalText fontSize={'14px'} margin="0 0 5px 0">
                  {formatCurrency(
                    parseInt(detail?.sub_total || '0') <= 10000 ?  (parseInt(detail?.sub_total || '0') * 5) / 100 :
                    parseInt(detail?.sub_total || '0') > 10000 && parseInt(detail?.sub_total || '0') <= 25000 ? 500 : 
                    parseInt(detail?.sub_total || '0') > 25000 && parseInt(detail?.sub_total || '0') <= 150000 ? 1000 :
                    parseInt(detail?.sub_total || '0') > 150000 && parseInt(detail?.sub_total || '0') <= 250000 ? 1500 : 
                    parseInt(detail?.sub_total || '0') > 250000 ? 5000: 0
                  )}
                </NormalText>
              </div>
            ) : (
              <></>
            )}
            <div>
              <NormalText fontSize={'11px'} margin="0 0 5px 0">
                Discount
              </NormalText>
              <NormalText fontSize={'14px'} margin="0 0 5px 0">
                {formatCurrency(Number(detail?.discount_amount ?? 0))}
              </NormalText>
            </div>
            <div>
              <NormalText fontSize={'11px'} margin="0 0 5px 0">
                Total
              </NormalText>
              <NormalText fontSize={'14px'} margin="0 0 5px 0">
                {formatCurrency(Number(detail?.total))}
              </NormalText>
            </div>
            <div>
              <NormalText fontSize={"11px"} margin="0 0 5px 0">
                Metode Pembayaran
              </NormalText>
              <NormalText fontSize={"14px"} margin="0 0 5px 0">
                {detail?.payment_method === "virtual_account"
                  ? "Virtual Account"
                  : detail?.payment_method === "cash"
                  ? "Cash"
                  : detail?.payment_method === "qr_code"
                  ? "QRIS"
                  : ""}
              </NormalText>
            </div>
            <div>
              <NormalText fontSize={"11px"} margin="0 0 5px 0">
                Status Pembayaran
              </NormalText>
              <NormalText fontSize={"14px"} margin="0 0 5px 0">
                {detail?.payment_status}
              </NormalText>
            </div>
          </Grid>
        </Grid>
        <Stack direction={'row'} spacing={2} margin={'20px 0 20px 0'}>
          <Button variant="primary" onClick={() => navigate(-1)}>
            Kembali
          </Button>
        </Stack>
      </Card>
    </MerchantLayout>
  );
};

export default DetailOrder;
