import { Stack } from "../styled/layout.styled";
import { NormalText } from "../styled/text.styled";
import React, { FC, ReactNode, useState } from "react";
import { CSSProperties } from "styled-components";
import { Input, BorderInput, Label, IconStyled } from "./styles";
import { IconEye, IconEyeOff } from "@tabler/icons";

export interface InputPasswordProps {
  id?: string;
  ref?:
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined;
  placeholder?: string;
  label?: ReactNode;
  minLength?: number;
  maxLength?: number;
  isRequired?: boolean;
  value?: string | number | readonly string[] | undefined;
  inputMode?:
    | "none"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search"
    | undefined;
  type?: string;
  margin?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onInvalid?: React.FormEventHandler<HTMLInputElement> | undefined;
  validationText?: string;
  validationType?: "ERROR" | "WARNING" | "INFO" | string | undefined;
  disabled?: boolean | undefined;
  style?: CSSProperties;
}
export const InputPassword: FC<InputPasswordProps> = ({
  id,
  ref,
  label,
  placeholder,
  minLength,
  maxLength,
  isRequired,
  value,
  inputMode,
  margin,
  onChange,
  onInvalid,
  validationText,
  validationType,
  disabled,
  style,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  let validationRef = React.useRef<HTMLParagraphElement | null>();
  return (
    <>
      <BorderInput margin={margin} style={validationType ? { border: "1px solid #d51f40", margin: '0 0 5px 0' } : style}>
        <Label>{label}</Label>
        <Input
          id={id}
          ref={ref}
          placeholder={placeholder}
          onChange={onChange}
          inputMode={inputMode}
          type={passwordShown ? "text" : "password"}
          minLength={minLength}
          maxLength={maxLength}
          required={isRequired}
          value={value}
          disabled={disabled}
          onInvalid={(e) => {
            if (onInvalid) {
              onInvalid(e);
            }
            if (!e.currentTarget.value) {
              validationRef.current!!.style.display = "flex";
            } else {
              validationRef.current!!.style.display = "none";
            }
          }}
        />
        <IconStyled onClick={togglePasswordVisiblity}>
          {passwordShown ? (
            <IconEye color={"black"} />
          ) : (
            <IconEyeOff color={"black"} />
          )}
        </IconStyled>
      </BorderInput>
      {validationType && (
        <Stack margin="0 0 5px 0">
          <NormalText
            color={
              validationType === "ERROR"
                ? "#FF4D6D"
                : validationType === "WARNING"
                ? "#E7B450"
                : validationType === "INFO"
                ? "#DDD8D8"
                : "white"
            }
            fontSize="12px"
            lineHeight="146%"
            fontStyle="normal"
            fontWeight="400"
          >
            {validationText}
          </NormalText>
        </Stack>
      )}
    </>
  );
};

export default InputPassword;
