import React from "react";
import { styled } from "@mui/system";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import { NormalText } from "src/components/styled/text.styled";
import { Button } from "src/components/styled/button.styled";
import TextField from "src/components/Inputs/TextField";
import { Stack, Grid, Box, Switch } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Card from "src/components/Cards/card";
import Editor from "src/components/Editor";
import { Tab, TabPanel, TabsList } from "src/components/Tabs";
import { showProduct } from "src/redux/products";
import useRedux from "src/redux/useRedux";

const ProductView = () => {
  const navigate = useNavigate();
  const { thunkDispatch } = useRedux();
  const { id } = useParams();
  const [activeTab, setActiveTab] = React.useState(0);

  const [payload, setPayload] = React.useState({
    product_name: "",
    no_sku: "",
    category_name: "",
    sub_category_name: "",
    subs_category_name: "",
    brand_name: "",
    product_detail: {
      product_type: "",
      is_customizable: true,
      product_price: 0,
      discount_type: "percent",
      discount: 0,
      product_price_after_discount: 0,
      product_image_main: "",
      product_image_1: "",
      product_image_2: "",
      product_image_3: "",
      product_image_4: "",
    },
    product_description: {
      short_description: "",
      long_description: "",
    },
  });

  const handleSubmit = () => {
    navigate("/products/product/list");
  };

  React.useEffect(() => {
    thunkDispatch(showProduct(Number(id)))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          let response = res.data.data;
          var temp = {
            product_name: response.product_name,
            no_sku: response.no_sku,
            category_name:
              response && response.category && response.category.category_name
                ? response.category.category_name
                : "-",
            sub_category_name:
              response &&
              response.sub_category &&
              response.sub_category.sub_category_name
                ? response.sub_category.sub_category_name
                : "-",
            subs_category_name:
              response &&
              response.subs_category &&
              response.subs_category.subs_category_name
                ? response.subs_category.subs_category_name
                : "-",
            brand_name:
              response && response.brand && response.brand.brand_name
                ? response.brand.brand_name
                : "-",
            product_detail: { ...response.product_detail },
            product_description: { ...response.product_description },
          };
          setPayload(temp);
        }
      })
      .catch((err) => console.log("Err", err));
  }, []);

  return (
    <MerchantLayout>
      <Card>
        <NormalText fontWeight={"bold"} fontSize={"20px"} margin={"0 0 20px 0"}>
          Detail Produk
        </NormalText>
        <TabsUnstyled defaultValue={0} value={activeTab}>
          <TabsList>
            <Tab onChange={() => setActiveTab(0)}>Nama {"&"} Kategori</Tab>
            <Tab onChange={() => setActiveTab(1)}>Detail</Tab>
            <Tab onChange={() => setActiveTab(2)}>Deskripsi</Tab>
          </TabsList>
          <TabPanel value={0}>
            <StyledBox>
              <TextField
                label={"No. SKU"}
                isRequired
                readonly
                value={payload.no_sku}
              ></TextField>
              <TextField
                label={"Nama Produk"}
                isRequired
                readonly
                value={payload.product_name}
              ></TextField>
              <TextField
                label={"Ketegori"}
                isRequired
                value={payload.category_name}
                readonly
              ></TextField>
              <TextField
                label={"Sub Ketegori"}
                isRequired
                value={payload.sub_category_name}
                readonly
              ></TextField>
              <TextField
                label={"Sub Ketegori"}
                isRequired
                value={payload.subs_category_name}
                readonly
              ></TextField>
              <TextField
                label={"Brand"}
                isRequired
                value={payload.brand_name}
                readonly
              ></TextField>
              <Box width={"100%"} textAlign={"right"}>
                <Button variant="primary" onClick={() => setActiveTab(1)}>
                  Selanjutnya
                </Button>
              </Box>
            </StyledBox>
          </TabPanel>
          <TabPanel value={1} key={1}>
            <StyledBox>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    label={"Tipe Produk"}
                    isRequired
                    value={
                      payload.product_detail.product_type === "MAIN"
                        ? "Main"
                        : "Additional"
                    }
                    readonly
                  ></TextField>
                  <NormalText fontSize="12px">Customizable *</NormalText>
                  <Switch
                    value={payload.product_detail.is_customizable}
                    checked={payload.product_detail.is_customizable}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    label={"Harga Produk (Rp)"}
                    isRequired
                    readonly
                    value={Number(payload.product_detail.product_price)}
                  ></TextField>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        label={"Tipe Diskon"}
                        isRequired
                        readonly
                        value={
                          payload.product_detail.discount_type === "percent"
                            ? "Persentase"
                            : payload.product_detail.discount_type ===
                              "discount"
                            ? "Potongan Harga"
                            : "-"
                        }
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        label={"Diskon"}
                        isRequired
                        readonly
                        placeholder={
                          payload.product_detail.discount_type
                            ? payload.product_detail.discount_type === "percent"
                              ? "%"
                              : "Rp"
                            : "Silahkan pilih tipe diskon"
                        }
                        value={payload.product_detail.discount}
                      ></TextField>
                    </Grid>
                  </Grid>
                  <TextField
                    label={"Harga produk setelah diskon (Rp)"}
                    isRequired
                    disabled
                    placeholder={
                      !payload.product_detail.product_price ||
                      !payload.product_detail.discount
                        ? "Silhkan atur diskon produk"
                        : ""
                    }
                    value={Number(
                      payload.product_detail.product_price_after_discount
                    )}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <NormalText fontSize={"15px"} fontWeight={"bold"}>
                    Foto Produk *
                  </NormalText>
                  <NormalText fontSize={"12px"} margin={"0 0 10px 0"}>
                    Masukan foto produk dengan ukuran maksimal 512 KB{" "}
                  </NormalText>
                  <Grid container spacing={1}>
                    <Grid xs={4} sm={6} md={1.5}>
                      <img
                        style={{ width: "130px", height: "130px" }}
                        src={payload.product_detail.product_image_main}
                        alt={"Image Main"}
                      />
                    </Grid>
                    <Grid xs={4} sm={6} md={1.5}>
                      <img
                        style={{ width: "130px", height: "130px" }}
                        src={payload.product_detail.product_image_1}
                        alt={"Image 1"}
                      />
                    </Grid>
                    <Grid xs={4} sm={6} md={1.5}>
                      <img
                        style={{ width: "130px", height: "130px" }}
                        src={payload.product_detail.product_image_2}
                        alt={"Image 2"}
                      />
                    </Grid>
                    <Grid xs={4} sm={6} md={1.5}>
                      <img
                        style={{ width: "130px", height: "130px" }}
                        src={payload.product_detail.product_image_3}
                        alt={"Image 3"}
                      />
                    </Grid>
                    <Grid xs={4} sm={6} md={1.5}>
                      <img
                        style={{ width: "130px", height: "130px" }}
                        src={payload.product_detail.product_image_4}
                        alt={"Image 4"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box width={"100%"} margin={"20px 0 0 0"}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Button variant="danger" onClick={() => setActiveTab(0)}>
                    Kembali
                  </Button>
                  <Button variant="primary" onClick={() => setActiveTab(2)}>
                    Selanjutnya
                  </Button>
                </Stack>
              </Box>
            </StyledBox>
          </TabPanel>
          <TabPanel value={2}>
            <StyledBox>
              <TextField
                label={"Deskripsi singkat"}
                isRequired
                readonly
                value={payload.product_description.short_description}
              ></TextField>
              <NormalText fontSize={"12px"} margin={"10px 0 5px 0"}>
                Deskripsi *
              </NormalText>
              <Editor
                readOnly={true}
                value={payload.product_description.long_description}
              ></Editor>
              <Box width={"100%"} margin={"20px 0 0 0"}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Button variant="danger" onClick={() => setActiveTab(1)}>
                    Kembali
                  </Button>
                  <Button variant="primary" onClick={() => handleSubmit()}>
                    Kembali ke daftar produk
                  </Button>
                </Stack>
              </Box>
            </StyledBox>
          </TabPanel>
        </TabsUnstyled>
      </Card>
    </MerchantLayout>
  );
};

const StyledBox = styled(Box)`
  width: 100%;
  min-height: 200px;
  background: white;
  padding: 10px;
`;

export default ProductView;
