import { useState } from "react";
import { Stack } from "../../../components/styled/layout.styled";
import { useNavigate, useLocation } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import styles from "./styles";
import { Box, Button as ButtonMUI, Switch, TextField } from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";
import moment from "moment";

interface RouteState {
  data: any;
  status: string;
}

const AddBrandPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { data } = location.state as RouteState;
  const [dataAdd, setDataAdd] = useState({
    name: data?.banner_name ?? "",
    dateFrom: data?.date_from ?? "",
    dateTo: data?.date_to ?? "",
    imageWeb: data?.banner_image_web ?? "",
    imageMobile: data?.banner_image_mobile ?? "",
    imagePotrait: data?.banner_image_kiosk ?? "",
    status: data?.is_active ?? false,
  });

  return (
    <MerchantLayout>
      <Stack direction="column">
        <Box sx={styles.boxStyled}>
          <NormalText
            textAlign="start"
            fontWeight={"bold"}
            fontSize={"18px"}
            margin={"0 0 20px 0"}
          >
            Detail Banner
          </NormalText>
          <form>
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Nama Banner *
            </NormalText>
            <TextField
              id="outlined-read-only-input"
              sx={{ width: "100%" }}
              value={dataAdd.name}
              InputProps={{
                readOnly: true,
              }}
            />
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Dari Tanggal *
            </NormalText>
            <TextField
              id="outlined-read-only-input"
              sx={{ width: "100%" }}
              value={moment(dataAdd.dateFrom).format("DD MMMM YYYY HH:mm")}
              InputProps={{
                readOnly: true,
              }}
            />
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Sampai Tanggal *
            </NormalText>
            <TextField
              id="outlined-read-only-input"
              sx={{ width: "100%" }}
              value={moment(dataAdd.dateTo).format("DD MMMM YYYY HH:mm")}
              InputProps={{
                readOnly: true,
              }}
            />
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Gambar Banner Web *
            </NormalText>
            <img
              src={dataAdd.imageWeb}
              style={{ height: 110, width: 310 }}
              className="brand-image"
              alt="image brand"
              loading="lazy"
            />
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Gambar Banner Mobile *
            </NormalText>
            <img
              src={dataAdd.imageMobile}
              style={{ height: 60, width: 180 }}
              className="brand-image"
              alt="image brand"
              loading="lazy"
            />
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Gambar Banner Potrait *
            </NormalText>
            <img
              src={dataAdd.imagePotrait}
              style={{ height: 240, width: 140 }}
              className="brand-image"
              alt="image brand"
              loading="lazy"
            />
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Status *
            </NormalText>
            <Switch checked={dataAdd.status} disabled />
            <Box textAlign={"start"} margin={"20px 0 0 0 "}>
              <ButtonMUI
                variant="contained"
                onClick={() => navigate("/informasi/banner")}
                sx={{ width: 100, height: 40, marginRight: 3 }}
                color="error"
              >
                Kembali
              </ButtonMUI>
            </Box>
          </form>
        </Box>
      </Stack>
    </MerchantLayout>
  );
};

export default AddBrandPage;
