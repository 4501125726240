import { BannerState, BannerResponseProps } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { getBanner, createBanner, removeBanner, updateBanner } from './bannerServices';

export const initialState: BannerState = {
    data: {} as BannerResponseProps,
    status: "loading",
    error: {}
}

export const BannerSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBanner.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getBanner.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(getBanner.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(createBanner.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(createBanner.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(createBanner.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(removeBanner.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(removeBanner.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(removeBanner.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(updateBanner.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(updateBanner.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(updateBanner.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
    }
})

export const BannerReducer = BannerSlice.reducer