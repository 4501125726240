import { ProductsState, ProductResponseProps } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { getAllProductStore } from './product.store.services';

export const initialState: ProductsState = {
    data: {} as ProductResponseProps,
    status: "loading",
    error: {}
}

export const ProductStoreSlice = createSlice({
    name: 'productStore',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllProductStore.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getAllProductStore.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(getAllProductStore.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
    }
})

export const ProductStoreReducer = ProductStoreSlice.reducer