import React, { useState, useEffect } from 'react';
import { Stack } from '../../../components/styled/layout.styled';
import { useNavigate, useLocation } from 'react-router-dom';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import styles from './styles';
import {
  Box,
  Button as ButtonMUI,
  FormHelperText,
  Switch,
  Grid,
} from '@mui/material';
import { NormalText } from 'src/components/styled/text.styled';
//import IconSandboxSmall from "src/assets/media/icons/ic-sandbox-small.svg";
import NewIconSandboxSmall from 'src/assets/media/icons/OrderingShort.svg';
import TextField from 'src/components/Inputs/TextField';
import NoPhoto from 'src/assets/media/images/NoPhotoList.svg';

interface RouteState {
  data: any;
  status: string;
}

const AddBrandPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { data, status } = location.state as RouteState;
  const [dataAdd, setDataAdd] = useState({
    name: data?.brand_name ?? '',
    imageWeb: data?.image_web ?? '',
    imageMobile: data?.image_mobile ?? '',
    iconWeb: data?.icon_web ?? '',
    iconMobile: data?.icon_mobile ?? '',
    brandType: data?.brand_type ?? '',
    brandDesc: data?.brand_description ?? '',
    status: data?.is_active ?? false,
  });

  return (
    <MerchantLayout>
      <Stack direction="column">
        <Box sx={styles.boxStyled}>
          <NormalText
            textAlign="start"
            fontWeight={'bold'}
            fontSize={'18px'}
            margin={'0 0 20px 0'}
          >
            Detail Brand
          </NormalText>
          <form>
            <TextField
              label="Nama"
              isRequired
              id="outlined-read-only-input"
              readonly
              value={dataAdd.name}
            />
            <TextField
              label="Tipe"
              isRequired
              id="outlined-read-only-input"
              readonly
              value={dataAdd.brandType}
            />
            <TextField
              label="Deskripsi"
              isRequired
              id="outlined-read-only-input"
              readonly
              value={dataAdd.brandDesc}
            />
            <NormalText
              textAlign="left"
              margin={'20px 0 5px 0'}
              fontSize={'14px'}
            >
              Status *
            </NormalText>
            <Switch checked={dataAdd.status} disabled />
            <NormalText
              textAlign="left"
              fontWeight="bold"
              margin={'20px 0 5px 0'}
              fontSize={'18px'}
            >
              Ikon Brand
            </NormalText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} textAlign={'center'}>
                <NormalText
                  textAlign="center"
                  margin={'0 0 5px 0'}
                  fontSize={'14px'}
                >
                  Gambar Web
                </NormalText>
                <img
                  src={dataAdd.iconWeb ? dataAdd.iconWeb : NewIconSandboxSmall}
                  style={{ height: 200, width: 200 }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} textAlign={'center'}>
                <NormalText
                  textAlign="center"
                  margin={'0 0 5px 0'}
                  fontSize={'14px'}
                >
                  Gambar Mobile
                </NormalText>
                <img
                  src={
                    dataAdd.iconMobile
                      ? dataAdd.iconMobile
                      : NewIconSandboxSmall
                  }
                  style={{ height: 150, width: 150 }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              </Grid>
            </Grid>
            <NormalText
              textAlign="left"
              fontWeight="bold"
              margin={'20px 0 5px 0'}
              fontSize={'18px'}
            >
              Banner Brand
            </NormalText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} textAlign={'center'}>
                <NormalText
                  textAlign="center"
                  margin={'0 0 5px 0'}
                  fontSize={'14px'}
                >
                  Gambar Web
                </NormalText>
                <img
                  src={dataAdd.imageWeb ? dataAdd.imageWeb : NoPhoto}
                  style={{ height: 120, width: 220 }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} textAlign={'center'}>
                <NormalText
                  textAlign="center"
                  margin={'0 0 5px 0'}
                  fontSize={'14px'}
                >
                  Gambar Mobile
                </NormalText>
                <img
                  src={dataAdd.imageMobile ? dataAdd.imageMobile : NoPhoto}
                  style={{ height: 100, width: 200 }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              </Grid>
            </Grid>
            <Box textAlign={'start'} margin={'20px 0 0 0 '}>
              <ButtonMUI
                variant="contained"
                onClick={() => navigate('/products/brands')}
                sx={{ width: 100, height: 40, marginRight: 3 }}
                color="error"
              >
                Kembali
              </ButtonMUI>
            </Box>
          </form>
        </Box>
      </Stack>
    </MerchantLayout>
  );
};

export default AddBrandPage;
