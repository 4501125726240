import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppSettingState, CreateAppSettingTypes, ShowAppSettingState } from "./types";
import ApiService from "src/services/ApiService";
import axios from "axios";
import { getToken } from 'src/helper/generalFunction';

export const getAppSetting = createAsyncThunk('AppSettings/get', async (type: 'KIOSK' | 'MOBILEQR') => {
    try {
        const config = {
            headers: {
                token: getToken(),
                api_key: process.env.REACT_APP_API_KEY,
            }
        };
        const response = await ApiService.get(`${process.env.REACT_APP_API_URL}/merchant/setting/getsetting?device_type=${type}`, config)
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as AppSettingState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as AppSettingState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as AppSettingState;

    }
})

export const createAppSetting = createAsyncThunk('AppSettings/post', async (props: any) => {
    try {

        const response = await axios.post(process.env.REACT_APP_API_URL + '/merchant/appsetting', props, {
            headers: {
                api_key: process.env.REACT_APP_API_KEY,
                token: getToken()
            }
        })
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as AppSettingState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as AppSettingState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as AppSettingState;

    }
});

export const updateAppSetting = createAsyncThunk('AppSettings/update', async ({ setting_id, props }: { setting_id: string, props: any }) => {
    try {
        // const { merchant_name, primary_color, secondary_color, app_logo, favicon, threshold, is_deleted } = props;
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/merchant/appsetting/${setting_id}/update`, props, {
            headers: {
                api_key: process.env.REACT_APP_API_KEY,
                token: getToken(),
            }
        })
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as AppSettingState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as AppSettingState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as AppSettingState;

    }
})
// export const updateAppSetting = createAsyncThunk('AppSettings/update', async (props: CreateAppSettingTypes) => {
//     try {
//         const { merchant_name, primary_color, secondary_color, app_logo, favicon, threshold, is_deleted } = props;
//         const response = await axios.put(`${process.env.REACT_APP_API_URL}/merchant/appsetting/${props.id}/update`, { merchant_name, primary_color, secondary_color, app_logo, favicon, threshold, is_deleted }, {
//             headers: {
//                 api_key: process.env.REACT_APP_API_KEY,
//                 token: getToken(),
//             }
//         })
//         if (response && response.data) {
//             return {
//                 data: response.data,
//                 status: "success",
//                 error: null
//             } as unknown as AppSettingState;
//         } else {
//             return {
//                 data: response.data?.message,
//                 status: "empty",
//                 error: null
//             } as unknown as AppSettingState;
//         }
//     } catch (err) {
//         if (err)
//             return {
//                 data: 'Something went wrong!',
//                 status: "error",
//                 error: err
//             } as unknown as AppSettingState;

//     }
// })