import styled, { css } from "styled-components";
interface Props {
  open: Boolean;
}
export const Container = styled.div<Props>`
  background: #f4f6f9;
  margin: 0 0 0 220px;
  padding: 15px;
  min-height: calc(100vh - calc(3.5rem + 1px));
  transition: margin-left 0.3s ease-in-out;
  overflow-x: hidden !important;

  ${(p) =>
    p.open === true &&
    css`
      /* width: 93% !important; */
      margin-left: 80px !important;
    `}
`;
