import React from 'react';
import Tables from 'src/components/Table/tableData';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import { IconButton, Grid } from '@mui/material';
import { Delete, Edit, Search } from '@mui/icons-material';
import UserGroups from '../layout';
import { useNavigate } from 'react-router-dom';
import {
  DeleteRoleProps,
  getRole,
  removeRole,
  RoleResponseProps,
} from 'src/redux/role';
import useRedux from 'src/redux/useRedux';
import { ToastContainer, toast } from 'react-toastify';
import ConfirmationModal from 'src/components/Modal/confirmation';
import { isAuthenticated } from 'src/helper/generalFunction';
import 'react-toastify/dist/ReactToastify.css';
import checkPermission from 'src/helper/checkPermission';

const ListRoles = () => {
  const navigate = useNavigate();
  const [roles, setRoles] = React.useState<RoleResponseProps>(
    {} as RoleResponseProps,
  );
  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [filter, setFilter] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [id, setId] = React.useState(0);

  React.useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login');
    }
  }, []);

  const handleDelete = (id: number) => {
    thunkDispatch(
      removeRole({
        role_id: id,
        access_token: Auth.data.access_token,
      } as DeleteRoleProps),
    )
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          toast.success('Berhasil menghapus role');
        } else if (res && res.status === 'error') {
          toast.warning(res?.error?.response?.data?.message ?? 'Something went wrong!');
        }
        setLoading(!loading);
        getAll();
        setDeleteModal(!deleteModal);
      })
      .catch((err) => {
        toast.error('Gagal menghapus role, coba lagi..');
        console.log(err);
      });
  };

  const {
    thunkDispatch,
    storeState: { Auth },
  } = useRedux();

  const getAll = () => {
    setLoading(true);
    thunkDispatch(
      getRole({
        filter: filter,
        limit: limit,
        offset: offset,
      }),
    )
      .unwrap()
      .then((res) => {
        if (res && res.status == 'success') {
          setRoles(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    getAll();
  }, [filter, limit, offset]);

  const columns = [
    {
      name: 'Role',
      selector: (row: any) => row.name,
      sortable: true,
    },
    {
      name: 'Deskripsi',
      selector: (row: any) => row.description,
      sortable: true,
    },
    {
      name: 'Aksi',
      center: true,
      cell: (item: any) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {checkPermission('Role', 'is_edit') && (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => navigate(`/users/roles/${item.id}/update`)}
              >
                <Edit />
              </IconButton>
            )}
            <IconButton
              aria-label="view"
              color="warning"
              onClick={() => navigate(`/users/roles/${item.id}/view`)}
            >
              <Search />
            </IconButton>
            {checkPermission('Role', 'is_delete') && (
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => {
                  setId(item.id);
                  setDeleteModal(!deleteModal);
                }}
              >
                <Delete />
              </IconButton>
            )}
          </Grid>
        );
      },
    },
  ];

  return (
    <MerchantLayout>
      <ToastContainer />
      <ConfirmationModal
        title="Konfirmasi"
        titleStyle={{ fontWeight: 'bold' }}
        description="Apakah anda yakin ingin menghapus role ini?"
        open={deleteModal}
        handleConfirm={() => handleDelete(id)}
        handleClose={() => setDeleteModal(!deleteModal)}
      ></ConfirmationModal>
      <UserGroups tabActive={1}>
        <Tables
          records={roles.data}
          columns={columns}
          title="Daftar Role"
          isSearch={true}
          addButton={checkPermission('Role', 'is_add') ? true : false}
          titleAdd="Tambah Role"
          handleAddButton={() => navigate('/users/roles/add-role')}
          onChangeSearch={(e) => setFilter(e.target.value)}
          valueSearch={filter}
          progressPending={loading}
          paginationPerPage={limit}
          onChangePage={(page) => setOffset(page - 1)}
          totalRow={
            roles && roles.meta && roles.meta.total ? roles.meta.total : 0
          }
          onChangeRowsPerPage={(currentRowsPerPage) => {
            setOffset(0);
            setLimit(currentRowsPerPage);
          }}
        />
      </UserGroups>
    </MerchantLayout>
  );
};

export default ListRoles;
