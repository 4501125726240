import { SubCategoryState, SubCategoryResponseProps } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { getSubCategory, createSubCategory, removeSubCategory, updateSubCategory } from './subcategoryServices';

export const initialState: SubCategoryState = {
    data: {} as SubCategoryResponseProps,
    status: "loading",
    error: {}
}

export const SubCategorySlice = createSlice({
    name: 'role',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSubCategory.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getSubCategory.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(getSubCategory.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(createSubCategory.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(createSubCategory.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(createSubCategory.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(removeSubCategory.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(removeSubCategory.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(removeSubCategory.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(updateSubCategory.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(updateSubCategory.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(updateSubCategory.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
    }
})

export const SubCategoryReducer = SubCategorySlice.reducer