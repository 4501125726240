
class AuthService {

    getDecryptedUser = () => {
        const localStorage =
          window.localStorage.getItem('@sandboxdata',
          ) ?? undefined;
        return JSON.parse(localStorage ?? '{}');
      };

    isAuthenticated = () => {
        let isAuth = false
        const decrypt = this.getDecryptedUser()
        if (decrypt && decrypt.Auth) {
            const auth = JSON.parse(decrypt.Auth)
          if (auth && auth.data && new Date(auth.data.expired_date).getTime() > new Date().getTime()) {
            isAuth =  true;
          }
        }
      
        return isAuth;
      };
      
      getToken = () => {
        let token = ''
        const decrypt = this.getDecryptedUser()
        if (decrypt && decrypt.Auth) {
          const auth = JSON.parse(decrypt.Auth)
          if (auth && auth.data && auth.data.access_token) {
            token = auth.data.access_token
          }
        }
      
        return token;
      };
}

export default AuthService