import React from "react";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import { useNavigate, useParams } from "react-router-dom";
import useRedux from "src/redux/useRedux";
import Card from "src/components/Cards/card";
import { NormalText } from "src/components/styled/text.styled";
import { Button } from "src/components/styled/button.styled";
import TextField from "src/components/Inputs/TextField";
import Dropdown, { OptionProps } from "src/components/Inputs/Dropdown";
import { Stack, Box, Switch, Grid } from "@mui/material";
import { discounttypes } from "src/constants/general";
import { getAllStore, ListStoreData } from "src/redux/storelist";
import { formatCurrency } from "src/helper/generalFunction";
import {
  getDetailProductStore,
  assignProductStore,
  ProductStoreType,
  CreateProductStoreTypes,
  updateStatus,
  updateProductStore,
  removeProductStore,
} from "src/redux/productStore";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Dialog from "src/components/Modal/dialog";
import { IconX } from "@tabler/icons";
import CurrencyFormat from "react-currency-format";
import { IconButton } from "@mui/material";
import { Delete, Edit, Search } from "@mui/icons-material";
import ConfirmationModal from "src/components/Modal/confirmation";
import checkPermission from "src/helper/checkPermission";

const initial = {
  id: "",
  store_id: "",
  product_id: "",
  is_active: true,
  store_price: "",
  discount_type: "",
  discount: "",
  final_price: 0,
  stock: 0,
  is_publish: true,
  is_stock: false
};

const AssignStoreProduct = () => {
  const navigate = useNavigate();
  const { key } = useParams();
  const { thunkDispatch } = useRedux();
  const [page, setPage] = React.useState(0);
  const [submit, setSubmit] = React.useState(false);
  const [limit, setLimit] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [isCreate, setIsCreate] = React.useState(true);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [deleteID, setDeleteID] = React.useState(0);
  const [stores, setStores] = React.useState<ListStoreData[]>();
  const [product, setProduct] = React.useState<ProductStoreType>();
  const [payload, setPayload] = React.useState(initial);

  const getTotalDisc = () => {
    if (payload.store_price && payload.discount_type) {
      let type = payload.discount_type;
      let price = Number(payload.store_price);
      let disc = Number(payload.discount);
      let totalDisc = 0;
      let afterPrice = price;
      if (type === "percent") {
        totalDisc = price * (disc / 100);
        afterPrice = price - totalDisc;
      } else {
        if (price > 0) {
          afterPrice = price - disc;
        } else {
          afterPrice = price;
        }
      }
      setPayload({
        ...payload,
        final_price: afterPrice,
      });
    }
  };

  const handleUpdateStatus = (id: number, status: boolean) => {
    thunkDispatch(updateStatus({ is_active: status, id: id }))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          toast.success("Berhasil mengubah status");
          fetchDetail();
        }
      });
  };

  const handleUpdateClick = (store: any) => {
    setIsCreate(false);
    setPayload(store);
    setOpen(true);
  };

  const changeStatus = (e: any) => {
    // console.log('status', e.target.checked);
    
    setPayload({ ...payload, is_publish: e.target.checked });
  }

  const changeStatusStock = (e: any) => {
    setPayload({ ...payload, is_stock: e.target.checked });
  }

  const handleUpdate = () => {
    setSubmit(true);
    if (
      payload.store_id &&
      payload.store_price &&
      Number(payload.store_price) > 0
    ) {
      let body: CreateProductStoreTypes = {
        discount: Number(payload.discount),
        discount_type: payload.discount_type,
        final_price: Number(payload.final_price),
        is_active: payload.is_active,
        id: payload.id,
        product_id: Number(payload.product_id),
        store_id: Number(payload.store_id),
        store_price: Number(payload.store_price),
        stock: Number(payload.stock),
        is_publish: Boolean(payload.is_publish),
        is_stock: Boolean(payload.is_stock)
      };
      thunkDispatch(updateProductStore(body))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            toast.success("Sukses mengubah harga toko!");
            fetchDetail();
            setOpen(false);
          }
        });
    }
  };

  const handleDelete = () => {
    thunkDispatch(removeProductStore(deleteID))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          toast.success("Product toko berhasil dihapus");
          fetchDetail();
          setDeleteModal(false);
        }
      });
  };

  const columns = [
    {
      name: "Toko",
      selector: (row: any) => row.store_name,
      sortable: true,
    },
    {
      name: "Harga (Rp)",
      cell: (row: any) => {
        return formatCurrency(row.store_price);
      },
      sortable: true,
    },
    {
      name: "Stok Produk",
      cell: (row: any) => {
        return row.stock ? row.stock : "-";
      },
      sortable: true,
    },
    {
      name: "Diskon",
      cell: (row: any) => {
        return (
          <NormalText>
            {row.discount_type === "discount" ? "Rp" : ""}{" "}
            {row.discount === "discount"
              ? formatCurrency(row.discount)
              : row.discount}
            {row.discount_type === "percent" ? "%" : ""}
          </NormalText>
        );
      },
    },
    {
      name: "Harga Diskon",
      cell: (row: any) => {
        return formatCurrency(row.final_price);
      },
    },
    {
      name: "Status",
      center: true,
      cell: (row: any) => {
        return (
          <Switch
            checked={row.is_active}
            onChange={() => handleUpdateStatus(row.id, !row.is_active)}
            disabled={
              checkPermission("Product Store", "is_edit") ? false : true
            }
          />
        );
      },
    },
    {
      name: "Opsi",
      center: true,
      cell: (row: any) => {
        return (
          <>
            <IconButton
              aria-label="edit"
              color="warning"
              onClick={() => {
                navigate(`/products/store-product/${row.id}/history`);
              }}
            >
              <Search />
            </IconButton>
            {checkPermission("Product Store", "is_edit") && (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  setSubmit(false);
                  handleUpdateClick(row);
                }}
              >
                <Edit />
              </IconButton>
            )}
            {checkPermission("Product Store", "is_delete") && (
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => {
                  setDeleteID(row.id);
                  setDeleteModal(true);
                }}
              >
                <Delete />
              </IconButton>
            )}
          </>
        );
      },
    },
  ];

  const handleSave = () => {
    setSubmit(true);
    if (
      payload.store_id &&
      payload.store_price &&
      Number(payload.store_price) > 0
    ) {
      let form: CreateProductStoreTypes = {
        discount: Number(payload.discount),
        discount_type: payload.discount_type,
        is_active: true,
        final_price: Number(payload.final_price),
        product_id: Number(key),
        store_id: Number(payload.store_id),
        store_price: Number(payload.store_price),
        stock: Number(payload.stock),
        is_publish: Boolean(payload.is_publish),
        is_stock: Boolean(payload.is_stock)
      };

      thunkDispatch(assignProductStore(form))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            toast.success("Produk toko berhasil di assign!");
            setOpen(false);
            setPayload(initial);
            fetchDetail();
          } else if (res && res.status === "error") {
            toast.warning(res.error.response.data.message);
          }
        });
    }
  };

  const fetchDetail = () => {
    thunkDispatch(getDetailProductStore(Number(key)))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setProduct(res.data.data);
          console.log(res);
        }
      });
  };

  React.useEffect(() => {
    if (key) {
      fetchDetail();
    }
    thunkDispatch(getAllStore({ offset: 0, limit: 0 }))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setStores(res.data.data);
        }
      });
  }, []);

  React.useEffect(() => {
    getTotalDisc();
  }, [payload.store_price, payload.discount, payload.discount_type]);

  React.useEffect(() => {
    if (Number(payload.discount) > 100 && payload.discount_type === "percent") {
      setPayload({ ...payload, discount: "99" });
    }
  }, [payload.discount_type]);

  React.useEffect(() => {
    if (!payload.discount_type) {
      setPayload({ ...payload, final_price: Number(payload.store_price) });
    }
  }, [payload.store_price]);

  console.log(product)

  return (
    <MerchantLayout>
      <ToastContainer />
      <ConfirmationModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleConfirm={() => handleDelete()}
        description={"Apakah anda yakin ingin menghapus item ini?"}
      />
      <Dialog open={open} handleClose={() => setOpen(false)}>
        <Box sx={{ backbround: "white" }}>
          <Stack
            margin={"0 0 10px 0"}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <NormalText fontWeight="bold" fontSize={"18px"}>
              {!isCreate ? "Ubah" : ""} Harga Toko
            </NormalText>
            <IconX
              style={{ cursor: "pointer" }}
              onClick={() => setOpen(false)}
            />
          </Stack>
          <Grid item xs={12} sm={12} md={12}>
            <Dropdown
              value={payload.store_id}
              onChange={(e: any) => {
                setPayload({ ...payload, store_id: e });
              }}
              label="Pilih Toko"
              isRequired
              validationText="Toko wajib diisi"
              validationType={submit && !payload.store_id ? "ERROR" : ""}
              options={
                stores
                  ? stores.map((item) => {
                      return {
                        label: item.store_name,
                        value: item.id,
                      } as OptionProps;
                    })
                  : []
              }
            />

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <div>
                  <NormalText fontSize={"12px"}>Harga Produk (Rp) *</NormalText>
                  <CurrencyFormat
                    customInput={TextField}
                    min={0}
                    thousandSeparator={true}
                    value={payload.store_price}
                    onValueChange={(e: any) => {
                      setPayload({ ...payload, store_price: e.value });
                    }}
                  />
                  {submit && !payload.store_price ? (
                    <NormalText fontSize={"12px"} color={"#f12147"}>
                      Harga produk wajib diisi!
                    </NormalText>
                  ) : (
                    submit &&
                    Number(payload.store_price) < 0 && (
                      <NormalText fontSize={"12px"} color={"#f12147"}>
                        Harga produk tidak boleh minus!
                      </NormalText>
                    )
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div>
                  <NormalText fontSize={"12px"}>Stok Produk *</NormalText>
                  <TextField
                    type={"number"}
                    disabled={!payload.is_stock}
                    value={payload.stock}
                    validationText={"Stok produk tidak boleh kosong"}
                    onChange={(e: any) => {
                      let val = Number(e.target.value);
                      setPayload({ ...payload, stock: val });
                    }}
                    // label={"Stok Produk"}
                  ></TextField>
                </div>
              </Grid>
            </Grid>
            <NormalText fontSize={"12px"}>Stock terbatas *</NormalText>
            <Switch 
                checked={payload.is_stock}
                value={payload.is_stock}
                onChange={changeStatusStock}
              />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <Dropdown
                  value={payload.discount_type}
                  onChange={(e: any) =>
                    setPayload({ ...payload, discount_type: e })
                  }
                  label="Tipe Diskon"
                  options={discounttypes}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  type={"number"}
                  value={payload.discount}
                  disabled={!payload.discount_type}
                  validationType={
                    payload.discount_type === "percent" &&
                    Number(payload.discount) >= 100
                      ? "ERROR"
                      : ""
                  }
                  validationText={"Presentase diskon tidak boleh melebihi 99"}
                  onChange={(e: any) => {
                    let val = Number(e.target.value);
                    if (payload.discount_type === "percent") {
                      if (Number(val) >= 100) {
                        val = 99;
                      }
                    } else if (payload.discount_type === "discount") {
                      if (val > Number(payload.store_price)) {
                        val = Number(payload.store_price);
                      }
                    }

                    if (val < 0) {
                      val = 0;
                    }
                    setPayload({ ...payload, discount: String(val) });
                  }}
                  label={"Diskon"}
                ></TextField>
              </Grid>
            </Grid>
            <TextField
              label={"Harga produk setelah diskon (Rp)"}
              readonly
              value={payload.final_price}
            ></TextField>
            <NormalText fontSize={"12px"}>Publish produk *</NormalText>
            <Switch 
                checked={payload.is_publish}
                value={payload.is_publish}
                onChange={changeStatus}
              />
            <Stack direction={"row"} spacing={1} margin={"10px 0 0 0"}>
              <Button
                variant="primary"
                onClick={() => (isCreate ? handleSave() : handleUpdate())}
              >
                {isCreate ? "Simpan" : "Simpan Perubahan"}
              </Button>
              <Button variant="secondary" onClick={() => setOpen(false)}>
                Batal
              </Button>
            </Stack>
          </Grid>
        </Box>
      </Dialog>
      <Card>
        <NormalText fontWeight="bold" fontSize="20px" margin="0 0 20px 0">
          Assign Produk Toko
        </NormalText>
        <NormalText fontSize="12px">Nama Produk</NormalText>
        <NormalText fontWeight="bold" fontSize="14px">
          {product?.product_name}
        </NormalText>
        <NormalText fontSize="12px" margin="10px 0 0 0 ">
          Harga Produk
        </NormalText>
        <NormalText fontWeight="bold" fontSize="14px">
          {formatCurrency(Number(product?.product_detail.product_price ?? 0))}
        </NormalText>
        {checkPermission("Product Store", "is_add") && (
          <Button
            variant="primary"
            onClick={() => {
              setSubmit(false);
              setOpen(true);
              setIsCreate(true);
            }}
            margin={"20px 0 20px 0"}
          >
            Tambah Toko {"&"} Harga Toko
          </Button>
        )}

        <NormalText fontWeight="bold" fontSize="20px" margin="20px 0 10px 0">
          Daftar Produk Toko
        </NormalText>
        <DataTable
          columns={columns}
          data={
            product && product.productStore && product.productStore.length > 0
              ? product.productStore
              : []
          }
          responsive
          pagination
          striped
          paginationRowsPerPageOptions={[5, 10, 25, 50]}
          paginationTotalRows={
            product && product.productStore && product.productStore.length > 0
              ? product.productStore.length
              : 0
          }
          paginationPerPage={limit}
          onChangeRowsPerPage={(limit) => setLimit(limit)}
          onChangePage={(page) => setPage(page)}
        />
        <Button onClick={() => navigate("/products/store-product")}>
          Kembali
        </Button>
      </Card>
    </MerchantLayout>
  );
};

export default AssignStoreProduct;
