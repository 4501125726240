import React, { useState } from "react";
import { Stack } from "../../../components/styled/layout.styled";
import { useNavigate, useLocation } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import styles from "./styles";
import {
  Box,
  Button as ButtonMUI,
  FormHelperText,
  Switch,
} from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";
import TextField from "src/components/Inputs/TextField";
import useRedux from "src/redux/useRedux";
import PopUp from "src/components/Modal/modal";
import Dropdown, { OptionProps } from "src/components/Inputs/Dropdown";
import { createPuplist, updatePuplist } from "src/redux/puplist";

interface RouteState {
  data: any;
  statusUrl: string;
  dataStore: any;
}

const AddBrandPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { data, statusUrl, dataStore } = location.state as RouteState;

  const {
    thunkDispatch,
    storeState: { Auth, Puplist, StoreList },
  } = useRedux();

  const convertNamaStore = (id: number) => {
    let res =
      id && dataStore && dataStore.length !== 0
        ? dataStore.find((element: any) => element.id === id)
        : null;
    return res ? res.store_name : "";
  };

  const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState("");
  const [dataAdd, setDataAdd] = useState({
    name: statusUrl === "edit" ? data?.pupoint_name ?? "" : "",
    store: statusUrl === "edit" ? data?.store_id ?? 0 : 0,
    status: statusUrl === "edit" ? data?.is_active : false,
    store_name:
      statusUrl === "edit" ? convertNamaStore(data?.store_id ?? null) : 0,
  });
  const [errorValidasi, setErrorValidasi] = useState({
    name: "",
    store: "",
    status: "",
  });

  const handleEditUser = () => {
    let dataObject = {
      id: data?.id ?? "",
      pupoint_name: dataAdd.name,
      store_id: dataAdd.store,
      is_active: dataAdd.status,
    };
    thunkDispatch(updatePuplist(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setOpenModal(true);
          setStatusModal("success");
        } else {
          setOpenModal(true);
          setStatusModal("failed");
        }
      })
      .catch((err) => {
        setStatusModal("failed");
        setOpenModal(true);
        console.error(err);
      });
  };

  const handleAddUser = () => {
    let dataObject = {
      id: "",
      pupoint_name: dataAdd.name,
      store_id: dataAdd.store,
      is_active: dataAdd.status,
    };
    thunkDispatch(createPuplist(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setOpenModal(true);
          setStatusModal("success");
        } else {
          setOpenModal(true);
          setStatusModal("failed");
        }
      })
      .catch((err) => {
        setStatusModal("failed");
        setOpenModal(true);
        console.error(err);
      });
  };

  const handleValidate = () => {
    if (!dataAdd.name || !dataAdd.store) {
      setErrorValidasi({
        ...errorValidasi,
        name: !dataAdd.name ? "Nama PuP Wajib Diisi" : "",
        store: !dataAdd.store ? "Toko Wajib Diisi" : "",
        status: "",
      });
    } else if (dataAdd.name.length > 50) {
      setErrorValidasi({
        ...errorValidasi,
        name: dataAdd.name.length > 50 ? "Maks 50 karakter nama Pup" : "",
        store: "",
        status: "",
      });
    } else {
      setErrorValidasi({
        ...errorValidasi,
        name: "",
        store: "",
        status: "",
      });
      statusUrl === "edit" ? handleEditUser() : handleAddUser();
    }
  };

  return (
    <MerchantLayout>
      <PopUp open={openModal} handleClose={() => setOpenModal(!openModal)}>
        <NormalText
          textAlign="center"
          fontWeight={"bold"}
          fontSize={"20px"}
          margin={"0 0 20px 0"}
        >
          {statusModal === "success" ? "Sukses" : "Gagal"}
        </NormalText>
        <NormalText textAlign="center" fontSize={"14px"} margin={"0 0 20px 0"}>
          {statusModal === "success" ? (
            statusUrl === "edit" ? (
              <span>Berhasil mengupdate data Pickup Point</span>
            ) : (
              <span>
                Berhasil menambahkan <br /> Pickup Point baru
              </span>
            )
          ) : Puplist?.error?.response?.data?.message ? (
            <span>{Puplist.error.response.data.message}</span>
          ) : statusUrl === "edit" ? (
            <span>
              Gagal mengupate Pickup point, <br /> silahkan periksa kembali data
              anda
            </span>
          ) : (
            <span>
              Gagal menambah Pickup point, <br /> silahkan periksa kembali data
              anda
            </span>
          )}
        </NormalText>
        <Box textAlign={"center"} margin={"20px 0 0 0 "}>
          <ButtonMUI
            variant="contained"
            onClick={() =>
              statusModal === "success"
                ? navigate("/stores/pickup-point-list/list")
                : setOpenModal(!openModal)
            }
            sx={{ width: 100, height: 40, marginRight: 3 }}
            color="error"
          >
            Oke
          </ButtonMUI>
        </Box>
      </PopUp>
      <Stack direction="column">
        <Box sx={styles.boxStyled}>
          <NormalText
            textAlign="start"
            fontWeight={"bold"}
            fontSize={"18px"}
            margin={"0 0 20px 0"}
          >
            {statusUrl === "edit" ? "Edit Pickup Point" : "Tambah Pickup Point"}
          </NormalText>
          <form>
            <TextField
              type={"text"}
              label={"Nama"}
              isRequired
              value={dataAdd.name}
              onChange={(event: any) =>
                setDataAdd({
                  ...dataAdd,
                  name: event.target.value,
                })
              }
              validationType={
                errorValidasi.name || dataAdd.name.length > 50 ? "ERROR" : ""
              }
              validationText={errorValidasi.name}
              placeholder="Masukkan nama PuP anda"
            />
            <Dropdown
              label={"Toko"}
              validationType={errorValidasi.store ? "ERROR" : ""}
              validationText={errorValidasi.store}
              isRequired
              options={
                dataStore && dataStore.length !== 0
                  ? dataStore.map((item: any) => {
                      return {
                        label: item.store_name,
                        value: item.id,
                      } as OptionProps;
                    })
                  : []
              }
              value={dataAdd.store}
              onChange={(value: number) => {
                setDataAdd({
                  ...dataAdd,
                  store: value,
                  store_name: convertNamaStore(value),
                });
              }}
            />
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Status *
            </NormalText>
            <Switch
              checked={dataAdd.status}
              onChange={(e: any) =>
                setDataAdd({
                  ...dataAdd,
                  status: e.target.checked,
                })
              }
            />
            <FormHelperText style={{ color: "#f12147" }}>
              {errorValidasi.status}
            </FormHelperText>
            <Box textAlign={"start"} margin={"20px 0 0 0 "}>
              <ButtonMUI
                variant="contained"
                onClick={() => navigate("/stores/pickup-point-list/list")}
                sx={{ width: 100, height: 40, marginRight: 3 }}
                color="error"
              >
                Kembali
              </ButtonMUI>
              <ButtonMUI
                variant="contained"
                onClick={() => handleValidate()}
                sx={{ width: 100, height: 40 }}
              >
                Save
              </ButtonMUI>
            </Box>
          </form>
        </Box>
      </Stack>
    </MerchantLayout>
  );
};

export default AddBrandPage;
