import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import UserGroups from '../layout';
import useRedux from 'src/redux/useRedux';
import {
  getLoyaltySetting,
  LoyaltyData,
  deleteLoyalty,
} from 'src/redux/loyaltySettings';
import PopUp from 'src/components/Modal/modal';
import { NormalText } from 'src/components/styled/text.styled';
import Confirmation from 'src/components/Modal/confirmation';
import Tables from 'src/components/Table/tableData';
import { Button as ButtonMUI, Grid, IconButton, Box } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import checkPermission from 'src/helper/checkPermission';
import { formatCurrency } from 'src/helper/generalFunction';
import ConfirmationModal from 'src/components/Modal/confirmation';

const Page = () => {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [data, setData] = useState<LoyaltyData[]>();
  const [changeStatus, setChangeStatus] = useState(false);

  const columns = [
    {
      name: 'Sub Category',
      selector: (row: any) => {
        return (
          <div>
            <NormalText fontSize={'12px'} margin={'0px 0px 0px 0px'}>
              {row.subs_category_name}
            </NormalText>
          </div>
        );
      },
      sortable: true,
      center: true,
    },
    // {
    //   name: "Tipe Pemakaian",
    //   selector: (row: any) => {
    //     return (
    //       <div>
    //         <NormalText margin={"0px 0px 15px 0px"} fontSize={"12px"}>
    //           {row.usage_type}
    //         </NormalText>
    //       </div>
    //     );
    //   },
    //   sortable: true,
    //   center: true,
    // },
    // {
    //   name: "Nilai Pemakaian Loyalti",
    //   selector: (row: any) => {
    //     return (
    //       <div style={{ paddingTop: 10, paddingBottom: 10, height: "100%" }}>
    //         <NormalText fontSize={"12px"} margin={"0px 0px 0px 0px"}>
    //           {row.loyalty_usage_value}
    //         </NormalText>
    //       </div>
    //     );
    //   },
    //   sortable: true,
    //   center: true,
    // },
    // {
    //   name: "Maks. Nilai Loyalti",
    //   selector: (row: any) => {
    //     return (
    //       <NormalText margin={"0px 0px 0px 0px"} fontSize={"12px"}>
    //         {row.max_loyalty_usage_value}
    //       </NormalText>
    //     );
    //   },
    //   sortable: true,
    //   center: true,
    // },

    {
      name: 'Tipe Cashback',
      selector: (row: any) => {
        return (
          <NormalText margin={'0px 0px 0px 0px'} fontSize={'12px'}>
            {row.cashback_type}
          </NormalText>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: 'Nilai Cashback',

      selector: (row: any) => {
        return (
          <NormalText margin={'0px 0px 0px 0px'} fontSize={'12px'}>
            {row.cashback_type === 'Percentage'
              ? Number(row.cashback_value) + '%'
              : formatCurrency(Number(row.cashback_value))}
          </NormalText>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: 'Maks.Nilai Cashback',
      selector: (row: any) => {
        return (
          <NormalText margin={'0px 0px 0px 0px'} fontSize={'12px'}>
            {formatCurrency(Number(row.max_cashback_value))}
          </NormalText>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: 'Aksi',
      center: true,
      cell: (row: any) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {checkPermission('Loyalty Setting', 'is_edit') && (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  navigate('/setting/loyaltysetting/edit', {
                    state: { data: row, statusUrl: 'edit' },
                  });
                }}
              >
                <Edit />
              </IconButton>
            )}
            {checkPermission('Loyalty Setting', 'is_delete') && (
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => {
                  setDeleteModal(true)
                  setDeleteId(row.id)
                }}
              >
                <Delete />
              </IconButton>
            )}
          </Grid>
        );
      },
    },
  ];

  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [statusModal, setStatusModal] = useState('');
  const [orderNo, setOrderNo] = useState('');
  const {
    thunkDispatch,
    storeState: { Auth, AppSetting, LoyaltySetting },
  } = useRedux();

  const getAll = () => {
    let dataObject = {
      offset: offset - 1,
      limit: limit,
      filter: filter,
    };
    thunkDispatch(getLoyaltySetting(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == 'success') {
          console.log(res.data.data);
          if (res.data.data && res.data.data.length !== 0) {
            setData(res.data.data);
          }
          setTotalData(
            res.data && res.data.meta && res.data.meta.total
              ? res.data.meta.total
              : 0,
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    getAll();
  }, []);

  const deletLoyalty = (idLoyalty: any) => {
    let dataObject = {
      id: idLoyalty ?? '',
    };
    thunkDispatch(deleteLoyalty(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == 'success') {
          setLoading(false);
          getAll()
          setDeleteModal(false)
          setOpenModal(true);
          setStatusModal('success');
        } else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal('failed');
        }
      })
      .catch((err) => {
        setLoading(false);
        setStatusModal('failed');
        setOpenModal(true);
        console.error(err);
      });
    setLoading(false);
  };

  return (
    <MerchantLayout>
      <UserGroups tabActive={2}>
        <ConfirmationModal open={deleteModal} title={'Konfirmasi'} description={'Apakah anda yakin akan menghapus data'} handleConfirm={() => deletLoyalty(deleteId)} handleClose={() => setDeleteModal(false)} />
        <PopUp open={openModal} handleClose={() => setOpenModal(false)}>
          <NormalText
            textAlign="center"
            fontWeight={'bold'}
            fontSize={'20px'}
            margin={'0 0 20px 0'}
          >
            {statusModal === 'success' ? 'Sukses' : 'Gagal'}
          </NormalText>
          <NormalText
            textAlign="center"
            fontSize={'14px'}
            margin={'0 0 20px 0'}
          >
            {statusModal === 'success' ? (
              <span>Berhasil menghapus data loyalti</span>
            ) : LoyaltySetting?.error?.response?.data?.message ? (
              <span>{LoyaltySetting.error.response.data.message}</span>
            ) : (
              <span>
                Gagal menghapus loyalti, <br /> silahkan periksa kembali data
                anda
              </span>
            )}
          </NormalText>
          <Box textAlign={'center'} margin={'20px 0 0 0 '}>
            <ButtonMUI
              variant="contained"
              onClick={() => setOpenModal(false)}
              sx={{ width: 100, height: 40, marginRight: 3 }}
              color="error"
            >
              Oke
            </ButtonMUI>
          </Box>
        </PopUp>
        <Tables
          records={data}
          handleAddButton={() =>
            navigate('/setting/loyaltysetting/create', {
              state: { data: null, statusUrl: 'add' },
            })
          }
          columns={columns}
          title="Pengaturan Loyalti Sub Category"
          isSearch={true}
          addButton={
            checkPermission('Loyalty Setting', 'is_add') ? true : false
          }
          titleAdd="Tambah Loyalti"
          onChangeSearch={(e) => setFilter(e.target.value)}
          valueSearch={filter}
          progressPending={loading}
          paginationPerPage={limit}
          totalRow={totalData}
          onChangePage={(page) => setOffset(page)}
          onChangeRowsPerPage={(currentRowsPerPage) =>
            setLimit(currentRowsPerPage)
          }
        />
      </UserGroups>
    </MerchantLayout>
  );
};

export default Page;
