import { createAsyncThunk } from '@reduxjs/toolkit';
import { BankState, BankDetailState, BankTypes } from './types';
import { PaginationProps } from 'src/redux/types';
import ApiService from 'src/services/ApiService';
import { getToken } from 'src/helper/generalFunction';
import axios from 'axios';

export const getAllBanks = createAsyncThunk(
  'banks/get',
  async (props: PaginationProps) => {
    try {
      const config = {
        headers: {
          api_key: `${process.env.REACT_APP_API_KEY}`,
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/bankaccount?limit=${
          props.limit ?? 10
        }&offset=${props.offset ?? 0}${
          props.filter ? '&filter=' + props.filter : ''
        }`,
        config,
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as BankState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as BankState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as BankState;
    }
  },
);

export interface BankListType {
  name: string;
  code: string;
  is_activated: boolean;
}

export const getMasterBankList = async () => {
  const config = {
    headers: {
      api_key: `${process.env.REACT_APP_API_KEY}`,
    },
  };

  let response = await axios.get(
    `${process.env.REACT_APP_API_URL}/master/check-availble-bank`,
    config,
  );

  if (response) {
    if (response.status === 200) {
      return response.data.data as BankListType[];
    }
  } else {
    return [] as BankListType[];
  }
};

export const createNewBank = createAsyncThunk(
  'banks/post',
  async (props: BankTypes) => {
    try {
      const config = {
        headers: {
          api_key: `${process.env.REACT_APP_API_KEY}`,
          token: getToken(),
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/merchant/bankaccount`,
        props,
        config,
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as BankState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as BankState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as BankState;
    }
  },
);

export const updateBank = createAsyncThunk(
  'banks/put',
  async (props: BankTypes) => {
    try {
      const config = {
        headers: {
          api_key: `${process.env.REACT_APP_API_KEY}`,
          token: getToken(),
        },
      };
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/merchant/bankaccount/${props.id}`,
        {
          bank_name: props.bank_name,
          account_number: props.account_number,
          account_name: props.account_name,
          is_primary: props.is_primary,
        },
        config,
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as BankState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as BankState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as BankState;
    }
  },
);

export const updatePrimaryBank = createAsyncThunk(
  'banks/primary/put',
  async (props: { id: number | string; is_primary: boolean }) => {
    try {
      const config = {
        headers: {
          api_key: `${process.env.REACT_APP_API_KEY}`,
          token: getToken(),
        },
      };
      const response = await ApiService.put(
        process.env.REACT_APP_API_URL +
          '/merchant/bankaccount/change-primary-account',
        props,
        config,
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as BankState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as BankState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as BankState;
    }
  },
);

export const removeBank = createAsyncThunk(
  'banks/delete',
  async (bank_id: string) => {
    try {
      const config = {
        headers: {
          api_key: `${process.env.REACT_APP_API_KEY}`,
          token: getToken(),
        },
      };
      const response = await ApiService.delete(
        `${process.env.REACT_APP_API_URL}/merchant/bankaccount/${bank_id}`,
        config,
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as BankState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as BankState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as BankState;
    }
  },
);
