import { createAsyncThunk } from "@reduxjs/toolkit";
import { RoleState, CreateRoleTypes, ShowRoleState } from "./types";
import ApiService from "src/services/ApiService";
import axios from "axios";
import { getToken } from 'src/helper/generalFunction';

export interface RoleListProps {
    offset?: number;
    limit?: number;
    filter?: string;
}

export const getRole = createAsyncThunk('role/get', async (props: RoleListProps) => {
    try {
        const config = {
            headers:{
                token: getToken()
            }
        };        
        const response = await ApiService.get(`${process.env.REACT_APP_API_URL}/merchant/role/list?limit=${props.limit ?? 0}&offset=${props.offset ?? 0}${props.filter ? '&filter=' + props.filter : ''}`,config)
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as RoleState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as RoleState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as RoleState;

    }
})

export interface ShowRoleProps {
    role_id: number;
    access_token: string;
}

export const showRole = createAsyncThunk('role/show', async (props: ShowRoleProps) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/merchant/role/${props.role_id}/detailrole`, {
            headers: {
                api_key: 'Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e',
                token: props.access_token
            }
        })
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as ShowRoleState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as ShowRoleState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as ShowRoleState;

    }
})

export const createRole = createAsyncThunk('role/post', async (props: CreateRoleTypes) => {
    try {
        const { key, description, features, name, is_cashier } = props;
        const response = await axios.post(process.env.REACT_APP_API_URL + '/merchant/role', JSON.stringify({
            key, name, description, features, is_cashier
        }), {
            headers: {
                api_key: process.env.REACT_APP_API_KEY,
                token: props.access_token,
                "Content-Type": 'application/json',
            }
        })
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as RoleState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as RoleState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as RoleState;

    }
})
export const updateRole = createAsyncThunk('role/update', async (props: CreateRoleTypes) => {
    try {
        const { key, description, features, name, is_cashier } = props;
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/merchant/role/${props.id}/updaterole`, JSON.stringify({
            key, name, description, features, is_cashier
        }), {
            headers: {
                api_key: process.env.REACT_APP_API_KEY,
                token: props.access_token,
                "Content-Type": 'application/json',
            }
        })
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as RoleState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as RoleState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as RoleState;

    }
})

export interface DeleteRoleProps {
    role_id: number;
    access_token: string;
}

export const removeRole = createAsyncThunk('role/delete', async (props: DeleteRoleProps) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/merchant/role/${props.role_id}/deleterole`, {
            headers: {
                api_key: process.env.REACT_APP_API_KEY,
                token: props.access_token,
                "Content-Type": 'application/json',
            }
        })
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as RoleState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as RoleState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as RoleState;

    }
})