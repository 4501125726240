import React from "react";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import { useNavigate, useParams } from "react-router-dom";
import useRedux from "src/redux/useRedux";
import Card from "src/components/Cards/card";
import { NormalText } from "src/components/styled/text.styled";
import { Button } from "src/components/styled/button.styled";
import { Switch } from "@mui/material";
import {
  getDetailProductStore,
  ProductStoreType,
} from "src/redux/productStore";
import {
  getDetailProductAddOns,
  ProductAddOnDataDetailReponse,
} from "src/redux/productAddon";
import DataTable from "react-data-table-component";

const AssignProductAddOn = () => {
  const navigate = useNavigate();
  const { key } = useParams();
  const { thunkDispatch } = useRedux();
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [product, setProduct] = React.useState<ProductStoreType>();
  const [productAddons, setProductAddons] =
    React.useState<ProductAddOnDataDetailReponse>();

  const columns = [
    {
      name: "Product",
      selector: (row: any) => row.product_name,
      sortable: true,
    },
    {
      name: "Tipe",
      selector: (row: any) => row.product_type,
      sortable: true,
    },
    {
      name: "Status",
      center: true,
      cell: (row: any) => {
        return <Switch checked={row.is_active} onChange={() => null} />;
      },
    },
  ];

  const fetchListAssignedAddon = (id: number) => {
    thunkDispatch(
      getDetailProductAddOns({
        product_id: id,
        limit: limit,
        offset: page,
      })
    )
      .unwrap()
      .then((response) => {
        if (response && response.status === "success") {
          console.log("AAA", response.data);
          setProductAddons(response.data);
        }
      });
  };

  const fetchDetail = () => {
    thunkDispatch(getDetailProductStore(Number(key)))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setProduct(res.data.data);
          fetchListAssignedAddon(res.data.data.product_id);
        }
      });
  };

  React.useEffect(() => {
    if (key) {
      fetchDetail();
    }
  }, []);

  React.useEffect(() => {
    fetchListAssignedAddon(Number(key));
  }, [limit, page]);

  return (
    <MerchantLayout>
      <Card>
        <NormalText fontWeight="bold" fontSize="20px" margin="0 0 20px 0">
          Assign Produk Add-On
        </NormalText>
        <NormalText fontSize="12px">Nama Produk</NormalText>
        <NormalText fontWeight="bold" fontSize="14px">
          {product?.product_name}
        </NormalText>

        <NormalText fontWeight="bold" fontSize="20px" margin="20px 0 10px 0">
          Daftar Produk Add-On
        </NormalText>
        <DataTable
          columns={columns}
          data={
            productAddons && productAddons.data && productAddons.data.length > 0
              ? productAddons.data
              : []
          }
          responsive
          pagination
          striped
          paginationRowsPerPageOptions={[5, 10, 25, 50]}
          paginationTotalRows={
            productAddons &&
            productAddons.meta &&
            productAddons.meta &&
            productAddons.meta.total
              ? productAddons.meta.total
              : 0
          }
          paginationPerPage={limit}
          onChangeRowsPerPage={(limit) => setLimit(limit)}
          onChangePage={(page) => setPage(page)}
        />
        <Button onClick={() => navigate("/products/product-addon")}>
          Kembali
        </Button>
      </Card>
    </MerchantLayout>
  );
};

export default AssignProductAddOn;
