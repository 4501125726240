import { useState, useEffect } from "react";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import { useNavigate } from "react-router-dom";
import useRedux from "src/redux/useRedux";
import Card from "src/components/Cards/card";
import { NormalText } from "src/components/styled/text.styled";
import { Button } from "src/components/styled/button.styled";
import TextField from "src/components/Inputs/TextField";
import Dropdown, { OptionProps } from "src/components/Inputs/Dropdown";
import {
  Box,
  Button as ButtonMUI,
  FormHelperText,
  Grid,
  Stack,
  CircularProgress,
  TextField as TextFieldMUI,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { ProvincesData, getAllProvinces } from "src/redux/regions/province";
import { CityData, getAllCity } from "src/redux/regions/city";
import { getAllSubUrban, SubUrbanData } from "src/redux/regions/suburban";
import { getAllArea, AreaData } from "src/redux/regions/area";
import { ToastContainer, toast } from "react-toastify";
import { uploadImage } from "src/helper/uploadFile";
import styled from "styled-components";
import { createNewGroupStore } from "src/redux/storelist";

const UploadLabel = styled.label`
  width: 9%;
  height: 40px;
  background-color: white;
  color: black;
  padding: 10px;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 2px;
  border: 1px solid #1985d2;
  text-align: center;
  &:hover {
    background-color: #1985d2;
    color: #fff;
  }
`;

const GroupStoreForm = () => {
  const navigate = useNavigate();
  const { thunkDispatch } = useRedux();
  const [provinces, setProvinces] = useState<ProvincesData[]>();
  const [cities, setCities] = useState<CityData[]>();
  const [listSubUrban, setListSubUrban] = useState<SubUrbanData[]>();
  const [areas, setAreas] = useState<AreaData[]>();
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [geolocation, setGeolocation] = useState({
    lat: "",
    long: "",
  });
  const [payload, setPayload] = useState({
    group_name: "",
    group_logo: "",
    address: "",
    address_type: "false",
    phone: "",
    province_id: 0,
    city_id: 0,
    suburb_id: 0,
    area_id: 0,
    url_gmap: "",
  });

  const [errorValidasi, setErrorValidasi] = useState({
    logoGroupToko: "",
  });

  const [loadingImg, setLoadingImg] = useState(false);
  const [disabledField, setDisabledField] = useState(true);

  const fetchCities = (prov_id: number) => {
    thunkDispatch(getAllCity(prov_id))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          console.log("C", res.data.data);
          setCities(res.data.data.cities);
        }
      });
  };

  const fetchSuburban = (city_id: number) => {
    thunkDispatch(getAllSubUrban(city_id))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setListSubUrban(res.data.data.suburbs);
        }
      });
  };

  const fetchArea = (suburb_id: number) => {
    thunkDispatch(getAllArea(suburb_id))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setAreas(res.data.data.areas);
        }
      });
  };

  const validMapsUrl = (url: string) => {
    var regex = new RegExp("@(.*),(.*),");
    return url.match(regex);
  };

  const validPhone = () => {
    if (
      payload.phone &&
      String(payload.phone)[0] === "0" &&
      payload.phone.length >= 7
    ) {
      return true;
    } else {
      return false;
    }
  };

  const v2getLatLong = (url: string) => {
    var longlat = /\/\@(.*),(.*),/.exec(url);
    if (longlat) {
      console.log("#2", longlat);
    }
  };

  const getGolocation = (url: string) => {
    var lat_long_match = validMapsUrl(url);
    console.log("Maps URL", url);
    console.log("#1", lat_long_match);
    v2getLatLong(url);
    if (lat_long_match) {
      var lat = lat_long_match[1];
      var long = lat_long_match[2];
      setGeolocation({
        lat: lat,
        long: long,
      });
      setInvalidUrl(false);
    } else {
      setInvalidUrl(true);
    }
  };

  useEffect(() => {
    thunkDispatch(getAllProvinces())
      .unwrap()
      .then((res: any) => {
        if (res && res.status === "success") {
          setProvinces(res.data.data);
        }
      });
  }, []);

  const handleImage = (e: any, data: string) => {
    if (e.target.files && e.target.files[0]) {
      let imageName = e.target.files[0];
      let fileSize = imageName.size / (1024 * 1024);
      var img = new Image();
      img.src = window.URL.createObjectURL(imageName);
      img.onload = function () {
        let width = img.naturalWidth;
        let height = img.naturalHeight;

        let ValidHeight = 300;
        let ValidWidth = 300;

        if (width > ValidWidth || height > ValidHeight || fileSize > 2) {
          setErrorValidasi({
            ...errorValidasi,
            [data]: `Resolusi gambar maks. ${ValidWidth}x${ValidHeight} & ukuran maks. 2MB`,
          });
        } else {
          handleUploadFile(imageName, data).then((res: any) => {
            console.log("OK");
          });
          setErrorValidasi({
            ...errorValidasi,
            [data]: "",
          });
        }
      };
    }
  };

  const handleUploadFile = async (file: any, typeUpload: string) => {
    let nameUpload = typeUpload;
    setLoadingImg(true);
    let formdata = new FormData();
    formdata.append("image_count", "1");
    formdata.append("image-1", file);
    await uploadImage(formdata, "setting")
      .then((res) => {
        if (res.status === 200) {
          setLoadingImg(false);
          setPayload({
            ...payload,
            group_logo: res.data.data["image-1"],
          });
        }
        setLoadingImg(false);
      })
      .catch(() => {
        setLoadingImg(false);
        setErrorValidasi({
          ...errorValidasi,
          [nameUpload]: "Gagal mengupload image",
        });
      });
  };

  const handleSubmit = () => {
    setIsSubmit(true);
    let payloadData: any = { ...payload };
    payloadData.address_type =
      payloadData.address_type === "true" ? true : false;

    if (payloadData.address_type === true) {
      if (
        payloadData.group_name &&
        payloadData.address &&
        payloadData.address_type &&
        payloadData.province_id != 0 &&
        payloadData.city_id != 0 &&
        payloadData.suburb_id != 0 &&
        payloadData.area_id != 0 &&
        payloadData.group_name.length <= 50 &&
        validPhone() &&
        validMapsUrl(payload.url_gmap)
      ) {
        thunkDispatch(createNewGroupStore(payloadData))
          .unwrap()
          .then((res) => {
            if (res && res.status === "success") {
              toast.success("Berhasil menambahkan group toko");
              setTimeout(() => {
                navigate("/stores/store/list");
              }, 1500);
            } else {
              toast.error("Terjadi kesalahan, silahkan coba lagi");
            }
          })
          .catch((err) => {
            toast.error("Terjadi kesalahan, silahkan coba lagi");
            if (process.env.NODE_ENV !== "production") {
              console.log("Got Error", err);
            }
          });
      } else {
        toast.warning("Silahkan cek kembali inputan anda!");
      }
    } else {
      if (payloadData.group_name) {
        thunkDispatch(createNewGroupStore(payloadData))
          .unwrap()
          .then((res) => {
            if (res && res.status === "success") {
              toast.success("Berhasil menambahkan group toko");
              setTimeout(() => {
                navigate("/stores/store/list");
              }, 1500);
            } else {
              toast.error("Terjadi kesalahan, silahkan coba lagi");
            }
          })
          .catch((err) => {
            toast.error("Terjadi kesalahan, silahkan coba lagi");
          });
      } else {
        toast.warning("Silahkan cek kembali inputan anda!");
      }
    }
  };

  const handleDisable = () => {
    if (payload.address_type === "false") {
      setDisabledField(true);
    } else {
      setDisabledField(false);
    }
  };

  const handleDeleteLogo = () => {
    setLoadingImg(true);
    setPayload({ ...payload, group_logo: "" });
    setTimeout(() => {
      setLoadingImg(false);
    }, 500);
  };

  useEffect(() => {
    handleDisable();
  }, [payload.address_type]);

  return (
    <MerchantLayout>
      <ToastContainer />
      <Card>
        <NormalText fontWeight="bold" fontSize="20px" margin="0 0 20px 0">
          Tambah Group Baru
        </NormalText>

        <TextField
          label={"Nama Group"}
          isRequired
          validationText={
            isSubmit && !payload.group_name
              ? "Nama group wajib diisi"
              : isSubmit && payload.group_name.length > 50
              ? "Maksimal karakter untuk nama group 50 karakter"
              : ""
          }
          validationType={
            (isSubmit && !payload.group_name) ||
            (isSubmit && payload.group_name.length > 50)
              ? "ERROR"
              : ""
          }
          value={payload.group_name}
          onChange={(e: any) => {
            setPayload({ ...payload, group_name: e.target.value });
          }}
        ></TextField>

        <NormalText fontSize={"12px"} margin="0px 0 5px 0">
          Apakah toko Anda terdapat di alamat yang berbeda?
        </NormalText>

        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            value={payload.address_type}
            name="address_type"
            onChange={(e: any) => {
              setPayload({
                ...payload,
                address_type: e.target.value,
              });
            }}
          >
            <FormControlLabel
              value={"true"}
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 14,
                    },
                  }}
                />
              }
              label={<NormalText fontSize="12px">Satu Alamat</NormalText>}
            />
            <FormControlLabel
              value={"false"}
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 14,
                    },
                  }}
                />
              }
              label={<NormalText fontSize="12px">Berbeda - beda</NormalText>}
            />
          </RadioGroup>
        </FormControl>

        <TextField
          label={"Alamat"}
          isRequired={payload.address_type === "true"}
          validationText="Alamat wajib diisi"
          validationType={
            isSubmit && !payload.address && payload.address_type === "true"
              ? "ERROR"
              : ""
          }
          value={payload.address}
          onChange={(e: any) => {
            setPayload({ ...payload, address: e.target.value });
          }}
          disabled={disabledField}
        ></TextField>

        <Grid container spacing={2} marginBottom={"10px"}>
          <Grid item xs={3}>
            <Dropdown
              label={"Provinsi"}
              validationType={
                isSubmit &&
                payload.province_id === 0 &&
                payload.address_type === "true"
                  ? "ERROR"
                  : ""
              }
              validationText={"Provinsi wajib diisi"}
              isRequired={payload.address_type === "true"}
              options={
                provinces
                  ? provinces.map((item) => {
                      return {
                        label: item.province_name,
                        value: item.id,
                      } as OptionProps;
                    })
                  : []
              }
              value={payload.province_id}
              onChange={(value: number) => {
                fetchCities(value);
                setListSubUrban([]);
                setAreas([]);
                setPayload({
                  ...payload,
                  province_id: value,
                  city_id: 0,
                  suburb_id: 0,
                  area_id: 0,
                });
              }}
              disabled={disabledField}
            />
          </Grid>
          <Grid item xs={3}>
            <Dropdown
              label={"Kota"}
              isRequired={payload.address_type === "true"}
              validationType={
                isSubmit &&
                payload.city_id === 0 &&
                payload.address_type === "true"
                  ? "ERROR"
                  : ""
              }
              validationText={"Kota wajib diisi"}
              options={
                cities
                  ? cities.map((item) => {
                      return {
                        label: item.city_name,
                        value: item.id,
                      } as OptionProps;
                    })
                  : []
              }
              value={payload.city_id}
              onChange={(value: number) => {
                fetchSuburban(value);
                setAreas([]);
                setPayload({
                  ...payload,
                  city_id: value,
                  suburb_id: 0,
                  area_id: 0,
                });
              }}
              disabled={disabledField}
            />
          </Grid>
          <Grid item xs={3}>
            <Dropdown
              label={"Kecamatan"}
              isRequired={payload.address_type === "true"}
              validationType={
                isSubmit &&
                payload.suburb_id === 0 &&
                payload.address_type === "true"
                  ? "ERROR"
                  : ""
              }
              validationText={"Kecamatan wajib diisi"}
              options={
                listSubUrban
                  ? listSubUrban.map((item) => {
                      return {
                        label: item.suburbName,
                        value: item.id,
                      } as OptionProps;
                    })
                  : []
              }
              value={payload.suburb_id}
              onChange={(value: any) => {
                setPayload({ ...payload, suburb_id: value });
                fetchArea(value);
              }}
              disabled={disabledField}
            />
          </Grid>
          <Grid item xs={3}>
            <Dropdown
              label={"Kelurahan"}
              isRequired={payload.address_type === "true"}
              validationType={
                isSubmit &&
                payload.area_id === 0 &&
                payload.address_type === "true"
                  ? "ERROR"
                  : ""
              }
              validationText={"Kelurahan wajib diisi"}
              options={
                areas
                  ? areas.map((item) => {
                      return {
                        label: item.area_name,
                        value: item.id,
                      } as OptionProps;
                    })
                  : []
              }
              value={payload.area_id}
              onChange={(value: number) => {
                setPayload({ ...payload, area_id: value });
              }}
              disabled={disabledField}
            />
          </Grid>
        </Grid>

        <TextField
          label={"Nomor Telepon"}
          isRequired={payload.address_type === "true"}
          type={"number"}
          variant={"tel"}
          validationText={
            isSubmit && !payload.phone
              ? "Nomor telepon wajib diisi"
              : (isSubmit && payload.phone && payload.phone.length < 7) ||
                (isSubmit && payload.phone && String(payload.phone)[0] !== "0")
              ? "Panjang nomor telepon min. 7 digit dan diawali dengan angka nol"
              : ""
          }
          validationType={
            (isSubmit && !payload.phone && payload.address_type === "true") ||
            (isSubmit &&
              payload.phone.length < 7 &&
              payload.address_type === "true") ||
            (isSubmit &&
              String(payload.phone)[0] !== "0" &&
              payload.address_type === "true")
              ? "ERROR"
              : ""
          }
          value={payload.phone}
          onChange={(e: any) => {
            let v = e.target.value;
            if (e.target.value < 0) {
              v = 0;
            }
            setPayload({ ...payload, phone: v });
          }}
          disabled={disabledField}
        ></TextField>

        <TextField
          label={"URL Google Maps"}
          isRequired={payload.address_type === "true"}
          validationText={
            invalidUrl
              ? "Url yang and masukan tidak valid"
              : isSubmit && !payload.url_gmap
              ? "URL Google Maps wajib diisi"
              : ""
          }
          validationType={
            invalidUrl ||
            (isSubmit && !payload.url_gmap && payload.address_type === "true")
              ? "ERROR"
              : ""
          }
          value={payload.url_gmap}
          onChange={(e: any) => {
            setPayload({ ...payload, url_gmap: e.target.value });
            getGolocation(e.target.value);
          }}
          disabled={disabledField}
        ></TextField>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label={"Latitude"}
              isRequired={payload.address_type === "true"}
              readonly
              value={geolocation.lat}
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={"Longitude"}
              isRequired={payload.address_type === "true"}
              readonly
              value={geolocation.long}
            ></TextField>
          </Grid>
        </Grid>

        <Box>
          <NormalText textAlign="left" margin={"7px 0 5px 0"} fontSize={"12px"}>
            Logo Group
          </NormalText>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <UploadLabel
                htmlFor="logoGroupToko"
                style={{
                  width: "100%",
                  backgroundColor: "#ccc",
                  borderColor: "#ccc",
                }}
              >
                {loadingImg ? <CircularProgress size={15} /> : null}{" "}
                {loadingImg ? "" : "Pilih Gambar"}
              </UploadLabel>
              {payload && payload.group_logo ? (
                <UploadLabel
                  style={{
                    width: "100%",
                    backgroundColor: "#E56C75",
                    borderColor: "#E56C75",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={handleDeleteLogo}
                >
                  {loadingImg ? <CircularProgress size={15} /> : null}{" "}
                  {loadingImg ? "" : "Hapus Gambar"}
                </UploadLabel>
              ) : null}
              <input
                accept="image/*"
                id="logoGroupToko"
                multiple
                type="file"
                onChange={(e: any) => handleImage(e, "logoGroupToko")}
                style={{ display: "none" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              {payload.group_logo && (
                <img
                  src={payload.group_logo}
                  style={{ height: 150, width: 150, marginLeft: "30px" }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              )}
            </Grid>
          </Grid>
          <FormHelperText style={{ color: "#1565c0" }}>
            Resolusi gambar maks. 300x300 Pixels {"&"} ukuran maks. 2MB
          </FormHelperText>
          <FormHelperText style={{ color: "#f12147" }}>
            {errorValidasi.logoGroupToko}
          </FormHelperText>
        </Box>

        <Stack direction={"row"} spacing={2} margin={"20px 0 20px 0"}>
          <Button
            variant="danger"
            onClick={() => navigate("/stores/store/list")}
          >
            Batal
          </Button>
          <Button variant="primary" onClick={() => handleSubmit()}>
            Simpan
          </Button>
        </Stack>
      </Card>
    </MerchantLayout>
  );
};

export default GroupStoreForm;
