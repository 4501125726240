import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CreateVoucherProps,
  CreateVoucherState,
  ListVoucherState,
  ListVoucherProps,
  DetailVoucherState,
  UpdateVoucherState,
  UpdateVoucherProps,
  DeleteVoucherState,
  DetailAssignVoucherToStoreState,
  AssignStoreProps,
} from "./types";

import { getToken } from "src/helper/generalFunction";
import axios from "axios";

export const getVouchers = createAsyncThunk(
  "vouchers/get",
  async (props: ListVoucherProps) => {
    try {
      const config = {
        headers: {
          api_key: process.env.REACT_APP_API_KEY,
          token: getToken(),
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchants/vouchers?limit=${
          props.limit ?? 0
        }&offset=${props.offset ?? 0}${
          props.filter ? "&filter=" + props.filter : ""
        }`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as ListVoucherState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as ListVoucherState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as ListVoucherState;
    }
  }
);

export const detailVoucher = createAsyncThunk(
  "voucher/detail",
  async (id: any) => {
    try {
      const config = {
        headers: {
          api_key: process.env.REACT_APP_API_KEY,
          token: getToken(),
          "Content-Type": "application/json",
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchants/vouchers/${id}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as DetailVoucherState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as DetailVoucherState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as DetailVoucherState;
    }
  }
);

export const createVoucher = createAsyncThunk(
  "voucher/create",
  async (props: CreateVoucherProps) => {
    try {
      const {
        value,
        purchasePrice,
        expiryDay,
        name,
        code,
        description,
        voucherType,
        valueText,
        howToUse,
      } = props;

      const config = {
        headers: {
          api_key: process.env.REACT_APP_API_KEY,
          token: getToken(),
          "Content-Type": "application/json",
        },
      };

      const body = {
        value: value,
        purchasePrice: purchasePrice,
        expiryDay: expiryDay,
        name: name,
        code: code,
        description: description,
        voucherType: voucherType,
        valueText: valueText,
        howToUse: howToUse,
      };

      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/merchants/vouchers/create",
        body,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as CreateVoucherState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as CreateVoucherState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as CreateVoucherState;
    }
  }
);

export const updateVoucher = createAsyncThunk(
  "voucher/update",
  async (props: UpdateVoucherProps) => {
    try {
      const {
        id,
        value,
        purchasePrice,
        expiryDay,
        name,
        code,
        description,
        voucherType,
        valueText,
        howToUse,
      } = props;

      const config = {
        headers: {
          api_key: process.env.REACT_APP_API_KEY,
          token: getToken(),
          "Content-Type": "application/json",
        },
      };

      const body = {
        value: value,
        purchasePrice: purchasePrice,
        expiryDay: expiryDay,
        name: name,
        code: code,
        description: description,
        voucherType: voucherType,
        valueText: valueText,
        howToUse: howToUse,
      };

      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/merchants/vouchers/edit/${id}`,
        body,
        config
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as UpdateVoucherState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as UpdateVoucherState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as UpdateVoucherState;
    }
  }
);

export const deleteVoucher = createAsyncThunk(
  "voucher/delete",
  async (id: any) => {
    try {
      const config = {
        headers: {
          api_key: process.env.REACT_APP_API_KEY,
          token: getToken(),
          "Content-Type": "application/json",
        },
      };

      const response = await axios.delete(
        process.env.REACT_APP_API_URL + `/merchants/vouchers/delete/${id}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as DeleteVoucherState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as DeleteVoucherState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as DeleteVoucherState;
    }
  }
);

export const assignVoucherToStore = createAsyncThunk(
  "voucher/assign-to-store",
  async (props: AssignStoreProps) => {
    try {
      const { voucher_id, store_id, is_edit } = props;

      const config = {
        headers: {
          api_key: process.env.REACT_APP_API_KEY,
          token: getToken(),
          "Content-Type": "application/json",
        },
      };

      const body = {
        voucher_id: voucher_id,
        store_id: store_id,
        is_edit: is_edit.toString(),
      };

      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/merchants/vouchers/assign-to-store",
        body,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as CreateVoucherState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as CreateVoucherState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as CreateVoucherState;
    }
  }
);

export const detailAssignVoucherToStore = createAsyncThunk(
  "voucher/detail-assign-to-store",
  async (id: any) => {
    try {
      const config = {
        headers: {
          api_key: process.env.REACT_APP_API_KEY,
          token: getToken(),
          "Content-Type": "application/json",
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchants/vouchers/get-available-store/${id}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as DetailAssignVoucherToStoreState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as DetailAssignVoucherToStoreState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as DetailAssignVoucherToStoreState;
    }
  }
);
