import React, { useState, useEffect } from 'react';
import { Stack } from '../../../../components/styled/layout.styled';
import UserGroups from '../layout';
import { useNavigate } from 'react-router-dom';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import styles from './styles';
import {
  Box,
  Autocomplete,
  Button as ButtonMUI,
  TextField as TextFieldMUI,
  FormHelperText,
} from '@mui/material';
import { NormalText } from 'src/components/styled/text.styled';
import TextField from 'src/components/Inputs/TextField';
import { getRole, RoleData } from 'src/redux/role';
import useRedux from 'src/redux/useRedux';
import { updatePasswordMerchant } from 'src/redux/profile';
import PopUp from 'src/components/Modal/modal';
import { AuthLogout } from "src/redux/auth";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [roles, setRoles] = React.useState<RoleData[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState('');
  const [dataAdd, setDataAdd] = useState({
    oldPassword: '',
    newPassword: '',
    veriticationPassword: '',
  });
  const [loading, setLoading] = useState(true);
  const [errorValidasi, setErrorValidasi] = useState({
    oldPassword: '',
    newPassword: '',
    veriticationPassword: '',
  });

  const {
    thunkDispatch,
    storeState: { Auth , Profile},
  } = useRedux();

  const handleLogout = () => {
    thunkDispatch(AuthLogout());
    navigate("/login");
  };

  const handleAddUser = () => {
    let dataObject = {
      data: {
        old_password: dataAdd.oldPassword,
        password: dataAdd.newPassword,
        confirm_password: dataAdd.veriticationPassword,
      },
      id_user: 0,
    };
    thunkDispatch(updatePasswordMerchant(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == 'success') {
          setLoading(false);
          setOpenModal(true);
          setStatusModal('success');
        } else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal('failed');
        }
      })
      .catch((err) => {
        setLoading(false);
        setStatusModal('failed');
        setOpenModal(true);
        console.error(err);
      });
    setLoading(false);
  };

  const handleValidate = () => {
    if (
      !dataAdd.oldPassword ||
      !dataAdd.newPassword ||
      !dataAdd.veriticationPassword
    ) {
      setErrorValidasi({
        ...errorValidasi,
        oldPassword: !dataAdd.oldPassword ? 'Kata Sandi Lama wajib diisi' : '',
        newPassword: !dataAdd.newPassword ? 'Kata Sandi Baru wajib diisi' : '',
        veriticationPassword: !dataAdd.veriticationPassword
          ? 'Veritifikasi Kata sandi wajib diisi'
          : '',
      });
    } else if (dataAdd.newPassword !== dataAdd.veriticationPassword) {
      setErrorValidasi({
        ...errorValidasi,
        oldPassword: '',
        newPassword: 'Kata sandi baru tidak sama dengan Vertifikasi kata sandi',
        veriticationPassword:
          'Kata sandi baru tidak sama dengan Vertifikasi kata sandi',
      });
    } else {
      setErrorValidasi({
        ...errorValidasi,
        oldPassword: '',
        newPassword: '',
        veriticationPassword: '',
      });
      handleAddUser();
    }
  };

  return (
    <MerchantLayout>
      <PopUp open={openModal} handleClose={() => setOpenModal(!openModal)}>
        <NormalText
          textAlign="center"
          fontWeight={'bold'}
          fontSize={'20px'}
          margin={'0 0 20px 0'}
        >
          {statusModal === 'success' ? 'Sukses' : 'Gagal'}
        </NormalText>
        <NormalText textAlign="center" fontSize={'14px'} margin={'0 0 20px 0'}>
          {statusModal === 'success' ? (
            <span>Berhasil mengupdate kata sandi</span>
          ) : Profile?.error?.response?.data?.message ? (
            <span>{Profile.error.response.data.message}</span>
          ) : (
            <span>
              Gagal mengupdate kata sandi, <br /> silahkan periksa kembali data anda
            </span>
          )}
        </NormalText>
        <Box textAlign={'center'} margin={'20px 0 0 0 '}>
          <ButtonMUI
            variant="contained"
            onClick={() =>
              statusModal === 'success'
                ? handleLogout()
                : setOpenModal(!openModal)
            }
            sx={{ width: 100, height: 40, marginRight: 3 }}
            color="error"
          >
            Oke
          </ButtonMUI>
        </Box>
      </PopUp>
      <UserGroups tabActive={1}>
        <Stack direction="column">
          <Box sx={styles.boxStyled}>
            <NormalText
              textAlign="start"
              fontWeight={'bold'}
              fontSize={'18px'}
              margin={'0 0 20px 0'}
            >
              Ubah Kata Sandi
            </NormalText>
            <form>
              <NormalText
                textAlign="left"
                margin={'20px 0 5px 0'}
                fontSize={'14px'}
              >
                Kata Sandi Lama *
              </NormalText>
              <TextField
                type={'password'}
                style={{ height: 45 }}
                value={dataAdd.oldPassword}
                onChange={(event: any) =>
                  setDataAdd({
                    ...dataAdd,
                    oldPassword: event.target.value,
                  })
                }
                validationType={errorValidasi.oldPassword ? 'ERROR' : ''}
                validationText={errorValidasi.oldPassword}
                placeholder="Masukkan kata sandi lama anda "
              />
              <NormalText
                textAlign="left"
                margin={'20px 0 5px 0'}
                fontSize={'14px'}
              >
                Kata Sandi Baru *
              </NormalText>
              <TextField
                type={'password'}
                style={{ height: 45 }}
                value={dataAdd.newPassword}
                onChange={(event: any) =>
                  setDataAdd({
                    ...dataAdd,
                    newPassword: event.target.value,
                  })
                }
                validationType={errorValidasi.newPassword ? 'ERROR' : ''}
                validationText={errorValidasi.newPassword}
                placeholder="Masukkan kata Sandi baru anda"
              />
              <NormalText
                textAlign="left"
                margin={'20px 0 5px 0'}
                fontSize={'14px'}
              >
                Veritifikasi Kata Sandi *
              </NormalText>
              <TextField
                type={'password'}
                value={dataAdd.veriticationPassword}
                style={{ height: 45 }}
                onChange={(event: any) =>
                  setDataAdd({
                    ...dataAdd,
                    veriticationPassword: event.target.value,
                  })
                }
                validationType={
                  errorValidasi.veriticationPassword ? 'ERROR' : ''
                }
                validationText={errorValidasi.veriticationPassword}
                placeholder="Masukkan verifikasi kata sandi anda"
              />
              <Box textAlign={'start'} margin={'20px 0 0 0 '}>
                {/* <Button
                  variant={"danger"}
                  margin={"0 10px 0 0"}
                  onClick={() => navigate("/users/list")}
                >
                  CANCEL
                </Button> */}
                <ButtonMUI
                  variant="contained"
                  onClick={() => navigate('/')}
                  sx={{ width: 100, height: 40, marginRight: 3 }}
                  color="error"
                >
                  Batal
                </ButtonMUI>
                <ButtonMUI
                  variant="contained"
                  onClick={() => handleValidate()}
                  sx={{ width: 100, height: 40 }}
                >
                  Simpan
                </ButtonMUI>
                {/* <Button variant={"primary"} onClick={() => (handleValidate())}>
                  Save
                </Button> */}
              </Box>
            </form>
          </Box>
        </Stack>
      </UserGroups>
    </MerchantLayout>
  );
};

export default ResetPasswordPage;
