import { OrderResponseProps, OrderState } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import { getList, updateStatus } from "./orderService";
// create user
export const initialState: OrderState = {
  data: {} as OrderResponseProps,
  status: "loading",
  error: {},
};

export const OrderSlice = createSlice({
  name: "Order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getList.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(getList.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      })
      .addCase(updateStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(updateStatus.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      });
  },
});

export const OrderReducer = OrderSlice.reducer;
