import React, { useState } from 'react';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import TextField from 'src/components/Inputs/TextField';
import {
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
} from '@mui/material';
import { NormalText } from 'src/components/styled/text.styled';
import { Button } from 'src/components/styled/button.styled';
import Tables from 'src/components/Table/tableData';
import { Box, IconButton } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import CurrencyFormat from 'react-currency-format';
import {
  getAllBanks,
  BankResponse,
  BankTypes,
  getMasterBankList,
  BankListType,
  createNewBank,
  removeBank,
  updateBank,
  updatePrimaryBank,
} from 'src/redux/bank';
import useRedux from 'src/redux/useRedux';
import { TextField as TextFieldMUI } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { toast } from 'react-toastify';
import ConfirmationModal from 'src/components/Modal/confirmation';
import Dialog from 'src/components/Modal/dialog';
import { IconX } from '@tabler/icons';
import checkPermission from 'src/helper/checkPermission';
import { onlyLettersAndSpaces } from 'src/helper/generalFunction';

const BankPage = () => {
  const { thunkDispatch } = useRedux();
  const [availableBanks, setAvailableBanks] = useState<BankListType[]>([]);
  const [offset, setOffset] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [updateSubmit, setUpdateSubmit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [limit, setLimit] = useState(10);
  const [banks, setBanks] = useState<BankResponse>();
  const [payload, setPayload] = useState<BankTypes>({
    account_name: '',
    account_number: '',
    bank_name: '',
    is_primary: false,
  });
  const [updatePayload, setUpdatePayload] = useState<BankTypes>({
    account_name: '',
    account_number: '',
    bank_name: '',
    is_primary: false,
  });

  const fetchBanks = () => {
    setLoading(true);
    thunkDispatch(
      getAllBanks({
        limit: limit,
        offset: offset,
      }),
    )
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          setBanks(res.data);
          setLoading(false);
        }
      });
  };

  const handleDelete = () => {
    thunkDispatch(removeBank(deleteId))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          toast.success(res.data.message);
          fetchBanks();
          setDeleteModal(false);
        }
      })
      .catch((err) => {
        if (err.error) {
          toast.error(err.error.response.data.message);
        }
      });
  };

  const clearData = () => {
    setPayload({
      ...payload,
      account_name: '',
      account_number: '',
      bank_name: '',
      is_primary: false,
    });
  };

  const handleSubmit = () => {
    setSubmit(true);
    setPayload({
      ...payload,
      is_primary: String(payload.is_primary) === 'false' ? false : true,
    });
    if (
      payload.account_name != '' &&
      payload.account_number !== '' &&
      payload.bank_name !== ''
    ) {
      thunkDispatch(createNewBank(payload))
        .unwrap()
        .then((res) => {
          if (res && res.status === 'success') {
            toast.success(res.data.message);
            fetchBanks();
            setSubmit(false);
            clearData();
          } else if (res && res.status === 'error') {
            toast.error(res.error.response.data.message);
          }
        })
        .catch((err) => {
          if (err.error) {
            console.log(err.response);
          }
        });
    }
  };

  const handleUpdateBank = () => {
    setUpdateSubmit(true);
    if (
      updatePayload.account_name != '' &&
      updatePayload.account_number !== '' &&
      updatePayload.bank_name !== ''
    ) {
      thunkDispatch(
        updateBank({
          ...updatePayload,
          is_primary:
            String(updatePayload.is_primary) === 'false' ? false : true,
        }),
      )
        .unwrap()
        .then((res) => {
          if (res && res.status === 'success') {
            toast.success(res.data.message);
            fetchBanks();
            setEditModal(false);
          } else if (res && res.status === 'error') {
            toast.error(res.error.response.data.message);
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log('error', err);
          } else {
            console.log('err', err);
          }
        });
    }
  };

  const handleChangePrimary = (
    id: string | number | undefined,
    is_primary: boolean,
  ) => {
    thunkDispatch(updatePrimaryBank({ id: id ?? '', is_primary: is_primary }))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          toast.success(res.data.message);
          fetchBanks();
          setEditModal(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log('error', err);
        } else {
          console.log('err', err);
        }
      });
  };

  React.useEffect(() => {
    getMasterBankList().then((res) => {
      if (res && res.length > 0) {
        setAvailableBanks(res);
      }
    });
  }, []);

  React.useEffect(() => {
    fetchBanks();
  }, [limit, offset]);

  const columns = [
    {
      name: 'Bank',
      selector: (row: any) => row.bank_name,
      sortable: true,
    },
    {
      name: 'No Rekening',
      selector: (row: any) => row.account_number,
      sortable: true,
    },
    {
      name: 'Nama Rekening',
      selector: (row: any) => row.account_name,
      sortable: true,
    },
    {
      name: 'Utama?',
      cell: (row: BankTypes) => {
        return (
          <Switch
            checked={row.is_primary}
            onChange={() => handleChangePrimary(row.id, !row.is_primary)}
            disabled={checkPermission('Bank', 'is_edit') ? false : true}
          />
        );
      },
      sortable: false,
    },
    {
      name: 'Aksi',
      cell: (row: BankTypes) => {
        return (
          <>
            {checkPermission('Bank', 'is_edit') && (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  setEditModal(true);
                  setUpdatePayload(row);
                }}
              >
                <Edit />
              </IconButton>
            )}
            {checkPermission('Bank', 'is_delete') && (
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => {
                  console.log(row);
                  setDeleteId(row.id ?? '');
                  setDeleteModal(true);
                }}
              >
                <Delete />
              </IconButton>
            )}
          </>
        );
      },
      sortable: false,
    },
  ];

  return (
    <MerchantLayout>
      <ConfirmationModal
        open={deleteModal}
        description={'Apakah anda yakin ingin menghapus akun bank?'}
        handleConfirm={() => handleDelete()}
        handleClose={() => setDeleteModal(false)}
      />
      <Dialog open={editModal} handleClose={() => setEditModal(false)}>
        <Stack
          margin={'0 0 20px 0'}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <NormalText fontSize="20px" fontWeight="bold">
            Ubah Rekening Bank
          </NormalText>
          <IconX cursor={'pointer'} onClick={() => setEditModal(false)} />
        </Stack>
        <NormalText fontSize="12px" margin={'20px 0 5px 0'}>
          Nama Bank
        </NormalText>
        <Autocomplete
          id="combo-box-bank-name"
          disableClearable
          options={availableBanks ?? []}
          getOptionLabel={(option) => option.name || ''}
          onChange={(event: any, value: any) =>
            setUpdatePayload({ ...updatePayload, bank_name: value.code ?? '' })
          }
          sx={{ width: '300px', margin: '0 0 10px 0' }}
          defaultChecked
          value={availableBanks?.find(
            (x) => x.code === updatePayload.bank_name,
          )}
          renderInput={(params) => (
            <TextFieldMUI
              {...params}
              style={{ fontSize: 11 }}
              size="small"
              placeholder="Cari..."
              value={updatePayload.bank_name}
              defaultValue={updatePayload.bank_name}
            />
          )}
        />
        {updateSubmit && updatePayload.bank_name === '' && (
          <NormalText fontSize="12px" color={'#f12147'} margin={'-5px 0 0 0'}>
            Silahkan isi nama bank
          </NormalText>
        )}
        <TextField
          label={'Nama Rekening'}
          validationType={
            updateSubmit && updatePayload.account_name === '' ? 'ERROR' : ''
          }
          validationText={'Silahkan isi nama rekening'}
          value={updatePayload.account_name}
          onChange={(e) => {
            setUpdatePayload({
              ...updatePayload,
              account_name: e.target.value,
            });
          }}
        />
        <NormalText fontSize="12px">Nomor Rekening</NormalText>
        <CurrencyFormat
          customInput={TextField}
          value={updatePayload.account_number}
          onValueChange={(e: any) => {
            setUpdatePayload({
              ...updatePayload,
              account_number: e.value,
            });
          }}
        />
        {updateSubmit && updatePayload.account_number === '' && (
          <NormalText fontSize="12px" color={'#f12147'} margin={'-3px 0 0 0'}>
            Silahkan isi nomor rekening
          </NormalText>
        )}
        <Stack direction={'row'} spacing={3} alignItems={'center'}>
          <NormalText textAlign="start" fontSize={'12px'} margin={'0 0 7px 0'}>
            Rekening Utama ?
          </NormalText>
          <RadioGroup
            row
            aria-labelledby="radio-group-account-primary"
            name="primary_accound"
            sx={{ fontSize: '20px' }}
            value={updatePayload?.is_primary}
            onChange={(event: any) => {
              setUpdatePayload({
                ...updatePayload,
                is_primary: event.target.value,
              });
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Ya" />
            <FormControlLabel value={false} control={<Radio />} label="Tidak" />
          </RadioGroup>
        </Stack>
        <Stack justifyContent={'end'}>
          <Button
            width="200px"
            variant="primary"
            margin="20px 0 20px"
            onClick={() => handleUpdateBank()}
          >
            Ubah Rekening
          </Button>
        </Stack>
      </Dialog>
      <Box sx={{ background: 'white', borderRadius: '10px' }} padding={'20px'}>
        {checkPermission('Bank', 'is_add') && (
          <Box>
            <NormalText fontSize="20px" fontWeight="bold" margin={'0 0 20px 0'}>
              Rekening Bank
            </NormalText>
            <NormalText fontSize="12px" margin={'20px 0 5px 0'}>
              Nama Bank
            </NormalText>
            <Autocomplete
              id="combo-box-bank-name"
              options={availableBanks ?? []}
              getOptionLabel={(option) => option.name || ''}
              onChange={(event: any, value: any) =>
                setPayload({ ...payload, bank_name: value.code ?? '' })
              }
              sx={{ width: '300px', margin: '0 0 10px 0' }}
              value={availableBanks?.find((x) => x.code === payload.bank_name) ?? {} as BankListType}
              renderInput={(params) => (
                <TextFieldMUI
                  {...params}
                  style={{ fontSize: 11 }}
                  size="small"
                  placeholder="Cari..."
                  value={payload.bank_name}
                  defaultValue={payload.bank_name}
                />
              )}
            />
            {submit && payload.bank_name === '' && (
              <NormalText
                fontSize="12px"
                color={'#f12147'}
                margin={'-5px 0 0 0'}
              >
                Silahkan isi nama bank
              </NormalText>
            )}
            <TextField
              style={{ width: '300px' }}
              label={'Nama Rekening'}
              pattern="[A-Za-z]"
              validationType={
                submit && payload.account_name === '' && submit && !onlyLettersAndSpaces(payload.account_name) ? 'ERROR' : ''
              }
              validationText={submit && payload.account_name === '' ? 'Silahkan isi nama rekening' : 'Nama rekening harus berisikan huruf'}
              value={payload.account_name}
              onChange={(e) => {
                if (onlyLettersAndSpaces(e.target.value)) {
                  setPayload({ ...payload, account_name: e.target.value });
                }
              }}
            />
            <NormalText fontSize="12px">Nomor Rekening</NormalText>
            <CurrencyFormat
              minLength={10}
              maxLength={20}
              style={{ width: '300px' }}
              customInput={TextField}
              value={payload.account_number}
              onValueChange={(e: any) => {
                setPayload({
                  ...payload,
                  account_number: e.value,
                });
              }}
            />
            {submit && payload.account_number === '' && (
              <NormalText
                fontSize="12px"
                color={'#f12147'}
                margin={'-3px 0 0 0'}
              >
                Nomor rekening wajib diisi.
              </NormalText>
            )}
            {submit && payload.account_number.length < 10 && (
              <NormalText
                fontSize="12px"
                color={'#f12147'}
                margin={'-3px 0 0 0'}
              >
                Silahkan isi nomor rekening minimal 10 karakter.
              </NormalText>
            )}
            <Stack direction={'row'} spacing={3} alignItems={'center'}>
              <NormalText
                textAlign="start"
                fontSize={'12px'}
                margin={'0 0 7px 0'}
              >
                Rekening Utama ?
              </NormalText>
              <RadioGroup
                row
                aria-labelledby="radio-group-account-primary"
                name="primary_accound"
                sx={{ fontSize: '20px' }}
                value={payload?.is_primary}
                onChange={(event: any) => {
                  setPayload({
                    ...payload,
                    is_primary: event.target.value,
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Ya" />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Tidak"
                />
              </RadioGroup>
            </Stack>
            {/* Bank@is_view */}
            <Button
              width="200px"
              variant="primary"
              margin="20px 0 20px"
              onClick={() => handleSubmit()}
            >
              Tambah Rekening
            </Button>
          </Box>
        )}
        <Tables
          hasContainer={false}
          records={banks && banks.data ? banks.data : []}
          columns={columns}
          isSearch={false}
          progressPending={loading}
          totalRow={
            banks && banks.meta && banks.meta.total ? banks.meta.total : 0
          }
          paginationPerPage={limit}
          onChangePage={(page) => setOffset(page - 1)}
          onChangeRowsPerPage={(currentRowsPerPage) =>
            setLimit(currentRowsPerPage)
          }
        />
      </Box>
    </MerchantLayout>
  );
};

const dummy = [
  {
    bank_name: 'BCA',
    account: '094754565',
    account_name: 'Bakti Baktian',
    primary: true,
  },
  {
    bank_name: 'BCA',
    account: '788485657',
    account_name: 'Asri Bestie',
    primary: false,
  },
  {
    bank_name: 'BCA',
    account: '348348934',
    account_name: 'Andre Tauga',
    primary: false,
  },
];

export default BankPage;
