import { ReactNode } from "react";
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import { Tab, TabsList, TabPanel } from "src/components/Tabs";

interface Props {
  onFirstClick?: () => void;
  onSecondClick?: () => void;
  onThirdClick?: () => void;
  tabActive: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  children: ReactNode;
}

const UserGroups = ({ tabActive, children }: Props) => {
  const navigate = useNavigate();
  return (
    <TabsUnstyled defaultValue={tabActive}>
      <TabsList>
        <Tab onClick={() => navigate("/orders/new")}>Baru</Tab>
        <Tab onClick={() => navigate("/orders/process")}>Proses</Tab>
        <Tab onClick={() => navigate("/orders/ready")}>Siap di pickup</Tab>
        <Tab onClick={() => navigate("/orders/send")}>Dikirim</Tab>
        <Tab onClick={() => navigate("/orders/complete")}>Selesai</Tab>
        <Tab onClick={() => navigate("/orders/cancel")}>Batal</Tab>
        <Tab onClick={() => navigate("/orders/pending")}>Pending</Tab>
      </TabsList>
      <TabPanel value={0}>
        <StyledBox>{tabActive === 0 && children}</StyledBox>
      </TabPanel>
      <TabPanel value={1}>
        <StyledBox>{tabActive === 1 && children}</StyledBox>
      </TabPanel>
      <TabPanel value={2}>
        <StyledBox>{tabActive === 2 && children}</StyledBox>
      </TabPanel>
      <TabPanel value={3}>
        <StyledBox>{tabActive === 3 && children}</StyledBox>
      </TabPanel>
      <TabPanel value={4}>
        <StyledBox>{tabActive === 4 && children}</StyledBox>
      </TabPanel>
      <TabPanel value={5}>
        <StyledBox>{tabActive === 5 && children}</StyledBox>
      </TabPanel>
      <TabPanel value={6}>
        <StyledBox>{tabActive === 6 && children}</StyledBox>
      </TabPanel>
    </TabsUnstyled>
  );
};

const StyledBox = styled(Box)`
  width: 100%;
  min-height: 200px;
  background: white;
  padding: 10px;
`;

export default UserGroups;
