import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import React from "react";

interface InputDateTimeProps {
  value: Date | string | null;
  onChange: (
    value: string | Date | number | null,
    keyboardInputValue?: string | undefined
  ) => void;
  [x: string]: any;
}

const InputDateTime = ({ value, onChange, ...props }: InputDateTimeProps) => {
  const [dateValue, setDateValue] = React.useState<string | Date | null>(null);
  const handleChange = (
    value: string | Date | null,
    keyboardInputValue?: string | undefined
  ) => {
    if (onChange) {
      onChange(value, keyboardInputValue);
    }
    setDateValue(value);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        label=""
        value={dateValue || value}
        disableIgnoringDatePartForTimeValidation
        onError={() => null}
        onChange={handleChange}
        ampm={false}
        renderInput={(params) => <TextField size="small" {...params} />}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default InputDateTime;
