import { createAsyncThunk } from "@reduxjs/toolkit";
import { TableTypesState, CreateTableTypeProps, TableTypeDetailState } from "./types";
import { PaginationProps } from "src/redux/types";
import ApiService from "src/services/ApiService";
import { getToken } from 'src/helper/generalFunction';

export const getAllTableType = createAsyncThunk('tabletype/get', async (props: PaginationProps) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.get(`${process.env.REACT_APP_API_URL}/merchant/tabletypes?limit=${props.limit ?? 10}&offset=${props.offset ?? 0}${props.filter ? '&filter=' + props.filter : ''}`, config)

        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as TableTypesState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as TableTypesState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as TableTypesState;

    }
})

export const showDetailTableType = createAsyncThunk('tabletype/show', async (store_id: number) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.get(`${process.env.REACT_APP_API_URL}/merchant/tabletypes/${store_id}`, config)

        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as TableTypeDetailState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as TableTypeDetailState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as TableTypeDetailState;

    }
})

export const createNewTableType = createAsyncThunk('tabletype/post', async (props: CreateTableTypeProps) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.post(process.env.REACT_APP_API_URL + '/merchant/tabletypes', props, config)
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as TableTypesState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as TableTypesState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as TableTypesState;

    }
})

export const updateTableType = createAsyncThunk('stores/put', async (props: CreateTableTypeProps) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const { name, maximum_table_count, minimum_table_count } = props
        const response = await ApiService.put(`${process.env.REACT_APP_API_URL}/merchant/tabletypes/${props.id}`, { name, maximum_table_count, minimum_table_count }, config)
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as TableTypesState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as TableTypesState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as TableTypesState;

    }
})

export const removeTableType = createAsyncThunk('tabletype/delete', async (type_id: number) => {
    try {
        const config = {
            headers: {
                token: getToken()
            }
        };
        const response = await ApiService.delete(`${process.env.REACT_APP_API_URL}/merchant/tabletypes/${type_id}`, config)
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as TableTypesState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as TableTypesState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as TableTypesState;

    }
})