import { createAsyncThunk } from "@reduxjs/toolkit";
import { OrderReportState } from "./types";
import axios from "axios";
import { getToken } from "src/helper/generalFunction";

export interface ListOrderProps {
  offset?: number;
  limit?: number;
  status_order?: string;
  start_date?: any;
  end_date?: any;
  store_id?: string;
  product_type?: string;
}

export const getList = createAsyncThunk(
  "orderReport/list",
  async (props: ListOrderProps) => {
    const config = {
      headers: {
        api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
        token: getToken(),
      },
    };
    let url = `/merchant/order/reports/data?limit=${props.limit}&offset=${
      props.offset
    }${props.status_order ? `&status_order=${props.status_order}` : ""}${
      props.start_date ? `&start_date=${props.start_date}` : ""
    }${props.end_date ? `&end_date=${props.end_date}` : ""}${
      props.store_id && props.store_id !== "0"
        ? `&store_id=${props.store_id}`
        : ""
    }${props.product_type ? `&product_type=${props.product_type}` : ""}`;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${url}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as OrderReportState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as OrderReportState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as OrderReportState;
    }
  }
);

export interface downloadOrderProps {
  status_order?: string;
  start_date?: any;
  end_date?: any;
  store_id?: string;
}

export const downloadReport = createAsyncThunk(
  "orderReport/list",
  async (props: downloadOrderProps) => {
    const config = {
      headers: {
        api_key: "Basic 15cb7ebf9-3dcc-h28s-b056-2522c1eed03e",
        token: getToken(),
      },
    };
    let url = `/merchant/order/reports/data/download?${
      props.status_order ? `&status_order=${props.status_order}` : ""
    }${props.start_date ? `&start_date=${props.start_date}` : ""}${
      props.end_date ? `&end_date=${props.end_date}` : ""
    }${
      props.store_id && props.store_id !== "0"
        ? `&store_id=${props.store_id}`
        : ""
    }`;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${url}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as OrderReportState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as OrderReportState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as OrderReportState;
    }
  }
);
