import { SubSubCategoryState, SubCategoryResponseProps } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { getSubSubCategory, createSubSubCategory, removeSubSubCategory, updateSubSubCategory } from './subcategorylevelServices';

export const initialState: SubSubCategoryState = {
    data: {} as SubCategoryResponseProps,
    status: "loading",
    error: {}
}

export const SubSubCategorySlice = createSlice({
    name: 'role',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSubSubCategory.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getSubSubCategory.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(getSubSubCategory.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(createSubSubCategory.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(createSubSubCategory.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(createSubSubCategory.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(removeSubSubCategory.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(removeSubSubCategory.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(removeSubSubCategory.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(updateSubSubCategory.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(updateSubSubCategory.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(updateSubSubCategory.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
    }
})

export const SubSubCategoryReducer = SubSubCategorySlice.reducer