import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Card, Stack, Button, Grid, Box } from "@mui/material";
import TextField from "src/components/Inputs/TextField";
import { NormalText } from "src/components/styled/text.styled";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import { valueText, voucherTypes } from "src/constants/general";
import Dropdown from "src/components/Inputs/Dropdown";
import CurrencyFormat from "react-currency-format";
import useRedux from "src/redux/useRedux";
import { detailVoucher } from "src/redux/voucher";
import Editor from "src/components/Editor";

const DetailVoucherPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { thunkDispatch } = useRedux();
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    value: "",
    purchasePrice: "",
    expiryDay: "",
    name: "",
    code: "",
    description: "",
    voucherType: "",
    valueText: "",
    howToUse: "",
  });

  const loadData = () => {
    setLoading(true);
    thunkDispatch(detailVoucher(id))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          const {
            value,
            purchasePrice,
            expiryDay,
            name,
            code,
            description,
            voucherType,
            valueText,
            howToUse,
          } = res?.data?.data;
          setPayload({
            value: String(value),
            purchasePrice: String(purchasePrice),
            expiryDay: String(expiryDay),
            name: name,
            code: code,
            description: description,
            voucherType: voucherType,
            valueText: valueText,
            howToUse: howToUse.content,
          });
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, []);

  return (
    <MerchantLayout>
      <Card sx={{ padding: "2%" }}>
        <NormalText fontSize="20px" fontWeight="bold" margin="0 0 20px 0">
          Detail Voucher
        </NormalText>

        <TextField
          label={"Nama Voucher"}
          value={payload.name}
          readonly
        ></TextField>

        <TextField
          label={"Deskripsi"}
          value={payload.description}
          readonly
        ></TextField>

        <TextField
          label={"Kode Voucher"}
          value={payload.code}
          readonly
        ></TextField>

        <CurrencyFormat
          label={"Harga Voucher"}
          customInput={TextField}
          min={0}
          thousandSeparator={true}
          value={payload.purchasePrice}
          readOnly
        />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Dropdown
              value={payload.valueText}
              label="Tipe Nilai Voucher"
              options={valueText}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <CurrencyFormat
              label={"Nilai Voucher"}
              customInput={TextField}
              min={0}
              thousandSeparator={true}
              value={payload.value}
              readOnly
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Dropdown
              isRequired
              value={payload.voucherType}
              label="Tipe Voucher"
              options={voucherTypes}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label={"Masa Berlaku (hari)"}
              isRequired
              value={payload.expiryDay}
              readonly
            ></TextField>
          </Grid>
        </Grid>

        <Box marginTop={"5px"}>
          <NormalText fontSize={"12px"} margin="0 0 10px 0">
            {/* {label} {isRequired ? "*" : ""} */}
            Cara Penggunaan *
          </NormalText>

          <Editor
            value={payload.howToUse}
            readOnly={true}
          ></Editor>

      
        </Box>

        <Stack direction={"row"} spacing={2} margin={"20px 0 20px 0"}>
          <Button
            sx={{
              textTransform: "none",
              background: "#e56c75",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#e56c75",
                borderColor: "#e56c75",
                boxShadow: "none",
              },
            }}
            onClick={() => navigate("/products/vouchers")}
            className="px-4"
          >
            Kembali
          </Button>
        </Stack>
      </Card>
    </MerchantLayout>
  );
};

export default DetailVoucherPage;
