import { SesiKasirState, SesiKasirResponseProps } from './types';
import { createSlice } from '@reduxjs/toolkit';
import { getSesiKasir } from './sesiKasirServices';

export const initialState: SesiKasirState = {
  data: {} as SesiKasirResponseProps,
  status: 'loading',
  error: {},
};

export const SesiKasirSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSesiKasir.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSesiKasir.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.error = action.payload.error;
        }
      })
      .addCase(getSesiKasir.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error;
      });
  },
});

export const SesiKasirReducer = SesiKasirSlice.reducer;
