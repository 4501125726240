import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ProductsState,
  CreateProductTypes,
  ProductDetailState,
  ProductAdditionalState,
  ProductTypeState,
  ProductDashboardState,
} from "./types";
import { PaginationProps } from "src/redux/types";
import ApiService from "src/services/ApiService";
import { getToken } from "src/helper/generalFunction";

export const getAllProduct = createAsyncThunk(
  "products/get",
  async (props: PaginationProps) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/product?limit=${
          props.limit ?? 10
        }${props.status !== "" ? "&isActive=" + props.status : ""}&offset=${
          props.offset ?? 0
        }${props.filter ? "&filter=" + props.filter : ""}`,
        config
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as ProductsState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as ProductsState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as ProductsState;
    }
  }
);

export const showProduct = createAsyncThunk(
  "products/show",
  async (product_id: number) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/product/${product_id}`,
        config
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as ProductDetailState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as ProductDetailState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as ProductDetailState;
    }
  }
);

export const createNewProducts = createAsyncThunk(
  "products/post",
  async (props: CreateProductTypes) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.post(
        process.env.REACT_APP_API_URL + "/merchant/product_v2",
        props,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as ProductsState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as ProductsState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as ProductsState;
    }
  }
);

export const updateStore = createAsyncThunk(
  "products/put",
  async (props: CreateProductTypes) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const {
        product_name,
        brand_id,
        category_id,
        sub_category_id,
        subs_category_id,
        product_detail,
        product_description,
        no_sku,
        product_store,
      } = props;
      const response = await ApiService.put(
        `${process.env.REACT_APP_API_URL}/merchant/product_v2/${props.id}`,
        {
          product_name,
          brand_id,
          subs_category_id,
          category_id,
          sub_category_id,
          product_detail,
          product_description,
          no_sku,
          product_store,
        },
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as ProductsState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as ProductsState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as ProductsState;
    }
  }
);

export interface UpdateStatusProps {
  id: number;
  status: boolean;
  is_customizable: boolean;
}

export const updateStatus = createAsyncThunk(
  "products/updatestatus",
  async (props: UpdateStatusProps) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const { status, is_customizable, id } = props;
      const response = await ApiService.put(
        `${process.env.REACT_APP_API_URL}/merchant/product/${id}/status`,
        { status, is_customizable },
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as ProductsState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as ProductsState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as ProductsState;
    }
  }
);

export const removeProduct = createAsyncThunk(
  "products/delete",
  async (product_id: number) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.delete(
        `${process.env.REACT_APP_API_URL}/merchant/product/${product_id}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as ProductsState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as ProductsState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as ProductsState;
    }
  }
);

export const getAdditionalProduct = createAsyncThunk(
  "products/additional",
  async () => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/product/list/additional`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as ProductAdditionalState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as ProductAdditionalState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as ProductAdditionalState;
    }
  }
);

export const getProductType = createAsyncThunk("products/type", async () => {
  try {
    const config = {
      headers: {
        token: getToken(),
      },
    };
    const response = await ApiService.get(
      `${process.env.REACT_APP_API_URL}/merchant/product/type/list?limit=0&offset=0`,
      config
    );
    if (response && response.data) {
      return {
        data: response.data,
        status: "success",
        error: null,
      } as unknown as ProductTypeState;
    } else {
      return {
        data: response.data?.message,
        status: "empty",
        error: null,
      } as unknown as ProductTypeState;
    }
  } catch (err) {
    if (err)
      return {
        data: "Something went wrong!",
        status: "error",
        error: err,
      } as unknown as ProductTypeState;
  }
});

export const createNewProductType = createAsyncThunk(
  "products/create/type",
  async ({ product_type }: { product_type: string }) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.post(
        process.env.REACT_APP_API_URL + "/merchant/product/type/create",
        { product_type },
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as ProductTypeState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as ProductTypeState;
      }
    } catch (err) {
      if (err)
        return {
          data: err,
          status: "error",
          error: err,
        } as unknown as ProductTypeState;
    }
  }
);

export const getProductTotal = createAsyncThunk(
  "products/dashboard/total",
  async ({
    start_date,
    end_date,
  }: {
    start_date: string;
    end_date: string;
  }) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        process.env.REACT_APP_API_URL +
          `/merchant/dashboard/total-product?start_date=${start_date}&end_date=${end_date}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as ProductDashboardState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as ProductDashboardState;
      }
    } catch (err) {
      if (err)
        return {
          data: err,
          status: "error",
          error: err,
        } as unknown as ProductDashboardState;
    }
  }
);
