import React, { useState } from "react";
import {
  Grid,
  IconButton,
  Box,
  Autocomplete,
  Button as ButtonMUI,
  TextField as TextFieldMUI,
  FormHelperText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import UserGroups from "../layout";
import Tables from "src/components/Table/tableData";
import { Delete, Edit, Search } from "@mui/icons-material";
import Confirmation from "src/components/Modal/confirmation";
import Modal from "src/components/Modal/modal";
import { NormalText } from "src/components/styled/text.styled";
import {
  getList,
  StoreAccessProps,
  deleteAssign,
  createAssign,
  updateAssign,
  detailDataAccess,
} from "src/redux/storeAccess";
import useRedux from "src/redux/useRedux";
import { getAllStore, ListStoreData } from "src/redux/storelist";
import { getListUser, ListUserData } from "src/redux/user";
import checkPermission from "src/helper/checkPermission";

type ExampleData = {
  user: {
    id: any;
    full_name: any;
  };
  store: any[];
};

const StoreAccessPage = () => {
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [totalData, setTotalData] = useState(0);
  const [dataList, setDataList] = useState<StoreAccessProps[]>();
  const [statusModal, setStatusModal] = useState("");
  const [addUser, setAddUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editUser, setEditUser] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [idUser, setIdUser] = useState();
  const [viewUser, setViewUser] = useState(false);
  const [errorValidasi, setErrorValidasi] = useState({
    user: "",
    store: "",
  });
  const [data, setData] = useState<ExampleData>({
    user: {
      id: 0,
      full_name: "",
    },
    store: [],
  });

  const [stores, setStores] = React.useState<ListStoreData[]>([]);

  React.useEffect(() => {
    handleGetList();
    fetchStores();
  }, []);

  const fetchStores = () => {
    setLoading(true);
    thunkDispatch(getAllStore({ filter: "", limit: 100, offset: 0 }))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setStores(res.data.data);
        }
      });
    setLoading(false);
  };

  const [dataUser, setDataUser] = useState<ListUserData[]>([]);

  const handleGetList = () => {
    let dataObject = {
      offset: 0,
      limit: 100,
      filter: "",
      access_token: Auth.data.access_token,
    };
    thunkDispatch(getListUser(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setDataUser(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
    setLoading(false);
  };

  const handleButtonEdit = (row: any) => {
    let dataTempStore: any = [];
    row.data_store.length && row.data_store.length !== 0
      ? row.data_store.map((temp: any, z: number) => {
          let findIndexStore =
            stores && stores.length !== 0
              ? stores.findIndex((element) => element.id == temp.store_id)
              : -1;
          findIndexStore >= 0
            ? dataTempStore.push(stores[findIndexStore])
            : null;
        })
      : null;
    let findIndexUser =
      dataUser && dataUser.length !== 0
        ? dataUser.findIndex((element) => element.id == row.user_merchant_id)
        : -1;
    let dataTempUser =
      findIndexUser >= 0 ? dataUser[findIndexUser] : { id: 0, full_name: "" };
    setData({
      ...data,
      user: dataTempUser,
      store: dataTempStore,
    });
    setIdUser(row.id);
    setEditUser(true);
  };

  const columns = [
    {
      name: "Nama",
      width: "25%", // added line here
      style: { textAlign: "left", paddingLeft: "20px", paddingRight: "5px" },
      selector: (row: any) => {
        return (
          <NormalText textAlign="center" fontSize={"12px"}>
            {row.user_name}
          </NormalText>
        );
      },
      sortable: true,
    },
    {
      name: "Toko",
      center: false,
      style: { display: "block" },
      selector: (row: any) => {
        return (
          <Grid
            container
            spacing={1}
            sx={{ marginTop: "5px", marginBottom: "5px" }}
          >
            {row.data_store && row.data_store.length !== 0
              ? row.data_store.map((item: any, i: number) => (
                  <Grid item xs={12} sm={6} md={3} key={"store-" + i}>
                    <div
                      style={{
                        borderRadius: 20,
                        padding: "8px",
                        backgroundColor: "#fefefe",
                        // minWidth: "100px",
                        boxShadow: "0 0 1px 0 rgb(0 0 0 / 0.2)",
                        border: "1px solid #eee",
                      }}
                    >
                      <NormalText textAlign="center" fontSize={"12px"}>
                        {item.store_name}
                      </NormalText>
                    </div>
                  </Grid>
                ))
              : null}
          </Grid>
        );
      },
      sortable: false,
    },
    {
      name: "Aksi",
      width: "25%", // added line here
      style: { paddingLeft: "5px", paddingRight: "5px" },
      center: true,
      cell: (row: any) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {checkPermission("Store Access", "is_edit") && (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => handleButtonEdit(row)}
              >
                <Edit />
              </IconButton>
            )}
            <IconButton
              aria-label="search"
              color="warning"
              onClick={() => {
                setData({
                  ...data,
                  user: { id: row.user_merchant_id, full_name: row.user_name },
                  store: row.data_store,
                });
                setViewUser(true);
              }}
            >
              <Search />
            </IconButton>
            {checkPermission("Store Access", "is_delete") && (
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => {
                  setIdUser(row.id);
                  setDeleteUser(true);
                }}
              >
                <Delete />
              </IconButton>
            )}
          </Grid>
        );
      },
    },
  ];

  const {
    thunkDispatch,
    storeState: { Auth, StoreAccess },
  } = useRedux();

  const getAll = () => {
    setLoading(true);
    thunkDispatch(
      getList({
        filter: filter,
        limit: limit,
        offset: offset - 1,
      })
    )
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setTotalData(res.data.meta.total);
          setDataList(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [openModal, setOpenModal] = useState(false);
  const [statusDel, setStatusDel] = useState("");

  const deleteAccess = () => {
    let dataDel = {
      id_assign: idUser,
    };
    thunkDispatch(deleteAssign(dataDel))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          getAll();
          setStatusDel("success");
          setOpenModal(false);
          setDeleteUser(false);
        } else {
          setOpenModal(true);
          setStatusDel("failed");
        }
      })
      .catch((err) => {
        setOpenModal(true);
        setStatusDel("failed");
        console.error(err);
      });
    setLoading(false);
  };

  const [createStatus, setCreateStatus] = useState("");

  const createAccess = () => {
    let storeId: detailDataAccess[] = [];
    data.store && data.store.length !== 0
      ? data.store.map((item: any, i: number) => storeId.push({ id: item.id }))
      : null;
    let dataCreate = {
      id_assign: "",
      data: {
        user_merchant_id: data.user && data.user.id ? data.user.id : "",
        store_id: storeId,
      },
    };
    thunkDispatch(createAssign(dataCreate))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setOpenModal(true);
          getAll();
          setCreateStatus("success");
        } else {
          setOpenModal(true);
          setCreateStatus("failed");
        }
      })
      .catch((err) => {
        setOpenModal(true);
        setCreateStatus("failed");
        console.error(err);
      });
    setLoading(false);
  };

  const [statusEdit, setStatusEdit] = useState("");

  const editAccess = () => {
    let storeId: detailDataAccess[] = [];
    data.store && data.store.length !== 0
      ? data.store.map((item: any, i: number) => storeId.push({ id: item.id }))
      : null;
    let dataCreate = {
      id_assign: idUser,
      data: {
        user_merchant_id: data.user && data.user.id ? data.user.id : "",
        store_id: storeId,
      },
    };
    thunkDispatch(updateAssign(dataCreate))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setOpenModal(true);
          getAll();
          setStatusEdit("success");
        } else {
          setOpenModal(true);
          setStatusEdit("failed");
        }
      })
      .catch((err) => {
        setOpenModal(true);
        setStatusEdit("failed");
        console.error(err);
      });
    setLoading(false);
  };

  React.useEffect(() => {
    getAll();
  }, [filter, limit, offset]);

  const handleValidate = () => {
    if (
      (!data.user.id && !data.user.full_name) ||
      !data.store ||
      (Array.isArray(data.store) && data.store.length === 0)
    ) {
      setErrorValidasi({
        ...errorValidasi,
        user:
          !data.user.id && !data.user.full_name ? "Pengguna Wajib Diisi" : "",
        store: data.store && data.store.length !== 0 ? "" : "Toko Wajib Diisi",
      });
    } else {
      setErrorValidasi({
        ...errorValidasi,
        user: "",
        store: "",
      });
      idUser && !addUser ? editAccess() : createAccess();
    }
  };

  const handleCloseModal = () => {
    setStatusModal("");
    setAddUser(false);
    setEditUser(false);
    setDeleteUser(false);
    setOpenModal(false);
    setStatusDel("");
    setCreateStatus("");
    setStatusEdit("");
    setViewUser(false);
  };

  return (
    <MerchantLayout>
      <Confirmation
        handleConfirm={() => {
          deleteUser ? deleteAccess() : handleCloseModal();
        }}
        open={deleteUser || openModal}
        handleClose={() => {
          handleCloseModal();
        }}
        hasCancel={deleteUser ? true : false}
        title={
          deleteUser
            ? "Konfirmasi"
            : statusModal === "success" ||
              statusDel === "success" ||
              createStatus === "success" ||
              statusEdit === "success"
            ? "Sukses"
            : "Gagal"
        }
        description={
          deleteUser
            ? "Apakah kamu yakin menghapus akses toko ini?"
            : createStatus === "success"
            ? "Berhasil menyimpan data pengguna"
            : statusDel === "success"
            ? "Berhasil menghapus data akses"
            : statusEdit === "success"
            ? "Berhasil mengubah data pengguna"
            : StoreAccess?.error?.response?.data?.message
            ? StoreAccess.error.response.data.message
            : statusDel === "failed"
            ? "Gagal menghapus"
            : "Gagal menyimpan data, silahkan periksa kembali data anda"
        }
      ></Confirmation>
      <Modal
        handleClose={() => handleCloseModal()}
        open={addUser || editUser || viewUser}
      >
        <Box sx={{ width: "800px" }}>
          <NormalText style={{ fontWeight: "bold", fontSize: "20px" }}>
            {editUser
              ? "Ubah Akses Toko"
              : viewUser
              ? "Detail Akses Toko"
              : "Tambah Akses Toko"}
          </NormalText>
          <form>
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Pilih Pengguna *
            </NormalText>
            {viewUser ? (
              <NormalText
                textAlign="left"
                fontSize={"16px"}
                fontWeight={"bold"}
              >
                {data.user.full_name}
              </NormalText>
            ) : (
              <>
                <Autocomplete
                  id="combo-box-demo"
                  options={dataUser}
                  getOptionLabel={(option) => option.full_name || ""}
                  defaultValue={data.user}
                  onChange={(event: any, value: any) =>
                    setData({
                      ...data,
                      user: value,
                    })
                  }
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextFieldMUI
                      {...params}
                      style={{ fontSize: 14 }}
                      size="small"
                      placeholder="Silahkan pilih pengguna anda"
                      value={data.user}
                    />
                  )}
                />
                <FormHelperText style={{ color: "#f12147" }}>
                  {errorValidasi.user}
                </FormHelperText>
              </>
            )}
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Pilih Toko *
            </NormalText>
            {viewUser ? (
              data.store && data.store.length !== 0 ? (
                data.store.map((x: any, y: number) => (
                  <NormalText
                    textAlign="left"
                    fontSize={"16px"}
                    fontWeight={"bold"}
                  >
                    {x.store_name}
                  </NormalText>
                ))
              ) : null
            ) : (
              <>
                <Autocomplete
                  id="combo-box-demo"
                  multiple={true}
                  options={stores}
                  getOptionLabel={(option) => option.store_name || ""}
                  defaultValue={data.store}
                  onChange={(event: any, value: any) =>
                    setData({
                      ...data,
                      store: value,
                    })
                  }
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextFieldMUI
                      {...params}
                      style={{ fontSize: 14 }}
                      size="small"
                      placeholder="Silahkan pilih toko anda"
                      value={data.store}
                    />
                  )}
                />
                <FormHelperText style={{ color: "#f12147" }}>
                  {errorValidasi.store}
                </FormHelperText>
              </>
            )}
            {viewUser ? (
              <ButtonMUI
                variant="contained"
                onClick={() => handleCloseModal()}
                sx={{
                  width: 100,
                  height: 40,
                  marginRight: 3,
                  marginTop: "20px",
                }}
              >
                Oke
              </ButtonMUI>
            ) : (
              <Box textAlign={"start"} margin={"20px 0 0 0 "}>
                <ButtonMUI
                  variant="contained"
                  onClick={() => handleCloseModal()}
                  sx={{ width: 100, height: 40, marginRight: 3 }}
                  color="error"
                >
                  Batal
                </ButtonMUI>
                <ButtonMUI
                  variant="contained"
                  onClick={() => handleValidate()}
                  sx={{ width: 100, height: 40 }}
                >
                  Simpan
                </ButtonMUI>
              </Box>
            )}
          </form>
        </Box>
      </Modal>
      <UserGroups tabActive={2}>
        <Tables
          records={dataList}
          columns={columns}
          title="Daftar Akses Toko"
          isSearch={true}
          addButton={checkPermission("Store Access", "is_add") ? true : false}
          titleAdd="Tambah Pengguna"
          onChangeSearch={(e) => setFilter(e.target.value)}
          valueSearch={filter}
          handleAddButton={() => {
            setData({
              ...data,
              user: {
                id: 0,
                full_name: "",
              },
              store: [],
            });
            setAddUser(true);
          }}
          progressPending={loading}
          paginationPerPage={limit}
          totalRow={totalData}
          onChangePage={(page) => setOffset(page)}
          onChangeRowsPerPage={(currentRowsPerPage) =>
            setLimit(currentRowsPerPage)
          }
        />
      </UserGroups>
    </MerchantLayout>
  );
};

export default StoreAccessPage;
