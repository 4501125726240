import React from "react";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import { IconButton, Grid, Stack, Card } from "@mui/material";
import { Delete, Edit, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationModal from "src/components/Modal/confirmation";
import useRedux from "src/redux/useRedux";
import {
  getAllStore,
  removeStore,
  StoreResponseProps,
  getAllGroupStore,
  StoreGroupResponseProps,
  removeGroupStore
} from "src/redux/storelist";
import checkPermission from "src/helper/checkPermission";
import { toast } from "react-toastify";

import { Box, InputBase, Tab, Tabs } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { Button } from "src/components/styled/button.styled";
import { NormalText } from "src/components/styled/text.styled";
import ListStore from "./listStore";
import ListGroup from "./listGroupStore";

const SearchBox = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "5px",
  border: "1px solid #e6e6e6",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "25%",
  marginTop: "10px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "10px",
    maxWidth: "unset",
    width: "100%",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    fontSize: "14px",
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
      width: "100%",
    },
  },
}));

const StoreList = () => {
  const navigate = useNavigate();
  const { thunkDispatch } = useRedux();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filter, setFilter] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [stores, setStores] = React.useState({} as StoreResponseProps);
  const [groups, setGroups] = React.useState({} as StoreGroupResponseProps);
  const [deleteId, setDeleteId] = React.useState(0);
  const [tabActive, setTabActive] = React.useState("store");

  const columns = [
    {
      name: "Nama Toko",
      selector: (row: any) => row.store_name,
      sortable: true,
    },
    {
      name: "Kode Toko",
      selector: (row: any) => row.store_code,
      sortable: true,
    },
    {
      name: "Alamat",
      selector: (row: any) => row.address,
      sortable: true,
      width: "15vw",
    },
    {
      name: "Kota",
      selector: (row: any) => row.city.city_name,
      sortable: true,
    },
    {
      name: "No. Telepon",
      selector: (row: any) => row.store_phone,
      sortable: true,
    },
    {
      name: "Aksi",
      center: true,
      cell: (item: any) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {checkPermission("Store", "is_edit") && (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => navigate(`/stores/store/${item.id}/update`)}
              >
                <Edit />
              </IconButton>
            )}
            <IconButton
              aria-label="edit"
              color="warning"
              onClick={() => navigate(`/stores/store/view/${item.id}`)}
            >
              <Search />
            </IconButton>
            {checkPermission("Store", "is_delete") && (
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => {
                  setDeleteId(item.id);
                  setOpen(!open);
                }}
              >
                <Delete />
              </IconButton>
            )}
          </Grid>
        );
      },
    },
  ];

  const columnsGroup = [
    {
      name: "Nama Group",
      selector: (row: any) => row.group_name,
      sortable: true,
    },
    {
      name: "Kode Group",
      selector: (row: any) => row.group_code,
      sortable: true,
    },
    {
      name: "Jumlah Anggota",
      selector: (row: any) => row.store_count,
      sortable: true,
    },
    {
      name: "Aksi",
      center: true,
      cell: (item: any) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {checkPermission("Store", "is_edit") && (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  navigate(`/stores/group-store/${item.id}/update`);
                }}
              >
                <Edit />
              </IconButton>
            )}
            <IconButton
              aria-label="view"
              color="warning"
              onClick={() => {
                navigate(`/stores/group-store/${item.id}/view`);
              }}
            >
              <Search />
            </IconButton>
            {checkPermission("Store", "is_delete") && (
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => {
                  setDeleteId(item.id);
                  setOpen(!open);
                }}
              >
                <Delete />
              </IconButton>
            )}
          </Grid>
        );
      },
    },
  ];

  const fetchStores = () => {
    setLoading(true);
    thunkDispatch(
      getAllStore({ filter: filter, limit: rowsPerPage, offset: page - 1 })
    )
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setLoading(false);
          setStores(res.data);
        }
      });
  };

  const fetchGroupStores = () => {
    setLoading(true);
    thunkDispatch(
      getAllGroupStore({ filter: filter, limit: rowsPerPage, offset: page - 1 })
    )
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          //console.log(res);
          setLoading(false);
          setGroups(res.data);
        }
      });
  };

  const handleDelete = () => {
    if (tabActive === "store") {
      thunkDispatch(removeStore(deleteId))
        .unwrap()
        .then((res) => {
          if (res && res.status === "error") {
            toast.warning(res.error.response.data.message);
          } else {
            toast.success("Berhasil menghapus toko");
          }
          setOpen(false);
          fetchStores();
        });
    } else {
      thunkDispatch(removeGroupStore(deleteId))
        .unwrap()
        .then((res) => {
          if (res && res.status === "error") {
            toast.warning(res.error.response.data.message);
          } else {
            toast.success("Berhasil menghapus group toko");
          }
          setOpen(false);
          fetchGroupStores()
        });
    }
  };

  React.useEffect(() => {
    if (tabActive === "store") {
      fetchStores();
    } else {
      fetchGroupStores();
    }
  }, [rowsPerPage, filter, page, tabActive]);

  return (
    <MerchantLayout>
      <ConfirmationModal
        open={open}
        description={
          tabActive === "store"
            ? "Yakin ingin menghapus toko ini?"
            : "Yakin ingin menghapus group toko ini?"
        }
        handleConfirm={() => handleDelete()}
        handleClose={() => setOpen(!open)}
      />

      <Card sx={{ padding: "2%" }}>
        <NormalText fontWeight="bold" fontSize="20px" margin="0 0 20px 0">
          Daftar Toko & Daftar Group Toko
        </NormalText>

        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            position: "relative",
            margin: "0 0 2% 0",
          }}
        >
          <Tabs
            value={tabActive}
            onChange={() => {}}
            aria-label="basic tabs example"
          >
            <Tab
              sx={{ textTransform: "none" }}
              label="Daftar Toko"
              value="store"
              onClick={() => setTabActive("store")}
            />
            <Tab
              sx={{ textTransform: "none" }}
              label="Daftar Group"
              value="group"
              onClick={() => setTabActive("group")}
            />
          </Tabs>

          <Stack
            direction={"row"}
            sx={{ top: 0, right: 0, position: "absolute" }}
          >
            {/* {checkPermission("Store", "is_add") ? (
              <Button
                variant="primary"
                onClick={() => {
                  navigate(
                    `/stores/store/${
                      tabActive === "store" ? "form" : "formgroup"
                    }`
                  );
                }}
              >
                {tabActive === "store" ? "Tambah Toko" : "Tambah Group"}
              </Button>
            ) : null} */}
            {checkPermission("Store", "is_add") ? (
              tabActive === "store" ? (
                <Button
                  variant="primary"
                  onClick={() => {
                    navigate(`/stores/store/form`);
                  }}
                >
                  Tambah Toko
                </Button>
              ) : (
                <Button
                  variant="primary"
                  onClick={() => {
                    navigate(`/stores/group-store/create`);
                  }}
                >
                  Tambah Group
                </Button>
              )
            ) : null}
          </Stack>
        </Box>

        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          margin={"0 0 2% 0"}
        >
          <SearchBox>
            <StyledInputBase
              placeholder={
                tabActive === "store" ? "Cari toko..." : "Cari group..."
              }
              inputProps={{ "aria-label": "search" }}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </SearchBox>
        </Stack>

        {tabActive === "store" ? (
          <ListStore
            records={stores.data}
            columns={columns}
            progressPending={loading}
            totalRow={
              stores && stores.meta && stores.meta.total ? stores.meta.total : 0
            }
            paginationPerPage={rowsPerPage}
            onChangePage={(page) => setPage(page)}
            onChangeRowsPerPage={(currentRowsPerPage) =>
              setRowsPerPage(currentRowsPerPage)
            }
          />
        ) : (
          <ListGroup
            records={groups.data}
            columns={columnsGroup}
            totalRow={0}
            onChangePage={(page) => setPage(page)}
          />
        )}
      </Card>
    </MerchantLayout>
  );
};

export default StoreList;
