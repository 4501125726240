import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import Tables from 'src/components/Table/tableData';
import {
  Switch,
  IconButton,
  Grid,
  FormControl,
  Select,
  Stack,
  MenuItem,
} from '@mui/material';
import { Delete, Edit, Search } from '@mui/icons-material';
import {
  DeleteBrandProps,
  getBrand,
  removeBrand,
  BrandData,
  UpdateStatusProps,
  updateStatus,
} from 'src/redux/brands';
import useRedux from 'src/redux/useRedux';
import { NormalText } from 'src/components/styled/text.styled';
import ConfirmationModal from 'src/components/Modal/confirmation';
import checkPermission from 'src/helper/checkPermission';
//import IconSandboxSmall from "src/assets/media/icons/ic-sandbox-small.svg";
import NewIconSandboxSmall from 'src/assets/media/icons/OrderingShort.svg';

const statusFilter = [
  {
    id: 'true',
    label: 'Aktif',
  },
  {
    id: 'false',
    label: 'Non Aktif',
  },
];

const UserListPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<BrandData[]>([]);
  const [dataFirst, setDataFirst] = useState<BrandData[]>([]);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [idUser, setIdUser] = useState(0);
  const [statusDel, setStatusDel] = useState('');
  const [status, setStatus] = useState('true');
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [update, setUpdate] = useState(false);

  const handleDeleteUser = (id: any) => {
    setIdUser(id);
    setOpenConfirmation(true);
  };

  const columns = [
    {
      name: 'Brand Name',
      selector: (row: any) => row.brand_name,
      sortable: true,
      center: true,
    },
    {
      name: 'Image',
      selector: (row: any) => {
        return (
          <img
            style={{ width: '50px', height: '50px' }}
            src={row.icon_mobile ? row.icon_mobile : NewIconSandboxSmall}
            className="brand-image"
            alt="image brand"
            loading="lazy"
          />
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: 'Status',
      selector: (row: any) => row.is_active,
      center: true,
      cell: (row: any) => {
        return (
          <Switch
            checked={row.is_active}
            onChange={(e: any) => handleUpdate(e.target.checked, row.id)}
            disabled={checkPermission('Brand', 'is_edit') ? false : true}
          />
        );
      },
    },
    {
      name: 'Action',
      center: true,
      cell: (row: any) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {checkPermission('Brand', 'is_edit') && (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  navigate('/products/brands/edit-brands', {
                    state: { data: row, statusUrl: 'edit' },
                  });
                }}
              >
                <Edit />
              </IconButton>
            )}
            <IconButton
              aria-label="search"
              color="warning"
              onClick={() => {
                console.log('VIEW ROW', row);
                navigate('/products/brands/view-brands', {
                  state: { data: row, status: 'view' },
                });
              }}
            >
              <Search />
            </IconButton>
            {checkPermission('Brand', 'is_delete') && (
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => handleDeleteUser(row.id)}
              >
                <Delete />
              </IconButton>
            )}
          </Grid>
        );
      },
    },
  ];

  const {
    thunkDispatch,
    storeState: { Auth, Brand },
  } = useRedux();

  const getAll = () => {
    setLoading(true);
    let dataObject = {
      offset: offset - 1,
      limit: limit,
      filter: filter,
      isActive: status,
    };
    thunkDispatch(getBrand(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == 'success') {
          setData(res.data.data);
          setDataFirst(res.data.data);
          setTotalData(
            res.data && res.data.meta && res.data.meta.total
              ? res.data.meta.total
              : 0
          );
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  React.useEffect(() => {
    getAll();
  }, [filter, limit, offset, status]);

  const handleDelete = () => {
    let dataDelete = {
      brand_id: idUser,
      access_token: Auth.data.access_token,
    };
    thunkDispatch(removeBrand(dataDelete as DeleteBrandProps))
      .unwrap()
      .then((res) => {
        if (res && res.status == 'success') {
          setOpenConfirmation(!openConfirmation);
          setOpenModal(true);
          getAll();
          setStatusDel('success');
          setLoading(false);
        } else {
          setOpenConfirmation(!openConfirmation);
          setLoading(false);
          setOpenModal(true);
          setStatusDel('failed');
        }
      })
      .catch((err) => {
        setOpenConfirmation(!openConfirmation);
        setLoading(false);
        setStatusDel('failed');
        console.error(err);
      });
    setLoading(false);
  };

  const Filter = (val: any) => {
    let tempVal = val !== '' ? (val == 1 ? true : false) : null;
    let res =
      tempVal !== null && dataFirst.length != 0
        ? dataFirst.filter((word: any) => word.is_active == tempVal)
        : dataFirst;
    setData(res);
    setStatus(val);
  };

  const handleUpdate = (val: boolean, id: number) => {
    let dataUpdate = {
      id: id,
      access_token: Auth.data.access_token,
      data: {
        is_active: val,
      },
    };
    thunkDispatch(updateStatus(dataUpdate as UpdateStatusProps))
      .unwrap()
      .then((res) => {
        if (res && res.status == 'success') {
          setUpdate(true);
          getAll();
          setStatusDel('success');
          setLoading(false);
        } else {
          setLoading(false);
          setUpdate(true);
          setStatusDel('failed');
        }
      })
      .catch((err) => {
        setLoading(false);
        setUpdate(true);
        setStatusDel('failed');
        console.error(err);
      });
    setLoading(false);
  };

  return (
    <MerchantLayout>
      <ConfirmationModal
        title="Konfirmasi"
        titleStyle={{ fontWeight: 'bold' }}
        description="Apakah anda yakin ingin menghapus brand ini?"
        open={openConfirmation}
        handleConfirm={() => handleDelete()}
        handleClose={() => setOpenConfirmation(!openConfirmation)}
      ></ConfirmationModal>
      <ConfirmationModal
        open={openModal || update}
        handleClose={() => {
          setOpenModal(false);
          setStatusDel('');
          setUpdate(false);
        }}
        handleConfirm={() => {
          setOpenModal(false);
          setStatusDel('');
          setUpdate(false);
        }}
        hasCancel={false}
        title={statusDel == 'success' ? 'Berhasil' : 'Gagal'}
        description={
          statusDel == 'success'
            ? `Sukses ${update ? 'mengubah status' : 'menghapus'} brand`
            : Brand?.error?.response?.data?.message
            ? Brand.error.response.data.message
            : `Gagal ${update ? 'mengubah status' : 'menghapus'} brand`
        }
      />
      <Tables
        records={data}
        columns={columns}
        title="Daftar Brand"
        isSearch={true}
        addButton={checkPermission('Brand', 'is_add')}
        titleAdd="Tambah Brand"
        onChangeSearch={(e) => setFilter(e.target.value)}
        valueSearch={filter}
        handleAddButton={() =>
          navigate('/products/brands/add-brands', {
            state: { data: null, statusUrl: 'add' },
          })
        }
        progressPending={loading}
        paginationPerPage={limit}
        totalRow={totalData}
        onChangePage={(page) => setOffset(page)}
        onChangeRowsPerPage={(currentRowsPerPage) =>
          setLimit(currentRowsPerPage)
        }
        filter={
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <NormalText textAlign="start" fontSize={'16px'}>
              Filter Status
            </NormalText>
            <FormControl sx={{ minWidth: 250 }}>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{ height: 40 }}
              >
                <MenuItem value="">
                  <em>Pilih Status</em>
                </MenuItem>
                {statusFilter.map((value, index) => {
                  return <MenuItem value={value.id}>{value.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Stack>
        }
      />
    </MerchantLayout>
  );
};

export default UserListPage;
