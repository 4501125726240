import React, {useEffect, useState} from "react";
import BasicPopover from "src/components/Popover/basic";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { Box, Stack, Avatar } from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";
// import Sound from "src/assets/media/audio/notif.mp3";
import { setInterval } from "timers/promises";
import useRedux from 'src/redux/useRedux';
import { getList, OrderProps } from 'src/redux/order';
import moment from 'moment';
import { formatCurrency } from "src/helper/generalFunction";
import { useNavigate } from "react-router-dom";
import IconNotif from "src/assets/media/icons/ListNotif.svg";
import IconNewNotif from "src/assets/media/icons/ListNewNotif.svg";
import CloseIcon from '@mui/icons-material/Close';
import { updateNotification } from "src/redux/notification/notification.reducer"



const NotificationSection = () => {
  const [open, setOpen] = React.useState(false);
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(20);
  const [filter, setFilter] = useState('');
  const [oldData, setOldData] = useState<OrderProps[]>();
  const [countNotif, setCountNotif] = useState(0);
  moment.locale('en');


  const {
    thunkDispatch,
    storeState: { AppOption, Notification:{notifications} },
  } = useRedux();
  const navigate = useNavigate();

  const getTimeDiff = (timestamp:any) => {
    let a = moment();
    let b = moment(timestamp);
    let diff = a.diff(b, 'year');
    if (diff === 0) {
        diff = a.diff(b, 'month');
        if (diff === 0) {
            diff = a.diff(b, 'days');
            if (diff === 0) {
                diff = a.diff(b, 'hour');
                if (diff === 0) {
                    diff = a.diff(b, 'minute');
                    if (diff === 0) {
                        diff = a.diff(b, 'second');
                        return `${diff} detik`;
                    } else {
                        return `${diff} menit`;
                    }
                } else {
                    return `${diff} jam`;
                }
            } else {
                return `${diff} hari`;
            }
        } else {
            return `${diff} bulan`;
        }
    } else {
        return `${diff} tahun`;
    }
  };

  const lessThanSixHours = (timestamp:any) => {
    let a = moment();
    let b = moment(timestamp);
    let diffent = a.diff(b);
    return diffent < (1000 * 60 * 60 * 6) 
      ? true
      : false
  }


  
  let dataObject = {
    offset: offset - 1,
    limit: limit,
    filter: filter,
    store_id: AppOption.selected_store.id ? AppOption.selected_store.id.toString() : '',
    status: 'NEW_ORDER',
  };

  const getAll = () => {
    setLoading(true);
    thunkDispatch(getList(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == 'success') {
          if (res.data.data) {
            setOldData(res.data.data);
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const resetNotification = () => {
    let prop : {count_notif: number;} = {
      count_notif    : 0,
    }
    thunkDispatch(updateNotification(prop));
  }

  useEffect(() => {
    getAll();
  }, [AppOption])

  

  return (
    <>
      <a className="nav-link" data-toggle="dropdown">
        <StyledButton
          onClick={(e) => {
            setOpen(!open);
            setAnchor(e.currentTarget);
            setCountNotif(0);
            resetNotification();
          }}
        >
          <FontAwesomeIcon
            color={"rgba(0, 0, 0, 0.5)"}
            icon={faBell}
          ></FontAwesomeIcon>
          { notifications.count_notif && notifications.count_notif !== 0
            ? <span className="badge badge-danger badge-pill navbar-badge ml-2"><b>{notifications.count_notif}</b></span>
            : ""
          }
        </StyledButton>
      </a>
      <BasicPopover
        id="notification popover"
        anchorEl={anchor}
        open={open}
        handleClose={() => {
          setOpen(!open);
          setAnchor(null);
        }}
      >
        <Box width={"346px"} maxHeight={"430px"} textAlign={'left'} padding={'20px 13px'} borderRadius={"100%"}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <NormalText 
              fontSize="13px" 
              color={"black"} 
              margin={'0 0 3px 0'}
            >
              <b>Notifikasi</b>
            </NormalText>
            <CloseIcon sx={{cursor:"pointer"}} onClick={() => {
               setOpen(!open);
               setAnchor(null);    
            }}/>
          </Stack>
          <Box sx={{
            borderBottom:'1px solid rgba(0, 0, 0, 0.2)',
          }} mb={2} ></Box>
          <Box 
            sx={{
              cursor:"pointer",
              height:"340px",
              overflow: "hidden",
              overflowY:"scroll"
            }} 
          >
          { oldData && 
            oldData.length === 0 && 
              <Box textAlign={"center"}>
                <NormalText
                  fontStyle={'italic'}
                  color={"rgba(0, 0, 0, 0.5)"} 
                >
                  Oops, belum ada data
                </NormalText>
              </Box> 
          }

          { oldData && 
            oldData.length !== 0 &&
            oldData.map((order,index) =>
              <Box key={index} 
                onClick={() => navigate(`/orders/${order.order_number}/detail`)}
              >
                <Stack direction={"row"} justifyContent={"start"}>
                  <img
                    style={{ 
                      height: "45px", 
                      width: "45px", 
                      margin:"5px 13px 0 0" 
                    }}
                    src={ lessThanSixHours(order.payment_date) ? IconNewNotif : IconNotif }
                    alt="User Image"
                  />
                  <Stack direction={"column"}>
                    <NormalText 
                      fontSize="14px" 
                      color={"black"}
                    >
                      <b>{order.customer_name}</b> 
                    </NormalText>
                    <NormalText 
                      fontSize="12px" 
                      color={"rgba(0, 0, 0, 0.7)"}
                    >
                      Membuat pesanan baru dengan total harga <b>{formatCurrency(Number(order.total_amount_payment))}</b>
                    </NormalText>
                    <NormalText 
                      fontSize="12px" 
                      color={"rgba(0, 0, 0, 0.5)"} 
                      textAlign={'left'} 
                      margin={'0 0 8px 0'} 
                      fontStyle={'italic'}
                    > 
                      <Stack direction={"row"}>
                        {getTimeDiff(order.payment_date)} yang lalu  
                        <Avatar
                          sx={{
                            width: 5,height:5,margin:"7px" 
                          }}
                        /> 
                        {moment(order.payment_date).format('LT')}
                      </Stack>
                    </NormalText>
                  </Stack>
                </Stack>
                <Box sx={{
                  borderBottom:'1px solid rgba(0, 0, 0, 0.2)',
                  marginBottom:'5px',
                  width:'90%',
                  marginLeft:'5%',
                }}>
                </Box>
              </Box>
            
            )
          }
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor:"#0075FF",
            width:"346px",
            height:"45px",
            cursor:"pointer"
          }}
          onClick={() => navigate(`/notifications`)}
        >
          <NormalText 
            fontSize="14px" 
            fontWeight="700"
            color={"white"}
            textAlign={"center"}
            padding={"10px 0 0 0"}
          >
            Lihat Semua
          </NormalText>
        </Box>
      </BasicPopover>
    </>
  );
};

const StyledButton = styled.button`
  border: none;
  background: transparent;
  width: auto;
  height: auto;
`;

export default NotificationSection;
