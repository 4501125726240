import { createAsyncThunk } from '@reduxjs/toolkit';
import { ProfileState } from './types';
import ApiService from 'src/services/ApiService';
import { getToken } from 'src/helper/generalFunction';

export interface PasswordUpdate {
  data: {
    old_password?: string;
    password?: string;
    confirm_password?: string;
  };
  id_user: any;
}

export const updatePasswordUser = createAsyncThunk(
  'profil-user/update',
  async (props: PasswordUpdate) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.put(
        `${process.env.REACT_APP_API_URL}/users/merchant/update/${props.id_user}/password`,
        props.data,
        config,
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as ProfileState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as ProfileState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as ProfileState;
    }
  },
);

export const updatePasswordMerchant = createAsyncThunk(
  'profil-user/update',
  async (props: PasswordUpdate) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.put(
        `${process.env.REACT_APP_API_URL}/merchant/update/password`,
        props.data,
        config,
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as ProfileState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as ProfileState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as ProfileState;
    }
  },
);

export interface profileUpdate {
  data: {
    email?: string;
    name?: string;
    merchant_url?: any;
    phone?: string;
    address?: string;
    province_id?: number;
    city_id?: number;
    district_id?: number;
    subdistrict_id?: number;
    postal_code?: any;
  };
  id_user: any;
}

export const updateProfileMerchant = createAsyncThunk(
  'profil-user/update',
  async (props: profileUpdate) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.put(
        `${process.env.REACT_APP_API_URL}/merchant/update/profile`,
        props.data,
        config,
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as ProfileState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as ProfileState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as ProfileState;
    }
  },
);

export interface updateUser {
  data: {
    email?: string;
    first_name?: string;
    last_name?: string;
  },
  id_user: any;
};

export const updateProfileUser = createAsyncThunk(
  'profil-user/update',
  async (props: updateUser) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.put(
        `${process.env.REACT_APP_API_URL}/users/merchant/update/${props.id_user}/profile`,
        props.data,
        config,
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as ProfileState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as ProfileState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as ProfileState;
    }
  },
);
