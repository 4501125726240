import React from 'react';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import { useNavigate, useParams } from 'react-router-dom';
import useRedux from 'src/redux/useRedux';
import Card from 'src/components/Cards/card';
import { NormalText } from 'src/components/styled/text.styled';
import { Button } from 'src/components/styled/button.styled';
import {
  Stack,
  Box,
  Switch,
  Grid,
  TextField,
  Autocomplete,
  Modal,
} from '@mui/material';
import {
  getDetailProductStore,
  ProductStoreType,
} from 'src/redux/productStore';
import {
  getDetailProductAddOns,
  ProductAddOnDataDetailReponse,
  CreateproductAddOns,
  assignProductStore,
  updateProductStore,
  removeProductStore,
} from 'src/redux/productAddon';
import {
  getAdditionalProduct,
  ProductAdditional,
  getProductType,
  createNewProductType,
  ProductTypeProps,
} from 'src/redux/products';
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { IconX, IconPlus } from '@tabler/icons';
import { IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import ConfirmationModal from 'src/components/Modal/confirmation';
import styled from 'styled-components';
import checkPermission from 'src/helper/checkPermission';

const Container = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  background: white;
  min-height: 200px;
  transform: translate(-50%, -50%);
  width: 700px;
  border-radius: 10px;
  padding: 20px;

  &:focus {
    border: none !important;
  }

  @media only screen and (max-width: 350px) {
    width: 100%;
  }
`;

const AssignProductAddOn = () => {
  const navigate = useNavigate();
  const { key } = useParams();
  const { thunkDispatch } = useRedux();
  const [page, setPage] = React.useState(0);
  const [submit, setSubmit] = React.useState(false);
  const [limit, setLimit] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [isCreate, setIsCreate] = React.useState(true);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [deleteID, setDeleteID] = React.useState(0);
  const [product, setProduct] = React.useState<ProductStoreType>();
  const [payload, setPayload] = React.useState<CreateproductAddOns>({
    is_active: true,
    product_assign_id: 0,
    product_id: 0,
    product_type: '',
  });
  const [productAddons, setProductAddons] =
    React.useState<ProductAddOnDataDetailReponse>();
  const [additionals, setAdditionals] = React.useState<ProductAdditional[]>();
  const [productTypes, setProductTypes] = React.useState<ProductTypeProps[]>();
  const [val, setVal] = React.useState<ProductTypeProps>();
  const [product_type_name, setProductTypeName] = React.useState('');

  const handleUpdateStatus = (row: any) => {

    thunkDispatch(
      updateProductStore({
        ...row,
        product_id: Number(key),
        is_active: !row.is_active,
      }),
    )
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          toast.success('Berhasil mengubah status');
          fetchListAssignedAddon(Number(key));
        }
      })
      .catch((err) => {
        if (err.error) {
          console.log('err', err.error);
        }
      });
  };

  const handleAddModal = () => {
    setOpen(true);
    setPayload({
      ...payload,
      product_assign_id: 0,
      product_type: '',
    });
  };

  const handleUpdateClick = (store: any) => {
    setIsCreate(false);
    setPayload(store);
    setOpen(true);
  };

  const handleUpdate = () => {
    thunkDispatch(updateProductStore({ ...payload, product_id: Number(key) }))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          toast.success('Berhasil mengubah  data');
          fetchListAssignedAddon(Number(key));
          setOpen(false);
        }
      })
      .catch((err) => {
        if (err.error) {
          console.log('err', err.error);
        }
      });
  };

  const handleDelete = () => {
    thunkDispatch(removeProductStore(deleteID))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          toast.success('Berhasil menghapus produk add on');
          setDeleteModal(false);
          fetchListAssignedAddon(Number(key));
        }
      });
  };

  const columns = [
    {
      name: 'Product',
      selector: (row: any) => row.product_name,
      sortable: true,
    },
    {
      name: 'Tipe',
      selector: (row: any) => row.product_type,
      sortable: true,
    },
    {
      name: 'Status',
      center: true,
      cell: (row: any) => {
        return (
          <Switch
            checked={row.is_active}
            onChange={() => handleUpdateStatus(row)}
            disabled={checkPermission('Product Add On', 'is_edit')}
          />
        );
      },
    },
    {
      name: 'Opsi',
      center: true,
      cell: (row: any) => {
        return (
          <>
            {checkPermission('Product Add On', 'is_edit') && (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  handleUpdateClick(row);
                }}
              >
                <Edit />
              </IconButton>
            )}
            {checkPermission('Product Add On', 'is_delete') && (
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => {
                  setDeleteID(row.id);
                  setDeleteModal(true);
                }}
              >
                <Delete />
              </IconButton>
            )}
          </>
        );
      },
    },
  ];

  const handleSave = () => {
    thunkDispatch(assignProductStore(payload))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          toast.success('Berhasil menambah produk add-on');
          fetchListAssignedAddon(payload.product_id);
          setOpen(false);
        } else if (res && res.status === 'error') {
          if (res.error.response) {
            toast.warning(res.error.response.data.message);
          }
        }
      });
  };

  const fetchProductType = () => {
    thunkDispatch(getProductType())
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          setProductTypes(res.data.data);
        }
      });
  };

  const handleSaveProdcutType = () => {
    if (product_type_name && product_type_name.length > 0) {
      thunkDispatch(createNewProductType({ product_type: product_type_name }))
        .unwrap()
        .then((res) => {
          if (res && res.status === 'success') {
            toast.success('Berhasil manambahkan tipe produk');
            setPayload({ ...payload, product_type: product_type_name });
            fetchProductType();
            if (
              productTypes &&
              productTypes.find((x) => x.product_type === product_type_name)
            ) {

              setVal(
                productTypes.find((x) => x.product_type === product_type_name),
              );
            }
          } else {
            toast.warning(res?.error.response.data.message);
          }
        })
        .catch((e) => {
          console.log('ERROR', e);
        });
    } else {
      toast.warning('Produk tipe tidak boleh kosong');
    }
  };

  const fetchListAssignedAddon = (id: number) => {
    thunkDispatch(
      getDetailProductAddOns({
        product_id: id,
        limit: limit,
        offset: page,
      }),
    )
      .unwrap()
      .then((response) => {
        if (response && response.status === 'success') {
          setProductAddons(response.data);
        }
      });
  };

  const fetchDetail = () => {
    thunkDispatch(getDetailProductStore(Number(key)))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          setProduct(res.data.data);
          setPayload({ ...payload, product_id: res.data.data.product_id });
          fetchListAssignedAddon(res.data.data.product_id);
        }
      });
  };

  React.useEffect(() => {
    if (key) {
      fetchDetail();
    }
  }, []);

  React.useEffect(() => {
    fetchListAssignedAddon(Number(key));
  }, [limit, page]);

  React.useEffect(() => {
    fetchProductType();
    thunkDispatch(getAdditionalProduct())
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          setAdditionals(res.data.data);
        }
      });
  }, []);

  return (
    <MerchantLayout>
      <ToastContainer />
      <ConfirmationModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleConfirm={() => handleDelete()}
        description={'Apakah anda yakin ingin menghapus item ini?'}
      />
      <Modal
        sx={{ minHeight: 'auto' }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-description"
        aria-describedby="modal-modal-description"
      >
        <Container>
          <Box sx={{ backbround: 'white', width: '650px' }}>
            <Stack
              margin={'0 0 10px 0'}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <NormalText fontWeight="bold" fontSize={'18px'}>
                {!isCreate ? 'Ubah' : ''} Tambah Produk Add-On
              </NormalText>
              <IconX
                style={{ cursor: 'pointer' }}
                onClick={() => setOpen(false)}
              />
            </Stack>
            <NormalText
              textAlign="left"
              margin={'10px 0 5px 0'}
              fontSize={'12px'}
            >
              Pilih produk add-ons *
            </NormalText>
            <Autocomplete
              id="combo-box-product-additional"
              options={additionals ?? []}
              getOptionLabel={(option) => option.product_name || ''}
              onChange={(event: any, value: any) =>
                setPayload({ ...payload, product_assign_id: value.id ?? 0 })
              }
              sx={{ width: '100%' }}
              defaultChecked
              value={additionals?.find(
                (x) => x.id === payload.product_assign_id,
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ fontSize: 12 }}
                  size="small"
                  placeholder="Cari produk"
                  value={payload.product_assign_id}
                  defaultValue={payload.product_assign_id}
                />
              )}
            />
            <NormalText
              textAlign="left"
              margin={'10px 0 5px 0'}
              fontSize={'12px'}
            >
              Tipe produk *
            </NormalText>
            <Grid container spacing={1} alignItems={'center'}>
              <Grid item xs={10} sm={10} md={10}>
                <Autocomplete
                  id="combo-box-product-type"
                  options={productTypes ?? []}
                  getOptionLabel={(option) => option.product_type || ''}
                  onChange={(event: any, value: any) => {
                    setPayload({
                      ...payload,
                      product_type: value.product_type,
                    });
                  }}
                  selectOnFocus
                  clearOnBlur
                  value={productTypes?.find(
                    (x) => x.product_type === payload.product_type,
                  )}
                  defaultChecked={true}
                  sx={{ width: '100%' }}
                  noOptionsText={
                    'Anda dapat menambah tipe produk baru dengan menekan tombol + disamping'
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ fontSize: 12 }}
                      size="small"
                      defaultValue={payload.product_type}
                      value={payload.product_type}
                      placeholder="Cari / Tambah produk"
                      onChange={(e) => setProductTypeName(e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <Button
                  onClick={() => handleSaveProdcutType()}
                  width={'50px !important'}
                >
                  <IconPlus />
                </Button>
              </Grid>
            </Grid>
            <Stack direction={'row'} spacing={1} margin={'10px 0 0 0'}>
              <Button
                variant="primary"
                onClick={() => (isCreate ? handleSave() : handleUpdate())}
              >
                {isCreate ? 'Simpan' : 'Simpan Perubahan'}
              </Button>
              <Button variant="secondary" onClick={() => setOpen(false)}>
                Batal
              </Button>
            </Stack>
          </Box>
        </Container>
      </Modal>
      <Card>
        <NormalText fontWeight="bold" fontSize="20px" margin="0 0 20px 0">
          Assign Produk Add-On
        </NormalText>
        <NormalText fontSize="12px">Nama Produk</NormalText>
        <NormalText fontWeight="bold" fontSize="14px">
          {product?.product_name}
        </NormalText>
        {checkPermission('Product Add On', 'is_add') && (
          <Button
            variant="primary"
            onClick={() => {
              handleAddModal();
              setIsCreate(true);
            }}
            margin={'20px 0 20px 0'}
          >
            Tambah Produk Add-On
          </Button>
        )}

        <NormalText fontWeight="bold" fontSize="20px" margin="20px 0 10px 0">
          Daftar Produk Add-On
        </NormalText>
        <DataTable
          columns={columns}
          data={
            productAddons && productAddons.data && productAddons.data.length > 0
              ? productAddons.data
              : []
          }
          responsive
          pagination
          striped
          paginationRowsPerPageOptions={[5, 10, 25, 50]}
          paginationTotalRows={
            productAddons &&
              productAddons.meta &&
              productAddons.meta &&
              productAddons.meta.total
              ? productAddons.meta.total
              : 0
          }
          paginationPerPage={limit}
          onChangeRowsPerPage={(limit) => setLimit(limit)}
          onChangePage={(page) => setPage(page)}
        />
        <Button onClick={() => navigate('/products/product-addon')}>
          Kembali
        </Button>
      </Card>
    </MerchantLayout>
  );
};

export default AssignProductAddOn;
