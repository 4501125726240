import React, { FC, ReactNode } from "react";
import { Stack } from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";
import { CSSProperties } from "styled-components";
import { Input, BorderInput, Label } from "./styles";

export interface InputProps {
  id?: string;
  placeholder?: string;
  label?: ReactNode;
  minLength?: number;
  maxLength?: number;
  isRequired?: boolean;
  value?: string | number | readonly string[] | undefined;
  inputMode?:
    | "none"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search"
    | undefined;
  type?: string;
  margin?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onInvalid?: React.FormEventHandler<HTMLInputElement> | undefined;
  validationText?: string;
  validationType?: "ERROR" | "WARNING" | "INFO" | string | undefined;
  disabled?: boolean | undefined;
  defaultValue?: any;
  style?: CSSProperties;
  inputDirection?: string;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
}
export const InputField: FC<InputProps> = ({
  id,
  label,
  placeholder,
  minLength,
  maxLength,
  isRequired,
  value,
  inputMode,
  type,
  margin,
  onChange,
  onInvalid,
  validationText,
  validationType,
  disabled,
  style,
  defaultValue,
  inputDirection,
  onKeyUp,
}) => {
  return (
    <Stack margin={margin} direction="column">
      <BorderInput
        style={
          validationType
            ? { border: "1px solid #d51f40", margin: "0 0 5px 0" }
            : style
        }
      >
        <Label>{label}</Label>
        <Input
          id={id}
          textAlign={inputDirection}
          autoComplete="off"
          autoCorrect="off"
          aria-autocomplete="none"
          placeholder={placeholder}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            }
          }}
          inputMode={inputMode}
          type={type}
          minLength={minLength}
          maxLength={maxLength}
          required={isRequired}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
          onInvalid={onInvalid}
          onKeyUp={onKeyUp}
          autoFocus={validationType ? true : false}
        />
      </BorderInput>
      {validationType && (
        <Stack>
          <NormalText
            color={
              validationType === "ERROR"
                ? "#f12147"
                : validationType === "WARNING"
                ? "#E7B450"
                : validationType === "INFO"
                ? "#DDD8D8"
                : "white"
            }
            fontSize="12px"
            fontStyle="normal"
            fontWeight="400"
            margin={"0 0 5px 0"}
          >
            {validationText}
          </NormalText>
        </Stack>
      )}
    </Stack>
  );
};

export default InputField;
