import React, { useState, useEffect } from "react";
import { Stack } from "../../../components/styled/layout.styled";
import { useNavigate, useLocation } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import styles from "./styles";
import {
  Box,
  Button as ButtonMUI,
  FormHelperText,
  Switch,
  CircularProgress,
} from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";
import TextField from "src/components/Inputs/TextField";
// import { Button } from "src/components/styled/button.styled";
import { createCategory, updateCategory } from "src/redux/category";
import useRedux from "src/redux/useRedux";
import PopUp from "src/components/Modal/modal";
import { uploadImage } from "src/helper/uploadFile";
import styled from "styled-components";

interface RouteState {
  data: any;
  statusUrl: string;
}

const UploadLabel = styled.label`
  min-width: 9%;
  height: 40px;
  background-color: white;
  color: black;
  padding: 10px;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 2px;
  border: 1px solid #1985d2;
  text-align: center;
  &:hover {
    background-color: #1985d2;
    color: #fff;
  }
`;

const AddBrandPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { data, statusUrl } = location.state as RouteState;
  const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState("");
  const [dataAdd, setDataAdd] = useState({
    name: statusUrl === "edit" ? data?.category_name ?? "" : "",
    imageWeb: statusUrl === "edit" ? data?.image_web ?? "" : "",
    imageWebName: statusUrl === "edit" ? data?.image_web ?? "" : "",
    imageMobileName: statusUrl === "edit" ? data?.image_mobile ?? "" : "",
    imageMobile: statusUrl === "edit" ? data?.image_mobile ?? "" : "",
    metaTitle: "",
    metaDescription: "",
    metaKeyword: "",
    status: statusUrl === "edit" ? data?.is_active : false,
  });
  const [loading, setLoading] = useState(true);
  const [errorValidasi, setErrorValidasi] = useState({
    name: "",
    imageWeb: "",
    imageMobile: "",
    status: "",
    metaTitle: "",
    metaDescription: "",
    metaKeyword: "",
  });

  const [loadingImg, setLoadingImg] = useState(false);
  const [loadingImgIcon, setLoadingImgIcon] = useState(false);

  const {
    thunkDispatch,
    storeState: { Auth, Category, AddUser },
  } = useRedux();

  const handleEditUser = () => {
    let dataObject = {
      id: data?.id ?? "",
      data: {
        category_name: dataAdd.name,
        is_active: dataAdd.status,
        image_web: dataAdd.imageWeb,
        image_mobile: dataAdd.imageMobile,
      },
      access_token: Auth.data.access_token,
    };
    thunkDispatch(updateCategory(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("success");
        } else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("failed");
        }
      })
      .catch((err) => {
        setLoading(false);
        setStatusModal("failed");
        setOpenModal(true);
        console.error(err);
      });
    console.log(Category);
    setLoading(false);
  };

  const handleAddUser = () => {
    let dataObject = {
      id: "",
      data: {
        category_name: dataAdd.name,
        is_active: true,
        image_web: dataAdd.imageWebName,
        image_mobile: dataAdd.imageMobileName,
      },
      access_token: Auth.data.access_token,
    };
    thunkDispatch(createCategory(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("success");
        } else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("failed");
        }
      })
      .catch((err) => {
        setLoading(false);
        setStatusModal("failed");
        setOpenModal(true);
        console.error(err);
      });
    console.log(AddUser);
    setLoading(false);
  };

  const handleValidate = () => {
    if (!dataAdd.name) {
      setErrorValidasi({
        ...errorValidasi,
        name: !dataAdd.name ? "Nama Kategori Wajib Diisi" : "",
        imageWeb: "",
        imageMobile: "",
        metaTitle: !dataAdd.metaTitle ? "Judul Meta Wajib Diisi" : "",
        metaKeyword: !dataAdd.metaKeyword ? "Keyword Meta Wajib Diisi" : "",
        metaDescription: !dataAdd.metaDescription
          ? "Deskripsi Meta Wajib Diisi"
          : "",
      });
    } else if (dataAdd.name.length > 50) {
      setErrorValidasi({
        ...errorValidasi,
        name: dataAdd.name.length > 50 ? "Maks 50 karakter nama brands" : "",
        imageWeb: "",
        imageMobile: "",
        metaTitle: "",
        metaKeyword: "",
        metaDescription: "",
        status: "",
      });
    } else {
      setErrorValidasi({
        ...errorValidasi,
        name: "",
        imageWeb: "",
        imageMobile: "",
        metaTitle: "",
        metaKeyword: "",
        metaDescription: "",
        status: "",
      });
      statusUrl === "edit" ? handleEditUser() : handleAddUser();
    }
  };

  const handleUploadFile = async (file: any, typeUpload: string) => {
    typeUpload === "imageWeb" ? setLoadingImg(true) : setLoadingImgIcon(true);
    let key = typeUpload === "imageWeb" ? "category_web" : "category_mobile";
    let formdata = new FormData();
    formdata.append("image_count", "1");
    formdata.append("image-1", file);
    await uploadImage(formdata, "category")
      .then((res) => {
        if (res.status === 200) {
          setLoadingImg(false);
          setLoadingImgIcon(false);
          setDataAdd({
            ...dataAdd,
            [typeUpload]: res.data.data["image-1"],
            [`${typeUpload}Name`]: res.data.data["image-1"],
          });
        }
        setLoadingImg(false);
        setLoadingImgIcon(false);
      })
      .catch((err) => {
        setLoadingImg(false);
        setLoadingImgIcon(false);
        console.error(err);
        setErrorValidasi({
          ...errorValidasi,
          [typeUpload]: "Gagal mengupload image",
        });
      });
  };

  const handleImage = (e: any, data: string) => {
    if (e.target.files && e.target.files[0]) {
      let imageName = e.target.files[0];
      var img = new Image();
      let fileSize = imageName.size / (1024 * 1024);

      img.src = window.URL.createObjectURL(e.target.files[0]);

      img.onload = function () {
        var width = img.naturalWidth;
        var height = img.naturalHeight;
        let imageValidationWidth = data === "imageWeb" ? 360 : 300;
        let imageValidationHeight = data === "imageWeb" ? 120 : 300;
        if (
          width > imageValidationWidth ||
          height > imageValidationHeight ||
          fileSize > 5
        ) {
          setDataAdd({
            ...dataAdd,
            [data]: "",
            [`${data}Name`]: "",
          });
          setErrorValidasi({
            ...errorValidasi,
            [data]: `Dimensi tidak boleh lebih dari ${imageValidationWidth}x${imageValidationHeight} & Mask 5mb`,
          });
        } else {
          handleUploadFile(imageName, data).then((res: any) => {
            console.log("IMAGE UP OK");
          });
          setErrorValidasi({
            ...errorValidasi,
            [data]: "",
          });
        }
      };
    }
  };

  return (
    <MerchantLayout>
      <PopUp open={openModal} handleClose={() => setOpenModal(!openModal)}>
        <NormalText
          textAlign="center"
          fontWeight={"bold"}
          fontSize={"20px"}
          margin={"0 0 20px 0"}
        >
          {statusModal === "success" ? "Success" : "Failed"}
        </NormalText>
        <NormalText textAlign="center" fontSize={"14px"} margin={"0 0 20px 0"}>
          {statusModal === "success" ? (
            <span>
              Sukses {statusUrl === "edit" ? "Mengupdate" : "Menambah"} Kategori
            </span>
          ) : Category?.error?.response?.data?.message ? (
            <span>{Category.error.response.data.message}</span>
          ) : (
            <span>
              Gagal {statusUrl === "edit" ? "Mengupdate" : "Menambah"} Kategori,{" "}
              <br /> mohon periksa kembali form data anda
            </span>
          )}
        </NormalText>
        <Box textAlign={"center"} margin={"20px 0 0 0 "}>
          <ButtonMUI
            variant="contained"
            onClick={() =>
              statusModal === "success"
                ? navigate("/products/categories")
                : setOpenModal(!openModal)
            }
            sx={{ width: 100, height: 40, marginRight: 3 }}
            color="error"
          >
            Oke
          </ButtonMUI>
        </Box>
      </PopUp>
      <Stack direction="column">
        <Box sx={styles.boxStyled}>
          <NormalText
            textAlign="start"
            fontWeight={"bold"}
            fontSize={"18px"}
            margin={"0 0 20px 0"}
          >
            {statusUrl === "edit" ? "Edit Kategori" : "Tambah Kategori"}
          </NormalText>
          <form>
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Nama Kategori *
            </NormalText>
            <TextField
              type={"text"}
              style={{ height: 45 }}
              value={dataAdd.name}
              onChange={(event: any) =>
                setDataAdd({
                  ...dataAdd,
                  name: event.target.value,
                })
              }
              validationType={errorValidasi.name ? "ERROR" : ""}
              validationText={errorValidasi.name}
              placeholder="Masukkan nama kategori anda"
            />
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Gambar Web
            </NormalText>
            <form>
              <UploadLabel
                htmlFor="imageUploadWeb"
                style={{
                  backgroundColor: loadingImg ? "#ccc" : "",
                  borderColor: loadingImg ? "#ccc" : "",
                }}
              >
                {loadingImg ? <CircularProgress size={15} /> : null}{" "}
                {loadingImg ? "" : "Pilih Gambar"}
              </UploadLabel>
              <input
                accept="image/*"
                id="imageUploadWeb"
                multiple
                type="file"
                onChange={(e: any) => handleImage(e, "imageWeb")}
                style={{ display: "none" }}
              />
              {dataAdd.imageWeb && (
                <img
                  src={dataAdd.imageWeb}
                  style={{ height: 160, width: 180, marginLeft: "30px" }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              )}
            </form>
            <FormHelperText style={{ color: "#1565c0" }}>
              Resolusi gambar maks. 360x120 Pixels {"&"} ukuran maks. 5MB
            </FormHelperText>
            <FormHelperText style={{ color: "#f12147" }}>
              {errorValidasi.imageWeb}
            </FormHelperText>
            <NormalText
              textAlign="left"
              margin={"20px 0 5px 0"}
              fontSize={"14px"}
            >
              Icon
            </NormalText>
            <form>
              <UploadLabel
                htmlFor="imageIcon"
                style={{
                  backgroundColor: loadingImgIcon ? "#ccc" : "",
                  borderColor: loadingImgIcon ? "#ccc" : "",
                }}
              >
                {loadingImgIcon ? <CircularProgress size={15} /> : null}{" "}
                {loadingImgIcon ? "" : "Pilih Gambar"}
              </UploadLabel>
              <input
                accept="image/*"
                id="imageIcon"
                multiple
                type="file"
                onChange={(e: any) => handleImage(e, "imageMobile")}
                style={{ display: "none" }}
              />
              {dataAdd.imageMobile && (
                <img
                  src={dataAdd.imageMobile}
                  style={{ height: 150, width: 150, marginLeft: "30px" }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              )}
            </form>
            <FormHelperText style={{ color: "#1565c0" }}>
              Resolusi gambar maks. 300x300 Pixels {"&"} ukuran maks. 5MB
            </FormHelperText>
            <FormHelperText style={{ color: "#f12147" }}>
              {errorValidasi.imageMobile}
            </FormHelperText>
            {statusUrl === "edit" ? (
              <>
                <NormalText
                  textAlign="left"
                  margin={"20px 0 5px 0"}
                  fontSize={"14px"}
                >
                  Status
                </NormalText>
                <Switch
                  checked={dataAdd.status}
                  onChange={(e: any) =>
                    setDataAdd({
                      ...dataAdd,
                      status: e.target.checked,
                    })
                  }
                />
                <FormHelperText style={{ color: "#f12147" }}>
                  {errorValidasi.status}
                </FormHelperText>
              </>
            ) : null}

            <Box textAlign={"start"} margin={"20px 0 0 0 "}>
              <ButtonMUI
                variant="contained"
                onClick={() => navigate("/products/categories")}
                sx={{ width: 100, height: 40, marginRight: 3 }}
                color="error"
              >
                Kembali
              </ButtonMUI>
              <ButtonMUI
                variant="contained"
                onClick={() => handleValidate()}
                sx={{ width: 100, height: 40 }}
              >
                Simpan
              </ButtonMUI>
            </Box>
          </form>
        </Box>
      </Stack>
    </MerchantLayout>
  );
};

export default AddBrandPage;
