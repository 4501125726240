import React, {useEffect, useState} from "react";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import { Box, Avatar, Stack, Chip} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NormalText } from "src/components/styled/text.styled";
import styled from "styled-components";
import useRedux from "src/redux/useRedux";
import { getList, OrderProps } from 'src/redux/order';
import moment from 'moment';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import IconNotif from "src/assets/media/icons/ListNotif.svg";
import { formatCurrency } from "src/helper/generalFunction";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

const AllNotifications = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  // const [open, setOpen] = React.useState(false);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(20);
  const [filter, setFilter] = useState('');
  const [selectedButton, setSelectedButton] = useState("semua");
  const [dataOrder, setDataOrder] = useState<OrderProps[]>();
  const { 
    thunkDispatch,
    storeState: { Auth, AppOption, Notification:{notifications} },
   } = useRedux();
  moment.locale('en')

  const getTimeDiff = (timestamp:any) => {
    let a = moment();
    let b = moment(timestamp);
    let diff = a.diff(b, 'year');
    if (diff === 0) {
        diff = a.diff(b, 'month');
        if (diff === 0) {
            diff = a.diff(b, 'days');
            if (diff === 0) {
                diff = a.diff(b, 'hour');
                if (diff === 0) {
                    diff = a.diff(b, 'minute');
                    if (diff === 0) {
                        diff = a.diff(b, 'second');
                        return `${diff} detik`;
                    } else {
                        return `${diff} menit`;
                    }
                } else {
                    return `${diff} jam`;
                }
            } else {
                return `${diff} hari`;
            }
        } else {
            return `${diff} bulan`;
        }
    } else {
        return `${diff} tahun`;
    }
  };

  const lessThanSixHours = (timestamp:any) => {
    let a = moment();
    let b = moment(timestamp);
    let diffent = a.diff(b);
    return diffent < (1000 * 60 * 60 * 6) 
      ? true
      : false
  }

  let dataObject = {
    offset: offset - 1,
    limit: limit,
    filter: filter,
    store_id: AppOption.selected_store.id ? AppOption.selected_store.id.toString() : '',
    status: 'NEW_ORDER',
  };

  const getAll = () => {
    setLoading(true);
    thunkDispatch(getList(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == 'success') {
          if (res.data.data) {
            setDataOrder(res.data.data);
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  useEffect(() => {
    getAll();
  }, [AppOption]);

  return (
    <MerchantLayout>
      <Box
        sx={{
          height: "670px",
          backgroundColor: "white",
          justifyContent: "center",
          border: "0 solid rgba(0,0,0,.125)",
          borderRadius: "0.25rem",
        }}
      >
        <Box>
            <Stack direction="row" justifyContent={"space-between"}>
                <Stack direction="row">
                    <Avatar  
                      sx={{ 
                        width: 28, 
                        height: 28,
                        margin:"40px 15px 10px 30px",
                        cursor:"pointer", 
                      }}
                      onClick={() => navigate(-1)}
                    >
                      <ArrowBackIosNewIcon color="inherit"></ArrowBackIosNewIcon>
                    </Avatar>
                    <NormalText fontSize="28px" fontWeight="700" margin="35px 0 0 0">
                        Notifikasi
                    </NormalText>
                </Stack>
                <Stack direction="row">
                    <Box
                        sx={{
                            backgroundColor: selectedButton === 'semua'? "#0075FF" : "#FFFFFF",
                            border:"1px solid #0075FF",
                            borderRadius:"40px",
                            height:"40px",
                            padding:"10px 30px",
                            display:"flex",
                            alignItems:"center",
                            cursor:"pointer",
                            margin:"35px 10px 0 10px"
                        }}
                        onClick={()=> setSelectedButton('semua')}
                    >
                        <NormalText 
                            fontWeight="700" 
                            fontSize="18px" 
                            color={selectedButton === 'semua' ? "#FFFFFF" : "#0075FF"}
                        >
                            Semua
                        </NormalText>
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: selectedButton === 'update' ? "#0075FF" : "#FFFFFF",
                            border:"1px solid #0075FF",
                            borderRadius:"40px",
                            height:"40px",
                            padding:"10px 30px",
                            display:"flex",
                            alignItems:"center",
                            cursor:"pointer",
                            margin:"35px 20px 0 10px"
                        }}
                        onClick={()=> setSelectedButton('update')}
                    >
                        <NormalText 
                            fontWeight="700" 
                            fontSize="18px" 
                            color={selectedButton === 'update' ? "#FFFFFF" : "#0075FF"}
                        >
                            Update
                        </NormalText>
                    </Box>
                </Stack>
            </Stack>
        </Box>
        <Box 
          sx={{
            borderBottom:'1px solid #CCCCCC',
            width:'95%',
            marginLeft:'3%',
            marginTop:'20px',
          }}>
        </Box>
        <Box 
          sx={{
            // display:"flex",
            // flexDirection:"row",
            width:"95%",
            height:"560px",
            backgroundColor:"#FFFFFF",
            marginTop:"20px",
            marginLeft:"3%",
            boxShadow:"0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)",
            borderRadius:"15px",
            overflow: "hidden",
            overflowY:"scroll"
          }}
        >
        { dataOrder && 
          dataOrder.length === 0 &&
            <Box textAlign={"center"}>
              <NormalText
                fontSize="20px"
                fontStyle={'italic'}
                color={"rgba(0, 0, 0, 0.5)"} 
                margin={"30px"}
              >
                Oops, belum ada data
              </NormalText>
            </Box> 
        }

        { dataOrder && 
          dataOrder.length !== 0 &&
          dataOrder.filter((order, index) => selectedButton === "update"
            ? lessThanSixHours(order.payment_date)
            : true)
          .map((order,index) => 
            <Box
              key={index}
              sx={{
                width:"97%",
                height:"150px",
                backgroundColor: lessThanSixHours(order.payment_date) ? "#FEF6F0" : "#FFFFFF",
                marginTop:"2%",
                marginLeft:"1.5%",
                boxShadow:"0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)",
                borderRadius:"10px",
                cursor:"pointer",
              }}
              onClick={() => navigate(`/orders/${order.order_number}/detail`)}
            >
                <Box
                  sx={{
                    paddingTop:"25px",
                    paddingLeft:"20px",
                    paddingRight:"10px",
                  }}
                >
                    <Stack direction={"row"}>
                        <NormalText 
                            fontSize="14px"
                            color={"rgba(0, 0, 0, 0.5)"} 
                            textAlign={'left'} 
                            margin={'0 0 8px 0'} 
                            fontStyle={'italic'}
                        >
                          {getTimeDiff(order.payment_date)} yang lalu 
                        </NormalText>
                        <Box 
                            sx={{
                                width:"5px",
                                height:"5px",
                                borderRadius:"2px",
                                backgroundColor:"#CCCCCC",
                                margin:"8px",
                            }}
                        />
                        <NormalText 
                            fontSize="14px"
                            color={"rgba(0, 0, 0, 0.5)"} 
                            textAlign={'left'} 
                            margin={'0 10px 8px 0'} 
                            fontStyle={'italic'}
                        >
                          {moment(order.payment_date).format('LT')}
                        </NormalText>
                        { lessThanSixHours(order.payment_date)
                            ? <Chip label="Update" color="error" size="small"></Chip>
                            : ""
                        }
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Stack direction={"row"}>
                            <img
                                style={{ 
                                    height: "50px", 
                                    width: "50px", 
                                    margin:"5px 13px 0 0" 
                                }}
                                src={IconNotif}
                                alt="Icon Notif"
                            />
                            <Stack direction={"column"}>
                                <NormalText
                                    fontSize="22px"
                                    fontWeight="700px"
                                >
                                    <b>{order.customer_name}</b>
                                </NormalText>
                                <NormalText
                                    fontSize="18px"
                                    fontWeight="700px"
                                >
                                    Membuat pesanan baru dengan total harga <b>{formatCurrency(Number(order.total_amount_payment))}</b> 
                                </NormalText>
                            </Stack>
                        </Stack>
                        <Box
                            sx={{
                                margin:"20px"
                            }}
                        >
                            <Stack direction={"row"} >
                                <NormalText
                                    fontSize="16px"
                                    fontWeight="700px"
                                    color="#1E6B57"
                                    margin="0 13px"
                                >
                                    <b>Lihat Detail</b>
                                </NormalText>
                                <ArrowCircleRightIcon color="success"/>
                            </Stack>
                        </Box>

                    </Stack>

                </Box>
            </Box>

        )}
        </Box>

        
      </Box>
    </MerchantLayout>
  );
};

const Badge = styled.div`
  background: #009d0e;
  font-size: 10px !important;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 20px;
  color: white;
`;

const CustomButton = styled.div`
  background: 
`;

export default AllNotifications;
