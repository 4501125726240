import * as React from "react";
import Popover from "@mui/material/Popover";

interface Props {
  id?: string | undefined;
  open: boolean;
  handleClose: any;
  anchorEl?: Element | ((element: Element) => Element) | null | undefined;
  children?: React.ReactNode;
}

const BasicPopover = ({ id, open, handleClose, anchorEl, children }: Props) => {
  return (
    <Popover
      anchorEl={anchorEl}
      style={{ borderRadius: "30px"}}
      id={id}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      {children}
    </Popover>
  );
};

export default BasicPopover;
