import { ProductsState, ProductResponseProps } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { getAllProduct } from './product.services';

export const initialState: ProductsState = {
    data: {} as ProductResponseProps,
    status: "loading",
    error: {}
}

export const StoreSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllProduct.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getAllProduct.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(getAllProduct.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
    }
})

export const StoreListReducer = StoreSlice.reducer