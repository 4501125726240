import DataTable from "react-data-table-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  PaginationChangePage,
  ExpandableRowsComponent,
} from "react-data-table-component/dist/src/DataTable/types";

interface Props {
  columns?: any;
  records?: any;
  progressPending?: boolean;
  paginationPerPage?: number;
  onChangeRowsPerPage?(currentRowsPerPage: number, currentPage: number): any;
  onChangePage?: PaginationChangePage | undefined;
  totalRow?: number;
  expandableRows?: boolean | false;
  expandableRowDisabled?(e: any): any | false;
  expandableRowsComponent?: ExpandableRowsComponent<any> | undefined;
}

const UpdateMember = ({
  columns,
  records,
  progressPending,
  paginationPerPage,
  onChangeRowsPerPage,
  onChangePage,
  totalRow,
  expandableRows,
  expandableRowDisabled,
  expandableRowsComponent,
}: Props) => {
  return (
    <>
      <DataTable
        columns={columns}
        data={records}
        responsive
        pagination
        striped
        noDataComponent={
          <p style={{ margin: "20px 0", color: "grey" }}>
            Oops, belum ada data
          </p>
        }
        paginationTotalRows={totalRow}
        paginationRowsPerPageOptions={[5, 10, 25, 50]}
        progressPending={progressPending}
        progressComponent={<CircularProgress />}
        paginationPerPage={paginationPerPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        paginationServer
        expandableRows={expandableRows}
        persistTableHead
        expandableRowDisabled={expandableRowDisabled}
        expandableRowsComponent={expandableRowsComponent}
      />
    </>
  );
};

export default UpdateMember;
